body {
  margin: 0;
  font-family: 'Muli', sans-serif;
}

code {
  font-family: 'Muli', sans-serif;
}

/* #myVideo {
  background-color: #ACB2F2;
} */