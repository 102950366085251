@import url('https://fonts.googleapis.com/css?family=Muli:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
    font-family: 'OmnesRegular';
    src: url('../fonts/Omnes-Regular.woff') format('woff');
    font-weight: normal; /* Regular weight */
}
@font-face {
    font-family: 'OmnesMedium';
    src: url('../fonts/Omnes-Medium.woff') format('woff');
    font-weight: 500; /* Medium weight */
}
@font-face {
    font-family: 'OmnesSemiBold';
    src: url('../fonts/Omnes-SemiBold.woff') format('woff');
    font-weight: 600; /* Semibold weight */
}
@font-face {
    font-family: 'OmnesBold';
    src: url('../fonts/Omnes-Bold.woff') format('woff');
    font-weight: bold; /* Bold weight */
}

html::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #FAFAFA !important;
    z-index: -1; 
}

/* Prevent default black background on overscroll in Safari */
html, body {
    -webkit-overflow-scrolling: touch; /* Enable smooth scrolling */
    overscroll-behavior: contain; /* Control overscroll behavior */
    background-attachment: fixed; /* Fix background attachment */
    background-color: #FAFAFA !important; 
    padding: 0px !important;
    touch-action: pan-y;
    /* overflow-x: hidden !important; */
    /* touch-action: pan-y; */
}

/* body, html {
    padding: 0px !important;
    overflow-x: hidden !important;
    touch-action: pan-y;
    background-color: transparent !important;
    background: transparent !important;
} */

.container {
    width: 395px;
    margin: 0 auto;
}
.row {
    padding: 0px 12px;
}
@media screen and (max-width: 868px) {
  .siteSidebar{
    left: 568px !important;
    padding: 0px 21px 5px 15px !important;
    width: 194px !important;
    height: 300px !important;
  }
}
@media screen and (max-width: 600px) {
  .container {
        position: relative;
        width: 100% !important;
        height: auto !important;
  } 
  .frameSectionUpr .container {
    width: 100% !important;
  }
  .row {
       padding: 0px !important;
  }
  .webMenu, .videoRecord, .shrScrenTabs {
       width: 100% !important;
  }
  .program-page-container, .program-page {
    max-width: 100% !important;
    width: 100% !important;
  }
}
.modal-content {
    background-color: transparent !important;
    border: none !important;
}
.shrScrenTabs.shrScrenRmvBg .nav-tabs .nav-item {
    margin-bottom: 0px !important;
}

/* Sign up css */
.sign_up h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #4E4E4E;
    opacity: 0.5;
    margin-bottom: 28px;
    margin-top: 0px;
    font-family: 'OmnesMedium';
}
.sign_up h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 27px;
    text-align: center;
    color: #4E4E4E;
    /* margin-top: 36px; */
    margin-top: 0px;
    font-family: 'OmnesSemiBold';
}
.signup_form input:focus{
    border-bottom: 2px solid #FC6172;
    color: #FC6172;
    opacity: 1;
}
.signup_form h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 27px;
    text-align: center;
    color: #4E4E4E;
    padding-top: 14px;
    margin-bottom: 38px;
    font-family: 'OmnesSemiBold';
} 
.signupClose {
    top: 30px;
    position: absolute;
    right: 16px;
    cursor: pointer;
}

.signup_form input[type="text"],
.signup_form input[type="email"]{
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #ffffff !important;
    color: #242424;
    outline: none;
    font-stretch: normal;
    letter-spacing: normal;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    padding: 15px 17px;
    border-radius: 15px;
    font-family: 'OmnesMedium';
    max-height: 52px !important;
    height: initial !important;
}

.actvAccountImg {
    text-align: center;
}
.actvAccountImg {
    margin: 34px 0px 34px;
}
.signup_form select{
  font-weight: 500;
  width: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  font-style: normal;
  /* font-stretch: normal;
  line-height: normal; */
  letter-spacing: normal;
  color: #000000;
  font-family: 'OmnesMedium';
  text-transform: capitalize;
  padding-right: 0px;
}
.signup_form input[type="email"], .signup_form textarea{
  text-transform: initial !important;
}

/* .signup_first, .signup_last{
  width:100%;
  margin-bottom: 8px;
  margin-top: 4px;
} */

.signup_form label{
    font-family: 'OmnesRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    align-items: center;
    color: #242424 !important;
    opacity: 0.4;
}
.signup_logo{
  text-align: center;
}
.signup_logo img {
  padding: 0px;
  margin-bottom: 35px;
  cursor: pointer;
}
.signup_logo h1{
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  /* font-stretch: normal;
  line-height: normal; */
  letter-spacing: 5px;
  text-align: center;
  text-transform: uppercase;
  color: #1ba3d9;
  margin-bottom: 11px;
  font-family: 'OmnesMedium';
}
.common_main {
    padding: 12px 22px;
    position: relative;
    padding-bottom: 35px;
    background-image: url('../images/cmnYellowBg.svg');
    background-size: auto;
    background-position: top left;
    height: auto;
    min-height: 100vh !important;
    background-repeat: no-repeat;
    background-color: transparent !important;
    /* background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, #F9EAE2 0%, #F7E2E1 100%); */
    /* background-attachment: fixed; */
    /* background: linear-gradient(180deg, #ffffff 0, #f9eae2e8 33.333%, #F9EAE2 33.333%, #F9EAE2 66.666%, #F9EAE2 66.666%, #F7E2E1 100%); */
    /* background: linear-gradient(180deg, #FFFFFF 0%, #FFF1F0 81.25%); */
    /* background: linear-gradient(180deg, #FFFFFF 64%, #F2F2F2 100%); */
}
.common_mainAddOn {
    background: linear-gradient(180deg, #FFFFFF 0%, #F2F2F2 81.25%);
}
.signup_inner {
  padding: 30px 36px 30px;
  background-color: #ffffff; 
  border-radius: 10px;
  box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.1);
}
.signup_submit button{
    color: #ffffff;
    outline: none !important;
    margin-top: 28px;
    height: 52px;
    background: #F47C6A !important;
    border-radius: 30px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
    position: relative;
    font-family: 'OmnesSemiBold';
}
.signup_submit button:after {
    position: absolute;
    width: 88%;
    height: 7px;
    left: 0px;
    right: 0px;
    bottom: -2px;
    background: linear-gradient(117.02deg, #FF5A6C -28.17%, #F27381 366.38%);
    filter: blur(7px);
    transform: matrix(1, 0, 0, -1, 0, 0);
    content: "";
    margin: 0 auto;
}
.signup_form {
  text-align: center;
}
.signup_form div:before, .signup_form div:after {
    content: none !important;
}
.sign_up_declare p {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    text-align: center;
    color: #242424;
    opacity: 0.5;
    margin-bottom: 0px;
    font-family: 'OmnesRegular';
}
.sign_up_declare {
    margin-top: 24px;
}
.sign_up {
    margin-bottom: 28px;
    text-align: center;
    margin-top: 20px;
}
/* Sign up css end */

/* Sign up forget email css */
.signforget_email {
    text-align: center;
    margin-top: 15%;
}
.signforget_email p {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    text-transform: capitalize;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    font-family: 'OmnesSemiBold';
    color: #242424;
    opacity: 0.4;
    cursor: pointer;
}

.email_account {
    width: 100%;
    background: #FFFFFF;
    border-radius: 20px;
    text-align: center;
    padding: 21px 27px 30px;
    margin-top: 45px;
}
.email_account_content h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    /* letter-spacing: -0.02em; */
    color: #914949;
    font-family: 'OmnesSemiBold';
    margin-top: 36px;
    margin-bottom: 22px;
}
.email_account_content p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #242424;
    margin-bottom: 0px;
    font-family: 'OmnesMedium';
}
button.button_email {
    color: #ffffff;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    padding: 12px 34px;
    background: -webkit-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: -o-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: linear-gradient(158.95deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 90%;
    margin-top: 65px;
}
button.button_chat {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    padding: 0px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    color: #242424;
    margin-top: 16px;
    background: transparent !important;
    outline: none !important;
}
/* End of Sign up forget email css  */

/* Home page css */
.logo_upr {
    text-align: center;
}
.logo_upr img {
    cursor: pointer;
}
.logo_upr p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    align-items: center;
    text-align: center;
    color: #242424;
    opacity: 0.5;
    margin: 0px;
    font-family: 'OmnesRegular';
    margin-top: 8px;
}
.signup_opt button {
    color: #ffffff;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    /* padding: 12px 34px; */
    /* background: linear-gradient(
    158.95deg, #FC6171 -28.17%, #F27381 366.38%); */
    background: #F47C6A !important;
    border-radius: 30px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    height: 52px
}
.signup_submit {
    text-align: center;
}
.login_opt button {
    background-color: #ffffff80 !important;
    color: #242424;
    outline: none !important;
    border-radius: 30px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    min-height: 52px;
    font-family: 'OmnesSemiBold';
}
.login_opt {
    text-align: center;
    margin-top: 16px;
}
/* .login_opt button:hover {
    background: transparent !important;
    background-color: transparent !important;
    color: #fd5e6f;
} */
.signup_opt {
    text-align: center;
    /* margin-top: 23%; */
}
.social_icon {
    display: none;
    text-align: center;
    margin-top: 4%;
}
.social_icon a {
    display: inline-block;
    margin: 0px 6px;
    cursor: pointer;
}
.social_icon p {
    font-family: 'OmnesRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    align-items: center;
    text-align: center;
    color: #242424;
    opacity: 0.5;
    margin-bottom: 22px;
}

 /* End Home page css  */


 /* login css */
.log_up h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #4E4E4E;
    opacity: 0.5;
    margin-bottom: 28px;
    margin-top: 0px;
    font-family: 'OmnesMedium';
}
.logup_form input{
    font-weight: 500;
    width: 100%;
    border: none;
    border-bottom: 2px solid #FC6172;
    outline: none;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'OmnesMedium';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    display: -ms-flexbox;
    -ms-flex-align: center;
    align-items: center;
    color: #FC6172;
}
.logup_form input[type="email"], .logup_form textarea{
  text-transform: initial !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #ffffff !important; 
}
.logup_first, .logup_last{
  width:100%;
  margin-bottom: 8px;
  margin-top: 4px;
}
.logup_form label{
    font-stretch: normal;
    font-family: 'OmnesRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    align-items: center;
    color: #242424 !important;
    opacity: 0.4;
}
.logup_logo{
  text-align: center;
}
.logup_logo img {
  padding: 0px;
  margin-bottom: 35px;
  cursor: pointer;
}
.logup_logo h1{
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 5px;
  text-align: center;
  text-transform: uppercase;
  color: #1ba3d9;
  margin-bottom: 11px;
  font-family: 'OmnesMedium';
}
.logup_inner {
  padding: 30px 36px 30px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.1);
}
.logup_submit button{
    color: #ffffff;
    outline: none !important;
    margin-top: 15px;
    padding: 12px 34px;
    background: #F47C6A !important;
    border-radius: 30px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
    min-height: 52px;
    font-family: 'OmnesSemiBold';
}
.logup_form {
    text-align: center;
    margin-top: 71px;
}
.logup_form div:before, .logup_form div:after {
    content: none !important;
}
.log_up_declare p {
    font-family: 'OmnesRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    text-align: center;
    color: #242424;
    opacity: 0.5;
}
.log_up_declare {
    margin-top: 28px;
}
.log_up {
    margin-bottom: 28px;
    margin-top: 20px;
}
.forget_email p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-transform: capitalize;
    align-items: center;
    text-align: center;
    color: #4E4E4E;
    opacity: 0.5;
    cursor: pointer;
    font-family: 'OmnesSemiBold';
}
.forget_email {
    text-align: center;
    margin-top: 60%;
}

.backlink {
    top: 30px;
    position: absolute;
    left: 18px;
    cursor: pointer;
}
.clearlink {
    top: 30px;
    position: absolute;
    right: 18px;
    cursor: pointer;
}

.enter_pass_click p{
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    font-family: 'OmnesSemiBold';
    color: #242424;
    cursor: pointer;
}
.enter_pass_click {
    margin-top: 35px;
}
/* login css end */

/* Enter password css */
.show_password, .show_password div {
    width: 100% !important;
    position: relative;
}
.type_password div div  {
    position: absolute;
    right: 0;
    top: -5px;
  }
.type_password div svg, .type_password div img{
    position: absolute;
    right: 10px;
    top: 20px;
    color: #7a3e41;
  }
  .show_password {
    width: 100% !important;
  }
  /* End of password css */

/* sent css */
 
.sent_content h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 27px;
    text-align: center;
    color: #4E4E4E;
    margin-bottom: 7px;
    font-family: 'OmnesMedium';
}
.sent_content p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #8d8d8d;
    align-items: center;
    margin: 0px;
    font-family: 'OmnesRegular';
}
.sent_content p strong {
    /* color: #4E4E4E; */
    color: #000;
}
.load_more button {
    /* background: #ee5c6e;
    color: #ffffff;
    outline: none !important;
    padding: 12px 34px;
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 90%; */

    color: #ffffff;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    padding: 12px 34px;
    background: linear-gradient(
158.95deg
, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 292px;
    box-shadow: 0px 3px 13px rgb(252 97 114 / 65%);
    margin: 22px 0px;
}
.load_more {
    text-align: center;
}
.sent_content {
    margin-top: 8px;
    padding: 0px 8%;
}
.sentLinkIcon {
    text-align: center;
    margin-top: 28%;
}
/* End of sent css */

/* Model css */
.custom_Addchildmodel .modal-header {
    border: none !important;
    color: #ffffff;
    text-align: center;
    display: block;
    padding-right: 0px;
  }
  .custom_Addchildmodel .modal-content {
    background-color: transparent !important;
    width: 100%;
  }
  .custom_AddchildBody {
    font-family: 'Montserrat', sans-serif;
    min-height: 538px;
  }
  .Addchild_content {
    font-family: 'Montserrat', sans-serif;
    position: absolute;
    width: 100%;
    bottom: 0;
    text-align: center;
    margin: 0 auto;
  }
  .Addchild_content p {
    font-size: 13px;
    margin-bottom: 12px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
  }
  .Addchild_content button{
    font-family: 'OmnesSemiBold';
    font-style: normal;
    width: 100%;
    background-color: #ffffff;
    font-weight: 600;
    margin-top: 16px;
    padding: 7px 8px;
    outline: none !important;
    color: #000000;
    -webkit-box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.1);
    text-transform: initial;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.38px;
    color: #0076FF;
}
  .addChild_cancel{
    color: #ffffff !important;
    background-color: #1ba3d9 !important;
  }
  .addChild_cancel:hover {
    background-color: #1c8fbd !important;
  }
  .custom_Addchildmodel .modal-content {
    border: none !important;
  }
  .Addchild_content button:hover {
    color: #F27381;
    background-color: #ffffff !important;
  }
  @media (min-width: 576px){
    .modal-dialog {
        max-width: 325px !important;
        margin: 1.75rem auto;
    }
  }
  /* End of Model css */

  /* open account model css */
  .open_account {
    width: 100%;
    background: #FFFFFF;
    border-radius: 20px;
    text-align: center;
    padding: 21px 27px 30px;
    margin-top: 45px;
}
/* .open_account_content h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #914949;
    font-family: 'OmnesRegular';
} */
.open_account_content p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #242424;
    margin-bottom: 0px;
    opacity: 0.5;
    font-family: 'OmnesSemiBold';
}
.open_account_content h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    /* letter-spacing: -0.02em; */
    color: #914949;
    font-family: 'OmnesSemiBold';
    margin-top: 41px;
    margin-bottom: 22px;
}
.open_account button{
    color: #ffffff;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    padding: 12px 34px;
    background: -webkit-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: -o-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: linear-gradient(158.95deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 90%;
    margin-top: 78px;
}
.open_account_img {
    margin-top: 32px;
}
/* End account model css */

/* your gender */
.signup_gender {
    text-align: center;
    margin: 40px 0px 0px;
}
.signup_gender button {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    align-items: center;
    text-align: center;
    color: #242424;
    background: #ffffff !important;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    outline: none!important;
    border-radius: 30px;
    text-transform: capitalize;
    margin: 0px 10px;
    height: 52px;
    min-width: 132px;
    font-family: 'OmnesSemiBold';
}
button.acountFemaleBtn {
    color: #AE86E0 !important;
}
button.acountMaleBtn {
    color: #66B0C4 !important;
}
.signup_gender p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    align-items: center;
    color: #242424;
    opacity: 0.5;
    margin-bottom: 38px;
    font-family: 'OmnesRegular';
}
.self_declare {
    margin-top: 40px;
    padding: 0px 16px;
}
.self_declare p {
    font-family: 'OmnesRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    text-align: center;
    color: #242424;
    opacity: 0.5;
    margin-bottom: 0px;
}
/* End your gender */

/* Sign up create password */

.signup_pass {
    text-align: center;
    margin-top: 71px;
}
.signup_pass label {
    font-stretch: normal;
    font-family: 'OmnesRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    -ms-flex-align: center;
    align-items: center;
    color: #242424 !important;
    opacity: 0.4;
}

/* .signup_pass input:focus{
    border-bottom: 2px solid #FC6172;
    color: #FC6172 ;
    opacity: 1;
}
.signup_pass input {
    font-weight: 500;
    width: 100%;
    border: none;
    border-bottom: 2px solid #eedcda;
    outline: none;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'OmnesMedium';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    display: -ms-flexbox;
    -ms-flex-align: center;
    align-items: center;
    color: #242424; 
} */

.signup_password {
    width: 100% !important;
}
.signup_password, .signup_password div {
    width: 100% !important;
    position: relative;
}
.signuptype_password div div {
    position: absolute;
    right: 0;
    top: -5px;
}
.signuptype_password div svg,
.signuptype_password div img {
    position: absolute;
    right: 10px;
    top: 24px;
    color: #242424;
}
.signup_pass div:before, .signup_pass div:after {
    content: none !important;
}
.signup_password p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    font-family: 'OmnesRegular';
    align-items: center;
    color: #E51106;
    opacity: 0.9;
    margin-top: 5px;
    margin-bottom: 0px;
}
/* End of create password */

/* Residental Adress */

.residental_address p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    align-items: center;
    text-align: center;
    color: #242424;
    line-height: 18px;
    -ms-flex-align: center;
    opacity: 0.5;
    font-family: 'OmnesRegular';
}

.resident_form {
    text-align: center;
    margin-top: 35px;
}
.resident_form label {
    font-stretch: normal;
    font-family: 'OmnesRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    -ms-flex-align: center;
    align-items: center;
    color: #242424 !important;
    opacity: 0.4;
}
.resident_form input {
    font-weight: 500;
    width: 100%;
    border: none;
    border-bottom: 2px solid #eedcda;
    outline: none;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'OmnesMedium';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    display: -ms-flexbox;
    -ms-flex-align: center;
    align-items: center;
    color: #242424;                    
}

.resident_form input:focus{
    border-bottom: 2px solid #FC6172;
    color: #FC6172 ;
    opacity: 1;
}
.resident_form select {
    font-weight: 500;
    width: 90%;
    border: none;
    border-bottom: 2px solid #eedcda;
    outline: none;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'OmnesMedium';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    display: -ms-flexbox;
    -ms-flex-align: center;
    align-items: center;
    color: #242424;
}
.resident_form select:focus {
    background-color: transparent !important;
}
.resident_form div:before, .resident_form div:after {
    content: none !important;
}
.resident_form div{
    /* margin-top: 0px; */
    width: 100%;
}
.addressLine_two h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    color: #242424;
    margin-top: 20px;
    margin-bottom: 17px;
}
/* .resSection_main{
    height: auto !important;
} */
.multi_btns button {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #9b6b6b;
    border: 1px solid #f2e7e6;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 20px;
    font-family: 'OmnesSemiBold';
    text-transform: capitalize;
    padding: 8px 13px;
    margin-bottom: 10px;
    margin-right: 2px;
    outline: none !important;
}
.multi_btns {
    margin-top: 16px !important;
    text-align: left;
}
.multi_btns label {
    font-size: 14px;
}
button.atv_btn {
    color: #F27381;
    background: #fceceb;
}

/*End Residental Adress */

.sign_backlink {
    top: 30px;
    position: absolute;
    left: 16px;
    cursor: pointer;
    z-index: 111;
}

/* phone css */

.receive_msg p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    align-items: center;
    color: #242424;
    opacity: 0.5;
    text-align: center;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 1rem;
    font-family: 'OmnesRegular';
  }
  
  .verification_process1Cstm .phone_opt {
    text-align: center;
    margin-top: 28px;
  }
  .phone_opt button {
    color: #ffffff;
    outline: none !important;
    padding: 12px 34px;
    background: #F47C6A !important;
    border-radius: 30px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
    font-family: 'OmnesSemiBold';
    min-height: 52px;
  }

  .or_contact {
    text-align: center;
    /* margin: 145px 0px 0px; */
    margin: 40% 0px 0px;
}
.or_contact p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    -ms-flex-align: center;
    align-items: center;
    color: #242424;
    opacity: 0.5;
    font-family: 'OmnesRegular';
}
.or_contact button {
    font-family: 'OmnesSemiBold';
    outline: none !important;
    margin-top: 28px;
    padding: 20px 34px;
    background: -o-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    margin: 0px 3px;
    color: #242424;
    width: 48%;
    background: #ffffffba;
    border-radius: 20px;
}

.phone_verify input {
    padding: 6px 0 7px;
    font-weight: 500;
    width: 100%;
    border: none;
    border-bottom: 1px solid #dfdfdf;
    outline: none;
    font-size: 23px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    font-family: 'Montserrat', sans-serif;
    text-transform: capitalize;
    border-radius: 0px !important;
}

.verification_process1 .maskBefore {
    /* width: 12%; */
    width: 30px;
    margin-right: 0px !important;
    text-align: center;
}
.verification_process1 input {
    width: 80%;
    border: none;
    border-bottom: none !important;
    border-radius: 0px !important;
    background: transparent;
    padding: 7px 0px !important;
    color: #4E4E4E;
    outline: none;
    font-stretch: normal;
    letter-spacing: normal;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    font-family: 'OmnesMedium';
}
.verification_process1 .maskBefore input {
    border: none;
    border-bottom: none !important;
    border-radius: 0px !important;
    background: transparent;
    padding: 6px 0px !important;
    color: #4E4E4E;
    outline: none;
    font-stretch: normal;
    letter-spacing: normal;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    font-family: 'OmnesMedium';
    width: 100%;
    line-height: 9px !important;
}
.maskBefore div:before, .maskBefore div:after {
    content: none !important;
}
.verification_process1 {
    margin: 42px 0px 17px;
    width: 100%;
    border: 1px solid #e5e5e5;
    color: #242424;
    outline: none;
    font-stretch: normal;
    letter-spacing: normal;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    padding: 8px 17px;
    min-height: 52px;
    border-radius: 15px;
    font-family: 'OmnesMedium';
}
.verification_process1 input::-webkit-input-placeholder { /* Edge */
    color: rgb(36, 36, 36, 0.5);
  }
.verification_process1 input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: rgb(36, 36, 36, 0.5);
  }
.verification_process1 input::placeholder {
    color: rgb(36, 36, 36, 0.5);
}
  /* phone css */

/* Verification page css */
.verification_process2 {
    margin: 42px 0px 5px;
}
.verification_process2 input {
    width: 100%;
    border: none;
    border-bottom: 1px solid #dfdfdf;
    outline: none;
    font-stretch: normal;
    letter-spacing: normal;
    text-transform: capitalize;
    border-radius: 0px !important;
    background: transparent;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    -ms-flex-align: center;
    align-items: center;
    line-height: 19px !important;
    color: #242424;
    padding: 6px 0px !important;
    font-family: 'OmnesRegular';
}
.verification_process2 label {
    font-stretch: normal;
    font-family: 'OmnesRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    -ms-flex-align: center;
    align-items: center;
    color: #242424 !important;
    opacity: 0.4;
}
.verification_process2 div:before, .verification_process2 div:after {
    content: none !important;
}
.verification_process2 div {
    width: 100% !important;
}
/*End Verification page css */

/* Resend or Call */

.resend_call{
  display: flex;
}
.resend_call div{
  width:50%;
}
.resend_call p {
    margin: 0px;
}
.resend_call p{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    align-items: center;
    color: #AE86E0;
    font-family: 'OmnesMedium';
    cursor: pointer;
}
.resend_call div:last-child {
    text-align: right;
}
.resend_call p span{
    opacity: 0.4;
}
/*End of Resend or Call */

/* Phone sent */
.call_content {
    margin-top: 38%;
}
.call_content h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 27px;
    text-align: center;
    color: #4E4E4E;
    margin-top: 36px;
    font-family: 'OmnesSemiBold';
}
.call_content p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: #4E4E4E;
    padding: 0px 8%;
    line-height: 16px;
    align-items: center;
    margin-bottom: 0px;
    font-family: 'OmnesRegular';
    margin: 42px 0px;
}
.call_link {
    text-align: center;
}
.call_link button {
    color: #ffffff;
    outline: none !important;
    padding: 12px 34px;
    background: #F47C6A !important;
    border-radius: 30px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 90%;
    position: relative;
    min-height: 52px;
    font-family: 'OmnesSemiBold';
}
.call_link button:after {
    position: absolute;
    width: 88%;
    height: 7px;
    left: 0px;
    right: 0px;
    bottom: -2px;
    background: linear-gradient(117.02deg, #FF5A6C -28.17%, #F27381 366.38%);
    filter: blur(7px);
    transform: matrix(1, 0, 0, -1, 0, 0);
    content: "";
    margin: 0 auto;
}
/* End Phone sent */

/* restore password css */

.restorepass_submit {
    text-align: center;
}
.restorepass_submit button {
    color: #ffffff;
    outline: none !important;
    margin-top: 28px;
    height: 52px;
    background: #F47C6A !important;
    border-radius: 30px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    align-items: center;
    text-align: center; 
    text-transform: capitalize;
    width: 100%;
    position: relative;
    font-family: 'OmnesSemiBold';
}
.restorepass_submit button:after {
    position: absolute;
    width: 88%;
    height: 7px;
    left: 0px;
    right: 0px;
    bottom: -2px;
    background: linear-gradient(117.02deg, #FF5A6C -28.17%, #F27381 366.38%);
    -webkit-filter: blur(7px);
    filter: blur(7px);
    -webkit-transform: matrix(1, 0, 0, -1, 0, 0);
    transform: matrix(1, 0, 0, -1, 0, 0);
    content: "";
    margin: 0 auto;
}
.restorepass_email {
    text-align: center;
    margin-top: 75px;
}
.restorepass_email p {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    text-transform: capitalize;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    font-family: 'OmnesSemiBold';
    color: #242424;
    opacity: 0.4;
    cursor: pointer;
}
.restorepass_form {
    text-align: center;
    margin: 60px 0px 60px;
}

.restorepass_form div:before, .restorepass_form div:after {
    content: none !important;
}
.restorepass_form div {
    width: 100%;
}
/*End restore password css */

/* restore Email css */
.restoreemail_content {
    margin-top: 38%;
}
.restoreemail_content h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 27px;
    text-align: center;
    color: #4E4E4E;
    margin-bottom: 7px;
    font-family: 'OmnesMedium';
}
.restoreemail_content p {
    font-style: normal; 
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #8d8d8d;
    align-items: center;
    margin: 0px;
    font-family: 'OmnesRegular';
}
.restoreemail_content p strong {
    color: #000;
}
.restoreemail_link {
    text-align: center;
}
.restoreemail_link button {
    color: #ffffff;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    padding: 12px 34px;
    background: -webkit-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: -o-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: linear-gradient(158.95deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 90%;
}
img.clear_img {
    position: absolute;
    right: 5px;
    bottom: 12px;
    cursor: pointer;
}
.clear_input {
    position: relative;
}

/*End restore Email css */

/* build your family */
.build_content {
    margin-top: 38%;
}
.build_content h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 27px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #4E4E4E;
    font-family: 'OmnesSemiBold';
}
.build_content p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #242424;
    opacity: 0.5;
    align-items: center;
    margin: 29px 0px;
    font-family: 'OmnesRegular';
}
.prent_submit {
    text-align: center;
}
.prent_submit button {
    color: #ffffff;
    outline: none !important;
    margin-top: 10px;
    padding: 16px 34px;
    background: linear-gradient(158.95deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 18px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 90%;
    font-family: 'OmnesSemiBold';
}
.build_content {
    margin-top: 34px;
}
.add_parent {
    text-align: center;
    margin-top: 30%;
}
.add_parent_inner a {
    width: 29px;
    height: 29px;
    border-radius: 11px !important;
    -webkit-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg);
    color: #ffffff !important;
    font-size: 19px;
    font-weight: bold;
    position: absolute;
    top: 0;
    right: -7px;
    cursor: pointer;
    background: #FF5A6C;
}
.add_parent_inner {
    display: inline-block;
    margin: auto 0;
    position: relative;
}
.prent_submit p { 
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    margin-top: 28px;
    color: #242424;
    margin-bottom: 0px;
}
/* End of build your family */

/* Add a Relative */
.add_relation {
    position: relative;
}
.arrowWithText {
    margin-top: 52px;
}
.point_benefits h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 27px;
    text-align: center;
    color: #4E4E4E;
    margin-top: 36px;
    margin-bottom: 0px;
    font-family: 'OmnesSemiBold';
}
.point_benefits p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    align-items: center;
    text-align: center;
    color: #242424;
    line-height: 18px;
    margin-top: 14px;
    opacity: 0.5;
    font-family: 'OmnesRegular';
}
/* .addrelation_form {
    text-align: center;
} */
.addrelation_form div {
    /* margin-top: 0px; */
    width: 100%;
}

.survey_form div {
    /* margin-top: 0px; */
    width: 90%;
    margin-left: 10px;
}
.survey_form label {
    margin-left: 10px;
    font-stretch: normal;
    font-family: 'OmnesRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    -ms-flex-align: center;
    align-items: center;
    color: #242424 !important;
    opacity: 0.4;
}
.addrelation_form label {
    font-stretch: normal;
    font-family: 'OmnesRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    -ms-flex-align: center;
    align-items: center;
    color: #242424 !important;
    opacity: 0.4;
}

.addrelation_form input:focus{
    border-bottom: 2px solid #FC6172;
    color: #FC6172;
    opacity: 1;
}

.survey_form div {
    position: relative;
}
.survey_form input:focus{
    border-bottom: 2px solid #FC6172;
    color: #FC6172;
    opacity: 1;
}

.survey_form input {
    /* opacity: 0.2; */
    width: 100%;
    border: none;
    border-bottom: 2px solid #eedcda;
    color: #242424;
    outline: none;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'OmnesRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    display: -ms-flexbox;
    -ms-flex-align: center;
    align-items: center;
}

.survey_form select:focus {
    border-radius: 0;
    background-color: transparent !important;
}
.survey_form div:before, .survey_form div:after {
    content: none !important;
}

.addrelation_form input {
    /* opacity: 0.2; */
    width: 100%;
    border: none;
    border-bottom: 2px solid #eedcda;
    color: #242424;
    outline: none;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'OmnesRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    display: -ms-flexbox;
    -ms-flex-align: center;
    align-items: center;
}
.addrelation_form select {
    width: 90%;
    border: none;
    border-bottom: 2px solid #FC6172;
    outline: none;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'OmnesRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    display: -ms-flexbox;
    -ms-flex-align: center;
    align-items: center;
    color: #FC6172;
}
.addrelation_form select:focus {
    border-radius: 0;
    background-color: transparent !important;
}
.addrelation_form div:before, .addrelation_form div:after {
    content: none !important;
}
.clearimg {
    position: absolute;
    right: 6px;
    bottom: 22px;
    cursor: pointer;
}
.addrelation_form div {
    position: relative;
}
/*End of Add a Relative */

/*Add a child*/

.addchild_form div {
    margin-top: 0px;
    width: 100%;
    position: relative;
}
.addchild_form label {
    font-stretch: normal;
    font-family: 'OmnesRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    -ms-flex-align: center;
    align-items: center;
    color: #242424 !important;
    opacity: 0.7;
}

/* .addchild_form input:focus{
    border-bottom: 2px solid #FC6172;
    color: #FC6172;
    opacity: 1;
} */

.addchild_form input {
    width: 100%;
    outline: none;
    font-stretch: normal;
    letter-spacing: normal;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
    color: rgb(36, 36, 36, 0.5);
    border-radius: 15px;
    padding: 15.5px 12px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-family: 'OmnesMedium';
}
.addchild_form input[type="text"]::-webkit-input-placeholder { 
    color: rgba(0, 0, 0, 0.6);
}
.addchild_form input[type="text"]::-moz-placeholder { 
    color: rgba(0, 0, 0, 0.6);
}
.addchild_form input[type="text"]:-ms-input-placeholder { 
    color: rgba(0, 0, 0, 0.6);
}
.addchild_form input[type="text"]:-moz-placeholder { 
    color: rgba(0, 0, 0, 0.6);
}
.addchild_form div {
    margin-top: 0px;
    width: 100%;
    position: relative;
}
.addchild_submit {
    text-align: center;
}
.addchild_submit button {
    color: #ffffff;
    outline: none !important;
    margin-top: 22px;
    padding: 12px 34px;
    background: #F47C6A !important;
    border-radius: 30px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
    height: 52px;
    min-height: 52px;
    box-shadow: 0 18px 20px -18px #FF5A6C;
    font-family: 'OmnesSemiBold';
}
.addchild_form div:before, .addchild_form div:after {
    content: none;
}
.expectant_name p {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #242424;
    opacity: 0.5;
    position: absolute;
    right: 0;
    bottom: 13px;
    margin: 0px;
}
.expectant_name {
    position: relative;
}

.addchild_gender p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    font-family: 'OmnesRegular';
    align-items: center;
    color: #242424;
    opacity: 0.7;
}
.addchild_gender button {
    outline: none !important;
    border-radius: 30px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    margin: 0px 3px;
    min-width: 31.33%;
    height: 52px;
    color: rgb(36, 36, 36, 0.5);
    background: #ffffff !important;
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-family: 'OmnesSemiBold' !important;
}
.gender_active{
    color: #AE86E0 !important;
    opacity: initial !important;
}
.addchild_gender, .addChildPrnt {
    margin-top: 8px !important;
    margin-bottom: 0px !important;
}
.genderboy_active{
    color: #66B0C4 !important;
    opacity: initial !important;
}
.gendergirl_active{
    color: #F47C6A !important;
    opacity: initial !important;
}
/*End of Add a child  */


/* Build you family  */
.child_info {
    display: flex;
}
.child_left {
    width: 20%;
}
.child_rght {
    width: 80%;
    position: relative;
    padding-left: 10px;
}
.child_left label {
    font-style: normal;
    font-weight: 600;
    border-radius: 60px;
    margin-top: 0px;
    margin-bottom: 0px;
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
} 
.expectingSection .child_left label,
.expectingSection .all_children_left label {
    color: #927BC3; 
    background-color: #ad85df75;
}
.existingSection .child_left label,
.existingSection .all_children_left label {
    color: #719E7F;
    background-color: rgba(113, 158, 127, 0.3);
}
.child_rght label {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: #242424;
    margin-top: 12px;
    margin-bottom: 0px;
    font-family: 'OmnesMedium';
}
.child_rght p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #242424;
    opacity: 0.5;
    margin: 0px;
    font-family: 'OmnesMedium';
}
.child_info {
    margin-bottom: 24px;
    padding: 9px 10px;
    border-radius: 15px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #ffffff !important;
}
.child_rght a{
    font-size: 30px;
    top: 0;
    right: 0;
    position: absolute;
    cursor: pointer;
    color: #000000 !important;
    font-family: 'OmnesMedium';
}
.child_info_space {
    height: 25px;
}

.main_site_load{
    height: 100vh;
    /* background: linear-gradient(180deg, #ffffff 0, #f9eae2e8 33.333%, #F9EAE2 33.333%, #F9EAE2 66.666%, #F9EAE2 66.666%, #F7E2E1 100%); */
    background: linear-gradient(180deg, #FFFFFF 57.5%, #F2F2F2 100%) !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.many_childs{
    height: 68vh;
    /* height: 400px; */
    overflow-y: scroll;
}
.addbuild_submit {
    text-align: center;
}
.addbuild_submit button {
    color: #fff;
    outline: none!important;
    margin-top: 28px;
    height: 52px;
    background: #f47c6a!important;
    border-radius: 30px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    position: relative;
    font-family: "OmnesSemiBold";
    width: 300px;
}

.addbaby_submit {
    text-align: center;
}
.addbaby_submit button {
    font-family: 'OmnesSemiBold';
    outline: none !important;
    padding: 12px 34px;
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    line-height: 20px;
    text-transform: capitalize;
    width: 300px;
    font-size: 14px;
    align-items: center;
    text-align: center;
    letter-spacing: -0.01em;
    color: #242424;
    background: transparent !important;
}
/* End Build you family  */

/* add child model css */

.custom_editchilddmodel .modal-content {
    border: none !important;
}
.custom_editchildmodel .modal-content {
    background-color: transparent !important;
    width: 100%;
}
.custom_editchildBody {
    font-family: 'Montserrat', sans-serif;
    min-height: 538px;
}
.editchild_content {
    font-family: 'Montserrat', sans-serif;
    position: absolute;
    width: 100%;
    background: #FFFFFF;
    border-radius: 20px;
    text-align: center;
    margin: 0 auto;
    /* min-height: 150px; */
    padding: 25px 20px;
    top: 42%;
}
.editchild_content button {
    color: #ffffff;
    outline: none !important;
    margin-top: 5px;
    padding: 12px 34px;
    background: #F47C6A !important;
    border-radius: 30px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
    font-family: 'OmnesSemiBold';
    min-height: 52px;
}
.editchild_content button:first-child{
    background: #F47C6A !important;
}
button.deleteButton.closeModel.childdel_btn {
    background: transparent !important;
    color: #4E4E4E !important;
    opacity: 0.5;
}
/* button.childdel_btn {
    background: transparent;
    color: #242424;
    font-family: 'OmnesRegular';
} */
/*End of add child model css */

/* unknown child model */
.custom_unknwnchildBody {
    font-family: 'Montserrat', sans-serif;
    min-height: 538px;
}
.unknwnchild_content {
    font-family: 'Montserrat', sans-serif;
    position: absolute;
    width: 100%;
    background: #FFFFFF;
    border-radius: 20px;
    margin: 0 auto;
    min-height: 150px;
    padding: 25px 20px;
    top: 26%;
    left: 0;
}
.unknwnchild_content button {
    background: transparent;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    margin-top: 18px;
    margin-bottom: 8px;
    padding: 12px 34px;
    border-radius: 18px;
    -ms-flex-align: center;
    text-transform: capitalize;
    width: 100%;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    color: #242424;
}

.custom_parentinvite_content {
    width: 92% !important;
    top: 40px !important;
    left: 0 !important;
    right: 0px !important;
}

.parentinvite_content {
    font-family: 'Montserrat', sans-serif;
    position: absolute;
    width: 100%;
    background: #FFFFFF;
    border-radius: 20px;
    margin: 0 auto;
    min-height: 150px;
    padding: 25px 20px;
    top: 0px;
    left: 0px;
}
.parentinvite_content button {
    font-family: 'OmnesSemiBold';
    outline: none !important;
    margin-top: 5px;
    padding: 12px 34px;
    border-radius: 18px;
    -ms-flex-align: center;
    text-transform: capitalize;
    width: 100%;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    
}

.unknwnchild_head h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 27px;
    text-align: center;
    color: #4E4E4E;
    margin: 0px;
    margin-bottom: 16px;
    font-family: 'OmnesSemiBold';
}

.unknwnchild_head h4 {
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 114.21%;
    text-align: center;
    color: #4E4E4E;
    font-family: 'OmnesSemiBold';
    margin-bottom: 7px;
}

.unknwnchild_head p {
    font-size: 14px;
    line-height: 18px;
    align-items: center;
    text-align: center;
    color: #242424;
    opacity: 0.5;
    font-family: 'Montserrat', sans-serif;
}

.unchild_info {
    border: 1px solid #eae5e2;
    margin-bottom: 18px;
    display: flex;
    padding: 17px 18px;
    border-radius: 18px;
    min-height: 80px;
}
.unchild_left {
    width: 20%;
    text-align: center;
}
.unchild_rght {
    width: 80%;
    position: relative;
    padding-left: 12px;
}
.unchild_rght label {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 0px;
    line-height: 18px;
    color: #242424;
    font-family: 'OmnesSemiBold';
}
.unchild_rght p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    align-items: center;
    letter-spacing: 0.01em;
    color: #4E4E4E;
    opacity: 0.5;
    margin: 0px;
    font-family: 'OmnesRegular';
}
.unknwnchild_head {
    margin-top: 32px;
    margin-bottom: 15px;
}
/* End unknown child model */

/* Boarding slder */
.boarding {
    text-align: center;
    margin-top: 10%;
}
.boarding_inner {
    display: inline-block;
    margin: auto 0;
    position: relative;
    min-height: 100px;
}
.boarding_inner a {
    border-radius: 11px !important;
    color: #ffffff !important;
    font-size: 19px;
    font-weight: bold;
    position: absolute;
    top: -16px;
    left: -21px;
    cursor: pointer;
    background: #FF5A6C;
}
.boarding_content {
    margin-top: 34px;
    min-height: 130px;
    padding: 0px 37px;
}
.boarding_content h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 114.21%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #914949;
    font-family: 'OmnesSemiBold';
}
.boarding_content p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #242424;
    opacity: 0.5;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 0px;
    font-family: 'OmnesSemiBold';
}
.boarding_submit {
    text-align: center;
    width: 108%;
}
.boarding_submit button {
    color: #ffffff;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    margin-top: 0px;
    padding: 12px 34px;
    background: -webkit-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: -o-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: linear-gradient(158.95deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 80%;
}
 .custom_swiper .swiper-container {
    height: 86vh;
}
.custom_swiper .swiper-pagination-bullet-active {
    opacity: 1;
    background: #FF5A6C !important;
}
.slide_two .boarding_inner a {
    top: 32px !important;
    right: -21px !important;
    left: initial !important;
}

.bording_backlink {
    top: 23px;
    position: absolute;
    left: 16px;
    cursor: pointer;
}
.bording_clearlink {
    top: 0px;
    position: absolute;
    right: 18px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    text-align: right;
    letter-spacing: -0.01em;
    color: #242424;
}
.bording_up {
    margin-bottom: 28px;
    text-align: center;
}
/* End of Boarding slder */

/* discover no connetion */

.nocontion {
    text-align: center;
    margin-top: 85px;
}
.nocontion_inner {
    display: inline-block;
    margin: auto 0;
    position: relative;
    min-height: 100px;
}
.nocontion_content {
    margin-top: 34px;
    min-height: 150px;
    padding: 0px 37px;
}
.nocontion_content h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 114.21%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #914949;
    font-family: 'OmnesSemiBold';
}
.nocontion_content p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #242424;
    opacity: 0.5;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 0px;
    font-family: 'OmnesSemiBold';
}
.nocontion_submit {
    text-align: center;
}
.nocontion_submit button {
    font-family: 'OmnesSemiBold';
    outline: none !important;
    margin-top: 0px;
    padding: 12px 34px;
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 80%;
    color: #FF5A6C;
    background: #f9ddde !important;
}
.discover_head {
    display: flex;
    margin-top: 5%;
    margin: 0px 10px;
}
.discover_headLeft, .discover_headRght {
    width: 50%;
}
.discover_headLeft {
    text-align: left;
}
.discover_headRght {
    text-align: right;
}
.discover_headLeft label {
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 29px;
    letter-spacing: -0.02em;
    color: #914949;
    font-family: 'OmnesSemiBold';
    margin-top: 10px;
}
.webMenu ul li {
    display: inline-block;
    width: 25%;
    text-align: center;
}
.webMenu ul {
    padding-left: 0px;
    margin: 0px;
    list-style-type: none;
    padding: 14px 0px;
}
.webMenu {
    position: fixed;
    /* position: absolute; */
    bottom: 0;
    right: 0;
    left: 0;
    /* z-index: 11; */
    z-index: 999;
    width: 371px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(7px);
    /* background-color: hsla(15, 67%, 96%, 0.8) !important; */
    /* background-image: linear-gradient(to top, #f9ddde, #f8e6e4); */
    margin: 0 auto;
    background: linear-gradient(180deg, rgba(255, 240, 240, 0.9) 0%, rgba(255, 236, 236, 0.9) 100%);
    box-shadow: 0px 0px 24px rgb(255 90 108 / 23%);
    border-radius: 20px 20px 0px 0px;
    min-height: 58.04px;
}
.webMenu ul li a {
    cursor: pointer;
    position: relative;
}
.slides img{
    /* width: 137px !important; */
    width: 100% !important;
} 
.sharegain_swiper .swiper-slide-prev img, .sharegain_swiper .swiper-slide-next img{
    width: 130px !important;
    height: 130px !important;
    padding-top: 38%;
} 

.detail_swiper .swiper-slide-prev img, .detail_swiper .swiper-slide-next img{
    width: 130px !important;
    height: 130px !important;
    padding-top: 38%;
} 
/*End of discover no connetion */

/* Share gain css */
.addSliderBg {
    /* padding-top: 22px; */
    position: relative;
    text-align: center;
    background-image: url('../../assets/images/mainLogoutBg.svg') !important;
    background-size: cover;
    background-repeat: no-repeat !important;
    background-position: bottom center;
}

.detail_swiper {
    padding-top: 22px;
    position: relative;
    text-align: center;
}
.gain_extra label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    display: block;
    align-items: center;
    text-align: center;
    color: #242424;
    opacity: 0.5;
    margin-bottom: 0px;
}

.gain_extra h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px !important;
    line-height: 21px !important;
    color: #4E4E4E;
    width: 90%;
    margin: auto;
    text-align: center;
    margin-bottom: 0px;
    margin-top: 14px;
    font-family: 'OmnesSemiBold';
}
.gain_extra {
    /* text-align: center;
    margin-top: 34px; */
    margin-top: 15%;
    margin-left: -43%;
    width: 186%;
    text-align: left;
}
.nextslides {
    /* position: absolute;
    bottom: -48px;
    right: 17%;
    z-index: 9; */

    /* position: absolute;
    right: 17%;
    top: 44%;
    z-index: 9; */

    position: absolute;
    bottom: -2%;
    right: -12%;
    z-index: 9;
}
.contests_content_iner a span {
    vertical-align: middle;
}
.nextslides img {
    width: 58px;
}
.extragain_tabs ul.nav.nav-tabs {
    border-bottom: 0px !important;
    overflow-y: hidden !important;
    overflow-x: auto !important;
    flex-wrap: nowrap !important;
    justify-content: flex-start !important;
    display: flex !important;
    margin-bottom: 0px;
}
/* .extragain_tabs .nav-tabs .nav-link.active {
    color: #242424;
    opacity: initial !important;
    position: relative;
    background: transparent !important;
} */
.extragain_tabs a.nav-link {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #4E4E4E;
    border: none !important;
    opacity: 0.3;
}
.extragain_tabs li {
    text-align: center;
    cursor: pointer;
    display: inline-block;
    /* margin: 0px 10px;
    min-height: 50px; */
}
.extragain_tabs {
    margin-right: -9px;
    margin-top: 36px;
    margin-bottom: 8px;
    text-align: center !important;
}
.contests_content {
    background: #fffefe94;
    border-radius: 20px 20px 20px 20px;
    margin-bottom: 30px;
}
.contests_content_iner a {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    border: 1px solid #ffcfc17d;
    padding: 8px 14px;
    display: inline-block;
    color: #FF9B80 !important;
    border-radius: 14px; 
    cursor: pointer;
    margin-bottom: 13px;
}
.contests_content_iner { 
    padding: 20px 20px 25px;
}
.contests_content_iner label {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: -0.02em;
    color: #242424;
    margin: 0px 0px 6px;
}
.contests_content_iner p {
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    align-items: center;
    color: #242424;
    opacity: 0.5;
}
.contests_content_iner a img {
    margin-right: 4px;
}
/* .extragain_tabs .nav-tabs .nav-link.active:after {
    content: "";
    width: 6px;
    height: 6px;
    left: 50%;
    bottom: -17px;
    position: absolute;
    border-radius: 82px;
    background: #242424;
} */
.contests_contentImges img {
    width: 100%;
    border-radius: 20px 20px 20px 20px;
}
.common_main_rmove {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
}
.common_main_space {
    padding: 0px 20px;
    margin-bottom: 6%;
}
/* Share gain css */

/* freeies tabs css */
.contests_contentImg {
    position: relative;
}
.uperImgs {
    position: absolute;
    bottom: 16px;
    left: 16px;
}
.uperImgs img {
    width: 50px;
    cursor: pointer;
    margin-right: 8px;
}
.freebies_in a {
    border: none !important;
    background: #fcebe7 !important;
}
/*End of freeies tabs css */

/* offer tab css */
.price_toy .price_active {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    align-items: center;
    color: #242424;
    opacity: 0.5;
}
.price_toy strike.price_deactive {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    -ms-flex-align: center;
    align-items: center;
    color: #242424;
    opacity: 0.9;
    margin-right: 12px;
}
/* offer tab css */

/* Share and Gain details css */
.contests_dtl {
    border-radius: 20px 20px 20px 20px;
}
.contests_dtlImg {
    position: relative;
}
.contests_dtlImges img {
    width: 100%;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    border-radius: 0px 0px 20px 20px;
}
.dtlImgs {
    position: absolute;
    bottom: -23px;
    left: 16px;
}
.dtlImgs img {
    width: 50px;
    cursor: pointer;
    margin-right: 8px;
    border-radius: 14px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
}
.contests_dtl_iner {
    padding: 20px 0px;
}
.contests_dtl_iner label {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: -0.02em;
    color: #242424;
    margin: 17px 0px 6px;
}
.contests_dtl_iner p {
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    -ms-flex-align: center;
    align-items: center;
    color: #242424;
    opacity: 0.5;
}
div.detailspace_rmv {
    padding: 0px;
    padding-bottom: 22px;
}
.details_descp {
    padding: 0px 22px;
}
.lend_hend {
    text-align: center;
    width: 100%;
    background: #FFFFFF;
    padding: 8% 0px;
    border-radius: 20px;
}
.lend_hend h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.01em;
    color: #7C5E8C;
    font-family: 'OmnesSemiBold';
    margin-top: 20px;
}
.lend_hend p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    color: #7C5E8C;
    opacity: 0.6;
    margin-bottom: 0px;
}
.detailsSocial_share div:last-child {
    text-align: right;
}
.detailsSocial_share div {
    width: 50%;
}
.detailsSocial_share {
    display: flex;
}
.detailsSocial_share p {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #242424;
    margin-bottom: 0px;
    padding: 15px 0px;
}
.detailsSocial_share div:last-child a:last-child {
    margin-left: 12px;
}
.detailsSocial_share a {
    cursor: pointer;
}
.detailsSocial_share {
    padding: 24px 18px;
}
.one_plus p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    align-items: center;
    letter-spacing: 0.02em;
    color: rgba(141, 87, 87, 0.5);
    opacity: 0.99;
    margin-bottom: 0px;
}
.plus_connect_inr div {
    width: 50%;
}
.plus_connect_upr {
    padding: 24px 18px;
    background: rgb(251,245,245);
}
.lend_hendupr {
    /* background: #fdf7f7; */
    /* background: #faeeed; */
    border-radius: 20px;
    margin-top: 25px;
}
.one_plus_connect {
    text-align: right;
}
.one_plus_connect a {
    margin-right: -18px;
}
.one_plus_connect a:last-child {
    margin-right: 0px !important;
}
.one_plus p span{
    font-weight: bold;
    font-size: 14px;
    align-items: center;
    letter-spacing: 0.02em;
    color: #242424;
    opacity: 0.99;
}
.plus_connect_inr{
    display: flex;
}

.familyshare_submit {
    text-align: center;
}
.familyshare_submit button {
    font-family: 'OmnesSemiBold';
    outline: none !important;
    margin-top: 0px;
    padding: 12px 34px;
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 80%;
    color: #FF5A6C;
    background: #fde8e9 !important;
    margin-top: 18px;
}
.detailsSocial_upr {
    background: #faeeed;
    border-radius: 20px;
}
.descp_all label {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #242424;
    font-family: 'OmnesSemiBold';
}
.descp_all p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    align-items: center;
    color: #000000;
    opacity: 0.5;
    margin-bottom: 0px;
    font-family: 'OmnesRegular';
}
.descp_all {
    border-left: 2px solid #f7ecea;
    padding-left: 16px;
    padding-bottom: 5px;
    margin-bottom: 25px;
}
.detailback_white a {
    color: #ffffff !important;
}
/* End Share and Gain details css */

/* cute child css */
.contests_baby {
    position: relative;
    overflow: hidden;
    border-radius: 20px 20px 20px 20px;
    margin-bottom: 40px;
}
.contests_babyImg {
    position: relative;
}
.contests_babyImges img {
    width: 100%;
    /* -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 20px 20px; */
}
.babyImgs {
    position: absolute;
    bottom: -23px;
    left: 16px;
}
.babyImgs img {
    width: 50px;
    cursor: pointer;
    margin-right: 8px;
}
.details_baby {
    padding: 0px 22px;
}
.contests_baby_iner {
    padding: 0px 0px 20px;
}
.contests_baby_iner label {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: -0.02em;
    color: #242424;
    margin: 17px 0px 6px;
}
.contests_baby_iner p {
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    -ms-flex-align: center;
    align-items: center;
    color: #242424;
    opacity: 0.5;
}
.descp_baby {
    border-left: 2px solid #f7ecea;
    padding-left: 16px;
    padding-bottom: 5px;
    margin-bottom: 25px;
}
.descp_baby label {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #242424;
}
.descp_baby p {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    -ms-flex-align: center;
    align-items: center;
    /* letter-spacing: 0.01em; */
    color: #242424;
    opacity: 0.9;
    margin-bottom: 0px;
    font-family: 'OmnesRegular';
}
.detailsSocial_baby {
    background: #faeeed;
    border-radius: 20px;
}
.lend_baby {
    text-align: center;
    width: 100%;
    background: #FFFFFF;
    padding: 8% 0px;
    border-radius: 20px;
}
.lend_baby h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.01em;
    color: #7C5E8C;
    font-family: 'OmnesSemiBold';
    margin-top: 20px;
}
.lend_baby p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    color: #7C5E8C;
    opacity: 0.6;
    margin-bottom: 0px;
}
.babySocial_share {
    padding: 24px 18px;
}
.babySocial_share {
    display: -ms-flexbox;
    display: flex;
}
.babySocial_share div {
    width: 100%;
}
.babySocial_share p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #242424;
    margin-bottom: 0px;
}
.babySocial_share p span{
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #242424;
}
.babySocial_share div:last-child {
    text-align: center;
}
.babySocial_share div:first-child {
    text-align: left !important;
}
.detailback_section div:last-child {
    text-align: right;
}
.detailback_section div {
    width: 50%;
}
.detailback_section {
    display: flex;
    padding: 0px 22px;
    position: absolute;
    width: 100%;
    top: 14px;
}
.detailback_section img, .detailback_section a {
    cursor: pointer;
}

.babySocial_share div:last-child button {
    box-shadow: 0px 3px 13px rgba(252, 97, 114, 0.65);
    color: #ffffff;
    outline: none !important;
    margin-top: 0px;
    padding: 14px 16px;
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    border: none;
    line-height: 11px;
    /* letter-spacing: -0.01em; */
    font-family: 'OmnesSemiBold';
    background: -webkit-linear-gradient(305.64deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: -o-linear-gradient(305.64deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: linear-gradient(144.36deg, #FF5A6C -28.17%, #F27381 366.38%);
}
.exclusive_details {
    margin-top: 45px;
}
.exclusive_details a {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    padding: 8px 20px;
    display: inline-block;
    color: #F38181 !important;
    border-radius: 14px;
    cursor: pointer;
    background: #ff9b801a;
}
.exclusive_details a img {
    margin-right: 4px;
}
.exclusive_details a span {
    vertical-align: middle;
}
/*End of cute child css */

/* freebies details css */

.freebies_baby {
    border-radius: 20px 20px 20px 20px;
}
.freebies_babyImg {
    position: relative;
}

.freebies_Imges img {
    width: 100%;
}
.freebiesImgs {
    position: absolute;
    bottom: -23px;
    left: 16px;
}
.freebiesImgs img {
    width: 50px;
    cursor: pointer;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
    margin-right: 8px;
    border-radius: 14px;
}
.details_freebies {
    padding: 0px 22px;
}

.details_freebies_ios {
    padding: 0px 22px;
}

.freebies_iner {
    padding: 0px 0px 20px;
}
.freebies_iner label {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: #242424;
    margin: 10px 0px 6px;
    font-family: 'OmnesSemiBold';
}
.freebies_iner p {
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    align-items: center;
    color: #242424;
    opacity: 0.5;
    font-family: 'OmnesRegular';
}
.descp_freebies {
    border-left: 2px solid #f7ecea;
    padding-left: 16px;
    padding-bottom: 5px;
    margin-bottom: 25px;
}
.descp_freebies label {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #242424 !important;
    font-family: 'OmnesSemiBold';
}
.descp_freebies p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    align-items: center;
    color: #000000;
    opacity: 0.5;
    margin-bottom: 0px;
    font-family: 'OmnesRegular';
}
.ends_date p {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #242424 !important;
    font-family: 'OmnesSemiBold';
    margin-bottom: 0px;
    font-style: normal;
}
.ends_date {
    /* padding-left: 19px; */
    margin-top: 25px;
}

.session_end_date{
    padding-left: 19px;
}
.freebies_redem {
    text-align: center;
    margin-top: 30px;
}
.freebies_redem button {
    color: #ffffff;
    outline: none !important;
    padding: 12px 34px;
    background: #F47C6A !important;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 90%;
    /* box-shadow: 0px 3px 13px rgba(252, 97, 114, 0.65);
    box-shadow: 0px 4px 8px #f27d6ead; */
    box-shadow: 0 18px 20px -18px #FF5A6C;
    border-radius: 30px;
    min-height: 52px;
    font-family: 'OmnesSemiBold';
}
.declaration_frrebies {
    margin-top: 30px;
}
.declaration_frrebies p {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 120%;
    text-align: center;
    color: rgba(141, 87, 87, 0.5);
    /* padding: 12px 20px; */
}
.declaration_frrebies p span {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    text-align: center;
    color: #242424;
}
.freeies_babyImg {
    position: relative;
}
.freebiesImges img {
    width: 100%;
}
.freebiesImges{
    max-height: none !important;
}
.instant_details {
    margin-top: 10px;
}
.instant_tags {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    padding: 8px 20px;
    display: inline-block;
    color: #FF9B80 !important;
    border-radius: 14px;
    cursor: pointer;
    background: #ff9b801a;
}
.instant_details a img {
    margin-right: 7px;
    margin-bottom: 2px;
}
.instant_details a span {
    vertical-align: middle;
}
/* End of freebies details css */

/* offer details page */
.offers_descp {
    padding: 0px 22px;
}
.offers_dtl {
    border-radius: 20px 20px 20px 20px;
}
.offers_dtlImg {
    position: relative;
}
.offers_dtlImges img {
    width: 100%;
    border-radius: 0px 0px 20px 20px;
}
.offersImgs {
    position: absolute;
    bottom: -23px;
    left: 16px;
}
.offersImgs img {
    width: 50px;
    cursor: pointer;
    border-radius: 10px;
}
.details_offers {
    padding: 0px 22px;
}
.offers_dtl_iner {
    padding: 20px 0px;
}
.offers_dtl_iner label {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: -0.02em;
    color: #242424;
    margin: 17px 0px 6px;
}
.offers_all {
    border-left: 2px solid #f7ecea;
    padding-left: 16px;
    padding-bottom: 5px;
    margin-bottom: 25px;
}
.offers_all label {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #242424;
}
.offers_all p {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    -ms-flex-align: center;
    align-items: center;
    /* letter-spacing: 0.01em; */
    color: #242424;
    opacity: 0.9;
    margin-bottom: 0px;
    font-family: 'OmnesRegular';
}
.offersSocial_upr {
    background: #faeeed;
    border-radius: 20px;
}
.offers_hendupr {
    background: #fdf7f7;
    border-radius: 20px;
}
.offers_hend {
    text-align: center;
    width: 100%;
    background: #FFFFFF;
    padding: 8% 0px;
    border-radius: 20px;
}
.offers_hend h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    /* letter-spacing: -0.01em; */
    color: #7C5E8C;
    font-family: 'OmnesSemiBold';
    margin-top: 20px;
}
.offers_connect_upr {
    padding: 24px 18px;
}
.offers_connect_inr {
    display: -ms-flexbox;
    display: flex;
}
.offers_connect_inr div {
    width: 50%;
}
.offers_plus p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    -ms-flex-align: center;
    align-items: center;
    letter-spacing: 0.02em;
    color: rgba(141, 87, 87, 0.5);
    opacity: 0.99;
    margin-bottom: 0px;
}
.offers_plus_connect {
    text-align: right;
}
.offers_plus_connect a {
    margin-right: -18px;
}
.offers_submit {
    text-align: center;
}
.offers_submit button {
    font-family: 'OmnesSemiBold';
    outline: none !important;
    margin-top: 0px;
    padding: 12px 34px;
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 80%;
    color: #FF5A6C;
    background: #fde8e9 !important;
    margin-top: 18px;
}
.detailback_section div:last-child a {
    font-weight: bold;
    font-size: 14px;
    letter-spacing: -0.01em;
    color: #904949;
    
}

.offers_all ul {
    padding-left: 18px;
}

.offers_all ul li a{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    align-items: center;
    letter-spacing: 0.01em;
    color: #242424;
    opacity: 0.5;
}
.offers_all ul li {
    margin-bottom: 10px;
}
.offers_all ul {
    padding-left: 18px;
    margin-top: 26px;
}
.ends_offers h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #242424;
    margin-top: 28px;
    margin-bottom: 0px;
}
.offers_redem {
    margin-top: 5px;
    text-align: center;
}
.offers_redem button {
    color: #ffffff;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    padding: 12px 34px;
    background: -webkit-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: -o-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: linear-gradient(158.95deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 90%;
    -webkit-box-shadow: 0px 3px 13px rgba(252, 97, 114, 0.65);
    box-shadow: 0px 3px 13px rgba(252, 97, 114, 0.65);
}
.declaration_offers {
    margin-top: 25px;
}
.declaration_offers p {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 120%;
    text-align: center;
    color: rgba(141, 87, 87, 0.5);
}
.declaration_offers p span {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    text-align: center;
    color: #242424;
}
.offers_price {
    display: flex;
    padding: 6% 25px;
    background: #ffffff;
    border-radius: 20px;
}
.offers_price div {
    width: 50%;
}
.offers_price label {
    font-size: 14px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    color: #7C5E8C;
    opacity: 0.4;
}
.offers_price p {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.01em;
    color: #7C5E8C;
    margin-bottom: 0px;
}

.detail_toy strike.detail_deactive {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    -ms-flex-align: center;
    align-items: center;
    color: #242424;
    opacity: 0.9;
    margin-right: 12px;
}
.detail_toy .detail_active {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    -ms-flex-align: center;
    align-items: center;
    color: #242424;
    opacity: 0.5;
}

/* End offer details page */


.custom_dtlmodel .modal-content {
    border: none !important;
    background: transparent;
}
.custom_dtlmodel .modal-header {
    border: none !important;
    color: #ffffff;
    text-align: center;
    display: block;
    padding-right: 0px;
}
.custom_dltBody {
    font-family: 'Montserrat', sans-serif;
    min-height: 159px;
    background: #ffffff;
    border-radius: 20px 20px 0px 0px;
    padding: 19px 18px;
}
.custom_dlt_content {
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    display: -ms-flexbox;
    display: flex;
    margin-top: 44px;
}

.SupportContacts_label label {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #242424;
    display: block;
}

button.support_email {
    background: -webkit-linear-gradient(305.3deg, #FF5A6C -28.17%, #F27381 366.38%) !important;
    background: -o-linear-gradient(305.3deg, #FF5A6C -28.17%, #F27381 366.38%) !important;
    background: linear-gradient(144.7deg, #FF5A6C -28.17%, #F27381 366.38%) !important;
    border-radius: 20px !important;
    font-style: normal;
    font-weight: bold;
    font-size: 14px !important;
    line-height: 20px;
    -ms-flex-align: center;
    text-align: center;
    color: #FFFFFF !important;
    text-transform: capitalize !important;
}

.support_chat {
    background: #F7E2E1 !important;
    border-radius: 20px !important;
    font-weight: bold !important;
    font-size: 14px !important;
    line-height: 20px !important;
    -ms-flex-align: center;
    padding: 18px 0px;
    align-items: center;
    text-align: center;
    color: #242424 !important;
    text-transform: capitalize !important;
}

.custom_dlt_content button {
    width: 50%;
    margin: 0px 4px;
    padding: 16px 0px;
    outline: none !important;
}
.custom_dtlmodel .modal-header {
    position: absolute;
    top: -7px;
    right: 9px;
    z-index: 9;
}
.custom_dtlmodel button.closeModel{
    background: transparent;
    border: 0px;
    border: 1px solid #ff00003d;
    border-radius: 21px;
    padding: 0px 8px 2px;
    cursor: pointer;
    outline: none !important;
}
.custom_dtlmodel .modal-content {
    position: absolute;
    bottom: 0px;
}
.custom_dtlmodel {
    height: 100%;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

/* order details css */
.order_details {
    margin-bottom: 28px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    color: #904949;
}
h3.shipmethod {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #914949;
    margin-bottom: 60px;
    margin-top: 63px;
}
.ship_submit {
    text-align: center;
}
.ship_submit button {
    color: #ffffff;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    margin-top: 35px;
    padding: 12px 34px;
    background: -webkit-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: -o-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: linear-gradient(158.95deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 80%;
    -webkit-box-shadow: 0px 3px 13px rgba(252, 97, 114, 0.65);
    box-shadow: 0px 3px 13px rgba(252, 97, 114, 0.65);
}
.std_mail {
    display: flex;
    background: #ffffff;
    padding: 24px 20px;
    border-radius: 20px;
    margin-bottom: 12px;
    border: 1px solid;
    border-color: #ffffff !important;
}
.std_mail_left {
    width: 20%;
}
.std_mail_rght {
    width: 70%;
}
.std_mail_price {
    width: 10%;
}
.std_mail_rght h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    color: #242424;
    margin-bottom: 2px;
}
.std_mail_rght p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #242424;
    opacity: 0.5;
    margin-bottom: 0px;
}
.std_mail_price label {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    text-align: right;
    color: #242424;
    margin-bottom: 0px !important;
    padding-top: 12px;
}
.std_mail_nonactive {
    outline: none !important;
    background: #fcf3f0;
    border-color: #f5e9e7 !important;
}
.orderdetails_swiper .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
    top: 45px;
    bottom: auto !important;
}
.orderdetails_swiper .swiper-pagination-bullet-active {
    opacity: 1;
    background: #FF5A6C !important;
}
.orderdetails_slides {
    padding-bottom: 60px;
}
.std_mail_left img {
    width: 40px !important;
}
.bording_backlink img {
    width: 12px !important;
}
.bording_clearlink img {
    width: 17px !important;
}
.billing_info {
    display: flex;
    margin-bottom: 10px;
}
.billing_left {
    width: 24%;
}
.billing_rght {
    width: 75%;
}
.billing_info p {
    margin-bottom: 0px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #242424;
}
.billing_info label {
    margin-bottom: 0px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #242424;
    opacity: 0.5;
}
.billing_person {
    background: #FFFFFF;
    border-radius: 20px;
    padding: 25px 28px;
}
.billing_edit a {
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: right;
    color: #242424 !important;
    cursor: pointer;
    font-weight: bold;
    display: inline-block;
}
.billing_submit {
    text-align: center;
}
.billing_submit button {
    color: #ffffff;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    margin-top: 35px;
    padding: 12px 34px;
    background: -webkit-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: -o-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: linear-gradient(158.95deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 80%;
    -webkit-box-shadow: 0px 3px 13px rgba(252, 97, 114, 0.65);
    box-shadow: 0px 3px 13px rgba(252, 97, 114, 0.65);
}
.billing_other button {
    color: #242424;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    margin-top: 17px;
    padding: 12px 34px;
    background: -o-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 80%;
}
.billing_other {
    text-align: center;
}

/* Payment Info */

.payment_person {
    /* background: #FFFFFF;
    border-radius: 20px; */
    padding: 25px 28px;
}
.payment_info {
    display: flex;
    margin-bottom: 0px;
}
.payment_left {
    width: 50%;
}
.payment_info label {
    margin-bottom: 0px;
    -ms-flex-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #242424;
}
.payment_rght {
    width: 50%;
    text-align: right;
}
.payment_info p {
    margin-bottom: 0px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: right;
    letter-spacing: 0.01em;
    color: #242424;
}
.totalprice_info label {
    font-weight: bold !important;
    font-size: 16px !important;
}
.totalprice_info p{
    font-weight: bold !important;
    font-size: 16px !important;;
}
.totalprice_info {
    margin-top: 18px;
}
.payment_submit {
    text-align: center;
}
.payment_submit button {
    color: #ffffff;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    margin-top: 0px;
    padding: 12px 34px;
    background: -webkit-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: -o-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: linear-gradient(158.95deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 80%;
    -webkit-box-shadow: 0px 3px 13px rgba(252, 97, 114, 0.65);
    box-shadow: 0px 3px 13px rgba(252, 97, 114, 0.65);
}
.card_info {
    display: flex;
}
.card_infoLeft {
    width: 70%;
}
.card_infoRght {
    display: flex;
    width: 30%;
}
.card_infoRght input{
    text-align: center;
}
.card_info div:before, .card_info div:after {
    content: none !important;
}
.card_info input {
    border-bottom: 1px solid #ebdedd;
}
.card_info {
    margin-bottom: 15px;
}
.card_infoRght span {
    color: #242424;
    opacity: 0.4;
    padding-top: 5px;
}

/* Winner model css */
.custom_winnermodel .modal-content {
    border: none !important;
}
.custom_winnermodel .modal-content {
    background-color: transparent !important;
    width: 100%;
}
.custom_winnerBody {
    font-family: 'Montserrat', sans-serif;
    min-height: 538px;
}
.open_winner {
    width: 100%;
    background: #FFFFFF;
    border-radius: 20px;
    text-align: center;
    padding: 21px 27px 30px;
    margin-top: 45px;
}
.open_winner_img {
    margin-top: 32px;
}
.open_winner_content h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #914949;
    font-family: 'OmnesSemiBold';
    margin-top: 41px;
    margin-bottom: 22px;
}
.open_winner_content p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #242424;
    margin-bottom: 0px;
    opacity: 0.5;
    font-family: 'OmnesSemiBold';
}
.open_winner button {
    color: #ffffff;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    padding: 12px 34px;
    background: -webkit-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: -o-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: linear-gradient(158.95deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 90%;
    margin-top: 42px;
}
.dolater label {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    color: #242424;
    margin-top: 18px;
    font-family: 'OmnesSemiBold';
    margin-bottom: 0px;
}
/*End of Winner model css */

/* Home main css  */
.jump_login {
    text-align: center;
    background-repeat: no-repeat;
    background-image: url("../images/Rectangle2442.png");
    padding: 35px 12px;
    background-size: cover;
}
.jump_login button {
    width: 80%;
    background: linear-gradient(158.95deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 20px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    text-transform: capitalize;
    font-family: 'OmnesSemiBold';
    padding: 12px 12px;
    outline: none !important;
}
/* End of Home main css  */

/* Free ticket movies */
div.detailticket_rmv {
    padding: 0px;
    padding-bottom: 22px;
}
.ticket_baby {
    border-radius: 20px 20px 20px 20px;
}
.ticket_babyImg {
    position: relative;
}
.ticketImges img {
    width: 100%;
}
.ticketImgs {
    position: absolute;
    bottom: -23px;
    left: 16px;
    background: #FFFFFF;
    border-radius: 20px;
    padding: 20px 10px;
}
.ticketImgs img {
    width: 50px;
    cursor: pointer;
}
.details_ticket {
    padding: 0px 22px;
}
.ticket_details {
    margin-top: 45px;
}
.ticket_details a {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    padding: 8px 20px;
    display: inline-block;
    color: #ffffff !important;
    cursor: pointer;
    background: #7C5E8C;
    border-radius: 10px;
    font-family: 'OmnesSemiBold';
}
.ticket_details a img {
    margin-right: 4px;
}
.ticket_details a span {
    vertical-align: middle;
}
.ticket_iner {
    padding: 0px 0px 20px;
}
.ticket_iner label {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: -0.02em;
    color: #242424;
    margin: 17px 0px 6px;
}
.ticket_iner p {
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    -ms-flex-align: center;
    align-items: center;
    color: #242424;
    opacity: 0.5;
}
.descp_ticket {
    border-left: 3px solid #9049490f;
    padding-left: 16px;
    padding-bottom: 5px;
    margin-bottom: 25px;
}
.descp_ticket label {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #242424;
}
.descp_ticket p {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    -ms-flex-align: center;
    align-items: center;
    letter-spacing: 0.01em;
    color: #242424;
    opacity: 0.9;
    margin-bottom: 0px;
    font-family: 'OmnesRegular';
}
.ticket_date {
    padding-left: 19px;
}
.ticket_date p {
    margin-bottom: 0px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #242424;
}
.ticket_redem {
    text-align: center;
    margin-top: 30px;
}

.claimed_txt{
    color: #7c5e8c;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    /* padding: 12px 34px; */
    /* background: linear-gradient(158.95deg, #FF5A6C -28.17%, #F27381 366.38%); */
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
}
.ticket_redem1 {
    text-align: center;
    margin-top: 10px;
}
.ticket_redem1 button {
    color: #ffffff;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    padding: 12px 34px;
    background: -webkit-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: -o-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: linear-gradient(158.95deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 90%;
    -webkit-box-shadow: 0px 3px 13px rgba(252, 97, 114, 0.65);
    box-shadow: 0px 3px 13px rgba(252, 97, 114, 0.65);
}
.ticket_redem button {
    color: #ffffff;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    padding: 12px 34px;
    background: -webkit-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: -o-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: linear-gradient(158.95deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 90%;
    -webkit-box-shadow: 0px 3px 13px rgba(252, 97, 114, 0.65);
    box-shadow: 0px 3px 13px rgba(252, 97, 114, 0.65);
}
.declaration_ticket {
    margin-top: 30px;
}
.declaration_ticket p {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 120%;
    text-align: center;
    color: rgba(141, 87, 87, 0.5);
}
.declaration_ticket p span {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    text-align: center;
    color: #242424;
}

.skilltestmodel {
    height: 100%;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}
.skilltestmodel .modal-content {
    top: 15%;
    font-family: 'Montserrat', sans-serif;
    min-height: 259px;
    background: #ffffff !important;
    border-radius: 20px;
    left: 0px !important;
    padding: 36px 32px;
}
.skilltestmodel .modal-header {
    position: absolute;
    top: -5px;
    right: 11px;
    z-index: 9;
}
.skilltestmodel button.closeModel {
    background: transparent;
    border: 0px;
    border: 1px solid #ff00003d;
    border-radius: 21px;
    padding: 0px 8px 2px;
    cursor: pointer;
}
.skilltest_label label {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #914949;
    display: block;
    font-family: 'OmnesSemiBold';
    line-height: 27px;
    margin-bottom: 0px;
}
.skilltest_content {
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    display: -ms-flexbox;
    display: flex;
    margin-top: 0px;
    margin-bottom: 28px;
}
.skilltest_content button {
    background: #F47C6A !important;
    border-radius: 30px;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 50%;
    margin: 0px 4px;
    padding: 21px 0px;
    outline: none !important;
    min-height: 52px;
    color: #fff;
    font-family: 'OmnesSemiBold';
}
.cancel_edit {
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    display: -ms-flexbox;
    margin-top: 0px;
    margin-bottom: 28px;
}
.cancel_edit button {
    background: #ffeff1;
    border-radius: 20px;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    -ms-flex-align: center;
    padding: 18px 0px;
    align-items: center;
    text-align: center;
    color: #FD6071;
    text-transform: capitalize;
    width: 50%;
    margin: 0px 4px;
    padding: 21px 0px;
    outline: none !important;
}
.skill_query p {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 27px;
    text-align: center;
    color: #4E4E4E;
    margin-bottom: 12px;
    font-family: 'OmnesSemiBold';
}
.skill_query {
    margin: 44px 0px 30px;
}
.skill_declare p{
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 120%;
    text-align: center;
    color: rgba(141, 87, 87, 0.5);
    margin-bottom: 0px;
}
button.rightAnswer {
    background: #66BD99 !important;
}
button.rightwrong{
    background: #EE374A !important;
}
.wrong_try p {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    align-items: center;
    color: #E51106;
    opacity: 0.9;
    text-align: center;
    margin-bottom: 0px;
    font-family: 'OmnesRegular';
}
.wrong_try {
    margin-bottom: 16px;
}
/* End of Free ticket movies */

/* free ticket movies code */
.ticketcode {
    text-align: center;
    margin-top: 32px;
}
.ticketcode_inner {
    display: inline-block;
    margin: auto 0;
    position: relative;
}
.ticketcode_content {
    margin-top: 34px;
}
.ticketcode_content h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 114.21%;
    text-align: center;
    /* letter-spacing: -0.02em; */
    color: #914949;
    font-family: 'OmnesSemiBold';
}
.ticketcode_content p {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #904949;
    opacity: 0.5;
    -ms-flex-align: center;
    align-items: center;
    margin: 0px;
    font-family: 'OmnesRegular';
}
.ticketcode_submit {
    text-align: center;
}
.ticketcode_submit button {
    color: #ffffff;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    margin-top: 0px;
    padding: 16px 34px;
    background: -webkit-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: -o-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: linear-gradient(158.95deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 90%;
    box-shadow: 0 18px 20px -18px #FF5A6C;
    margin-bottom: 28px;
}
.ticketcode_submit p {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 120%;
    text-align: center;
    color: rgba(144, 73, 73, 0.5);
    margin-bottom: 0px;
}
.ticketcode_copy p {
    margin-bottom: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 114.21%;
    text-align: center;
    color: #242424;
}
.ticketcode_inr {
    background: #FFEFF1;
    border: 2px dotted #fdd6d8;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 18px 24px;
    outline: none !important;
}
.ticketcode_inr label {
    margin-bottom: 0px !important;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 114.21%;
    letter-spacing: -0.02em;
    color: #904949;
}
.ticketcode_inr span {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 11px;
    align-items: center;
    text-align: center;
    letter-spacing: -0.01em;
    color: #242424;
    padding-top: 9px;
    float: right;
    display: block !important;
}
.ticketcode_copy {
    margin: 55px 0px 55px;
}
.ticketcode_inner img {
    border-radius: 20px;
}
div.common_main_tkct{
    padding: 20px 29px;
    border: 1px solid #ffeff1ad;
    background: transparent;
    min-height: 657px;
    position: relative;
}
/* End free ticket movies code */

/* Kids go free movies code */
.common_main_tkct .clearlink {
    top: 14px !important;
    right: 22px !important;
}
/* End Kids go free movies code  */


/* Kids go free movies Lego code */
.common_main_tkct .clearlink{
    top: 14px !important;
    right: 22px !important;
}
.tkct_lego{
    /* background: linear-gradient(360deg, #FADA47 0%, rgba(250, 218, 71, 0) 100%) !important; */
    /* background: #fada47 !important; */
    background-size: cover !important;
    /* background-image: url("../images/2_2_1.1.png") !important; */
}
.tkct_lego img{
    border-radius: initial !important;
}
.tkct_lego .ticketcode_content p, .tkct_lego .ticketcode_content h1{
    color: #000000 !important;
}
.tkct_lego .ticketcode_copy p{
    color: #000000 !important;
    opacity: 0.4;
}
.tkct_lego .ticketcode_inr label{
    color: #D02C22 !important;
}
.tkct_lego .ticketcode_inr span{
    color: #000000 !important;
}
.tkct_lego .ticketcode_submit button{
    background: #D02C22 !important;
}
.tkct_legoBnr img {
    width: 100%;
}
/* End Kids go free movies Lego code  */

/* payment successfully done */
.pay_successmodel .modal-content {
    border: none !important;
}
.pay_successmodel .modal-content {
    background-color: transparent !important;
    width: 100%;
}
.pay_successchildBody {
    font-family: 'Montserrat', sans-serif;
    min-height: 538px;
}
.pay_success {
    width: 100%;
    background: #FFFFFF;
    border-radius: 20px;
    text-align: center;
    padding: 21px 27px 30px;
    margin-top: 45px;
}
.pay_success_img {
    margin-top: 32px;
}
.pay_success_content h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    /* letter-spacing: -0.02em; */
    color: #914949;
    font-family: 'OmnesSemiBold';
    margin-top: 41px;
    margin-bottom: 22px;
}
.pay_success_content p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #242424;
    margin-bottom: 0px;
    opacity: 0.5;
    font-family: 'OmnesMedium';
}
.pay_success button {
    color: #ffffff;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    padding: 12px 34px;
    background: -webkit-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: -o-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: linear-gradient(158.95deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 90%;
    margin-top: 40px;
}
button.pay_cancelorder {
    margin-top: 7px;
    font-size: 14px;
    line-height: 20px;
    background: transparent;
    color: #242424;
    font-family: 'OmnesRegular';
}
/*End of payment successfully done */

.orderbtn img{
    vertical-align: bottom;
    margin-right: 5px;
}
.orderbtn button {
    background: #66BD99;
    border-radius: 14px;
    border: none;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    padding: 10px 15px;
    outline: none !important;
    cursor: pointer;
    margin-top: 26px;
    font-family: 'OmnesSemiBold';
}

/* Home page screen two css */
.hometwo_upr {
    /* background: #faeeed;
    border-radius: 20px;
    margin-top: 25px;
    padding-bottom: 100px; */
    padding-bottom: 75px;
    border-radius: 20px;
}
/* .hometwo_hendupr {
    background: #fdf7f7;
    border-radius: 20px;
} */
.hometwo_hend {
    text-align: center;
    width: 100%;
    background: #FFFFFF;
    padding: 20px 24px 22px;
    border-radius: 20px;
    box-shadow: 0px 0px 4px 0px #0000001A;
}
.hometwo_hend h3 {
    color: #242424;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    margin: 14px 0px 6px;
    font-family: 'OmnesSemiBold';
}
.hometwo_hend p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    color: #242424;
    opacity: 0.5;
    margin-bottom: 0px;
    font-family: 'OmnesRegular';
}
/* .hometwo_redem {
    text-align: center;
    padding: 22px 12px 30px;
} */

.hometwo_redem button {
    background: #F47C6A !important;
    border-radius: 30px;
    box-shadow: 0px 4px 8px #f27d6ead;
    outline: none !important;
    padding: 12px 34px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    min-height: 52px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 90%;
    color: #ffffff;
}

.hometwo_redem_session button {
    color: #ffffff;
    outline: none !important;
    padding: 12px 34px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 90%;
    font-family: 'OmnesSemiBold';
    /* box-shadow: 0 18px 20px -18px #FF5A6C; */
    background: #F47C6A !important;
    border-radius: 30px;
    box-shadow: 0px 2px 7px 2px rgba(166,124,124,0.44);
    min-height: 52px;
}

.hometwo_redem_session_safari button {
    color: #ffffff;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    padding: 12px 34px;
    background: -webkit-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: -o-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: linear-gradient(158.95deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 90%;
    box-shadow: 0 18px 20px -18px #FF5A6C;
}
/* End of Home page screen two css */

/* Notification page css */
.notify {
    margin-bottom: 19px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
}
.notify_left {
    width: 25%;
    /* position: relative;
    float: left; */
}
.notify_left a{
    position: absolute;
    bottom: -3px;
    right: 12px;
}
.notify_rght {
    width: 75%;
    padding-left: 10px;
    /* float: right; */
}
.userCstmImg img {
    width: 32px !important;
    border: 2px solid #ffffff;
    border-radius: 60px !important;
}
.userCstmImg {
    right: -3px !important;
    bottom: -8px !important;
}
.notify_rght p {
    font-style: normal;
    font-size: 14px;
    line-height: 130%;
    margin-bottom: 0px;
    color: #242424;
}
.notify_rght span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #242424;
    opacity: 0.5;
    margin: 0px;
    font-family: 'OmnesMedium';
}
.notify_rght p label {
    font-weight: bold;
    display: inline !important;
}
.notify_left a img {
    width: 30px;
}
.notify_section label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #4E4E4E;
    margin: 0px;
    font-family: 'OmnesMedium';
}
.notify_left_image {
    width: 68px;
    height: 68px;
    border-radius: 20px !important;
}
.notify_section {
    margin-bottom: 40px;
}

.notify_acpt button {
    font-weight: bold;
    font-size: 14px;
    line-height: 11px;
    align-items: center;
    text-align: center;
    color: #FFFFFF !important;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    padding: 14px 25px;
    background: #F47C6A !important;
    border-radius: 10px;
    font-style: normal;
    -ms-flex-align: center;
    text-transform: capitalize;
}
.notify_acpt button span{
    color: #FFFFFF !important;
    opacity: initial !important;
    background-color: transparent !important;
}
.clear{
    clear: both
}
.notify_acpt {
    margin-top: 12px;
    margin-bottom: 15px;
    width: 75%;
    text-align: left;
}
.notify_main {
    /* background: transparent !important; */
    /* border: 1px solid #ffeff1; */
    outline: none;
}
.notify_acpt button:last-child span {
    color: #4E4E4E !important;
}
/*End of Notification page css */

/* All Notification css */

.all_notify {
    display: flex;
    margin-bottom: 20px;
}
.all_notify_left {
    width: 25%;
    position: relative;
}
.all_notify_left img {
    width: 59px;
}
.al_notify_rght {
    width: 75%;
}
.all_notify_rght p {
    font-style: normal;
    font-size: 14px;
    line-height: 130%;
    margin-bottom: 0px;
    color: #242424;
}
.all_notify_rght span {
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    align-items: center;
    letter-spacing: 0.01em;
    color: #242424;
    opacity: 0.5;
}
.all_notify_rght p label {
    font-weight: bold;
    display: inline !important;
}
.all_notify_rght {
    width: 65%;
    padding-top: 10px;
}
.all_notify_close {
    width: 10%;
    text-align: right;
    padding-top: 10px;
}
.all_notify_close img {
    cursor: pointer;
}
.all_notify_section {
    margin-top: 33px;
}

.notify_head {
    display: -ms-flexbox;
    display: flex !important;
    margin: 0px 16px;
}
.notify_headLeft, .notify_headRght {
    width: 50%;
}
.notify_headLeft label {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 29px;
    letter-spacing: -0.02em;
    color: #914949;
    font-family: 'OmnesMedium';
    margin-top: 0px;
}
.notify_headRght {
    text-align: right;
}
.notify_headRght a{
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 16px;
    /* letter-spacing: -0.02em; */
    color: #ffffff !important;
    border-radius: 2px;
    background: linear-gradient(117.02deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 15px 15px 15px 15px;
    padding: 11px 12px;
    font-family: 'OmnesSemiBold';
    box-shadow: 0px 3px 7px rgba(252, 97, 114, 0.65);
}
/* End of All Notification css */

/* baby moisturiser css */
.mois_babyImg {
    position: relative;
}
.moisImges img {
    width: 100%;
    border-radius: 0px 0px 20px 20px;
}
.moisImgs {
    position: absolute;
    bottom: -23px;
    left: 16px;
}
.moisImgs img {
    width: 62px;
    border-radius: 20px;
    cursor: pointer;
    box-shadow: -1px 3px 12px #c68b856b;
}
.details_mois {
    padding: 0px 22px;
}
.mois_details {
    margin-top: 45px;
}
.mois_details a {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    padding: 8px 20px;
    display: inline-block;
    color: #FF9B80 !important;
    border-radius: 14px;
    cursor: pointer;
    background: #ff9b801a;
}
.mois_details a img {
    margin-right: 4px;
}
.mois_details a span {
    vertical-align: middle;
}
.mois_iner {
    padding: 0px 0px 22px;
}
.mois_iner label {
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 114.21%;
    letter-spacing: -0.02em;
    color: #914949;
    margin: 17px 0px 6px;
}
.mois_iner p {
    margin-bottom: 0px;
    -ms-flex-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    align-items: center;
    color: #242424;
    opacity: 0.5;
}
.descp_mois {
    border-left: 3px solid #f7edec;
    padding-left: 16px;
    padding-bottom: 5px;
    margin-bottom: 25px;
}
.descp_mois label {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #242424;
}
.descp_mois p {
    font-style: normal;
    font-weight: normal;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0px;
    font-family: 'OmnesRegular';
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #242424;
    opacity: 0.9;
}
.mois_redem {
    text-align: center;
    margin-top: 30px;
}
.mois_redem button {
    color: #66bd99;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    padding: 14px 34px;
    background: -o-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 90%;
    background: #deebe3;
}
.declaration_mois {
    margin-top: 30px;
}
.declaration_mois p {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 120%;
    text-align: center;
    color: rgba(141, 87, 87, 0.5);
}
.moisback_section {
    display: flex;
    padding: 0px 22px;
    position: absolute;
    width: 100%;
    top: 14px;
    left: 0;
}
.moisback_secone, .moisback_secthree {
    width: 20%;
    height: 20%;
}
.moisback_secone_winner {
    width: 20%;
}
.moisback_sectwo {
    text-align: center;
    width: 60%;
}
.moisback_secthree {
    text-align: right;
    padding-top: 8px;
}
.moisback_section img {
    border-radius: 20px;
    cursor: pointer;
    max-width: 49px;
}
.mois_redem img {
    margin-right: 12px;
}
.declaration_mois p span {
    color: rgba(141, 87, 87, 0.91);
}
/* End of baby moisturiser css */

/* upload review css */
.moisback_sectwo label {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    color: #4e4e4e;
    opacity: .5;
    margin-bottom: 28px;
    margin-top: 20px;
    font-family: "OmnesMedium";
    display: block;
}
.upload_rvewMain{
    margin-top: 85px;
}
.upload_rvew {
    text-align: center;
}
.upload_rvew_inner {
    display: inline-block;
    margin: auto 0;
    position: relative;
    min-height: 100px;
}
.upload_rvew_content {
    margin-top: 40px;
    min-height: 132px;
    padding: 0px 37px;
}
.upload_rvew_content h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 114.21%;
    text-align: center;
    /* letter-spacing: -0.02em; */
    color: #914949;
    font-family: 'OmnesSemiBold';
}
.upload_rvew_content p {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #904949;
    opacity: 0.5;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 0px;
    font-family: 'OmnesRegular';
}
.upload_rvew_submit {
    text-align: center;
}
.upload_rvew_submit button {
    color: #ff9b80;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    margin-top: 0px;
    padding: 14px 34px;
    background: -o-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 90%;
    background: #ffebe5;
}
.upload_review {
    background: transparent;
    border: 1px solid #ffebe5c7;
}
/* End of upload review css */

/* baby moisturiser two css */
.uploadbtn_redem {
    text-align: center;
    margin-top: 30px;
}
.uploadbtn_redem button {
    color: #ff9b80;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    padding: 14px 34px;
    background: -o-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 90%;
    background: #f9b1a754;
}
/* End of baby moisturiser two css */


/* Request baby moisturiser two css */
.uploadrqest_redem {
    text-align: center;
    margin-top: 30px;
}
.uploadrqest_redem button {
    color: #ffffff;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    padding: 14px 34px;
    background: -webkit-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: -o-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: linear-gradient(158.95deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 90%;
    -webkit-box-shadow: 0px 3px 13px rgba(252, 97, 114, 0.65);
    box-shadow: 0px 3px 13px rgba(252, 97, 114, 0.65);
}
.mois_inerhght {
    padding-top: 45px;
}
/* End of Request baby moisturiser two css */

/* start products for reviews */
.productReview_upr {
    background: #ffffff;
    border-radius: 20px;
    margin-bottom: 18px;
}
.productReview_inr {
    background: #fdf7f7;
    border-radius: 20px;
}
.productReview_img {
    text-align: center;
    width: 100%;
    position: relative;
}
.productReview_img img {
    width: 100%;
    border-radius: 20px;
}
.productReview_text {
    padding: 22px 22px;
}
.productReview_text label {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: -0.02em;
    color: #242424;
    margin-bottom: 4px;
}
.productReview_text p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #242424;
    opacity: 0.5;
    margin-bottom: 0px;
}
.prodtRvw_lnk label {
    padding-top: 0px !important;
}
.reviewImg_smal {
    position: absolute;
    left: 25px;
    bottom: 28px;
    border-radius: 14px;
    cursor: pointer;
    box-shadow: -1px 3px 12px #c68b856b;
    background: #ffff;
    padding: 11px 7px;
}
.space_section {
    height: 42px;
}
/* End of products for reviews */

/* storyheadlinedetails */
.dtl_detailback_section {
    padding: 0px 22px;
    position: absolute;
    width: 100%;
    top: 14px;
}
.dtl_contests_baby {
    border-radius: 20px 20px 20px 20px;
}
.dtl_sub_babyImg {
    position: relative;
}
.dtl_subImges img {
    width: 100%;
}
.dtl_details_sub {
    padding: 0px 22px;
    padding-bottom: 13%;
}
.paddingbottom{
    padding-bottom: 10%;
}
.dtl_descp_sub {
    border-left: 2px solid #f7ecea;
    padding-left: 16px;
    margin-bottom: 25px;
}
.dtl_descp_sub label {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #242424;
}
.dtl_descp_sub p {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    -ms-flex-align: center;
    align-items: center;
    /* letter-spacing: 0.01em; */
    color: #242424;
    opacity: 0.9;
    margin-bottom: 0px;
    font-family: 'OmnesRegular';
}
.dtl_descp_sub a {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    align-items: center;
    /* letter-spacing: 0.01em; */
    opacity: 0.9;
    margin-bottom: 0px;
    font-family: 'OmnesRegular';
    color: #242424 !important;
    font-family: 'OmnesRegular';
}
.dtl_descp_sub a:hover{
    text-decoration: none;
}
.dtl_Imges{
    /* background-image: url("../images/Rectangle2.9.png"); */
    background-size: cover;
    min-height: 267px;
    text-align: center;
    padding: 68px 0px 75px;
    border-radius: 0px 0px 20px 20px;
}
.dtl_babyImg {
    margin-bottom: 25px;
}
.vdo_show{
    margin-bottom: 25px;
}
.dtl_suba_redem {
    text-align: center;
}
button.dtl_subshare {
    background: #f5d6e3;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    color: #FF5A6C;
    border-radius: 20px;
    padding: 12px 34px;
    width: 90%;
    margin-bottom: 12px;
}
.dtl_suba_redem button.dtl_subaenter {
    color: #ffffff;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    padding: 12px 34px;
    background: -webkit-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: -o-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: linear-gradient(158.95deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 90%;
    -webkit-box-shadow: 0px 3px 13px rgba(252, 97, 114, 0.65);
    box-shadow: 0px 3px 13px rgba(252, 97, 114, 0.65);
}
.dtl_suba_redem button {
    outline: none !important;
}
.dtl_Imges h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 114.21%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #FFFFFF;
}
.dtl_Imges p {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 20px;
    font-family: 'OmnesRegular';
}
.dtl_Imges button{
    background: linear-gradient(147deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 20px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    width: 157px;
    text-transform: capitalize;
    padding: 12px 12px;
    font-family: 'OmnesSemiBold';
    outline: none;
}
.fb_connect {
    background: #FFFFFF;
    opacity: 0.9;
    padding: 29px 0px;
    padding-bottom: 60px;
    text-align: center;
    border-radius: 20px 20px 0px 0px;
    margin-top: 42px;
}
div.detailspace_rmv_prc {
    padding: 0px;
    padding-bottom: 0px;
}
.fb_connect p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    align-items: center;
    color: #242424;
    opacity: 0.5;
    margin-top: 12px;
    margin-bottom: 0px;
}
.vdo_show img {
    width: 100%;
}
/* end of storyheadlinedetails */

/*prize css */
.custom_prizemodel .modal-content {
    background-color: transparent !important;
    width: 100%;
}
.custom_prizemodel .modal-content {
    border: none !important;
}
.custom_prizeBody {
    font-family: 'Montserrat', sans-serif;
    min-height: 538px;
}
.open_prize {
    width: 100%;
    background: #FFFFFF;
    border-radius: 20px;
    text-align: center;
    padding: 21px 27px 30px;
    margin-top: 45px;
}
.open_prize_img {
    margin-top: 32px;
}
.open_prize_content h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    /* letter-spacing: -0.02em; */
    color: #914949;
    font-family: 'OmnesRegular';
    margin-top: 41px;
    margin-bottom: 22px;
}
.open_prize_content p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #242424;
    margin-bottom: 0px;
    opacity: 0.5;
    font-family: 'OmnesMedium';
}
.open_prize button {
    color: #ffffff;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    padding: 12px 34px;
    background: -webkit-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: -o-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: linear-gradient(158.95deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 90%;
    margin-top: 42px;
}
.prizelater label {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    color: #242424;
    margin-top: 18px;
    font-family: 'OmnesSemiBold';
    margin-bottom: 0px;
    cursor: pointer;
}
/* Cute Baby Elephant Pillow */
.contests_baby1 {
    border-radius: 20px 20px 20px 20px;
}
.freeies_babyImg1 {
    position: relative;
}
.freebiesImges1 img {
    width: 100%;
}
.freebiesImgs1 {
    position: absolute;
    bottom: -23px;
    left: 16px;
}
.freebiesImgs1 img {
    width: 50px;
    cursor: pointer;
    margin-right: 8px;
}
.details_freebies1 {
    padding: 0px 22px;
}
.instant_details1 {
    margin-top: 45px;
}
.instant_details1 a {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    padding: 8px 20px;
    display: inline-block;
    color: #FF9B80 !important;
    border-radius: 14px;
    cursor: pointer;
    background: #ff9b801a;
}
.instant_details1 a img {
    margin-right: 4px;
}
.instant_details1 a span {
    vertical-align: middle;
}
.freebies_iner1 {
    padding: 0px 0px 20px;
}
.freebies_iner1 label {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: -0.02em;
    color: #242424;
    margin: 17px 0px 6px;
}
.freebies_iner1 p {
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    -ms-flex-align: center;
    align-items: center;
    color: #242424;
    opacity: 0.5;
}
.descp_freebies1 {
    border-left: 2px solid #f7ecea;
    padding-left: 16px;
    padding-bottom: 5px;
    margin-bottom: 25px;
}
.descp_freebies1 label {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #242424;
}
.descp_freebies1 p {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    -ms-flex-align: center;
    align-items: center;
    /* letter-spacing: 0.01em; */
    color: #242424;
    opacity: 0.9;
    margin-bottom: 0px;
    font-family: 'OmnesRegular';
}
.freebies_redem1 {
    text-align: center;
    margin-top: 30px;
}
.freebies_redem1 button {
    color: #ffffff;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    padding: 12px 34px;
    background: -webkit-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: -o-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: linear-gradient(158.95deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 90%;
    -webkit-box-shadow: 0px 3px 13px rgba(252, 97, 114, 0.65);
    box-shadow: 0px 3px 13px rgba(252, 97, 114, 0.65);
}
.declaration_frrebies1 {
    /* margin-top: 30px; */
}
.declaration_frrebies1 p {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 120%;
    text-align: center;
    color: rgba(141, 87, 87, 0.5);
}
.declaration_frrebies1 p span {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    text-align: center;
    color: #242424;
}
.prize_ul ul {
    padding-left: 18px;
}
.prize_ul ul li {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #242424;
    opacity: 0.9;
}
.prize_ul {
    margin-top: 20px;
}
.instant_upr {
    background: #faeeed;
    border-radius: 20px;
    margin-top: 25px;
}
.instant_hendupr {
    background: #fdf7f7;
    border-radius: 20px;
}
.instant_hend {
    text-align: center;
    width: 100%;
    background: #FFFFFF;
    padding: 28px 24px;
    border-radius: 20px;
}
.instant_hend h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    /* letter-spacing: -0.01em; */
    color: #7C5E8C;
    font-family: 'OmnesSemiBold';
    margin-top: 20px;
}
.instant_hend p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    color: #7C5E8C;
    opacity: 0.6;
    margin-bottom: 0px;
}
.instant_redem {
    text-align: center;
    padding: 12px 20px;
}
.instant_redem button {
    color: #ffffff;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    padding: 12px 34px;
    background: -webkit-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: -o-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: linear-gradient(158.95deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 90%;
    -webkit-box-shadow: 0px 3px 13px rgba(252, 97, 114, 0.65);
    box-shadow: 0px 3px 13px rgba(252, 97, 114, 0.65);
    margin: 22px 0px;
}
.declaration_instant p {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 120%;
    text-align: center;
    color: rgba(141, 87, 87, 0.5);
}
.declaration_instant p span {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    text-align: center;
    color: #242424;
}
.show_winer {
    display: flex;
    background: #faeeed;
    padding: 20px 20px;
    border-radius: 20px;
    margin-top: 28px;
}
.show_winer_rght {
    text-align: right;
}
.show_winer div {
    width: 50%;
}
.show_winer_left span {
    font-weight: bold;
    font-size: 14px;
    line-height: 114.21%;
    letter-spacing: -0.01em;
    color: #242424;
    margin-left: 16px;
}
.show_winer_rght a {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 114.21%;
    letter-spacing: -0.01em;
    background: #242424;
    border-radius: 9px;
    color: #fff !important;
    padding: 7px 13px;
}
.show_winer_rght img {
    margin-left: 16px;
    cursor: pointer;
}
/* End of Cute Baby Elephant Pillow */

/* all winners */
.all_winer_section {
    margin-top: 42px;
}
.all_winer {
    display: flex;
    margin-bottom: 18px;
}
.all_winer_left {
    width: 25%;
    position: relative;
}
.all_winer_left img {
    width: 68px;
    border-radius: 45px;
}
.all_winer_rght {
    width: 75%;
    padding-top: 4px;
}
.all_winer_rght label {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    color: #242424;
    margin-bottom: 0px;
}
.all_winer_rght p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 138.05%;
    align-items: center;
    letter-spacing: 0.01em;
    color: #242424;
    opacity: 0.5;
}
.all_winer_left label {
    background: #f3e8f0;
    width: 68px;
    height: 68px;
    padding: 20px 0px;
    display: inline-block;
    border-radius: 40px;
    text-align: center;
    font-style: normal;
    font-weight: 900;
    font-size: 26px;
    line-height: 114.21%;
    color: #8E53A6;
    text-transform: uppercase;
    margin-bottom: 0px;
}
/*End of all winners */

/* Family members css */
.all_family_section {
    margin-top: 40px;
    height: 320px;
    overflow-x: hidden;
    padding-bottom: 12px;
    overflow-y: auto !important;
}
.all_family {
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20px;
}
.all_family_left {
    width: 25%;
    position: relative;
}
.all_family_left label {
    background: #f3e8f0;
    width: 68px;
    height: 68px;
    padding: 20px 0px;
    display: inline-block;
    border-radius: 40px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 34px;
    color: #8E53A6;
    text-transform: uppercase;
    margin-bottom: 0px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: 'OmnesSemiBold';
}
.all_family_rght {
    width: 65%;
    padding-top: 4px;
}
.all_family_tick {
    padding-top: 17px;
    width: 10%;
    text-align: right;
}
.all_family_rght label {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    color: #242424;
    margin-bottom: 0px;
}
.all_family_rght p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 138.05%;
    -ms-flex-align: center;
    align-items: center;
    letter-spacing: 0.01em;
    color: #242424;
    opacity: 0.5;
}
.all_family_left img {
    width: 68px;
    border-radius: 45px;
}
.all_family_tick a {
    border-radius: 40px;
    padding: 8px 6px;
    background: #edefe9;
    display: inline-flex;
}
.all_family_tick span {
    border-radius: 40px;
    padding: 8px 6px;
    display: -ms-inline-flexbox;
    display: inline-flex;
}
.familyadd_upr, .allchild_submit {
    /* text-align: center;
    margin-top: 30px;
    position: absolute;
    left: 10%;
    right: 10%;
    bottom: 82px; */
    
    text-align: center;
    margin-top: 13px !important;
    position: initial !important;
    left: initial !important;
    right: initial !important;
    bottom: initial !important;
    padding-bottom: 44px !important;
    width: 100%;
}
.familyadd_upr, .allchild_submit_child {
    /* text-align: center;
    margin-top: 30px;
    position: absolute;
    left: 10%;
    right: 10%;
    bottom: 82px; */
    
    text-align: center;
    margin-top: 15px !important;
    position: initial !important;
    left: initial !important;
    right: initial !important;
    bottom: initial !important;
    width: 100%;
}

.familyadd_upr button {
    cursor: pointer;
    color: #ffffff;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    padding: 12px 34px;
    background: -webkit-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: -o-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: linear-gradient(158.95deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 99px;
    -webkit-box-shadow: 0px 3px 13px rgba(252, 97, 114, 0.65);
    box-shadow: 0px 3px 13px rgba(252, 97, 114, 0.65);
}

.allchild_submit button, .allchild_submit_child button {
    cursor: pointer;
    color: #ffffff;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    padding: 12px 34px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    box-shadow: 0px 3px 13px rgb(252 97 114 / 65%);
    background: linear-gradient(
        117.02deg
        , #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 20px;
    width: 90%;
    min-height: 52px;
}


/* End of family members css */

.login_opt button {
    outline: none !important;
}

/* css cute baby elephant pillow exclusive */
.contests_baby2 {
    border-radius: 20px 20px 20px 20px;
}
.freeies_babyImg2 {
    position: relative;
}
.freebiesImges2 img {
    width: 100%;
}
.freebiesImgs2 {
    position: absolute;
    bottom: -23px;
    left: 16px;
}
.freebiesImgs2 img {
    width: 50px;
    cursor: pointer;
    margin-right: 8px;
}
.details_freebies2 {
    padding: 0px 22px;
}
.instant_details2 {
    margin-top: 20px;
    margin-bottom: 10px;
    min-height: 1px;
}
/* .instant_details2 a:first-child {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    padding: 8px 20px;
    display: inline-block;
    color: #FF9B80 !important;
    border-radius: 14px;
    cursor: pointer;
    background: #ff9b801a;
    margin-right: 10px;
} */

.instant_tags {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    padding: 8px 20px;
    display: inline-block;
    color: #FF9B80 !important;
    border-radius: 14px;
    cursor: pointer;
    background: #ff9b801a;
    margin-right: 5px;
}

.pending_tags {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    padding: 8px 20px;
    display: inline-block;
    color: red !important;
    border-radius: 14px;
    cursor: pointer;
    background: #ff9b801a;
    margin-right: 10px;
}

.entered_points{
    background: #eef3ef !important;
    padding: 8px 20px !important;
    color: #66bd99 !important;
    font-weight: bold !important;
    font-size: 14px !important;
    line-height: 16px !important;
    border-radius: 14px !important;
    font-family: 'OmnesMedium';
}
.fiveone_pts a {
    background: #eef3ef;
    padding: 8px 20px;
    color: #66bd99 !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    border-radius: 14px;
    font-family: 'OmnesMedium';
}
.instant_details2 a {
    margin-right: 8px;
}
.instant_details2 a span {
    vertical-align: middle;
}
.freebies_iner2 {
    padding: 0px 0px 20px;
}
.freebies_iner2 label {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: -0.02em;
    color: #242424;
    margin: 17px 0px 6px;
}
.freebies_iner2 p {
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    -ms-flex-align: center;
    align-items: center;
    color: #242424;
    opacity: 0.5;
}
.descp_freebies2 {
    border-left: 2px solid #f7ecea;
    padding-left: 16px;
    padding-bottom: 5px;
    margin-bottom: 25px;
}
.descp_freebies2 label {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #242424;
}
.descp_freebies2 p {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    -ms-flex-align: center;
    align-items: center;
    /* letter-spacing: 0.01em; */
    color: #242424;
    opacity: 0.9;
    margin-bottom: 0px;
    font-family: 'OmnesRegular';
}
.prize_ul2 {
    margin-top: 20px;
}
.descp_freebies2 label {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #242424;
}
.prize_ul2 ul {
    padding-left: 18px;
}
.prize_ul2 ul li {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #242424;
    opacity: 0.9;
}
.instant_upr2 {
    background: #FFFFFF;
    border-radius: 20px;
    margin-top: 25px;
}
.instantSetPosition{
    position: relative;
}
.instant_upr5 {
    background: #fff;
    border-radius: 20px;
    margin-top: 25px;
}
.instant_hendupr2 {
    background: #fdf7f7;
    border-radius: 20px;
}
.instant_hendupr2 .instant_hend2 {
    background: #FFFFFF;
    box-shadow: 0px 1px 4px 0px #0000001A;
}
.instant_hendupr2SetBg{
    background: transparent;
}
.instant_hend2 {
    text-align: center;
    width: 100%;
    background: #FFFFFF;
    padding: 28px 36px;
    border-radius: 20px;
}
.instant_hend2 h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.01em;
    color: #7C5E8C;
    margin-top: 20px;
    font-family: 'OmnesSemiBold';
}
.instant_hend2 p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    color: #7C5E8C;
    opacity: 0.6;
    margin-bottom: 12px;
    font-family: 'OmnesRegular' !important;
}
.instant_redem2 {
    padding: 12px 20px;
    outline: none;
}
.instant_redem2 button {
    /* color: #ffffff;
    font-family: 'OmnesRegular';
    outline: none !important;
    padding: 12px 34px;
    background: -webkit-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: -o-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: linear-gradient(158.95deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
    -webkit-box-shadow: 0px 3px 13px rgba(252, 97, 114, 0.65);
    box-shadow: 0px 3px 13px rgba(252, 97, 114, 0.65);
    margin: 22px 0px; */

    color: #fff;
    outline: none!important;
    padding: 12px 34px;
    background: #F47C6A !important;
    border-radius: 30px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
    margin: 22px 0;
    /* box-shadow: 0px 4px 8px #f27d6ead; */
    box-shadow: 0 18px 20px -18px #FF5A6C;
    min-height: 52px;
    font-family: 'OmnesSemiBold';
}
.declaration_instant2 p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: rgb(36, 36, 36, 0.5);
    margin-bottom: 0px;
    font-family: 'OmnesRegular';
}
.declaration_instant2 span {
    /* font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 120%;
    text-align: center;
    color: rgba(141, 87, 87, 0.5);
    margin-bottom: 0px; */
    outline: none;
}

.declaration_instant_nestle p {
    font-style: normal;
    font-weight: normal;
    font-size: 11.5px;
    line-height: 120%;
    text-align: center;
    color: rgba(141, 87, 87, 0.5);
    margin-bottom: 0px;
}
.show_winer2 {
    display: -ms-flexbox;
    display: flex;
    background: #faeeed;
    padding: 20px 20px;
    border-radius: 20px;
    margin-top: 28px;
}
.show_winer2 div {
    width: 50%;
}
.show_winer_left2 span {
    font-weight: bold;
    font-size: 14px;
    line-height: 114.21%;
    letter-spacing: -0.01em;
    color: #242424;
    margin-left: 16px;
}
.show_winer_rght2 a {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 114.21%;
    letter-spacing: -0.01em;
    background: #242424;
    border-radius: 9px;
    color: #fff !important;
    padding: 7px 13px;
}
.show_winer_rght2 img {
    margin-left: 16px;
    cursor: pointer;
}
.show_winer_rght2 {
    text-align: right;
}
a.nationalCust img{
    background: #FFFFFF;
    border-radius: 15px;
    padding: 20px 4px;
}
/* end of css cute babye lephant pillow exclusive */


/* css cute baby elephant pillow Enter */
.contests_baby3 {
    border-radius: 20px 20px 20px 20px;
}
.freeies_babyImg3 {
    position: relative;
}
.freebiesImges3 img {
    width: 100%;
}
.freebiesImgs3 {
    position: absolute;
    bottom: -23px;
    left: 16px;
}
.freebiesImgs3 img {
    width: 50px;
    cursor: pointer;
    margin-right: 8px;
}
.details_freebies3 {
    padding: 0px 22px;
}
.instant_details3 {
    margin-top: 45px;
}
.instant_details3 a:last-child {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    padding: 8px 20px;
    display: inline-block;
    color: #F38181 !important;
    border-radius: 14px;
    cursor: pointer;
    background: #ff9b801a;
}
.instant_details3 a:first-child{
    padding: 5px 10px 8px;
    color: #66bd99 !important;
    background: #66BD99;
    border-radius: 14px;
    margin-right: 10px;
}
.fiveone_pts3 a {
    color: #ee5d6e !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    /* background: #66BD99; */
    border-radius: 14px;
    position: relative;
}
.instant_details3 a:last-child img {
    margin-right: 8px;
}
.instant_details3 a:last-child span {
    vertical-align: middle;
}
.freebies_iner3 {
    padding: 0px 0px 20px;
}
.freebies_iner3 label {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: -0.02em;
    color: #242424;
    margin: 17px 0px 6px;
}
.freebies_iner3 p {
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    -ms-flex-align: center;
    align-items: center;
    color: #242424;
    opacity: 0.5;
}
.descp_freebies3 {
    border-left: 2px solid #f7ecea;
    padding-left: 16px;
    padding-bottom: 5px;
    margin-bottom: 25px;
}
.descp_freebies3 label {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #242424;
}
.descp_freebies3 p {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    -ms-flex-align: center;
    align-items: center;
    letter-spacing: 0.01em;
    color: #242424;
    opacity: 0.9;
    margin-bottom: 0px;
    font-family: 'OmnesRegular';
}
.prize_ul3 {
    margin-top: 20px;
}
.descp_freebies3 label {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #242424;
}
.prize_ul3 ul {
    padding-left: 18px;
}
.prize_ul3 ul li {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #242424;
    opacity: 0.9;
}
.instant_upr3 {
    background: #faeeed;
    border-radius: 20px;
    margin-top: 25px;
}
.instant_hendupr3 {
    background: #fdf7f7;
    border-radius: 20px;
}
.instant_hend3 {
    text-align: center;
    width: 100%;
    background: #FFFFFF;
    padding: 20px 36px 28px;
    border-radius: 20px;
}
.instant_hend3 h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    color: #AE86E0;
    margin-top: 0px;
    font-family: 'OmnesSemiBold';
}
.instant_hend3 p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    color: #4E4E4E;
    margin-bottom: 0px;
    font-family: 'OmnesRegular';
}
.social_activity { 
    display: flex;
    justify-content: space-between;
    padding: 14px 18px 14px;
    background: #fcfcfc;
    width: 90%;
    margin: 12px auto 14px;
    border-radius: 20px; 
}
.show_winer3 {
    display: flex;
    background: #faeeed;
    padding: 20px 20px;
    border-radius: 20px;
    margin-top: 10px;
}
.show_winer3 div {
    width: 50%;
}
.show_winer_left3 span {
    font-weight: bold;
    font-size: 14px;
    line-height: 114.21%;
    letter-spacing: -0.01em;
    color: #242424;
    margin-left: 16px;
}
.show_winer_rght3 a {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 114.21%;
    letter-spacing: -0.01em;
    background: #242424;
    border-radius: 9px;
    color: #fff !important;
    padding: 7px 13px;
}
.show_winer_rght3 img {
    margin-left: 16px;
    cursor: pointer;
}
.show_winer_rght3 {
    text-align: right;
}
a.nationalCust3 img{
    background: #FFFFFF;
    border-radius: 15px;
    padding: 20px 4px;
}
.social_activityleft label {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #4E4E4E;
    margin-bottom: 0px !important;
    font-family: 'OmnesSemiBold';
}
.social_activityleft p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    align-items: center;
    color: #4E4E4E;
    opacity: 0.5;
    margin-bottom: 0px;
    font-family: 'OmnesRegular';
}
.social_activityleft p b{
    font-weight: bold;
    font-family: 'OmnesBold';
}
.social_activityright {
    text-align: right;
}
.social_activityright label {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #F47C6A;
    margin-bottom: 10px;
    /* font-family: 'OmnesSemiBold'; */
    font-family: 'OmnesBold';
}
.social_activityleft, .social_activityright {
    /* width: 50%; */
    outline: none;
}
.social_activityright a {
    margin-left: 6px;
    position: relative;
}
.rightsymbol {
    position: absolute;
    top: -11px;
    right: -3px;
}
.fiveone_pts3 a img {
    text-align: center;
    width: 80px
}
.social_connect_uprmain{
    /* background: #fdf7f6; */
    border-radius: 20px 20px;
}
.social_connect_upr {
    padding: 2px 18px 14px;
    background: #fcfcfc;
    width: 90%;
    margin: 0 auto;
    border-radius: 20px;
}
.social_connect_inr {
    display: flex;
}
.social_connect_inr div {
    width: 50%;
}
.one_social p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    align-items: center;
    color: #4E4E4E;
    opacity: 0.5;
    margin-bottom: 0px;
    font-family: 'OmnesRegular';
}
.one_social p span {
    font-weight: 700;
    font-size: 14px;
    align-items: center;
    font-family: 'OmnesSemiBold';
}
.one_social_connect {
    text-align: right;
}
.one_social_connect a {
    margin-right: -18px;
}
.borderProgress {
    margin-bottom: 18px;
}
.one_social_connect a:last-child {
    margin-right: 0px !important;
}
.borderProgress div {
    border-radius: 17px !important;
}
.awardcup_main {
    position: relative;
    display: inline-block;
}
img.awardcup {
    position: absolute;
    width: 30.76px;
    height: 27.2px;
    top: 37px;
    right: 35px;
}

.social_activ {
    padding: 12px 0px;
    display: -ms-flexbox;
    display: flex;
}
.social_activleft, .social_activright {
    width: 50%;
}
.social_activleft label {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #4E4E4E;
    margin-bottom: 0px !important;
    font-family: 'OmnesSemiBold';
}
.social_activright {
    text-align: right;
}
.social_activright label {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.01em;
    color: #66BD99;
    margin-bottom: 0px !important;
    /* font-family: 'OmnesSemiBold'; */
    font-family: 'OmnesBold';
}
/* end of css cute babye lephant pillow Enter */

/* Enter contest css */
.contest_backlink {
    top: 30px;
    position: absolute;
    left: 16px;
    cursor: pointer;
}
.contest_clearlink {
    top: 30px;
    position: absolute;
    right: 18px;
    cursor: pointer;
}
.contest_up {
    margin-bottom: 28px;
    text-align: center;
}

.survey_up h1{
    margin-bottom: 28px;
    text-align: center;
}

.survey_up h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-align: left;
    font-family: 'OmnesMedium';
    color: #904949;
    margin-bottom: 28px;
    margin-top: 0px;
    /* margin-left: 32px; */
}
.survey_up h2 {
    font-family: 'OmnesBold';
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    /* letter-spacing: -0.02em; */
    color: #914949;
    margin-top: 40px;
}
.contest_up h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #4E4E4E;
    margin-bottom: 28px;
    margin-top: 0px;
    font-family: 'OmnesSemiBold';
}
.contest_up h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #F47C6A;
    margin-top: 21px;
    font-family: 'OmnesSemiBold';
}
.contest_process1 {
    margin: 42px 0px 0px;
    position: relative;
}
.contest_maskBefore {
    width: 36px;
    margin-right: 0px !important;
    text-align: center;
}
.contest_maskBefore input {
    width: 100% !important;
    border-bottom: 1px solid #dfdfdf !important;
    line-height: 9px !important;
    padding: 5px 0px !important;
}
.contest_process1 input {
    width: 72%;
    border: none;
    border-bottom: 1px solid #dfdfdf;
    outline: none;
    font-stretch: normal;
    letter-spacing: normal;
    text-transform: capitalize;
    border-radius: 0px !important;
    background: transparent;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    -ms-flex-align: center;
    align-items: center;
    line-height: 21px !important;
    height: 34px !important;
    color: #242424;
    padding: 6px 0px !important;
    font-family: 'OmnesMedium';
}
.contest_opt {
    text-align: center;
    margin-top: 22px;
    margin-bottom: 15px;
    /* padding: 0px 16px; */
}

.contest_opt_policy_advisor {
    text-align: center;
    margin-top: 16px;
    margin-bottom: 0px;
    padding: 0px 16px;
}

.contest_opt_policy_advisor button{
    color: #ffffff;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    padding: 12px 34px;
    background: -webkit-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: -o-linear-gradient(291.05deg, rgb(66, 7, 14) -28.17%, #F27381 366.38%);
    background: linear-gradient(158.95deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
}

.contest_opt button {
    color: #ffffff;
    outline: none !important;
    padding: 12px 34px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
    font-family: 'OmnesSemiBold';
    background: #F47C6A !important;
    border-radius: 30px;
    min-height: 52px;
}
.contest_process1 p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    align-items: center;
    color: #242424;
    opacity: 0.5;
    margin-top: 12px;
    margin-bottom: 0px;
    font-family: 'OmnesRegular';
}
.contest_msg p {
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: #242424;
    opacity: 0.5;
    font-family: 'OmnesRegular';
}
.contest_maskBefore div:before, .contest_maskBefore div:after {
    content: none !important;
}
.contest_process1 input::-webkit-input-placeholder { /* Edge */
    color: #24242478;
  }
.contest_process1 input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #24242478;
  }
.contest_process1 input::placeholder {
    color: #24242478;
}
/* End of contest css */

/* Enter contest Two */
.contest_process2 {
    margin: 42px 0px 5px;
}
.contest_process2 div {
    width: 100% !important;
}
.contest_process2 label {
    font-stretch: normal;
    font-family: 'OmnesRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    -ms-flex-align: center;
    align-items: center;
    color: #242424 !important;
    opacity: 0.4;
}
.contest_process2 input {
    width: 100%;
    border: none;
    border-bottom: 1px solid #dfdfdf;
    outline: none;
    font-stretch: normal;
    letter-spacing: normal;
    text-transform: capitalize;
    border-radius: 0px !important;
    background: transparent;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    -ms-flex-align: center;
    align-items: center;
    line-height: 19px !important;
    color: #242424;
    padding: 6px 0px !important;
    font-family: 'OmnesRegular';
}
.contest_call {
    display: flex;
}
.contest_call div {
    width: 50%;
}
.contest_call p {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 11px;
    -ms-flex-align: center;
    align-items: center;
    /* letter-spacing: -0.01em; */
    font-family: 'OmnesSemiBold';
    color: #F27381;
}
.contest_process2 div:before, .contest_process2 div:after {
    content: none !important;
}
.contest_call p span {
    opacity: 0.4;
}
.contest_call div:last-child {
    text-align: right;
}
img.clearcontest_img {
    position: absolute;
    top: 3px;
    right: 0;
    cursor: pointer;
}
/* End Enter contest Two */

/* Add Relatives */
.share_point {
    display: block;
    align-items: center;
}
.share_pointRght p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #4E4E4E;
    opacity: 0.5;
    margin-bottom: 0px;
    padding-top: 0px;
    font-family: 'OmnesMedium';

}
.share_pointLeft {
    text-align: center;
}
.share_pointLeft, .share_pointRght {
    width: 100%;
}
.share_pointRght {
    text-align: right;
}
div.addrelative_tabs {
    text-align: left !important;
    margin-top: 12px;
    margin-bottom: 12px;
    margin-right: 0px !important;
    background: #ffffff;
    border-radius: 40px;
}
.your_odds label {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 27px;
    text-align: center;
    color: #4E4E4E;
    display: block;
    margin-bottom: 0px;
    font-family: 'OmnesSemiBold';
}
.your_odds p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #242424;
    opacity: 0.5;
    margin: 12px 0px 20px;
    font-family: 'OmnesRegular';
}
.your_odds button {
    outline: none !important;
    padding: 14px 34px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
    background: #F47C6A !important;
    color: #ffffff !important;
    border-radius: 30px;
    min-height: 52px;
    font-family: 'OmnesSemiBold';
    box-shadow: 0 18px 20px -18px #FF5A6C;
}
.your_odds {
    /* padding: 0px 28px; */
    margin-top: 2px;
}
span.two_person {
    border-radius: 30px;
    background-color: #fce6e6;
    display: inline-block;
    position: relative;
    padding: 18px 22px;
}
img.person_cup {
    background: #FF5A6C;
    border-radius: 12px;
    padding: 8px;
    width: 32px;
    position: absolute;
    top: 0;
    right: -15px;
    box-shadow: 0px 1px 8px rgba(252, 97, 114, 0.65);
}
img.person_cup_invite {
    background: #FF5A6C;
    border-radius: 12px;
    padding: 8px;
    width: 32px!important;
    position: absolute;
    top: 0;
    right: -15px;
    box-shadow: 0px 1px 8px rgba(252, 97, 114, 0.65);
}
img.person_group{
    width: 90px!important;
}

.starComon {
    position: absolute;
}
.star_group {
    position: relative;
    text-align: center;
}
.star_plusOne {
    left: 0;
}
img.star_plusTwo {
    left: 13px;
    bottom: 0;
}
img.star_plusFour {
    right: 0;
}
.star_plusThree {
    left: 55px;
    bottom: 0;
    top: 22px;
}
img.star_plusFive {
    right: 13px;
    bottom: 0;
}
img.star_plusseven {
    right: 48px;
    bottom: 0;
    top: 23px;
}
/* .addrelative_tabs li.nav-item {
    margin-right: 15px;
} */

/* End of Add Relatives */

/*Add Relatives form*/
.relation_dropdown {
    position: relative;
}
.addrelative_form {
    text-align: center;
    margin-top: 35px;
}
.addrelative_form div {
    margin-top: 0px;
    width: 100%;
}
.addrelative_form label {
    font-stretch: normal;
    font-family: 'OmnesRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    -ms-flex-align: center;
    align-items: center;
    color: #242424 !important;
    opacity: 0.4;
}
.addrelative_form input {
    font-weight: 500;
    width: 100%;
    border: none;
    border-bottom: 2px solid #eedcda;
    outline: none;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'OmnesMedium';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    display: -ms-flexbox;
    -ms-flex-align: center;
    align-items: center;
    color: #242424;
}
.addrelative_form select {
    font-weight: 500;
    width: 90%;
    border: none;
    border-bottom: 2px solid #eedcda;
    outline: none;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'OmnesMedium';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    display: -ms-flexbox;
    -ms-flex-align: center;
    align-items: center;
    color: #242424;
}
.addrelative_form div:before, .addrelative_form div:after {
    content: none !important;
}
.addrelative_submit button {
    color: #ffffff;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    margin-top: 28px;
    padding: 14px 34px;
    background: -webkit-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: -o-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: linear-gradient(158.95deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 85%;
}
.addrelative_heading h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 32px;
    color: #914949;
}
.addrelative_heading p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #242424;
    opacity: 0.5;
}
.addrelative_heading {
    text-align: center;
    margin-top: 60px;
}
/*End of Add Relatives form*/


/* All relative members */
.all_relative_section {
    margin-top: 55px;
}
.all_relative {
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20px;
}
.all_relative_left {
    width: 25%;
    position: relative;
}
.all_relative_left img {
    width: 59px;
    border-radius: 42px;
}
.all_relative_rght {
    width: 62%;
    padding-top: 0px;
}
.all_relative_rght label {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: #242424;
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: 'OmnesMedium';
}
.all_relative_rght p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #242424;
    opacity: 0.5;
    margin: 0px;
    font-family: 'OmnesMedium';
}
.all_relative_close {
    width: 13%;
    text-align: right;
    padding-top: 10px;
}
.all_relative_close img {
    cursor: pointer;
}
.all_relative_close a {
    cursor: pointer;
    color: #242424;
    font-size: 26px;
}
.all_relative_close a:last-child{
    margin-left: 15px;
}
@media screen and (max-width: 768px) {
    .all_relative_close a:last-child{
        margin-left: 8px !important;
    }
}
.allrel_submit button{
    color: #ffffff;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    padding: 14px 34px;
    background: -webkit-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: -o-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: linear-gradient(158.95deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 113px;
    -webkit-box-shadow: 0px 3px 13px rgba(252, 97, 114, 0.65);
    box-shadow: 0px 3px 13px rgba(252, 97, 114, 0.65);
}
.allrel_submit {
    text-align: center;
}
.allrel_submit {
    position: absolute;
    text-align: center;
    bottom: 62px;
    right: 5%;
    left: 5%;
}
/* End of All relative members */

/* All children */
.all_children_section, .your_odds_upr {
    margin-top: 62px;
}
.all_children {
    display: flex;
    /* margin-bottom: 20px; */
    align-items: center;
}
.all_children_left {
    width: 25%;
    position: relative;
}
.all_children_left img {
    width: 59px;
    border-radius: 42px;
    cursor: pointer;
}
.all_children_rght {
    width: 62%;
    padding-top: 0px;
}
.all_children_rght label {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: #242424;
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: 'OmnesMedium';
}
.all_children_rght p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #242424;
    opacity: 0.5;
    margin: 0px;
    font-family: 'OmnesMedium';
}
.all_children_close {
    width: 13%;
    text-align: right;
    padding-top: 0px;
}
/* .all_children_close a {
    cursor: pointer;
    color: #242424;
    font-size: 26px;
} */
.all_children_left label{
    background: #f2eff0;
    width: 68px;
    height: 68px;
    padding: 20px 0px;
    border-radius: 42px;
    text-align: center;
    margin-bottom: 0px !important;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 34px;
    color: #94CEDB;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: 'OmnesSemiBold';
}
.name_rght {
    background: #66BD99;
    padding: 4px 3px;
    position: absolute;
    border-radius: 50px;
    bottom: 8px;
    right: 21px;
}
.name_rght img {
    width: 8px !important;
    display: flex;
}
/* .allchild_submit {
    position: absolute;
    text-align: center;
    bottom: 62px;
    right: 5%;
    left: 5%;
}
.allchild_submit button {
    color: #ffffff;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    padding: 12px 34px;
    background: -webkit-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: -o-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: linear-gradient(158.95deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 99px;
    -webkit-box-shadow: 0px 3px 13px rgba(252, 97, 114, 0.65);
    box-shadow: 0px 3px 13px rgba(252, 97, 114, 0.65);
} */
/* End of All children */

/* Start share point model  */
.share_pointmodel {
    height: 100%;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}
.share_pointmodel .modal-content {
    position: absolute;
    bottom: 0px;
}
.share_pointmodel .modal-header {
    position: absolute;
    top: -7px;
    right: 9px;
    z-index: 9;
}
.share_pointmodel .modal-header {
    border: none !important;
    color: #ffffff;
    text-align: center;
    display: block;
    padding-right: 0px;
}
.share_pointmodel button.closeModel {
    background: transparent;
    border: 0px;
    border: 1px solid #ff00003d;
    border-radius: 21px;
    padding: 0px 8px 2px;
    cursor: pointer;
    outline: none !important;
}
.share_pointBody {
    font-family: 'Montserrat', sans-serif;
    min-height: 159px;
    background: #ffffff;
    border-radius: 28px 28px 0px 0px;
    padding: 30px 18px 19px;
}
.share_point_label label {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #242424;
    display: block;
    margin-bottom: 25px;
    font-family: 'OmnesBold';
}
.share_point_content div {
    width: 50%;
}
.share_point_content {
    display: flex;
}
.share_point_content {
    display: flex;
}
.share_point_left label {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 138.05%;
    align-items: center;
    color: #FC6171;
    opacity: 0.5;
    margin-bottom: 0px;
    font-family: 'OmnesBold';
}
.share_point_left p{
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 138.05%;
    align-items: center;
    /* letter-spacing: -0.01em; */
    color: #FC6171;
    opacity: 0.7;
    font-family: 'OmnesBold';
    margin-bottom: 0px;
}
.share_point_model {
    text-align: center;
}

.share_point_rght label {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 138.05%;
    align-items: center;
    color: #804372;
    opacity: 0.5;
    margin-bottom: 0px;
    font-family: 'OmnesBold';
}
.share_point_rght p{
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 138.05%;
    align-items: center;
    /* letter-spacing: -0.01em; */
    color: #804372;
    opacity: 0.7;
    font-family: 'OmnesBold';
    margin-bottom: 0px;
}
.share_point_left {
    border-right: 1px solid #f7f2f2;
}
/* End share point model */

/* Release account model */
.release_acmodel .modal-content {
    background-color: transparent !important;
    width: 100%;
}
.release_acmodel .modal-content {
    border: none !important;
}
.release_acBody {
    font-family: 'Montserrat', sans-serif;
    /* min-height: 538px; */
}
.release_ac {
    width: 100%;
    background: #FFFFFF;
    border-radius: 20px;
    text-align: center;
    padding: 30px 27px 30px;
    /* margin-top: 45px; */
}
button.release_acEdit {
    color: #ffffff;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    padding: 12px 34px;
    background: -webkit-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: -o-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: linear-gradient(158.95deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
}
button.release_acChild {
    color: #fd5e6f;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    padding: 12px 34px;
    background: -o-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: #ffeff1;
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
}
.release_ac_later label {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    color: #242424;
    margin-top: 0px;
    font-family: 'OmnesSemiBold';
    margin-bottom: 0px;
    cursor: pointer;
}
.release_ac button {
    margin-bottom: 10px;
}
/* End of Release account model */

/* Child mail css */
.child_email {
    text-align: center;
    margin-top: 75px;
}
.child_email_inner {
    display: inline-block;
    margin: auto 0;
    position: relative;
    min-height: 100px;
}
.child_email_content {
    margin-top: 38px;
    padding: 0px 37px;
    margin-bottom: 38px;
}
.child_email_content h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 114.21%;
    text-align: center;
    /* letter-spacing: -0.02em; */
    color: #914949;
    font-family: 'OmnesSemiBold';
}
.child_email_content p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #242424;
    opacity: 0.5;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 0px;
    font-family: 'OmnesMedium';
}
.child_email_submit {
    text-align: center;
}
.child_email_submit button {
    color: #ffffff;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    margin-top: 0px;
    padding: 12px 34px;
    background: -webkit-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: -o-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: linear-gradient(158.95deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 80%;
}
.child_email_inner a {
    border-radius: 11px !important;
    color: #ffffff !important;
    font-size: 19px;
    font-weight: bold;
    position: absolute;
    top: 34px;
    right: 37px;
    cursor: pointer;
    background: #FF5A6C;
}
.child_email_main {
    position: relative;
}
.childemail_backlink {
    top: 0px;
    position: absolute;
    left: 16px;
    cursor: pointer;
}
.childemail_backlink img {
    width: 12px !important;
}
.childemail_up {
    margin-bottom: 28px;
    text-align: center;
}
.childemail_clearlink {
    top: 0px;
    position: absolute;
    right: 18px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    text-align: right;
    letter-spacing: -0.01em;
    color: #242424;
}
.childemail_clearlink img {
    width: 17px !important;
}
.childemail_up label {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 114.21%;
    text-align: center;
    color: #904949;
    margin-bottom: 0px;
}

.childemail_form {
    text-align: center;
    margin-top: 55px;
}
.childemail_first {
    width: 100%;
    margin-bottom: 8px;
    margin-top: 4px;
}
.childemail_form label {
    font-stretch: normal;
    font-family: 'OmnesRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    -ms-flex-align: center;
    align-items: center;
    color: #242424 !important;
    opacity: 0.4;
}
.childemail_form input {
    font-weight: 500;
    width: 100%;
    border: none;
    border-bottom: 2px solid #FC6172;
    outline: none;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'OmnesMedium';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    display: -ms-flexbox;
    -ms-flex-align: center;
    align-items: center;
    color: #FC6172;
}
.childemail_submit button {
    color: #ffffff;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    margin-top: 25px;
    padding: 12px 34px;
    background: -webkit-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: -o-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: linear-gradient(158.95deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 90%;
}
.childemail_form div:before, .childemail_form div:after {
    content: none !important;
}

.childemail_content {
    margin-top: 38px;
    padding: 0px 37px;
    margin-bottom: 38px;
}
.childemail_content h3 {
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 32px;
    text-align: center;
    color: #914949;
    margin-bottom: 0px;
    font-family: 'OmnesSemiBold';
}
.childemail_content p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    align-items: center;
    text-align: center;
    color: #242424;
    opacity: 0.5;
    margin-top: 8px;
    margin-bottom: 0px;
    font-family: 'OmnesMedium';
}
/* End of Child mail css */

/* Child mail sent */
.childcall_content {
    margin-top: 38%;
}
.childcall_content h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 114.21%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #914949;
    font-family: 'OmnesSemiBold';
    margin-bottom: 0px;
}
.childcall_content p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    align-items: center;
    text-align: center;
    color: #242424;
    line-height: 21px;
    margin: 28px 0px 38px;
    font-family: 'OmnesRegular';
}
.childcall_link {
    text-align: center;
}
.childcall_link button {
    color: #ffffff;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    padding: 12px 34px;
    background: -webkit-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: -o-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: linear-gradient(158.95deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 80%;
}
/* End of  Childmailsent */

/* perks page css */
.perks_tabs ul.nav.nav-tabs {
    border-bottom: 0px !important;
    display: block;
    margin: 0px 0px 5px;
}
.perks_tabs li {
    text-align: center;
    cursor: pointer;
    display: inline-block;
    margin: 0px 3px;
}
.perks_tabs .nav-tabs .nav-link.active {
    color: #fa7269 !important;
    opacity: initial !important;
    position: relative;
    background: #f8f5fa;
    border: none !important;
}
.perks_tabs li a {
    color: #8E53A6;
}
.perks_tabs .nav-tabs .nav-link {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
    display: inline-flex;
    padding: 9px 15px;
    border-radius: 30px;
    color: #739e80 !important;
    min-width: 106px;
    background: #ffffff !important;
    min-height: 52px;
    justify-content: center;
    align-items: center;
    border: 1px solid #e5e5e5 !important;
    font-family: 'OmnesSemiBold';
}
.perks_tabs .nav-tabs .nav-link:hover{
    color: #739e80;
}
.perks_tabs h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 32px;
    font-family: 'OmnesSemiBold';
    color: #914949;
}
button.entertain_btn {
    background: #8E53A6 !important;
    color: #FFFFFF;
}
button.upgrade_btn {
    background: #eff4f0 !important;
    color: #66bd99;
}
.btn_collection button{
    border-radius: 14px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    font-family: 'OmnesMedium';
    padding: 9px 16px;
    outline: none !important;
    margin-right: 12px;
}
.upgrade_btn img {
    margin-right: 6px;
}
.btn_collection {
    margin: 20px 0px;
}
.perks_label label {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: -0.02em;
    color: #242424;
    margin-bottom: 8px;
}
.perks_label p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #242424;
    opacity: 0.5;
    margin: 0px;
}
.perks_img img {
    width: 100%;
    border-radius: 0px 0px 20px 20px;
}

.perks_img {
    max-height: 270px;
    overflow: hidden;
    border-radius: 0px 0px 20px 20px;
}

.perksmainimage{
    position: absolute;
    bottom: 10px;
    left: 10px;
}

.perks_contentIner {
    padding: 0px 24px;
}
.perks_content {
    background: #fdf9f9;
    padding-bottom: 25px;
    border-radius: 23px 23px 20px 20px;
    margin-bottom: 24px;
}
.perks_img_upr {
    position: relative;
}

.colimg {
    display: inline-block;
    height: 59px;
    width: 65px;
    background: #ffffff;
    border-radius: 20px;
    line-height: 55px;
    margin-right: 8px;
    bottom: 20px;
    position: absolute;
    left: 20px;
}

.colimg img{
    width: 65px !important;
    padding: 8px 6px;
}
/* End perks page css */

/* perk two css */
.perks_content_two {
    background: #FA7269 !important;
}
.perks_content_two button{
    background: #ffffff !important;
    color: #FA7269 !important;
}
.perks_content_two label, .perks_content_two p {
    color: #ffffff !important;
}
/* end perk two css */

/* perk three css */
.perks_content_three {
    background: #fbf1f0 !important;
}
.beauty_btn{
    background: #F15B70 !important;
    color: #ffffff !important;
}
.unlock_btn{
    background: #f0e1e9 !important;
    color: #7C5E8C !important;
}
.unlock_btn img {
    margin-right: 6px;
    width: 11px;
    margin-top: -4px;
}
.txt_list {
    margin-bottom: 30px;
    align-items: center;
}
.txt_list p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #242424;
    opacity: 0.5;
    text-align: center;
    margin-bottom: 0px;
}
/* end perk three css */

/* perk details page css */
.perks_dtl {
    border-radius: 20px 20px 20px 20px;
}
.perks_dtlImg {
    position: relative;
}
.perks_dtlImges img {
    width: 100%;
    border-radius: 0px 0px 20px 20px;
}
.perksImgs {
    position: absolute;
    bottom: -31px;
    left: 16px;
}
.perksImgs img {
    border-radius: 14px;
    width: 50px;
    cursor: pointer;
    margin-right: 8px;
    /* box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12); */
}
.perks_descp {
    padding: 0px 22px;
    padding-top: 20px;
}
.perks_dtl_iner {
    padding: 20px 0px;
}
.perks_dtl_iner label {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: #914949;
    margin: 0px 0px 6px;
    font-family: 'OmnesSemiBold';
}
.perks_dtl_iner p {
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    align-items: center;
    color: #242424;
    opacity: 0.5;
    font-family: 'OmnesRegular';
}
.perks_all {
    border-left: 2px solid #f7ecea;
    padding-left: 16px;
    padding-bottom: 5px;
    margin-bottom: 25px;
}
.perks_all label {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 17px;
    color: #242424;
    font-family: 'OmnesSemiBold';
}
.perks_all p {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    -ms-flex-align: center;
    align-items: center;
    /* letter-spacing: 0.01em; */
    color: #242424;
    opacity: 0.9;
    margin-bottom: 0px;
    font-family: 'OmnesRegular';
}
.deal_redem {
    text-align: center;
    margin-top: 30px;
}
.deal_redem button {
    color: #ffffff;
    outline: none !important;
    padding: 12px 34px;
    background: linear-gradient(158.95deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 18px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: initial;
    width: 90%;
    box-shadow: 0 18px 20px -18px #FF5A6C;
    min-height: 52px;
    font-family: 'OmnesSemiBold';
}
.deal_frrebies {
    margin-top: 24px;
    padding: 0px 16px;
}
.deal_frrebies p {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    /* color: rgba(141, 87, 87, 0.5);
}
.deal_frrebies p span {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    text-align: center;
    color: #242424;
}
.perks_loc {
    border-left: 2px solid #f3dfde;
    padding-left: 16px;
    padding-bottom: 5px;
    margin-bottom: 25px;
}
.perks_loc label {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #242424;
}
.perks_loc p {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    -ms-flex-align: center;
    align-items: center;
    letter-spacing: 0.01em;
    color: #242424;
    opacity: 0.9;
    margin-bottom: 0px;
    font-family: 'OmnesRegular';
}
.map_location img {
    /* width: 100%; */
    border-radius: 20px;
}
.playvideo {
    position: absolute;
    top: 36%;
    bottom: 40%;
    left: 5%;
    right: 5%;
    text-align: center;
}
.playvideo img {
    width: initial !important;
    cursor: pointer;
    border-radius: initial !important;
}
.family_fun {
    margin-top: 50px;
}
.family_fun button {
    color: #ffffff;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    padding: 8px 18px;
    background: linear-gradient(158.95deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: initial;
}
/* End of perk details page css */

/* open map css */
.custom_mapmodel .modal-content {
    border: none !important;
}
.custom_mapmodel .modal-content {
    background-color: transparent !important;
    width: 100%;
}
.custom_mapBody {
    font-family: 'Montserrat', sans-serif;
    min-height: 584px;
}
.Addmap_content {
    font-family: 'Montserrat', sans-serif;
    position: absolute;
    width: 100%;
    bottom: 0;
    text-align: center;
    margin: 0 auto;
}
.Addmap_content button {
    font-family: 'OmnesSemiBold';
    font-style: normal;
    width: 100%;
    background-color: #ffffff !important;
    font-weight: 600;
    margin-top: 11px;
    padding: 12px 8px;
    outline: none !important;
    box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.1);
    text-transform: initial;
    border-radius: 14px;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    /* letter-spacing: 0.38px; */
    color: #0076FF;
}
button.opnmap_btn {
    background-color: #c9c7c4 !important;
}
/* End of open map css */

/* Type of Deal css */
.custom_dealmodel .modal-content {
    border: none !important;
}
.custom_dealmodel .modal-content {
    background-color: transparent !important;
    width: 100%;
}
.custom_dealBody {
    font-family: 'Montserrat', sans-serif;
    /* min-height: 538px; */
}
.open_deal {
    position: relative;
    width: 100%;
    background: #FFFFFF;
    border-radius: 20px;
    text-align: center;
    margin-top: 5%;
}
.opendealInner{
    padding: 21px 27px 30px 27px;
}
.open_deal_content h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 27px;
    text-align: center;
    color: #4E4E4E;
    margin-top: 25px;
    margin-bottom: 22px;
    font-family: 'OmnesSemiBold';
}
.open_deal_content p {
    font-style: normal;
    font-weight: 400;
    margin-bottom: 0px;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #242424;
    opacity: 0.5;
    font-family: 'OmnesRegular';
}
.open_deal button {
    color: #ffffff;
    outline: none !important;
    padding: 12px 34px;
    border-radius: 30px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
    margin-top: 40px;
    font-family: 'OmnesSemiBold';
    min-height: 52px;
}
button.instore_btn {
    background: #ffffff !important;
    color: #4E4E4E;
    margin-top: 5px;
    border: 1px solid #0000001A;
    box-shadow: 0 3px 6px rgb(0, 0, 0, .1);
}
/* End of Type of Deal css */

/* Kids Go Free css */
div.common_main_kid {
    padding: 20px 29px;
    border: 1px solid #ffeff1ad;
    background: transparent;
    min-height: 657px;
    position: relative;
    /* background: linear-gradient(180deg, #ffffff 0, #f9eae2e8 33.333%, #F9EAE2 33.333%, #F9EAE2 66.666%, #F9EAE2 66.666%, #F7E2E1 100%); */
}

div.common_main_kid{
    height: auto !important;
    }
.common_main_kid .clearlink {
    top: 14px !important;
    right: 22px !important;
}
.kidcode {
    text-align: center;
    margin-top: 15px;
}
.kidcode_inner {
    display: inline-block;
    margin: auto 0;
    position: relative;
}
.kidcode_inner img {
    border-radius: 20px;
}
.kidcode_content {
    margin-top: 34px;
}
.kidcode_content h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #4E4E4E;
    font-family: 'OmnesSemiBold';
}
.kidcode_content p {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #719E7F;
    align-items: center;
    margin: 0px;
    font-family: 'OmnesMedium';
}
.kidcode_copy {
    margin: 35px 0px 22px;
}
.kidcode_copy p {
    margin-bottom: 14px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #242424;
    opacity: 0.5;
    font-family: 'OmnesRegular';
}
.kidcode_inr {
    background: #fffcfc;
    border: 2px dotted #719E7F;
    box-sizing: border-box;
    border-radius: 15px;
    padding: 16px 24px;
    min-height: 80px;
    outline: none !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.kidcode_inr label {
    margin-bottom: 0px !important;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 114.21%;
    letter-spacing: -0.02em;
    color: #904949;
}
.kidcode_inr span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    align-items: center;
    text-align: center;
    color: #242424;
    display: block !important;
    opacity: 0.5;
    font-family: 'OmnesRegular';
}
.kidcode_submit {
    text-align: center;
}
.kidcode_submit button {
    color: #ffffff;
    outline: none !important;
    margin-top: 0px;
    padding: 16px 34px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 90%;
    margin-bottom: 48px;
    background: #F47C6A !important;
    border-radius: 30px;
    /* box-shadow: 0px 4px 8px #f27d6ead; */
    box-shadow: 0 18px 20px -18px #FF5A6C;
    font-family: 'OmnesSemiBold';
}
.kidcode_submit p {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: rgb(36, 36, 36, 0.5);
    margin-bottom: 0px;
    font-family: 'OmnesRegular';
}
.kidcode_submit p span {
    font-weight: 600;
    font-family: 'OmnesSemiBold';
}
/* End of Kids Go Free css */

/* Bar css */
.barcode {
    text-align: center;
    margin-top: 15px;
}
.barcode_inner {
    display: inline-block;
    margin: auto 0;
    position: relative;
}
.barcode_inner img {
    border-radius: 20px;
}
.barcode_content {
    margin-top: 34px;
}
.barcode_content h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #4E4E4E;
    font-family: 'OmnesSemiBold';
    margin-bottom: 12px;
}
.barcode_content p {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #719E7F;
    align-items: center;
    margin: 0px;
    font-family: 'OmnesMedium';
}
.barcode_copy {
    margin: 18px 0px 38px;
}
.barcode_copy p {
    margin-bottom: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    opacity: 0.5;
    color: #242424;
    font-family: 'OmnesRegular';
}
.barcode_submit {
    text-align: center;
}
.barcode_submit p {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: rgb(36, 36, 36, 0.5);
    margin-bottom: 0px;
    font-family: 'OmnesRegular';
}
.barcode_submit p span {
    font-weight: 600;
    font-family: 'OmnesSemiBold';
}
.barcode_inr label {
    font-style: normal;
    font-size: 18px;
    line-height: 114.21%;
    text-align: center;
    /* letter-spacing: -0.02em; */
    color: #914949;
    font-family: 'OmnesRegular';
}
.barcode_inr img {
    width: 100%;
    cursor: pointer;
}
.time_schedule div {
    width: 50%;
}
.time_schedule {
    display: flex;
    text-align: center;
    margin-bottom: 36px;
}
.time_schedule label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #242424;
    opacity: 0.5;
    margin-bottom: 5px;
    font-family: 'OmnesRegular';
}
.time_schedule p {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #242424;
    margin-bottom: 0px;
    font-family: 'OmnesRegular';
}
/* End Bar css */

/* perks details two css */
.kidsSocial_share {
    padding: 20px 18px;
    display: flex;
    margin-bottom: 25px;
    background: #fdf7f7;
    border-radius: 20px;
}
.kidsSocial_share div {
    width: 50%;
}
.kidsSocial_share label {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    color: #242424;
}
.kidsSocial_share p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    align-items: center;
    letter-spacing: 0.01em;
    color: #242424;
    opacity: 0.9;
    margin-bottom: 0px;
}
.kidsSocial_share a {
    cursor: pointer;
}
.kidsSocial_share div:last-child {
    text-align: right;
    padding-top: 12px;
}
.kidsSocial_share div:last-child a:last-child {
    margin-left: 12px;
}
/* End perks details two css */

/* perks css three */
.rom_collection {
    margin: 10px 0px 0px;
    display: flex;
}
.rom_collection button {
    border-radius: 14px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    font-family: 'OmnesSemiBold';
    padding: 9px 16px;
    outline: none !important;
    margin-right: 12px;
}
button.entertain_rom {
    background: #8E53A6 !important;
    color: #FFFFFF;
}
button.upgrade_rom {
    background: #eff4f0 !important;
    color: #66bd99;
}
.upgrade_rom img {
    margin-right: 6px;
}
/* End perks css three */

/* Share and Gain Extra */
.gainextra_form {
    text-align: center;
    margin-top: 0px;
    background: transparent;
    border-radius: 22px 22px 0px 0px;
    padding-top: 0px;
    padding-bottom: 32px;
    /* padding-left: 22px; */
    /* padding-right: 22px; */
    /* min-height: 432px; */
    /* margin-left: -20px; */
    /* margin-right: -20px; */
    /* margin-bottom: -32px; */
}

.gainextra_form div {
    margin-top: 0px;
    width: 100%;
}

/* .gainextra_form label {
    font-stretch: normal;
    font-family: 'OmnesRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    align-items: center;
    color: #242424 !important;
    opacity: 0.5;
    margin-bottom: 0px !important;
} */

/* .gainextra_form input, .gainextra_form textarea {
    width: 100%;
    border: none;
    border-bottom: 2px solid #f1eaea;
    outline: none;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'OmnesRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    align-items: center;
    color: #242424;
    margin-bottom: 10px;
} */

.gainextra_form div:before, .gainextra_form div:after {
    content: none !important;
}
.gainextra_submit button { 
    color: #ffffff;
    outline: none !important;
    margin-top: 12px;
    padding: 14px 34px;
    background: #F47C6A !important;
    border-radius: 30px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
    min-height: 52px;
    font-family: 'OmnesSemiBold';
}
.sharegain_head {
    margin: 0px 16px;
    display: flex;
}
.sharegain_headLeft {
    width: 25%;
}
.sharegain_headRght {
    width: 75%;
    padding-top: 12px;
}
.sharegain_headRght label {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.01em;
    color: #242424;
    margin-bottom: 0px;
}
.sharegain_headRght p {
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    align-items: center;
    color: #242424;
    opacity: 0.5;
}
.contest_h2 h2 {
    margin-top: 40px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    align-items: center;
    color: #242424;
    opacity: 0.5;
    margin-bottom: 14px;
}
.sharegain_backlink {
    top: 30px;
    position: absolute;
    left: 16px;
    cursor: pointer;
}
.sharegain_up {
    margin-bottom: 28px;
    text-align: center;
    margin-top: 20px;
}
.sharegain_up h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    /* opacity: 0.5; */
    text-align: center;
    color: #4E4E4E;
    margin-top: 0px;
    font-family: 'OmnesMedium';
}
.sharegain_headLeft img {
    width: 68px;
    border-radius: 20px;
}
/* End of Share and Gain Extra */

/* Extra support css */
.gainextra_formFull {
    /* background: #ffff;
    border: 1px solid #dfdfdf42; */
    padding: 12px 22px;
}
.gainextra_formFull .gainextra_form {
    min-height: initial !important;
}
.gainextra_formFull .gainextra_submit button {
    /* margin-top: 40px; */
    margin-top: 0px;
}
/* End of Extra support css */

/* Extra help css */
.help_label h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 32px;
    color: #914949;
    font-family: 'OmnesSemiBold';
    margin-bottom: 34px;
}
.help_content {
    cursor: pointer;
    display: flex;
    padding: 12px 20px;
    background: #ffffff;
    border-radius: 30px;
    margin-bottom: 15px;
    border: 1px solid #e5e5e5;
    min-height: 52px;
}
.help_contentLeft { 
    width: 70%;
}
.help_contentRght {
    width: 30%;
}
.help_contentRght {
    text-align: right;
}
.help_contentLeft img {
    width: 22px;
}
.help_contentLeft span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #4E4E4E;
    margin-left: 18px;
    font-family: 'OmnesMedium';
}
.help_contentRght span {
    background: #FF5A6C;
    border-radius: 9px;
    color: #ffffff;
    padding: 5px 10px;
    margin-right: 18px;
    font-size: 14px;
    font-weight: 600;
}
.custom_faqs span.Collapsible__trigger {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    display: block;
    -ms-flex-align: center;
    align-items: center;
    letter-spacing: 0.01em;
    color: #242424;
    background: #ffffff;
    padding: 20px 22px;
    padding-right: 30px;
    border-radius: 20px;
    cursor: pointer;
}
.faqs_ans {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 138%;
    letter-spacing: 0.01em;
    color: #242424;
    padding: 20px 22px;
}
.custom_faqs {
    background: #ffffff70;
    border-radius: 20px;
    margin-bottom: 12px;
}
.faqs_ans p {
    margin-bottom: 0px;
}
.faq_label h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    /* letter-spacing: -0.01em; */
    color: #242424;
    font-family: 'OmnesSemiBold';
    margin-top: 35px;
    margin-bottom: 28px;
}

.custom_faqs span.Collapsible__trigger {
    position: relative;
}
.custom_faqs span.Collapsible__trigger.is-closed:after {
    border: solid #ddcdcd;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    content: "";
    top: 31px;
    right: 18px;
    position: absolute;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    /* -webkit-transform: rotate(-135deg); */
}
.custom_faqs span.Collapsible__trigger.is-open:after {
    border: solid #ddcdcd;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    content: "";
    top: 31px;
    right: 18px;
    position: absolute;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}
.custom_faqs_main .custom_faqs:last-child {
    margin-bottom: 32px;
}
.help_contentRght a {
    cursor: pointer;
}
/*End of Extra help css */
.terms_content h2 {
    font-style: normal;
    font-weight: 600; 
    font-size: 14px;
    line-height: 138%;
    color: #4E4E4E;
    margin-bottom: 22px;
    font-family: 'OmnesSemiBold';
}
.terms_content ul li a {
    font-style: normal;
    font-size: 14px;
    line-height: 138%;
    letter-spacing: 0.01em;
    color: #242424 !important;
    opacity: 0.9;
}
.terms_content ul {
    padding-left: 0px;
}
.terms_content ul li {
    /* list-style-type: decimal; */
    margin-bottom: 12px;
    list-style-type:none;
}
.terms_content ul li ul li {
    /* list-style-type: lower-latin !important; */
    list-style-type:none;
}
.terms_content ul li ul li {
    margin-top: 0px !important;
}
#termsCondition{
    height: 657px;
    overflow-y: scroll;
    margin-bottom: 0px;
}
#termsCondition::-webkit-scrollbar-track{
	background-color: #F5F5F5;
	border-radius: 10px;
}
#termsCondition::-webkit-scrollbar{
	width: 10px;
	background-color: #F5F5F5;
}
#termsCondition::-webkit-scrollbar-thumb{
    border-radius: 10px;
    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.44, #dfdfdf), 
    color-stop(0.72, #d2cece), color-stop(0.86, rgba(66, 66, 66, 0.23)));
}
.terms_content li b {
    margin-right: 8px;
    font-family: 'OmnesSemiBold';
    font-weight: 600;
    color: #4E4E4E;
}
.terms_content ul li ul li ul li {
    margin-bottom: 0px;
    font-style: normal;
    font-size: 14px;
    line-height: 138%;
    opacity: 0.9;
}
.terms_content p, .terms_content ol li {
    margin-bottom: 0px;
    font-style: normal;
    font-size: 14px;
    line-height: 138%;
    opacity: 0.7;
    font-family: 'OmnesRegular';
}
.terms_content ul ul ul, .terms_content p  {
    /* padding-left: 12px; */
    padding-left: 0px;
}
.terms_content p {
    margin-top: 14px;
    margin-bottom: 8px;
}
/* terms and condition css */

/* messages model */
.msg_dtlmodel {
    height: 100%;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}
.msg_dtlmodel .modal-content {
    position: absolute;
    bottom: 0px;
}
.msg_dtlmodel .modal-content {
    border: none !important;
    background: transparent;
}
.msg_dltBody {
    font-family: 'Montserrat', sans-serif;
    background: #ffffff;
    border-radius: 20px 20px 0px 0px;
    padding: 19px 18px;
}
.msg_dlt_content {
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    display: flex;
}
.msg_dlt_content button{
    float: right;
    background: -webkit-linear-gradient(305.3deg, #FF5A6C -28.17%, #F27381 366.38%) !important;
    background: -o-linear-gradient(305.3deg, #FF5A6C -28.17%, #F27381 366.38%) !important;
    background: linear-gradient(144.7deg, #FF5A6C -28.17%, #F27381 366.38%) !important;
    border-radius: 20px !important;
    font-style: normal;
    font-weight: bold;
    font-size: 14px !important;
    line-height: 18px;
    text-align: center;
    color: #FFFFFF !important;
    text-transform: capitalize !important;
    outline: none !important;
    padding: 11px 8px;
    width: 86%;
}
.msg_dlt_content div {
    width: 50%;
}
.msg_mail {
    padding-top: 8px;
}
.msg_mail a {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    color: #242424 !important;
}
.add_emails h3 {
    margin-bottom: 0px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    color: #242424;
}
/* End of messages model */

/* edit page css */
.edit_maskph label {
    text-align: left !important;
    float: left;
    font-size: 11px;
}
.edit_maskph input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #e2e2e2;
  }
.edit_maskph input::-moz-placeholder { /* Firefox 19+ */
    color: #e2e2e2;
  }
.edit_maskph input:-ms-input-placeholder { /* IE 10+ */
    color: #e2e2e2;
  }
.edit_maskph input:-moz-placeholder { /* Firefox 18- */
    color: #e2e2e2;
  }
.edit_maskph_inner::before {
    content: "+1";
    left: 0px;
    top: 0px;
    z-index: 999999 !important;
    color: black;
    position: absolute;
}
.edit_maskph_inner input {
    position: relative;
}
/* End of edit page css */

/* Edit user page css */
.edit_content {
    cursor: pointer;
    display: flex;
    padding: 13px 20px;
    background: #ffffff;
    border-radius: 30px;
    margin-bottom: 15px;
    max-height: 52px;
    border: 1px solid rgba(0, 0, 0, 0.1);
}
.edit_contentLeft {
    width: 70%;
}
.edit_contentLeft img {
    max-width: 22px;
}
.edit_contentLeft span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #4E4E4E;
    margin-left: 18px;
    font-family: 'OmnesMedium';
}
.edit_contentRght {
    text-align: right;
}
.edit_contentRght {
    width: 30%;
}
.edit_contentRght a {
    cursor: pointer;
}
.profile_pic {
    margin-bottom: 26px;
}
.profile_pic{
    position: relative;
}
.profile_pic a span {
    background: linear-gradient(117.02deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 20px;
    color: #fff;
    padding: 0px 7px 1px;
    display: table;
    position: absolute;
    right: -12px;
    bottom: -22px;
    height: 22px;
    cursor: pointer;
    box-shadow: 0px 1px 4px rgba(252, 97, 114, 0.65);
    width: 21px;
}
.andres_sec_main h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 32px;
    color: #914949;
    margin-bottom: 18px;
}
.profile_pic a {
    position: relative;
}
.andres_sec p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: start;
    line-height: 16px;
    color: #4E4E4E;
    opacity: 0.5;
    font-family: 'OmnesMedium';
}
.andres_sec button{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 114.21%;
    letter-spacing: -0.01em;
    color: #F27381;
    font-family: 'OmnesSemiBold';
    background: #fef1f2;
    padding: 9px 16px;
    text-transform: initial;
    border-radius: 20px;
    margin-top: -16px;
    outline: none !important;
}
.andres_sec {
    display: flex;
}
.andres_sec div {
    width: 50%;
}
.andres_sec div:last-child {
    text-align: right;
}
.andres_sec_main {
    margin-bottom: 26px;
    padding-left: 22px;
}

.editagain_tabs {
    /* margin-bottom: 32px; */
    margin-top: 35px;
    text-align: left;
}
.editagain_tabs ul.nav.nav-tabs {
    border-bottom: 0px !important;
    display: block;
    border-radius: 20px;
    background: #f7eeed;
}
.editagain_tabs li {
    text-align: center;
    cursor: pointer;
    display: inline-block;
    margin-right: 0px;
}
/* .editagain_tabs .nav-tabs .nav-link.active {
    color: #242424;
    opacity: initial !important;
    position: relative;
    background: transparent !important;
} */
.editagain_tabs a.nav-link {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.01em;
    color: #242424;
    border: none !important;
    opacity: 0.3;
}
/* .editagain_tabs .nav-tabs .nav-link.active:after {
    content: "";
    width: 6px;
    height: 6px;
    left: 50%;
    bottom: -21px;
    position: absolute;
    border-radius: 82px;
    background: #242424;
} */

.extragain_tabs span.nav_dot {
    display: none !important;
}
.extragain_tabs ul.nav.nav-tabs .nav-link {
    border: none;
    opacity: 0.4;
    background: #ffffffad;
    margin-bottom: 16px;
    display: flex;
    padding: 11px 12px;
    width: 80%;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    min-height: 52px;
    margin-bottom: 0px !important;
}
.addrelative_tabs ul.nav.nav-tabs a.active.nav-link {
    background: #ffffff;
    opacity: 1;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.extragain_tabs.fix_main .fixed-header{
    margin-right: -9px;
    margin-top: 36px;
    margin-bottom: 8px;
    text-align: center!important;
}
.extragain_tabs.fix_main ul.nav.nav-tabs {
    border-bottom: 0!important;
    overflow-y: hidden;
    overflow-x: auto;
    flex-wrap: nowrap!important;
    justify-content: flex-start!important;
    display: flex!important;
    margin-bottom: 32px;
}
.extragain_tabs.fix_main ul.nav.nav-tabs a.nav-link {
    padding-bottom: 0px !important;
}
.addrelative_tabs ul.nav.nav-tabs {
    border-radius: 20px;
    background: transparent !important;
}
.addrelative_tabs ul.nav.nav-tabs li.nav-item {
    width: 50%;
}
.addrelative_tabs ul.nav.nav-tabs .nav-link {
    width: 100%;
    min-height: 80px;
    opacity: 0.5;
}
.ticket_16{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    padding: 9px 14px;
    display: inline-block;
    color: #fff !important;
    cursor: pointer;
    background: #66BD99;
    border-radius: 14px;
    font-family: 'OmnesSemiBold';
}
.ticket_16 img {
    margin-right: 8px;
}
.ticket_16 span {
    vertical-align: middle;
}
.crown_16{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    padding: 9px 14px;
    display: inline-block;
    color: #F38181 !important;
    cursor: pointer;
    background: #fef1f2;
    border-radius: 14px;
    font-family: 'OmnesSemiBold';
    margin-left: 12px;
}
.crown_16 img {
    margin-right: 8px;
}
.crown_16 span {
    vertical-align: middle;
}
.extra_gainmain {
    background: #fefbfb;
    border-radius: 20px;
    padding: 30px 30px;
    margin-bottom: 24px;
}
.extra_gain {
    display: flex;
    margin-bottom: 20px;
}
.extra_gainLeft {
    width: 30%;
}
.extra_gainRght {
    width: 70%;
}
.extra_gainRght label {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.01em;
    color: #242424;
    margin-bottom: 5px;
}
.extra_gainRght p {
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #242424;
    opacity: 0.5;
}
.extra_gainRght {
    padding-top: 12px;
}
.extra_gainLeft img {
    border-radius: 20px;
    max-width: 68px;
}
.lineProgress {
    margin-bottom: 24px;
    margin-top: 24px;
    display: flex;
}
.lineProgress div {
    border-radius: 17px !important;
}

.social_connect_inr1 {
    display: flex;
}
.social_connect_inr1 div {
    width: 50%;
}
.rightsymbol1 {
    position: absolute;
    top: -11px;
    right: -3px;
}
.one_social_connect1 a {
    margin-right: -18px;
}
.one_social1 a {
    position: relative;
}
.one_social1 {
    text-align: right;
}
.one_social1 a:first-child {
    margin-right: 5px;
}
.tab_btmbar {
    display: flex;
    justify-content: space-between;
}
.tab_btmbarLft {
    width: 80%;
}
.tab_btmbarRght {
    margin-top: 40px;
    width: 20%;
}
.tab_btmbarRght {
    text-align: right;
    width: 20%;
}
.lineProgress_left {
    width: 78%;
}
.lineProgress_Rght {
    width: 22%;
}
.lineProgress_Rght p {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.01em;
    color: #66BD99;
    margin-bottom: 0px;
    margin-top: -6px;
    text-align: right;
}


.extra_fremain {
    background: #ffffff;
    border-radius: 20px;
    padding: 30px 30px;
    margin-bottom: 24px;
}
.extra_fre {
    display: flex;
    margin-bottom: 20px;
}
.extra_freLeft {
    width: 30%;
}
.extra_freLeft img {
    border-radius: 30px;
    width: 68px;
    height: 68px;
}
.extra_freRght {
    padding-top: 8px;
}
.extra_freRght {
    width: 70%;
}
.extra_freRght label {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: #4E4E4E;
    margin-bottom: 5px;
    font-family: 'OmnesSemiBold';
}
.extra_freRght p {
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #a6a6a6;
}
.ticket_16fre {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    padding: 9px 14px;
    display: inline-block;
    color: #ff9b80 !important;
    cursor: pointer;
    background: #fef2ef;
    border-radius: 14px;
    font-family: 'OmnesSemiBold';
    margin-bottom: 12px;
    margin-left: 0px;
}
.ticket_16fre img {
    margin-right: 8px;
    width: 15px;
}
.ticket_16fre span {
    vertical-align: middle;
}
.crown_16fre {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    padding: 9px 14px;
    display: inline-block;
    color: #F38181 !important;
    cursor: pointer;
    background: #fef1f2;
    border-radius: 14px;
    font-family: 'OmnesSemiBold';
    margin-left: 0px;
    margin-bottom: 12px;
}
.crown_16fre img {
    margin-right: 8px;
}
.crown_16fre.ticket_16fre span {
    vertical-align: middle;
}
.add_plus_btn span{
    background: #FF5A6C;
    border-radius: 10px;
    color: #fff;
    padding: 1px 11px;
    display: inline-block;
    right: -12px;
    bottom: -22px;
    cursor: pointer;
    text-align: center;
    font-size: 22px;
    font-weight: 600;
}
.add_plus_btn a {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 138.05%;
    align-items: center;
    letter-spacing: 0.02em;
    color: #FE5F70 !important;
    opacity: 0.99;
    margin-left: 12px;
}
.add_plus_main {
    display: flex;
    margin: 20px 0px 0px;
}
.add_plus_main div {
    width: 50%;
}
.add_plus_social {
    text-align: right;
}
.add_plus_social a:first-child {
    margin-right: 5px;
}
.add_plus_btn {
    padding-top: 6px;
}
/* End of Edit user page css */

/* Verifed user page css */
.user_Confirm button {
    background: #f0f8f5 !important;
    color: #66BD99 !important;
}
.user_Confirm img {
    margin-right: 6px;
}
/* End of Verifed user page css */

/* edit user first css */
.extra_contmain {
    background: #ffffffcc;
    border-radius: 20px;
    padding: 30px 30px;
    margin-bottom: 24px;
}
.extra_cont {
    display: flex;
    margin-bottom: 20px;
}
.extra_contLeft {
    width: 30%;
}
.extra_contLeft img {
    width: 68px;
    height: 68px;
    border-radius: 30px;
}
.extra_contRght {
    padding-top: 0px;
}
.extra_contRght {
    width: 70%;
}
.extra_contRght label {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: #4E4E4E;
    margin-bottom: 5px;
    font-family: 'OmnesSemiBold';
}
.extra_contRght p {
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    align-items: center;
    color: #4E4E4E;
    opacity: 0.5;
    font-family: 'OmnesRegular';
}
.contProgress {
    margin-bottom: 24px;
    margin-top: 24px;
}
.contProgress div {
    border-radius: 17px !important;
}
.add_familyBtn button { 
    color: #ffffff;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    padding: 9px 16px;
    background: #F47C6A !important;
    border-radius: 30px;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: initial;
    min-height: 52px;
    width: 100%;
}
.add_family div {
    width: 50%;
}
.add_family {
    display: flex;
}
.add_familyBtn {
    text-align: right;
    width: 50%;
}
.add_familySocial a:first-child {
    margin-right: 12px;
}
.showProgess div {
    width: 50%;
}
.showProgess {
    margin-bottom: 10px;
    display: flex;
}
.showProgess_txt { 
    text-align: right;
}
.showProgess div {
    width: 50%;
}
.showProgess_num label {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #66BD99;
    margin-bottom: 0px;
}
.showProgess_txt p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-align: right;
    color: rgba(102, 189, 153, 0.6);
    margin-bottom: 0px;
}
.showProgess_txt p b {
    color: #66BD99;
}

.contProgress_upr {
    display: flex;
    margin-bottom: 24px;
    margin-top: 24px;
}
.contProgress_lft {
    width: 20%;
    padding-right: 15px;
}
.contProgress_rght {
    width: 80%;
}
.contProgress_rght .showProgess {
    margin-bottom: 0px !important;
}
.contProgress_upr div {
    border-radius: 17px !important;
}
.add_familyBtn a {
    margin-right: -18px;
}
.add_familyBtn a:last-child {
    margin-right: 0px !important;
}

.contProgress_upr div {
    border-radius: 17px !important;
}
.contProgress_rght .showProgess2 {
    margin-bottom: 0px !important;
    display: flex;
}
.showProgess2 div {
    width: 50%;
}
.showProgess_num2 label {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #66BD99;
    margin-bottom: 0px;
}
.showProgess_txt2 p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-align: right;
    color: rgba(102, 189, 153, 0.6);
    margin-bottom: 0px;
}
.showProgess_num2 {
    margin-bottom: 10px;
}
.add_family2 {
    display: flex;
    justify-content: space-between;
}
.add_familySocial2 a:first-child {
    margin-right: 6px;
}
.ticket_16_two {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    padding: 9px 14px;
    display: inline-block;
    color: #66BD99 !important;
    cursor: pointer;
    background: #e6ede8;
    border-radius: 14px;
    margin-right: 10px;
    font-family: 'OmnesRegular';
}
.pending_entry span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 114.21%;
    text-align: right;
    color: #66BD99;
    font-family: 'OmnesRegular';
}
.pending_entry {
    margin-bottom: 20px;
}

.add_family3 {
    display: flex;
}
.add_family3 div {
    width: 50%;
}
.add_familyBtn3 {
    text-align: right;
}
.add_familyBtn3 button {
    color: #ffffff;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    padding: 9px 16px;
    background: -webkit-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: -o-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: linear-gradient(158.95deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: initial;
    width: 90%;
}
.add_familySocial3 button {
    color: #fff;
    outline: none !important;
    padding: 9px 16px;
    background: rgb(244, 124, 106, 0.7) !important;
    border-radius: 30px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: initial;
    width: 100%;
    min-height: 52px;
    font-family: 'OmnesSemiBold';
}
.add_familyBtn2 {
    text-align: right;
}
.add_familyBtn2 a {
    margin-right: -18px;
}
.add_familyBtn2 a:last-child {
    margin-right: 0px !important;
    display: inline-block;
    cursor: pointer;
}
.showProgess_num_pr label {
    color: #4A66AB !important;
}
/* End of edit user first css */

/* Passcode Lock css */
.passcode_Lock label {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 114.21%;
    letter-spacing: -0.01em;
    color: #242424;
    mix-blend-mode: normal;
}
.passcode_Lock {
    display: flex;
}
.passcode_Lock div {
    width: 50%;
}
.passcode_Lock div:last-child {
    text-align: right;
    margin-top: -5px;
}
.passcodeLock_main {
    background: #fff;
    border: 1px solid #f5f5f5fc;
}
.changepass_text label {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 114.21%;
    letter-spacing: -0.01em;
    color: #242424;
    mix-blend-mode: normal;
    margin-bottom: 16px;
}
.changepass_text p {
    margin-bottom: 0px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
    color: #904949;
    opacity: 0.5;
}
.changepass_text {
    margin-top: 20px;
    margin-bottom: 35px;
}
.passcode_Lockleft .MuiSwitch-colorSecondary.Mui-checked {
    color: #ff5a6c !important;
}
.passcode_Lockleft .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: #ffdee2 !important;
}
/* .passcode_Lockleft .MuiSwitch-thumb {
    color: #242424;
} */
.passcode_Lockleft .MuiSwitch-track {
    background-color: #e8dddd;
}
.usr_setting label {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #4E4E4E;
    display: block;
    margin-bottom: 0px;
    font-family: 'OmnesSemiBold';
}
.usr_setting p {
    margin-bottom: 0px;
    line-height: 18px;
}
.usr_setting p a {
    font-style: normal;
    font-size: 18px;
    line-height: 20px;
    color: #242424 !important;
    cursor: pointer;
    font-weight: 500;
    font-family: 'OmnesMedium';
}
.setting_main {
    background: #fff;
    border: 1px solid #f5f5f5fc;
    /* padding-left: 0px;
    padding-right: 0px; */
}
.usr_setting {
    /* padding: 0px 22px; */
    margin-top: 0px;
}
.usr_setting p span {
    min-width: 28px;
    display: inline-block;
}
.usr_setting label:first-child {
    margin-bottom: 8px;
}
.active_ac {
    float: right;
    margin-top: 8px;
}
.userlogout button {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 114.21%;
    letter-spacing: -0.01em;
    color: #242424;
    mix-blend-mode: normal;
    padding: 0px;
    text-transform: capitalize;
    outline: none !important;
    background: transparent !important;
    margin-top: 22px;
}
.userlogout {
    border-bottom: none !important;
}
.tab_btmbarRght img {
    cursor: pointer;
}
/* End of Passcode Lock css */

/* sort model css */
.sort_model .modal-content {
    background-color: transparent !important;
    width: 100%;
}
.sort_model .modal-content {
    border: none !important;
}
.sort_deal {
    width: 100%;
    background: #FFFFFF;
    border-radius: 20px;
    padding: 21px 27px 30px;
    margin-top: 35%;
}
.sort_Body {
    font-family: 'Montserrat', sans-serif;
    min-height: 538px;
}
.sort_dealBy span {
    font-style: normal;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.01em;
    color: #242424;
    color: #4E4E4E;
    font-family: 'OmnesSemiBold';
}
.sort_dealBy a{
    float: right;
    cursor: pointer;
}
.sort_deal ul {
    padding-left: 0px;
    margin-bottom: 0px;
    margin-top: 35px;
}
.sort_deal li {
    list-style-type: none;
}
.sort_deal li a {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    /* color: rgba(141, 87, 87, 0.5); */
    font-family: 'OmnesRegular';
}
.sort_deal li img {
    float: right;
}
.sort_deal ul li {
    margin-bottom: 12px;
}
/* .sort_deal li:first-child a {
    color: #242424 !important;
    font-weight: bold !important;
} */
/* End of sort model css */

/* family add css */
.freebies_iner4 {
    padding: 0px 0px 20px;
}
.contests_baby4 {
    border-radius: 20px 20px 20px 20px;
}
.freeies_babyImg4 {
    position: relative;
}
.freebiesImges4 img {
    width: 100%;
}
.freebiesImgs4 {
    position: absolute;
    bottom: -23px;
    left: 16px;
}
a.nationalCust4 img {
    background: #FFFFFF;
    border-radius: 15px;
    padding: 20px 4px;
}
.freebiesImgs4 img {
    width: 50px;
    cursor: pointer;
    margin-right: 8px;
}
.details_freebies4 {
    padding: 0px 22px;
}
.instant_details4 {
    margin-top: 45px;
}
.instant_details4 a:first-child {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    padding: 8px 20px;
    display: inline-block;
    color: #FF9B80 !important;
    border-radius: 14px;
    cursor: pointer;
    background: #ff9b801a;
    margin-right: 10px;
}
.instant_details4 a:first-child img {
    margin-right: 8px;
}
.instant_details4 a:last-child {
    background: #eef3ef;
    padding: 8px 20px;
    color: #66bd99 !important;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    border-radius: 14px;
}
.freebies_iner4 label {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: -0.02em;
    color: #242424;
    margin: 17px 0px 6px;
}
.freebies_iner4 p {
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    -ms-flex-align: center;
    align-items: center;
    color: #242424;
    opacity: 0.5;
}
.descp_freebies4 {
    border-left: 2px solid #f7ecea;
    padding-left: 16px;
    padding-bottom: 5px;
    margin-bottom: 25px;
}
.descp_freebies4 label {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #242424;
}
.descp_freebies4 p {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    -ms-flex-align: center;
    align-items: center;
    /* letter-spacing: 0.01em; */
    color: #242424;
    opacity: 0.9;
    margin-bottom: 0px;
    font-family: 'OmnesRegular';
}
.instant_upr4 {
    background: #fdf7f6;
    border-radius: 20px;
    margin-top: 25px;
}
.instant_hendupr4 {
    background: #fdf7f7;
    border-radius: 20px;
}
.instant_hend4 {
    text-align: center;
    width: 100%;
    background: #FFFFFF;
    padding: 28px 36px;
    border-radius: 20px;
}
.fiveone_pts4 a {
    background: #66bd99;
    padding: 8px 14px;
    color: #fff !important;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    border-radius: 14px;
}
.fiveone_pts4 a img {
    margin-right: 2px;
    vertical-align: sub;
}
.instant_hend4 h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    /* letter-spacing: -0.01em; */
    color: #7C5E8C;
    font-family: 'OmnesSemiBold';
    margin-top: 20px;
}
.instant_hend4 p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    color: #7C5E8C;
    opacity: 0.6;
    margin-bottom: 0px;
}
.instant_redem4 {
    padding: 24px 20px;
}
.instant_redem4 button {
    color: #ffffff;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    padding: 12px 34px;
    background: -webkit-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: -o-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: linear-gradient(158.95deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 90%;
    -webkit-box-shadow: 0px 3px 13px rgba(252, 97, 114, 0.65);
    box-shadow: 0px 3px 13px rgba(252, 97, 114, 0.65);
    margin: 22px 0px;
}
.declaration_instant4 p {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 120%;
    text-align: center;
    color: rgba(141, 87, 87, 0.5);
}
.show_winer4 {
    display: -ms-flexbox;
    display: flex;
    background: #faeeed;
    padding: 20px 20px;
    border-radius: 20px;
    margin-top: 28px;
}
.show_winer4 div {
    width: 50%;
}
.show_winer_left4 span {
    font-weight: bold;
    font-size: 14px;
    line-height: 114.21%;
    letter-spacing: -0.01em;
    color: #242424;
    margin-left: 16px;
}
.show_winer_rght4 {
    text-align: right;
}
.show_winer_rght4 a {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 114.21%;
    letter-spacing: -0.01em;
    background: #242424;
    border-radius: 9px;
    color: #fff !important;
    padding: 7px 13px;
}
.show_winer_rght4 img {
    margin-left: 16px;
    cursor: pointer;
}

.fmly_connect_inr {
    display: flex;
}
.fmly_connect_inr div {
    width: 50%;
}
.one_fmly_connect {
    text-align: right;
}
.one_fmly p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: rgba(141, 87, 87, 0.5);
    opacity: 0.99;
    margin-bottom: 0px;
}
.one_fmly p span {
    font-weight: bold;
    font-size: 14px;
    -ms-flex-align: center;
    align-items: center;
    letter-spacing: 0.02em;
    color: #242424;
    opacity: 0.99;
}
.one_fmly_connect a {
    margin-right: -18px;
}
.one_fmly_connect a:last-child {
    margin-right: 0px !important;
}

.contfmly {
    margin-bottom: 24px;
    margin-top: 0px;
}
.contfmly div {
    border-radius: 17px !important;
}
.showfmly div {
    width: 50%;
}
.showfmly_num label {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #242424;
    margin-bottom: 0px;
}
.showfmly_txt p {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-align: right;
    color: rgb(102, 189, 153);
    margin-bottom: 0px;
}
.showfmly_txt p b {
    color: #66BD99;
}
.showfmly {
    margin-bottom: 10px;
    display: flex;
}
.fmly_social {
    border-radius: 20px;
    margin-top: 25px;
    background: #faeeed;
}
.fmlySocial a:first-child {
    position: relative;
    margin-right: 8px;
}
.fmlySocial {
    text-align: right;
}

/* .upload_all {
    display: flex;
} */
.upload_inner {
    width: 49%;
    padding: 12px;
    display: inline-block;
}
.upload_inner > img {
    width: 100%;
    border-radius: 20px;
}
.upload_inner a {
    cursor: pointer;
    background: #242424;
    border-radius: 60px;
    padding: 3px 12px 5px;
    right: -9px;
    position: absolute;
    display: inline-block;
    top: -9px;
}
.upload_main {
    margin-top: 50px;
}
.upload_inner label {
    cursor: pointer;
    border-radius: 60px;
    position: absolute;
    top: 36%;
    left: 30%;
    right: 30%;
    bottom: 30%;
    text-align: center;
    margin-bottom: 0px;
}
.upload_in {
    position: relative;
    border-radius: 20px;
    height: 126px;
    text-align: center;
    background: #ffebe6;
}
.upload_inner label img {
    width: 40px;
    height: 40px;
}
.upload_inner label{
    align-items: center;
    text-align: center;
    letter-spacing: -0.01em;
    color: #FF9B80;
    font-size: 16px;
    font-weight: bold;
    line-height: 16px;
}

.upload_form {
    text-align: center;
    margin-top: 15px;
}
.upload_first{
    width: 100%;
    margin-bottom: 8px;
    margin-top: 4px;
}
.upload_form label {
    font-stretch: normal;
    font-family: 'OmnesRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 8px;
    -ms-flex-align: center;
    align-items: center;
    color: #242424 !important;
    opacity: 0.4;
}
.upload_form textarea {
    font-weight: 500;
    width: 100%;
    border: none;
    border-bottom: 1px solid #f3f3f3;
    outline: none;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'OmnesRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    align-items: center;
    color: #242424;
}
.upload_submit button {
    color: #ffffff;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    margin-top: 8%;
    padding: 14px 34px;
    background: -webkit-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: -o-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: linear-gradient(158.95deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 90%;
}
.upload_form div:before, .upload_form div:after {
    content: none !important;
}
.upload_in button.select_files {
    margin-top: 52px;
}
/* End of family add css */

/* Review approve and reject */
.urgnt_reviews h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 29px;
    letter-spacing: -0.02em;
    color: #914949;
    margin-bottom: 0px;
    margin-top: 14px;
}
.urgnt_reviews div {
    width: 50%;
}
.urgnt_reviews {
    display: flex;
    margin-bottom: 24px;
}
.urgnt_reviews div:last-child label:last-child {
    background: -webkit-linear-gradient(332.98deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: -o-linear-gradient(332.98deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: linear-gradient(117.02deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 20px;
    color: #fff !important;
    padding: 12px 20px;
    -webkit-box-shadow: 0 18px 20px -18px #FF5A6C;
    box-shadow: 0 18px 20px -18px #FF5A6C;
    margin-left: 6px;
}
.urgnt_reviews div:last-child a {
    display: inline;
}
.urgnt_reviews div:last-child {
    text-align: right;
}
.urgnt_reviews_main {
    background: #ffffff;
    padding: 25px 17px 12px;
    border-radius: 0px 0px 20px 20px;
    margin-bottom: 25px;
}

.all_reviews {
    display: flex;
    margin-bottom: 20px;
}
.all_reviews_left {
    width: 25%;
}
.all_reviews_left img {
    max-width: 60px;
}
.all_reviews_rght {
    width: 62%;
    padding-top: 6px;
}
.all_reviews_rght label {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    color: #242424;
    margin-bottom: 0px;
}
.all_reviews_rght p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    align-items: center;
    letter-spacing: 0.01em;
    color: #242424;
    opacity: 0.5;
    margin-bottom: 0px;
}
.all_reviews_close {
    width: 13%;
    text-align: right;
    padding-top: 10px;
}
.all_reviews_close img {
    cursor: pointer;
}
a.reviews_close {
    background: #EE374A;
    border: 2px solid #FFFFFF;
    border-radius: 60px;
    padding: 9px 9px;
    outline: none !important;
    cursor: pointer;
    position: absolute;
    bottom: -6px;
    right: -9px;
    display: inline-flex;
}
a.reviews_close img {
    width: initial !important;
    vertical-align: middle;
}
.all_reviews_left span {
    position: relative;
    display: inline-block;
}
/* End of review approve and reject */

/* approve reviews */
.urgnt_aprove_main {
    background: #ffffff;
    padding: 25px 17px 12px;
    border-radius: 0px 0px 20px 20px;
    margin-bottom: 25px;
}
.urgnt_aprove {
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 24px;
}
.urgnt_aprove div {
    width: 50%;
}
.urgnt_aprove h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 29px;
    letter-spacing: -0.02em;
    color: #914949;
    margin-bottom: 0px;
    margin-top: 14px;
}
.urgnt_aprove div:last-child label:last-child {
    background: -webkit-linear-gradient(332.98deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: -o-linear-gradient(332.98deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: linear-gradient(117.02deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 20px;
    color: #fff !important;
    padding: 12px 20px;
    -webkit-box-shadow: 0 18px 20px -18px #FF5A6C;
    box-shadow: 0 18px 20px -18px #FF5A6C;
    margin-left: 6px;
}
.all_aprove {
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20px;
}
.all_aprove_left {
    width: 25%;
}
.all_aprove_left span {
    position: relative;
    display: inline-block;
}
.all_aprove_left img {
    max-width: 60px;
}
a.aprove_close {
    background: #66BD99;
    border: 2px solid #FFFFFF;
    border-radius: 60px;
    padding: 9px 7px;
    outline: none !important;
    cursor: pointer;
    position: absolute;
    bottom: -6px;
    right: -9px;
    display: -ms-inline-flexbox;
    display: inline-flex;
}
a.aprove_close img {
    width: 10px !important;
    vertical-align: middle;
}
.all_aprove_rght {
    width: 62%;
    padding-top: 6px;
}
.all_aprove_rght label {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    color: #242424;
    margin-bottom: 0px;
}
.all_aprove_rght p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    -ms-flex-align: center;
    align-items: center;
    letter-spacing: 0.01em;
    color: #242424;
    opacity: 0.5;
    margin-bottom: 0px;
}
.all_aprove_close {
    width: 13%;
    text-align: right;
    padding-top: 10px;
}
.all_aprove_close img {
    cursor: pointer;
}
.urgnt_aprove div:last-child {
    text-align: right;
}
.urgnt_aprove div:last-child a {
    display: inline;
}
/*End of approve reviews */

/* Review equirements css */
.revw_babyImg {
    position: relative;
}
.revw_details_sub {
    padding: 0px 22px;
}
.revw_iner {
    padding: 0px 0px 20px;
}
.revw_iner label {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: -0.02em;
    color: #242424;
    margin: 17px 0px 6px;
}
.revw_iner p {
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    -ms-flex-align: center;
    align-items: center;
    color: #242424;
    opacity: 0.5;
}
.revw_descp_sub {
    border-left: 2px solid #f7ecea;
    padding-left: 16px;
    margin-bottom: 25px;
}
.revw_show {
    margin-bottom: 25px;
}
.revw_show img {
    width: 100%;
}
.revw_descp_sub {
    border-left: 2px solid #f7ecea;
    padding-left: 16px;
    margin-bottom: 25px;
}
.revw_descp_sub label {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #242424;
}
.revw_descp_sub p {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    -ms-flex-align: center;
    align-items: center;
    /* letter-spacing: 0.01em; */
    color: #242424;
    opacity: 0.9;
    margin-bottom: 0px;
    font-family: 'OmnesRegular';
}
.revw_suba_redem {
    text-align: center;
}
.revw_suba_redem button{
    color: #ffffff;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    padding: 12px 34px;
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 90%;
    background: #475EA8 !important;
    text-transform: initial;
    margin-bottom: 24px;
}
.revwImges {
    background: #ffffff;
    text-align: center;
    padding: 39px 0px;
    border-radius: 0px 0px 20px 20px;
}

.msgnr_redem {
    text-align: left;
}
.msgnr_redem button {
    color: #fc6272;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    padding: 8px 16px;
    border-radius: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    background: #ffeff1 !important;
    text-transform: initial;
    margin-top: 12px;
}
/* Review equirements css */

/* Update design css  */
.login_signup {
    position: relative;
}
.login_signup button {
    width: 80%;
    background: -webkit-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: -o-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: linear-gradient(158.95deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 20px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    text-transform: capitalize;
    font-family: 'OmnesSemiBold';
    padding: 12px 12px;
    outline: none !important;
    position: absolute;
    left: 10%;
    right: 10%;
    top: 30%;
    box-shadow: 0 18px 20px -18px #FF5A6C;
}
.login_signup img {
    width: 100%;
    border-radius: 20px;
}
.login_signup {
    position: relative;
    height: 114px;
    overflow: hidden;
}
.childinfo_collect {
    padding-top: 52px;
    position: relative;
    min-height: 444px;
}
.childinfo_Btns {
    position: absolute;
    width: 100%;
    bottom: 0;
}
.manage_space{
    height: 95px;
}
/* End Update design css  */

/* Residental address css */
.line_two h4 {
    font-size: 14px;
    line-height: 20px;
    color: #242424;
    font-weight: bold;
    text-align: left;
}
.line_two {
    margin-left: -22px;
    margin-right: -22px;
    background: #ffffff;
    border-radius: 20px;
    padding: 30px 21px;
    margin-top: 25px !important;
    margin-bottom: 12px !important;
    width: 114% !important;
}
.line_two h4 a {
    float: right;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #242424;
    opacity: 0.4;
    cursor: pointer;
}
/* End of Residental address css */

.loader_class{
    margin-top: 15%;
    width: 40px !important;
    height: 40px !important;
}

.loadmore_loading{
    margin-top: 15%;
    width: 30px !important;
    height: 30px !important;
}

.MuiFormControl-marginNormal{
    margin-top: 0px !important;
}
.upload_in img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    /* width: 100%;
    border-radius: 20px;
    max-height: 126px;
    width: 100%; */
}
.upload_in a img {
    border-radius: initial !important;
}

/* Standerd media  */
.custom_swiper .swiper-button-prev{
    opacity: 0;
    background-image: none !important;
}
.custom_swiper .swiper-button-next {
    opacity: 0;
}
.custom_swiper .bording_backlink{
    opacity: initial !important;
}
 .boarding_submit button {
    opacity: initial !important;
}

@media screen and (max-width: 414px){
    .call_link button {
        padding: 12px 20px !important;
    }
    .upload_in img {
        width: 100%;
     }
    .extragain_tabs {
        margin-top: 28px !important;
        margin-bottom: 8px !important;
    }
    .discover_headLeft label {
        margin-bottom: 0px !important;
    }
    /* .gain_extra h1 {
        font-size: 25px !important;
        line-height: 40px !important;
    } */
    /* .extragain_tabs a.nav-link {
        font-size: 18px !important;
    } */
    .signup_gender button {
        min-width: 142.94px !important;
    }

    .upload_in {
        min-height: 113px !important;
    }
    .gainextra_submit button {
        margin-top: 0px !important;
    }
    .gainextra_form {
        margin-top: 22px !important;
        padding-bottom: 18px !important;
        padding-top: 10px !important;
    }
    .contest_h2 h2 {
        margin-top: 22px !important;
    }
    .gainextra_form input, .gainextra_form textarea {
        margin-bottom: 4px !important;
    }
    .gainextra_submit button {
        margin-top: 5px !important;
    }
    .custom_faqs span.Collapsible__trigger {
        padding: 8px 22px !important;
    }
    .faq_label h2 {
        margin-top: 30px !important;
        margin-bottom: 12px !important;
    }
    .help_contentLeft span {
        font-size: 13px !important;
        margin-left: 11px !important;
    }
    .help_label h1 {
        margin-bottom: 18px !important;
    }

    /* 22-aug-css */
    .social_icon {
        margin-top: 4% !important;
    }
    .forget_email {
        margin-top: 25% !important;
    }
    .sign_up_declare {
        margin-top: 12% !important;
    }
    /* .signup_gender {
        margin: 60px 0px 48px !important;
    } */
    .signup_pass {
        margin-top: 30px !important;
    }
    .signup_pass {
        margin-top: 42px !important;
    }
    .sign_up {
        margin-bottom: 15px !important;
    }
    .prent_submit button {
        margin-top: 10px !important;
    }
    .add_parent {
        margin-top: 18% !important;
    }
    .build_content p {
        margin: 12px 0px !important;
    }
    .or_contact {
        margin: 12% 0px 0px !important;
    }
    .phone_opt {
        margin-top: 20px !important;
    }
    .or_contact button {
        padding: 16px 24px !important;
        width: 38% !important;
    }
    /* End of 21-aug-css */
    .signFooter p {
        padding: 0px 16px !important;
    }
}

@media (min-width: 568px) and (max-width: 767px) {
    .webMenu {
        /* width: 540px !important; */
        outline: none;
    }
}

@media (min-width: 560px)and (max-width: 767px){
    .swiper-slide {
        padding-bottom: 40px !important;
    }
}

@media (min-width: 320px)and (max-width: 560px){
    .swiper-slide {
        padding-bottom: 40px !important;
    }
}

@media (min-width: 767px){
    .swiper-slide {
        padding-bottom: 11% !important;
    }
}

@media (min-width: 414px) and (max-width: 992px) {
    .or_contact {
        margin: 12% 0px 0px !important;
    }
}

.closepopup_rel .closeModel {
    background: transparent !important;
    border: 1px solid #fb5f70 !important;
    color: #9c5757 !important;
    width: 15px !important;
    padding: 0px 11px 1px !important;
    line-height: initial !important;
    font-size: 12px !important;
    padding-left: 5px !important;
    cursor: pointer !important;
    border-radius: 18px;
    margin-top: 0px !important;

    font-family: 'OmnesSemiBold';
    outline: none !important;
    margin-top: 5px;
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
}
.closepopup_rel {
    right: 9px;
    position: absolute;
    top: 8px;
}
.error_message {
    text-align: center;
    margin-left: 0px !important;
    margin-top: 0px !important;
    display: block;
}
.closepopup_rel .modal-header {
    padding: 0px !important;
    border-bottom: none !important;
}
.add_familySocial2 img {
    width: 52px;
    height: 52px;
    border-radius: 30px;
}
.custom_align {
    border: 3px solid #FDF7F6;
    background: #f4eef6;
    color: #8E53A6;
    font-weight: bold;
    vertical-align: middle;
}
.add_familyBtn2 a img {
    margin-top: -3px !important;
    margin-left: -15px;
    z-index: 1;
    position: relative;
    border: 2px solid #fff;
    border-radius: 13px;
}
button.google {
    box-shadow: none !important;
}
/* End of Standerd media  */

.MuiSwitch-root{
    margin-top: -8px
}

/* @media screen and (max-width: 414px) {
    .extra_contRght, .extra_freRght {
        padding-top: 4px !important;
    }
} */

@media screen and (max-width: 320px) {
    .extra_contmain, .extra_fremain {
        padding: 16px 8px !important;
    }
    .extra_contLeft img, .extra_freLeft img {
        max-width: 56px !important;
    }
    .add_familyBtn button {
        padding: 9px 10px !important;
    }
    /* .all_relative_section2, .all_children_section {
        height: 320px !important;
        min-height: 320px !important;
    } */
    .all_relative_section2 {
        height: 320px !important;
        min-height: 320px !important;
    }
    .all_family_left label, .all_children_left label {
        width: 55px !important;
        height: 55px !important;
        font-size: 14px !important;
    }
}

/* @media screen and (min-width: 414px) {
    .logo_upr {
        margin-top: 40%;
    }
    .membrsProv {
        margin-bottom: 50px !important;
    }
} */

.swiper-slide.slides.swiper-slide-next{
    transition-duration: 0ms !important;
    transform: translate3d(0px, 0px, -401.078px) rotateX(0deg) rotateY(-40.1078deg) !important;
    z-index: 0 !important;
}
.swiper-slide.slides.swiper-slide-prev{
    transition-duration: 0ms !important;
    transform: translate3d(0px, 0px, -398.922px) rotateX(0deg) rotateY(39.8922deg) !important;
    z-index: 0 !important;
}
.swiper-slide.slides.swiper-slide-active {
    transform: initial !important;
}
.sharegain_swiper .swiper-slide-prev img {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.detail_swiper .swiper-slide-prev img {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}
.sharegain .swiper-slide-next img {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
.social_icon button.facebook {
    margin-right: 0px !important;
}

img.btnback_arrow {
    width: 22px;
    margin-right: 4px;
}
button.artBoard_btnyes {
    position: absolute;
    border: none;
    right: 0px;
    cursor: pointer !important;
    color: #3f51b5;
    font-weight: bold;
    background: transparent;
    padding: 0px;
    outline: none !important;
}
.artBoardbtn_div {
    margin-top: 10px !important;
}
.artBoard_btnbackdiv button {
    padding: 0px;
}

/* Country dropdown css */
.country_data{ 
    width: 100%;
    background: #FFFFFF;
    border-radius: 20px;
    padding: 21px 22px 30px 27px;
    margin-top: 45px;
}
.country_ac{
    display: flex;
    margin-bottom: 10px;
}
.country_acLft{
    width: 70%;
    text-align: left;
}
.country_acRght{
    width: 30%;
    text-align: right;
}
.country_acLft label{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #4E4E4E;
    margin-bottom: 10px;
    font-family: 'OmnesSemiBold';
}
.country_acRght img{
    cursor: pointer;
}
.country_dataList div{
     position: relative;
}
.cntrySelect{
    position: absolute;
    right: 22px;
    top: 16px;
}
.country_dataList{
    height: 310px;
    overflow-y: auto;
    margin-bottom: 0;
    overflow-x: hidden;
}
.country_dataList {
    scrollbar-color: #e7dad9 #f5f5f5;
    scrollbar-width: thin;
}
.country_dataList::-webkit-scrollbar-track{
	background-color: #F5F5F5;
	border-radius: 10px;
}
.country_dataList::-webkit-scrollbar{
	width: 3px;
	background-color: #F5F5F5;
}
.country_dataList::-webkit-scrollbar-thumb{
    border-radius: 10px;
    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.44, #dfdfdf), 
    color-stop(0.72, #d2cece), color-stop(0.86, rgba(66, 66, 66, 0.23)));
}
.country_dataList a {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    /* color: rgba(141,87,87,.5)!important; */
    white-space: pre-wrap;
    margin-bottom: 14px !important;
    display: block;
    opacity: 0.5;
    font-family: 'OmnesRegular';
}
.blue_country{
    color:blue !important;
}
.red_country{
    color:red !important;
}
.other_country{
    color: #242424 !important;
}
.country_dataList a option{
    white-space: pre-wrap;
 }
 .cntrySelect {
    position: absolute;
    right: 22px;
    top: 8px;
}
.country_active a{
    opacity: 1 !important;
}
.country_acRght .closeAddressModel {
    background: transparent;
    border: none;
    color: #4e4e4e;
    border-radius: 18px;
    cursor: pointer;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    padding: 0px;
    outline: none !important;
    font-family: 'OmnesRegular';
}
.country_acRght .modal-header {
    text-align: right !important;
    padding: 0px;
}
/* End of Country dropdown css */
.all_children_section {
    /* height: 320px;
    overflow-x: hidden;
    padding-bottom: 12px;
    overflow-y: auto !important; */
    outline: none;
}
.all_relative_section2 {
    overflow-y: auto !important;
    padding-right: 5px;
}
.all_children_section::-webkit-scrollbar-track{
	background-color: #F5F5F5;
	border-radius: 10px;
}
.all_children_section::-webkit-scrollbar{
	width: 5px;
	background-color: #F5F5F5;
}
.all_children_section::-webkit-scrollbar-thumb{
    border-radius: 10px;
    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.44, #dfdfdf), 
    color-stop(0.72, #d2cece), color-stop(0.86, rgba(66, 66, 66, 0.23)));
}
.all_relative_section2::-webkit-scrollbar-track{
	background-color: #F5F5F5;
	border-radius: 10px;
}
.all_relative_section2::-webkit-scrollbar{
	width: 5px;
	background-color: #F5F5F5;
}
.all_relative_section2::-webkit-scrollbar-thumb{
    border-radius: 10px;
    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.44, #dfdfdf), 
    color-stop(0.72, #d2cece), color-stop(0.86, rgba(66, 66, 66, 0.23)));
}
.common_main_space1{
    /* padding: 0px !important; */
    padding: 2px 12px;
    padding-top: 16px;
}
/* 4 sept css */
.urgent_main1{
    padding: 25px 8px;
    border-radius: 0px 0px 16px 16px;
    background-color: white;
    margin-top: -12px !important;
}
.editchild_contentDel {
    min-height: initial !important;
}
.editchild_contentDel button {
    color: #ffffff !important;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    margin-top: 5px;
    padding: 12px 34px;
    background: -webkit-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%) !important;
    background: -o-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: linear-gradient(158.95deg, #FF5A6C -28.17%, #F27381 366.38%) !important;
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
}
.add_familyBtn2 .custom_align a img {
    border: none !important;
    border-radius: initial !important;
    margin-left: initial !important;
    vertical-align: bottom !important;
    width: 24px;
    height: 24px;
}
/* Notify Top Slider css */

/* 4 sept css */
.all_notify31 {
    display: flex;
    margin-bottom: 22px;
    cursor: pointer;
    background-color: #f7e9e9;
    padding: 6px;
    border-radius: 10px;
}
.all_notify_left31 {
    width: 25%;
    position: relative;
}
.all_notify_left31 img {
    width: 68px;
    height: 68px;
    border-radius: 20px !important;
}
.al_notify_rght31 {
    width: 75%;
}
.all_notify_rght31 p {
    font-style: normal;
    font-size: 14px;
    line-height: 130%;
    margin-bottom: 4px;
    color: #242424;
}
.all_notify_rght31 span {
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    display: block;
    align-items: center;
    letter-spacing: 0.01em;
    color: #242424;
    opacity: 0.5;
}
.all_notify_rght31 p label {
    font-weight: bold;
    display: inline !important;
}
.all_notify_rght31 {
    width: 65%;
    padding-top: 3px;
}
.all_notify_close31 {
    width: 10%;
    text-align: right;
    padding-top: 10px;
}
.all_notify_close31 img {
    cursor: pointer;
    width: 22px;
}
.all_notify_section31 {
    margin-top: 13px;
    padding-bottom: 0px !important;
}
.notify_head31 {
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 16px;
}
.notify_headLeft31, .notify_headRght31 {
    width: 48%;
}
.notify_headLeft31 label {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 29px;
    letter-spacing: -0.02em;
    color: #914949;
    font-family: 'OmnesSemiBold';
    margin-top: 0px;
}
.notify_headRght31 {
    text-align: right;
    padding-right: 2px;
}
.notify_headRght31 a{
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 16px;
    /* letter-spacing: -0.02em; */
    color: #ffffff !important;
    border-radius: 2px;
    background: linear-gradient(117.02deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 15px 15px 15px 15px;
    padding: 11px 12px;
    font-family: 'OmnesSemiBold';
    box-shadow: 0px 3px 7px rgba(252, 97, 114, 0.65);
}
.custom_swiper31 .swiper-container {
    min-height: 20vh;
    max-height: 45vh;
    overflow: scroll;
}
.custom_swiper31 .swiper-pagination-bullet-active {
    opacity: 1;
    background: #724747 !important;
}
.custom_swiper31 .swiper-button-prev{
    opacity: 0;
    background-image: none !important;
}
.custom_swiper31 .swiper-button-next {
    opacity: 0;
}
.custom_swiper31 .bording_backlink{
    opacity: initial !important;
}
.custom_swiper31 .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: initial !important;
    left: 0;
    top: 18px !important;
    z-index: initial !important;
}
.notification_count, .notify_headLeft label{
    z-index:9!important;
}
.notification_count31{
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 12px;
    background: #FF5A6C;
    text-align: center;
    display: inline-block;
    position: relative;
}
.notification_count31 span {
    position: absolute;
    left: 0;
    font-style: normal;
    right: 0;
    top: 0;
    font-weight: 600;
    bottom: 0;
    color: #fff;
    margin-top: 7px;
}
button.google {
    position: relative;
}
button.google:after {
    top: 16px;
    bottom: 16px;
    position: absolute;
    left: 73px;
    right: 16px;
    content: '';
    height: 21px;
    width: 21px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('../images/google.png');
}
.common_main_space .tab-pane {
    padding: 0px 4px;
}

/* family and children Tab content  */
@media (min-width: 768px) and (max-width: 1024px) {
    .all_relative_section2, .all_children_section {
        /* height: 320px !important;
        min-height: 320px !important; */
        outline: none;
    }
} 
@media (min-width: 481px) and (max-width: 767px) {
    .all_relative_section2, .all_children_section {
        /* height: 320px !important;
        min-height: 320px !important; */
        outline: none;
    }
}
/* @media (min-width: 321px) and (max-width: 480px) {
    .all_relative_section2, .all_children_section {
        height: 368px !important;
    }
} */
/*End of family and children Tab content  */

/* 13 */
/* .detailback_section_program {
    display: flex;
    padding: 0px 22px;
    position: sticky !important;
    width: 100%;
    top: 17px;
}
.fullDetail {
    transform: scale(0);
    transition: all .5s;
}
.animate_cstm {
    transform: scale(1) !important;
}
.contests_content{
    height: 360px;
 }
.contests_contentAuto{
    height: auto !important;
}
div.fullDetail .container {
    width: 100% !important;
}
div.fullDetail .details_descp {
    padding: 0px !important;
}
div.fullDetail .common_main {
    background: none !important;
    background-image: none !important;
}
.common_main_space .fixed-header {
    margin-left: -22px;
    margin-right: -22px;
}
.common_mainNone .common_main_space1,
.common_mainNone .sharegain_swiper,
.common_mainNone .gain_extra,
.common_mainNone div#navbar{
    display: none !important;
} */
/* 13 */

/* 16 */
div.fullDetail .container {
    width: 100% !important;
}
.common_main_space .fixed-header {
    padding-left: 20px;
    padding-right: 20px;
    margin-left: -22px;
    margin-right: -22px;
    padding-top: 12px !important;
    padding-bottom: 0px !important;
}
.Instant_lght a {
    border: none !important;
    background: #fcebe7 !important;
}
.Instant_lght a {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    border: 1px solid #ffcfc17d;
    padding: 8px 14px;
    display: inline-block;
    color: #FF9B80 !important;
    border-radius: 14px;
    cursor: pointer;
    margin-bottom: 13px;
}
.Instant_lght a img {
    margin-right: 4px;
}
.Instant_lght a span {
    vertical-align: middle;
}
.sim_section label {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: #242424;
    margin: 10px 0px 6px;
    font-family: 'OmnesSemiBold';
}
.sim_section p {
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    align-items: center;
    color: #242424;
    opacity: 0.5;
    font-family: 'OmnesRegular';
}
div.fullDetail .common_main {
    background: none !important;
    background-image: none !important;
    padding-bottom: 0px !important;
}
.fullDetail .common_main {
    min-height: auto !important;
}
.contests_content {
    background: transparent !important;
}

/* Single view */
.contests_dtlImges{
    cursor: pointer;
}
/* .contests_cstm .program_detail_logo{
    bottom: 16px;
} */
.contests_cstm{
    padding: 0px;
    /* background: #ffffff; */
    background: transparent !important;
}

/* .contests_cstm .detailback_section, 
.contests_cstm .instant_details2, 
.contests_cstm .instant_upr2_cstm, 
.contests_cstm .descp_all, 
.contests_cstm .lend_hendupr, 
.contests_cstm .ends_date_cutm{ display: none; } */

.contests_cstm .dtailsCstm{
    margin-bottom: 40%;
    padding: 20px 20px;
    /* padding-top: 38px; */
    padding-top: 12px;
}

.height_ios{
    margin-bottom: 45%;
}

.height_ios_enter{
    margin-bottom: 45% !important;
}

.contests_cstm .sim_section{
    margin-bottom: 8px;
}
.enterConst_btn {
    left: 0;
    /* display: flex; */
    position: absolute;
    /* right: 19%; */
    /* bottom: 25%; */
    width: 100%;
    left: 0px;
    right: 0;
    top: 6%;
    text-align: center;
    margin: 0 auto;
    /* bottom: 0; */
}

.pendingContest_btn {
    position: absolute;
    right: 16px;
    bottom: 20px;
    background: white;
    border-radius: 20px;
    padding: 8px 12px;
}

.enterConst_btn a {
    color: green !important;
}
/* End of Single view */

/* details view */
.contests_cstmDetls .Instant_lght{
    display: none;
}
.contests_cstmDetls .sim_section{
    padding: 20px 20px 25px;
}
.contests_cstmDetls .sim_section{
    padding: 20px 0px !important;
}
.contests_cstmDetls .common_main_space1,
.contests_cstmDetls .sharegain_swiper,
.contests_cstmDetls .gain_extra,
.contests_cstmDetls div#navbar{
    display: none !important;
}
.dtlsSpace_rmv{
    padding: 0px !important;
}
.dtlsSpace_rmv .dtailsCstm{
    padding: 0px 22px;
}
.contests_cstmDetls .enterConst_btn{
    display: none;
}
/*End of details view */

/* Start of Story contest Tab content */
.stry_content {
    /* background: #fffefe94; */
    background: #ffffff;
    border-radius: 20px 20px 20px 20px;
    margin-bottom: 30px;
}
.stry_contentImg {
    position: relative;
}
.stry_contentImges {
    max-height: 200px;
    overflow: hidden;
    border-radius: 20px;
}
.stry_contentImges img {
    width: 100%;
    border-radius: 20px 20px 20px 20px;
}
.stry_content_iner {
    padding: 20px 20px 25px;
}
.stry_content_iner label {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: -0.02em;
    color: #242424;
    margin: 0px 0px 6px;
}
.stry_content_iner p {
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    -ms-flex-align: center;
    align-items: center;
    color: #242424;
    opacity: 0.5;
}
.strySpace_rmv{
    padding: 0px !important;
}
.stryDetailCantain .container{
    width: 100%;
}
.stryDetailCantain .row{
    padding: 0px;
}
.stryDetailCantain .common_main{
    background: transparent !important;
    padding-bottom: 1px !important;
}
.stryDetail .detailback_section, .stryDetail .dtl_ImgesCntnt, .stryDetail .dtl_details_sub{
    display: none;
}
.stryDetail .dtl_Imges{
    border-radius: 20px 20px 20px 20px;
}
.stryContents{
    display: none;
}
.stryDetail .stryContents{
    display: block !important;
}
.stryDetail .stryContents_wht {
    background: #ffffff;
    border-radius: 0px 0px 20px 20px;
}
.dtl_ImgesCntnt {
    padding: 0px 4px;
}
/*End of Story contest Tab content */

/*Review Tab content */
.rvews_content {
    /* background: #fffefe94; */
    background: #ffffff;
    border-radius: 20px 20px 20px 20px;
    margin-bottom: 30px;
}
.rvews_contentImg {
    position: relative;
}
.rvews_contentImges {
    max-height: 200px;
    overflow: hidden;
    border-radius: 20px;
}
.rvews_contentImges img {
    width: 100%;
    border-radius: 20px 20px 20px 20px;
}
.rvews_content_iner {
    padding: 20px 20px 25px;
}
.rvews_content_iner label {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: -0.02em;
    color: #242424;
    margin: 0px 0px 6px;
}
.rvews_content_iner p {
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    -ms-flex-align: center;
    align-items: center;
    color: #242424;
    opacity: 0.5;
}
.rvewsPage_dtl .moisback_section, 
.rvewsPage_dtl .program_detail_logo, 
.rvewsPage_dtl .revwDescp_cotain {
    display: none;
}
.rvewsPage_dtl .common_main {
    min-height: initial !important;
    background: transparent !important;
}
.rvewsPage_dtl .container {
    width: 100% !important;
}
.rvewsPage_dtl .reviewListMain {
    background: #ffffff;
    border-radius: 20px 20px 20px 20px;
}
.rvewsPage_dtl .mois_iner label {
    font-size: 20px !important;
    line-height: 23px !important;
    margin: 0px 0px 6px !important;
}
.rvewsPage_dtl .mois_iner p {
    font-size: 14px !important;
    line-height: 18px !important;
}
.rvewsPage_dtl .mois_iner {
    padding: 20px 0px 20px !important;
}
.revwsSpace_rmv{
    padding: 0px !important;
}
.stryContents {
    padding: 20px 20px 25px;
    border-radius: 0px 0px 20px 20px;
}
.stryContents label {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: -0.02em;
    color: #242424;
    margin: 0px 0px 6px;
}
.stryContents p {
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    -ms-flex-align: center;
    align-items: center;
    color: #242424;
    opacity: 0.5;
}
/*End of Review Tab content */
.helper{
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}
.upload_cntnt{
    background: #ffebe6;
    /* height: 136px; */
    height: 126px;
    overflow: hidden;
    border-radius: 20px;
}

.profile_pic a image{
    border-radius: 50px;
}
div#fb-root {
    width: 395px !important;
    margin: 0 auto !important;
    position: relative !important;
}
.fb_dialog {
    left: 50% !important;
    transform: translate(-50%, 0) !important;
    bottom: 38pt !important;
}
.fb_iframe_widget iframe {
    left: 50% !important;
    transform: translate(-50%, 0) !important;
    bottom: -8px !important;
}
/* session enter design  */
.sessionEntr_content {
    background: #fffefe94 !important;
}
.sessionEntrContainer {
    width: 100% !important;
}
.sessionEntrContainer .row {
    padding: 0px !important;
}
.sessionEntrContainer .common_main {
    background: transparent;
    min-height: initial !important;
}
.common_main_spaceRmv, .tab-pane-rmv-spc{
    padding: 0px !important;
}
.sessionCntntDtails .sessionAddOns {
    background: #fffefe94;
}
.sessionCntntDtails .detailback_section, 
.sessionCntntDtails .descp_freebies,
.sessionCntntDtails .ends_date, 
.sessionCntntDtails .hometwo_upr{display: none;}
/* Enter session enter design  */
.main_detail_logo{
    bottom: 16px ;
}
.profile_pic img{
    border-radius: 50%;
}
/* new updated images css */
.social_nw {width: 52px;}

/* .back_section img, .sharegain_backlink img, .sign_backlink img, 
.backlink img, .detailback_section img, .moisback_secone img{
    width: 12px;
} */

/* .webMenu ul li:first-child img {
    width: 27px;
}
.webMenu ul li:nth-child(2) img {
    width: 26px;
}
.webMenu ul li:nth-child(3) img {
    width: 25px;
}
.webMenu ul li:nth-child(4) img {
    width: 24px;
}
.webMenu ul li:nth-child(5) img {
    width: 22px;
} */

.edit_contentRght img {
    width: 8px;
}
.help_contentRght img {
    width: 8px;
}
.tab_btmbarRght img {
    width: 18px;
}
.discover_headRght img {
    /* height: 50px; */
    height: 26px;
}
.notify_headRght31 img{
    height: 50px; 
}
.nextslides img {
    width: 77px !important;
}
.Instant_lght a img {
    width: 12px;
}
.sort_dealBy a img {
    width: 12px;
    height: auto;
}
.sort_deal ul li img {
    width: 17px;
}
.twtrLink img {
    width: 19px;
}
.faceBokLink img {
    width: 10px;
}
.instant_details2 a img {
    width: 14px;
}
.unchild_left img {
    width: 35px;
}
.instant_details a img {
    width: 14px;
}
img.twiterLgin {
    width: 52px;
}
.country_active img {
    width: 17px;
}
.add_parent_inner img {
    width: 100px;
}
.clearimg {
    width: 22px;
}
.addClintImg {
    width: 36px;
}
.crown_16fre img {
    width: 15px;
    vertical-align: baseline;
}
.all_relative_close img {
    width: 16px;
}
.logo_upr img {
    width: 214.93px;
}
.moisback_secthree img {
    width: 17px;
    border-radius: 0px !important;
}
.upload_in a img {
    width: 8px !important;
    border-radius: initial !important;
}
.detailback_white img {
    width: 26px !important;
}
.fb_connect img {
    width: 10px;
}
.show_winer_rght2 img {
    width: 8px;
}
.show_winer_left2 img {
    width: 24px;
}
.custom_align img {
    width: 34px;
    height: 34px;
    margin-top: -5px;
    border-radius: 30px;
}
.fiveone_pts3 a img {
    text-align: center;
    width: 118px
}
.moisback_section img {
    width: 12px;
}
img.clearcontest_img {
    /* width: 17px; */
    width: 12px;
    height: auto;
}
.ticket_16fre img {
    width: 12px;
}
.backLinkWidth {
    text-align: left !important;
    width: 60px !important;
    height: 45px !important;
}
/* end of updated images css */
.cstm_dtlImges_display {
    display: none;
 }

.story_share {
    outline: none !important;
    padding: 11px 34px;
    background: #f5d6e3;
    border-radius: 30px;
    font-style: normal;
    font-size: 15px;
    line-height: 20px;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 11px;
    font-weight: 600;
    color: #ee576a !important;
    text-transform: uppercase;
    min-height: 52px;
    font-family: 'OmnesSemiBold';
    align-items: center;
    text-align: center;
    justify-content: center;
    display: flex;
    text-decoration: none !important;
}

.error_message_div{
    text-align: center;
    margin-top: 10px;
}
.phone_select_class{
    margin-top: 2px !important;
    color: #242424 !important;
}
/*.phone_class{
    margin-top: 2px !important;
    color: #3830 !important;
     text-transform: uppercase;
    text-decoration: none;
}*/
ul.perks_nav.nav.nav-tabs{
    overflow-y: hidden;
    overflow-x: auto;
    position: relative;
    padding-bottom: 3px;
}
.perks_navUpr .nav{
    flex-wrap: nowrap !important;
    justify-content: flex-start !important;
}
/* Scrollable tabs Design */
.scrollTbCstm button{
    max-width: initial !important;
    min-width: initial !important;
    border: none !important;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    font-family: 'OmnesSemiBold';
    text-align: center;
    cursor: pointer;
    display: inline-block;
    padding: 3px 14px !important;
    border-radius: 17px;
    background: #fff7f6;
    outline: none !important;
    margin-right: 6px;
    height: auto !important;
    max-height: initial !important;
    min-height: 42px !important;
}
.scrollTbCstm button span{
    font-size: 14px !important;
    font-family: 'OmnesRegular';
    text-transform: CAPITALIZE;
}
.scrollTbCstmUpr{
    background: transparent !important;
    box-shadow: initial !important;
}
.scrollTbCstm div span {
    background-color: transparent !important;
}
.common_mainTabs{
    padding-right: 0px !important;
    padding-left: 0px !important;
}
.kidcode_inr input{
    outline: none !important;
    background: transparent;
    border: none !important;
    width: 72%;
}
/* End Scrollable tabs Design */
.perksImgsNw a{
    display: inline-block;
    height: 59px;
    width: 65px;
    background: #ffffff;
    border-radius: 20px;
    line-height: 55px;
    margin-right: 8px;
    text-align: center;
}
.perksImgsNw img{
    margin-right: 0px !important;
}
.barcode_inr{
    text-align: center;
}
.kidcode_submit a{
    text-decoration: none !important;
}
.clear_input .clear_img{
    width: 28px;
}
.rvewsPage_dtl .revwImges {
    max-height: 270px;
    overflow: hidden;
    border-radius: 20px;
}
.rvewsPage_dtl .detailback_section, .rvewsPage_dtl .revw_descp_sub, 
.rvewsPage_dtl .vdo_show, .rvewsPage_dtl .revw_descp_sub, .rvewsPage_dtl .revw_suba_redem {
    display: none;
}
.pluginSkinLight.pluginFontHelvetica table{
    width: 328px !important;
}
.rvewsPage_dtl .revwImges {
    background: transparent !important;
}
.likeOn_fb{
    margin-left: -15px !important;
}
.rvewsPage_dtl .detailspace_rmv_prc {
    background: #ffffff !important;
    border-radius: 20px 20px 20px 20px !important;
}
.rvewsPage_dtl .revw_iner label {
    text-align: center;
    display: block;
    margin-top: -12px;
    margin-bottom: 25px;
}
.rvw_reqBtn, .rvewsPage_dtl .revw_iner p{
    display: none;
}
.rvewsPage_dtl .rvw_reqBtn {
    display: block !important;
}
.rvw_reqBtn {
    text-align: center;
}
.rvw_reqBtn button {
    text-transform: capitalize;
    font-family: 'OmnesSemiBold';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 11px;
    align-items: center;
    text-align: center;
    /* letter-spacing: -0.01em; */
    color: #FF9B80;
    background: #fef1ec;
    padding: 16px 24px;
    border-radius: 14px;
    outline: none !important;
}
/* 
.revwsSpace_rmv div#review {
     width: 35724px !important;
} 
*/
/* #barcode {
    width: 310px !important;
} */
.revwsSpace_rmv .container, .revwsSpace_rmv .row{
    width: 100% !important;
    padding: 0px !important;
}

 .rvewsPage_dtl .perks_all, .rvewsPage_dtl .deal_redem, 
.rvewsPage_dtl .deal_frrebies, .rvewsPage_dtl .perks_loc, .rvewsPage_dtl .map_location{
    display: none;
}
.rvewsPage_dtl .perksImgs {
    bottom: 16px !important;
}
.rvewsPage_dtl .perks_dtl {
    background: #ffffff;
    border-radius: 20px 20px 20px 20px;
}
.rvewsPage_dtl .row {
    padding: 0px !important;
}
/* Terms and Condition Model  */
.TermCon_model {
    height: 100%;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}
.TermCon_model .modal-content {
    top: 1%;
    font-family: 'OmnesRegular';
    min-height: 259px;
    background: #ffffff !important;
    border-radius: 20px;
    left: 0px !important;
    padding: 28px 24px;
}
@media (min-width: 576px){
.modal-dialog.TermCon_model {
    max-width: 360px !important;
    margin: 1.75rem auto;
  }
}
.TermCon_model .sharegain_up {
    margin-top: 0px !important;
}
.TermCon_Body .sharegain_backlink {
    top: -1px !important;
    left: 0px !important;
}
.tcModelBtn {
    padding: 8px 18px;
    background: #f9e5e5;
    margin-top: 53px;
    outline: none;
}
.tc_baby{
    text-align: center;
    border-radius: 20px 20px 20px 20px;
}
/* End of Terms and Condition Model  */

/* perks page new requirements */
.parksPage_dtl .perks_all, .parksPage_dtl .deal_redem, 
.parksPage_dtl .deal_frrebies, .parksPage_dtl .perks_loc, .parksPage_dtl .map_location, 
.parksPage_dtl .detailback_section{
    display: none;
}
.parksPage_dtl .perksImgs {
    bottom: 16px !important;
}
.parksPage_dtl .perks_dtl {
    background: #ffffff;
    border-radius: 24px 24px 20px 20px;
}
.parksPage_dtl .row {
    padding: 0px !important;
}
.parksPage_dtl .common_main {
    min-height: initial !important;
    background: transparent !important;
}
.parksPage_dtl .container {
    width: 100% !important;
}

.parksPage_dtl_rmv .common_main {
    min-height: initial !important;
    background: transparent;
}
.parksPage_dtl_rmv .container {
    width: 100% !important;
}
.parksPage_dtl_rmv .row, .common_main_perkRmv {
    padding: 0px !important;
}
.custom_dealBody .closepopup_rel {
    right: 15px !important;
    top: 31% !important;
}
/*End of perks page new requirements */

.popup_close_button{ 
    top: 18px !important;
    right: 20px !important;
    bottom: 30px !important;
}
.upgradJoin h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    color: #914949;
    font-family: 'OmnesSemiBold';
    margin-bottom: 0px;
}
.upgradJoin {
    background: #fceeee;
    padding: 25px 27px;
    border-radius: 0px 0px 20px 20px;
}
.upgradJoin p {
    font-style: normal;
    font-weight: normal;
    margin-bottom: 0px;
    font-family: 'OmnesRegular';
    font-size: 10px;
    text-align: center;
    color: #91494994;
    line-height: 12px;
}
.upgradJoin button {
    color: #ffffff;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    padding: 12px 34px;
    background: -o-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: -webkit-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: linear-gradient(158.95deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 90%;
    margin: 22px 0px 20px;
    -webkit-box-shadow: 0 6px 16px -6px #fc6071;
    -moz-box-shadow: 0 6px 16px -6px #fc6071;
    box-shadow: 0 6px 16px -6px #fc6071;
}
.upgradJoin p span {
    color: #91494f;
    cursor: pointer;
}
button.upgradeRght_btn {
    background: #f0f0f0;
    color: #babbc6;
    border-radius: 14px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    font-family: 'OmnesSemiBold';
    padding: 9px 16px;
    outline: none !important;
    margin: 10px 0px;
}
.upgradeRght_btn img {
    margin-right: 6px;
    width: 16px;
}
.error_message_div {
    margin-left: 0px !important;
}
#barcode {
    width: 100% !important;
    height: initial !important;
}
.waitImg {
    width: 16px !important;
    height: 16px !important;
}
@media screen and (max-width: 320px) {
.all_winer_left label {
    width: 60px !important;
    height: 60px !important;
    padding: 16px 0px !important;
 }
}
.swiper-button-next,.swiper-button-prev{
    display: block;
    cursor: pointer;
    opacity: 0;
}
.lend_hendupr_ne1 {
    /* background: #fdf7f7; */
    border-radius: 20px;
}
.instant_upr3_ne1 {
    /* background: #faeeed; */
    border-radius: 20px;
    margin-top: 25px;
}
.social_ne1 {
    /* background: #fdf7f6; */
    border-radius: 20px 20px;
}
.instant_hendupr3_ne1 {
    background: #fdf7f7;
    border-radius: 20px;
}
.instant_hend3_ne1 {
    text-align: center;
    width: 100%;
    background: #FFFFFF;
    padding: 28px 36px;
    border-radius: 20px;
    box-shadow: 0px 1px 4px 0px #0000001A;
}
.instant_hend3_ne1 h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: #242424;
    margin-top: 0px;
    margin-bottom: 7px;
    font-family: 'OmnesSemiBold';
}
.instant_hend3_ne1 p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    color: #242424;
    opacity: 0.5;
    margin-bottom: 0px;
}
.social_connect_ne1 {
    padding: 24px 25px;
    border-radius: 0px 20px;
}
.survey_start{
    padding: 0px 25px;
}
.survey_start .instant_redem2 button{
    padding: 15px 35px !important
}
.social_connect_inr_ne1 {
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
}
.one_social_ne1 p {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 19px;
    -ms-flex-align: center;
    align-items: center;
    letter-spacing: 0.02em;
    color: rgba(141, 87, 87, 0.5);
    opacity: 0.99;
    margin-bottom: 0px;
    font-family: 'OmnesRegular';
}
.one_social_ne1{
    margin: 12px 0px;
}
.one_social_connect_ne1 {
    text-align: right;
}
.one_social_connect_ne1 {
    display: flex;
    justify-content: flex-end;
}
.plus_ne1 {
    background: transparent !important;
    border: none !important;
}
.custom_align_ne1 {
    border: 2px solid #ffffff;
    background: #f1eff0;
    color: #99cad8;
    font-weight: bold;
    margin-left: -12px;
    z-index: 1;
    width: 40px!important;
    height: 40px!important;
    display: inline-block;
    border-radius: 50% 50%;
    position: relative;
    text-align: center !important;
    padding: 6px 0px 6px;
}
.one_social_connect_ne1 a:last-child {
    margin-right: 0px !important;
}
.custom_align_ne1 img {
    width: 34px;
    margin-top: -2px;
    cursor: pointer;
}
.cmbImg a:last-child img {
    background: #ffffff;
    padding: 2px 4px 0px;
    margin-left: -7px;
    width: 42px;
    border-radius: 18px;
}
.cmbImg img {
    width: 40px;
}
.survey_img img {
    background: #ffffff;
    padding: 2px 4px 0px;
    width: 45px;
}
.editChildInfo {
    text-align: center;
    margin-top: 15px;
}
.editChildInfo button {
    color: #fc6071;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    padding: 13px 34px;
    background: -o-linear-gradient(291.05deg, #FF5A6C -28.17%, #F27381 366.38%);
    background: #fee8ea;
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 25px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
}
.nextnum_div .MuiStepConnector-line {
    display: block;
    border-color: transparent;
    border-top-style: none;
}
.nextnum_div .MuiStepConnector-active .MuiStepConnector-line{
    display: block;
    border-color: #df767d !important;
    border-top-style: dotted !important;
}
.nextnum_div MuiStep-completed .MuiStepConnector-line{
    display: block;
    border-color: #df767d !important;
    border-top-style: dotted !important;
}
.nextnum_div .MuiStepConnector-completed .MuiStepConnector-line{
    display: block;
    border-color: #df767d !important;
    border-top-style: dotted !important;
}

/* .policy_me_nextnum_div .MuiStepConnector-line {
    display: block;
    border-color: transparent;
    border-top-style: none;
}
.policy_me_nextnum_div .MuiStepConnector-active .MuiStepConnector-line{
    display: block;
    border-color: #df767d !important;
    border-top-style: dotted !important;
}
.policy_me_nextnum_div MuiStep-completed .MuiStepConnector-line{
    display: block;
    border-color: #df767d !important;
    border-top-style: dotted !important;
} */
/* .policy_me_nextnum_div .MuiStepConnector-completed .MuiStepConnector-line{
    display: block;
    border-color: #df767d !important;
    border-top-style: dotted !important;
} */
.editChildInfo_stper button {
    color: #fff;
    outline: none!important;
    padding: 12px 34px;
    background: #F47C6A !important;
    border-radius: 30px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
    /* box-shadow: 0 18px 20px -18px #FF5A6C; */
    margin-top: 6px;
    min-height: 52px;
    font-family: 'OmnesSemiBold';
} 
 .editChildInfo_stper2 button {
    color: #fff;
    outline: none!important;
    padding: 12px 34px;
    border-radius: 30px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
    min-height: 52px;
    font-family: 'OmnesSemiBold';
    margin-top: 6px;
    background: #F47C6A !important;
    box-shadow: 0 18px 20px -18px #FF5A6C;
}
/* .editChildInfo_stper2 button:hover {
    background: #f1a085;
} */
.MuiStepConnector-lineVertical {
    border-left-width: 0px !important;
}
.steplabel_fstUpr .MuiStepIcon-root.MuiStepIcon-completed{
    color: #eec56d !important;
}
.editChildInfo_stper2 {
    text-align: center;
    margin-top: 15px;
}
.editChildInfo_stper {
    margin-top: 4px;
    display: flex;
    padding: 0px 22px 26px 22px;
}
.editChildInfo_stperLft {
    width: 15%;
}
.steplabel_secondUpr .MuiStepIcon-root.MuiStepIcon-completed{
    color: #f1a085 !important;
} 
.editChildInfo_stper3 {
    text-align: center;
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.editChildInfo_stper3 button {
    color: #fff;
    outline: none!important;
    padding: 12px 34px;
    border-radius: 30px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
    min-height: 52px;
    margin-top: 6px;
    font-family: 'OmnesSemiBold';
    background: #F47C6A;
    box-shadow: 0 18px 20px -18px #FF5A6C;
}
/* .editChildInfo_stper3 button:hover {
    background: #e37983;
} */
.steplabel_thirdUpr .MuiStepIcon-root.MuiStepIcon-completed {
    color: #e37983 !important;
}
.steplabel_fstUpr .MuiStepIcon-root.MuiStepIcon-active {
    color: #eec56d !important;
}
.row.fade_it_up {
    padding: 0px !important;
}
.phone_verify_backlink img {
    width: 24px !important;
    background: #f7e5e5;
    padding: 5px 8px;
    border-radius: 26px;
}

.survey_backlink img {
    width: 24px !important;
    /* background: #f7e5e5;
    padding: 5px 8px;
    border-radius: 26px; */
}
.editChildInfo_stper button:hover {
    background: #fbc757;
}
.survey_backlink {
    left: 0px !important;
}

.phone_verify_backlink {
    left: 0px !important;
}
.steplabel_second .MuiStepIcon-active {
    color: #f1a085 !important;
}
.steplabel_third .MuiStepIcon-active {
    color: #e37983 !important;
}
.steplabel_fstUpr, .steplabel_secondUpr, .steplabel_thirdUpr {
    /* padding: 0px !important; */
    text-align: center !important;
}
.steplabel_fstUpr span, .steplabel_secondUpr span, .steplabel_thirdUpr span{
    /* padding: 0px !important; */
}

.winClaim {
    display: flex;
    width: 100%;
    background: #F4F1F4;
    padding: 14px 14px;
    border-radius: 12px;
    justify-content: space-between;
}

.winClaimLft {
    /* width: 70%; */
}

.winClaimInr {
    display: flex;
}

.winClaimInrLft {
    /* padding-top: 12px; */
}

.winClaimLft img {
    width: 30px;
    margin-right: 8px;
    vertical-align: sub;
    margin-top: 5px;
    /* color: #dfdfdf; */
}

.winClaimLft p {
    margin: 0px;
    color: #CFC1D5;
}

.winClaimLft label {
    margin: 0px;
    color: #6A497A;
    font-weight: 700;
}

.winClaimRght {
    text-align: right;
}
.winClaimRght {
    /* width: 30%; */
}

.winClaimRght a {
    margin-top: 6px;
    padding: 6px 20px;
    background: #ff00008c;
    color: #ffffff !important;
    border-radius: 18px;
    display: inline-block;
}


.winClaimRght_claimed a {
    margin-top: 6px;
    padding: 6px 20px;
    background: #EBE4EB;
    color: #C7BAD0 !important;
    border-radius: 18px;
    display: inline-block;
}



 
/* Track */
.extragain_tabs ul.nav.nav-tabs::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

.extragain_tabs ul.nav.nav-tabs::-webkit-scrollbar {
    width: 2px !important;
    height: 6px !important;
}
/* Handle */
.extragain_tabs ul.nav.nav-tabs::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #dfdfdf; 
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}
.extragain_tabs ul.nav.nav-tabs::-webkit-scrollbar-thumb:window-inactive {
    background: #dfdfdf;
}
.nav_dot {
    bottom: -4px !important;
}

@media screen and (max-width: 767px){
    /* hide scrollbar */
    /* Hide scrollbar for Chrome, Safari and Opera */
    .extragain_tabs ul.nav.nav-tabs::-webkit-scrollbar {  display: none; } */
    /* Hide scrollbar for IE and Edge */
    .extragain_tabs ul.nav.nav-tabs { -ms-overflow-style: none; }
    .extragain_tabs ul.nav.nav-tabs::-webkit-scrollbar { width: 0 !important }
    .extragain_tabs ul.nav.nav-tabs { overflow: -moz-scrollbars-none; }
    .extragain_tabs ul.nav.nav-tabs { -ms-overflow-style: none; } 
}


/* new page css */
.rspmodel{
    height: 100%;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}
.rspmodel .modal-content{
    position: absolute;
    bottom: 0px;
}
.rsp_rght label{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: #4E4E4E;
    display: block;
    margin-bottom: 0px;
    min-height: 32px;
    font-family: 'OmnesSemiBold';
}
.rsp_rght p{
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    opacity: 0.5;
    color: #4E4E4E !important;
    margin-bottom: 0px;
    font-family: 'OmnesRegular';
}
.rsp_content{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 20px;
    background-color: #ffffff;
    border-radius: 18px;
    margin-bottom: 22px;
    box-shadow: 0px 1px 4px 0px #00000040;
    background: linear-gradient(180deg, #FFFFFF 57.5%, #F2F2F2 100%) !important;
}
.rsp_left{
    width: 33%;
}
.rsp_rght{
    width: 67%;
    padding-left: 16px;
}
.rsp_left img{
    /* width: 92%; */
    width: 100px;
    height: auto;
}
.rsp_left img{
    border-radius: 50px;
}
.rspBody{
    font-family: 'Montserrat', sans-serif;
    min-height: 160px;
    background: #FFFFFF;
    border-radius: 23px 23px 0px 0px;
    padding: 30px 0px 50px;
}
@media (min-width: 576px){
.modal-dialog.rspmodel {
    max-width: 372px !important;
    margin: 1.75rem auto;
  }
}
.wantMore button{
    color: #f1586a;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    padding: 12px 34px;
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    width: 100%;
    background: #ffffff;
}
.wantMore span{
    text-transform: initial !important;
    color: #9c5463;
}
.wantMore{
    margin: 22px 0px;
}
.rsp_notify{
    padding: 25px 14px;
    background-color: #ffffff;
    border-radius: 18px;
    text-align: center;
}
.rsp_notify p{
    font-size: 15px;
    line-height: initial;
    margin: 0px;
    color: #f1586a;
    font-family: 'OmnesRegular';
}
.rsp_notify button{
    margin-top: 14px;
    background: #fee8ea !important;
    color: #f1586a;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    padding: 10px 5px;
    border-radius: 11px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    text-transform: capitalize;
    width: 135px;
}
.rsp_modelCntnt {
    overflow-x: hidden;
    max-height: 442px;
    overflow-y: auto;
    padding: 4px 18px 15px;
}
.closerspMdl {
    position: absolute;
    right: 0;
    top: 0;
}
.findMoreBtn button {
    background: #F47C6A !important;
    color: #FFF;
    outline: none !important;
    padding: 15px 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    text-transform: capitalize;
    width: 80%;
    border-radius: 30px;
    min-height: 52px;
    font-family: 'OmnesMedium';
}
.findMoreBtn {
    text-align: center;
}

/* Add two arrows */
@media screen and (max-width: 767px){
    .twoArrows {
        width: 100% !important;
    }
}

.twoArrows {
    z-index: 1;
    bottom: 114px;
    position: fixed;
    margin: 0 auto;
    width: 374px;
    left: 0;
    right: 0;
    display: flex;
    margin-top: 0px;
    justify-content: space-between;
    align-items: center;
}
.arrow_left, .arrow_rght {
    /* width: 10%; */
}
.twoArrows img {
    width: 7px;
    vertical-align: initial;
}
.arrow_left {
    text-align: left;
}
.arrow_rght {
    text-align: right;
}
.arrow_rght img {
    transform: rotate(180deg);
}
.arrow_left a {
    /* margin-left: -30px; */
    /* background: #ffffff; */
    display: inline-block;
    padding: 10px 11px 8px 8px;
    border-radius: 0px 32px 32px 0px;
}
.arrow_rght a {
    /* margin-right: -30px; */
    /* background: #ffffff; */
    display: inline-block;
    padding: 10px 8px 10px 11px;
    border-radius: 32px 0px 0px 32px;
}
.declaration_frrebiesArow {
    margin-top: 0px !important;
}

/* .instant_upr2 .arrow_rght a {
    margin-right: -50px;
}

.instant_upr2 .arrow_left a {
    margin-left: -50px;
} */
.program_detail_logo a{
    height: 60px !important;
    width: 60px !important;
}
.program_detail_logo img{
    width: 60px !important;
    padding: 8px 8px !important;
    -webkit-box-shadow: -5px 3px 6px 2px rgba(68,68,68,.2);
    box-shadow: -5px 3px 6px 2px rgba(68,68,68,.2);
    border-radius: 22px;
}
/* .program-page-container .program_detail_logo a {
    box-shadow: 1px 4px 13px -3px rgba(0, 0, 0, 0.28);
} */
.fixFotrBtn a {
    display: inline-block;
    padding: 16px 17px;
    color: #ffffff !important;
    width: 278px;
    background: linear-gradient(158.95deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    font-family: 'OmnesSemiBold';
}
.fixFotrBtn {
    position: fixed;
    bottom: 52px;
    right: 0;
    left: 0;
    z-index: 999;
    width: 278px;
    cursor: pointer;
    /* -webkit-backdrop-filter: blur(5px); */
    margin: 0 auto;
    text-align: center;
}
.lend_hendBG {
    background: #faeeed;
    border-radius: 20px;
    margin-bottom: 25px;
}
@media screen and (min-width: 768px) {
    .arrow_left, .arrow_rght {
       /* position: absolute !important */
       width: 371px !important;
    }
}
.arrow_left {
    position: fixed !important;
    bottom: 80px;
    right: 0;
    left: 0;
    z-index: 10;
    /* width: 371px !important; */
    cursor: pointer !important;
    margin: 0 auto !important;
    text-align: left !important;
 }
 .arrow_left img {
    width: 7px;
    vertical-align: initial;
 }
 .arrow_rght {
    position: fixed !important;
    bottom: 80px;
    right: 0;
    left: 0;
    z-index: 10;
    /* width: 371px !important; */
    cursor: pointer !important;
    margin: 0 auto !important;
    text-align: right !important;
 }
 .arrow_rght img {
    width: 7px;
    vertical-align: initial;
 }
 /* new design css */
.myOptionBtn button {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #92494b;
    border: 2px solid #9b6b6b21;
    box-sizing: border-box;
    border-radius: 22px;
    font-family: 'OmnesSemiBold';
    text-transform: capitalize;
    outline: none !important;
    padding: 11px 0px;
    margin-right: 0px;
    min-width: 100px;
    margin: 0px 2px 10px;
}
.myOptionMain {
    display: flex;
    margin-top: 25px;
    justify-content: center;
    align-items: center;
}
.myOptionBtn {
    width: 65% !important;
}
.myOptionBtn2 {
    width: 35% !important;
}
.myOptionBtn label {
    font-family: 'OmnesSemiBold';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #914949 !important;
    margin-top: 0px;
    display: block;
    margin-bottom: 10px;
    opacity: 1;
}
.myOptionBtn2 button {
    display: block;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #7e5f8d;
    border: 2px solid #cfc4d6;
    box-sizing: border-box;
    border-radius: 22px;
    font-family: 'OmnesSemiBold';
    text-transform: capitalize;
    outline: none !important;
    padding: 18px 0px;
    margin-right: 0px;
    margin-bottom: 11px;
    width: 100%;
}
.myOptionBtn2 label {
    font-family: 'OmnesSemiBold';
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: #7e5f8d !important;
    margin-top: 0px;
    display: block;
    margin-bottom: 10px;
    text-align: center;
    opacity: 1;
}
.myOptionLine {
    margin-left: 11px;
    padding-left: 11px;
    padding-right: 11px;
    border-left: 2px solid #cfc4d685;
}
.myOptionLine button:last-child {
    margin-bottom: 0px;
}

.deleteButton{
    color: #242424 !important;
    font-weight: 600 !important;
    font-style: normal !important;
    font-size: 14px !important;
    line-height: 20px !important;
    background: #fff !important;
}

.relation_active{
    background-color: #EBD7D4 !important;
}
.opt_relation_active{
    background-color: #C4B7CD !important;
}

/* @media (min-width: 412px) and (max-width: 600px) {
    .nextslides {
        bottom: -11% !important;
        right: -11% !important;
    }
} */

@media screen and (max-width: 320px) {
    .nextslides img {
        width: 72px !important;
    }
    .nextslides {
        bottom: -17px !important;
    }
}

.accept_decline{
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-top: -15px;
}

.accept_decline .notify_left{
    width: 25% !important;
}
.all_family_left_circle{
    right: 0px !important;
    bottom: -13px !important;
    position: absolute;
}

.all_family_left_circle label {
    background: #f3e8f0;
    padding: 8px 0px;
    display: inline-block;
    text-align: center;
    font-style: normal;
    font-weight: 900;
    color: #8E53A6;
    text-transform: uppercase;
    margin-bottom: 0px;
    width: 32px !important;
    border: 2px solid #ffffff;
    border-radius: 60px !important;
}

/* .all_family_left_main{

} */

.all_family_left_main label{
    background: #f3e8f0;
    padding: 20px 0px;
    display: inline-block;
    text-align: center;
    font-style: normal;
    font-weight: 900;
    font-size: 26px;
    line-height: 114.21%;
    color: #8E53A6;
    text-transform: uppercase;
    margin-bottom: 0px;
    width: 69px;
    height: 68px;
    border-radius: 20px !important;
    font-family: 'OmnesSemiBold';
}

.perks_title{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    color: #4E4E4E;
    opacity: 0.5;
    margin-bottom: 28px;
    margin-top: 20px;
    text-align: center;
    display: block;
    font-family: 'OmnesMedium';
}

.confirm_close_button {
    top: 17px !important;
    right: 3px !important;
    bottom: 30px !important;
}

.help_label {
    margin-bottom: 20px;
}

.swiper-slide-next .gain_extra {
    display: none;
}
.swiper-slide-prev .gain_extra {
    display: none;
}

.swiper-slide-next .gain_extra {
    display: none;
}
.swiper-slide-prev .gain_extra {
    display: none;
}

.swiper-slide-next .slides .nextslides {
    display: none;
}

.swiper-slide-prev .slides .nextslides {
    display: none;
}

.swiper-slide{
    padding-bottom: 4%;
}

.swiper-pagination-bullet-active {
    background: #904948;
}

/* New design changes */
.addOneChild p {
    font-weight: 400;
    display: block;
    margin: 0px;
    color: #242424 !important;
    opacity: 0.5 !important;
    font-size: 14px;
    line-height: 17px;
    font-family: 'OmnesRegular';
}
.addOneChild {
    text-align: center;
}
.unchild_infoBg {
    background-color: #f9fafb;
    border: none !important;
    justify-content: center;
    align-items: center;
}
.unchild_infoBg label {
    padding-top: 0px !important;
}
/* .unchild_infoBg p {
    font-family: 'Muli', sans-serif !important;
    opacity: initial !important;
    font-weight: 500 !important;
    font-size: 13px;
    color: #bcb1b4 !important;
    line-height: 10px;
} */

.childdel_btnCncl {
    background: transparent !important;
    padding: 0px !important;
}
.childdel_btnCncl span{
    color: #4E4E4E !important;
    font-family: 'OmnesSemiBold';
    font-weight: 600;
    font-size: 14px !important;
    line-height: 20px;
    opacity: 0.5;
}
.unknwnchild_contentNw {
    padding: 28px 20px 23px 20px !important;
}

.unAdd_info {
    border: none;
    opacity: 0.4;
    background: #ffffffad;
    margin-bottom: 16px;
    display: flex;
    padding: 11px 16px;
    width: 80%;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    min-height: 80px;
}
.unAdd_left {
    width: 20%;
    text-align: center;
}
.unAdd_left img {
    width: 35px;
}
.unAdd_rght {
    width: 80%;
    position: relative;
    padding-left: 18px;
}
.unAdd_rght label {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 0px;
    line-height: 20px;
    color: #242424;
    font-family: 'OmnesSemiBold';
}
.unAdd_rght p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 138.05%;
    -ms-flex-align: center;
    align-items: center;
    letter-spacing: 0.01em;
    color: #242424;
    opacity: 0.5;
    margin: 0px;
}
.unAdd_infoUpr {
    display: flex;
    justify-content: center;
    align-items: center;
}
.unAdd_infoActv {
    background: #ffffff;
    opacity:1;
}
.unAdd_infoLft {
    margin-right: 4px;
}
.unAdd_infoRght {
    margin-left: 4px;
}
.unAdd_infoRght img {
    width: 30px !important;
}
.unAdd_infoActv {
    border: 1px solid rgba(0, 0, 0, 0.1) !important
}

/* ---------- */

.rspNot_content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 20px;
    border-radius: 18px;
    margin-bottom: 22px;
    box-shadow: 0px 1px 4px 0px #00000040 !important;
    background: linear-gradient(180deg, #FFFFFF 57.5%, #F2F2F2 100%) !important;
}
.rspNot_left {
    height: 100px;
    width: 100px;
    background: #fff;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.rspNot_left img {
    border-radius: 18px;
    width: 70px;
    height: 70px;
}
.rspNot_rght {
    width: 75%;
    padding-left: 14px;
}
.rspNot_rght label {
    font-weight: 600;
    font-style: normal;
    font-size: 18px;
    line-height: 20px;
    color: #4E4E4E;
    display: block;
    margin-bottom: 1px;
    font-family: 'OmnesSemiBold';
}
.rspNot_rght p {
    font-weight: 600;
    font-style: normal;
    font-size: 18px;
    line-height: 20px;
    color: #4E4E4E;
    display: block;
    margin-bottom: 1px;
    font-family: 'OmnesSemiBold';
    opacity: 1;
}
.rspNot_rght button {
    border: 1px solid rgb(0, 0, 0, 0.1);
    color: rgb(78, 78, 78, 0.8);
    width: 100%;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    background: #fff !important;
    min-height: 52px;
    border-radius: 30px;
    text-transform: capitalize;
    margin-top: 5px;
    font-family: 'OmnesSemiBold';
}

/* --------- */

.rspCncl_content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 16px 20px;
    background-color: #ffffff;
    border-radius: 18px;
    margin-bottom: 22px;
}
.rspCncl_left {
    width: 25%;
}
.rspCncl_left img {
    border-radius: 18px;
    width: 70px;
    height: 70px;
}
.rspCncl_rght {
    width: 75%;
    padding-left: 14px;
}
.rspCncl_rght label {
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 16px;
    /* letter-spacing: -0.01em; */
    color: #c6b6b9;
    display: block;
    margin-bottom: 1px;
    font-family: 'OmnesRegular';
}
.rspCncl_rght p {
    font-style: normal;
    font-size: 14px;
    line-height: 14px;
    /* letter-spacing: -0.01em; */
    color: #90757a;
    margin-bottom: 1px;
    font-family: 'OmnesRegular';
}
.rspCncl_rght button {
    margin-top: 5px;
    background: #f6f7f9 !important;
    color: #bfbabe;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    padding: 8px 22px;
    border-radius: 21px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: initial;
    text-transform: capitalize;
}
.rspCncl_left {
    position: relative;
}
img.rspCnclImg {
    position: absolute;
    bottom: -7px;
    right: -8px;
    width: 40px;
    height: 40px;
}

/* Add child upadte popup */
.ntcBody {
    font-family: 'Montserrat', sans-serif;
    min-height: 538px;
    position: "relative"
}
.ntvcontent {
    font-family: 'Montserrat', sans-serif;
    position: absolute;
    width: 100%;
    background: #FFFFFF;
    border-radius: 20px;
    margin: 0 auto;
    min-height: 150px;
    padding: 35px 28px 30px 28px !important;
    top: 26%;
    left: 0;
}
img.ntcimgClose {
    width: 12px;
    height: auto;
    position: absolute;
    cursor: pointer;
    top: 18px !important;
    right: 18px !important;
    bottom: 30px !important;
    max-width: 32px;
}
.ntc_head h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 27px;
    text-align: center;
    color: #4E4E4E;
    margin-bottom: 12px;
    font-family: 'OmnesSemiBold';
}
.ntc_head p {
    display: block;
    font-family: 'OmnesRegular';
    /* font-weight: 400;
    margin: 0px;
    color: #242424;
    font-family: 'OmnesRegular';
    opacity: 0.5;
    font-size: 14px;
    line-height: 17px; */
}
.ntc_headInr {
    text-align: center;
}
.ntcUpdate button {
    color: #FFFFFF;
    outline: none !important;
    margin-top: 0px;
    padding: 16px 34px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
    margin-top: 10px;
    background: #F47C6A !important;
    border-radius: 30px !important;
    font-family: 'OmnesSemiBold';
}
.ntcUpdate button:after {
    position: absolute;
    width: 88%;
    height: 7px;
    left: 0px;
    right: 0px;
    bottom: -2px;
    background: linear-gradient(117.02deg, #FF5A6C -28.17%, #F27381 366.38%);
    -webkit-filter: blur(7px);
    filter: blur(7px);
    -webkit-transform: matrix(1, 0, 0, -1, 0, 0);
    transform: matrix(1, 0, 0, -1, 0, 0);
    content: "";
    margin: 0 auto;
}

.ntcUpdateMultiple {
    margin-top: 12px;
}

.ntcUpdateMultiple button {
    margin-bottom: 6px;
    min-height: 52px;
    color: #ffffff;
    outline: none !important;
    margin-top: 0px;
    padding: 12px 34px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
    background: #F47C6A !important;
    border-radius: 30px !important;
    font-family: 'OmnesSemiBold';
}
.ntcCnfrm button {
    background: #FFFFFF !important;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    margin-top: 0px;
    padding: 15px 34px;
    border-radius: 30px;
    text-transform: capitalize;
    width: 100%;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    color: #4E4E4E;
    border: 1px solid #0000001A;
    box-shadow: 0px 3px 4px -2px #dfdfdf;
}
.ntcDate {
    text-align: center;
    margin-top: 28px;
}
.ntcCnfrm {
    margin-top: 20px;
}
.ntcUpdate {
    margin-top: 4px;
}
.ntcDate button {
    display: inline-block;
    color: #b2b2b2;
    font-family: 'OmnesRegular';
    outline: none !important;
    margin-top: 0px;
    padding: 16px 11px;
    border-radius: 15px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    background: #ededed !important;
    width: 100%;
}
/* End of Add child upadte popup */

.resMainSec {
    padding-top: 112px;
}
.signUpFxd {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 999;
    width: 371px;
    margin: 0 auto;
    backdrop-filter: blur(44px);
    /* background: linear-gradient(180deg, #ffffff 0, #fcf4f0 33.333%, #fcf5f1 66.333%, #fcf4f0 100%); */
    /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #ffffff8c), color-stop(33.333%, #fcf4f0fc), color-stop(66.333%, #fcf5f1), to(#fbf1ecd1)); */
}
@media screen and (max-width: 767px){
    .signUpFxd {
        width: 100%!important;
    }
}
@media (max-width: 767px) and (min-width: 568px){
    .signUpFxd {
        width: 540px!important;
    }
}
.shrScrenTabsRel{
    padding-top: 160px;
}
.shrScrenTabsRelChild{
    padding-top: 100px;
}
.shrScrenTabsAddSpc {
    padding-bottom: 80px;
}
.shrScrenTabs{
    padding: 12px 22px 0px 22px;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 999;
    width: 371px;
    margin: 0 auto;
    backdrop-filter: blur(44px);
}
/* @media screen and (max-width: 767px){
    .shrScrenTabs {
        width: 100%!important;
    }
} */

/* @media (max-width: 767px) and (min-width: 568px){
    .shrScrenTabs {
        width: 540px!important;
    }
} */

.all_relative_section2{
    height: initial !important;
    min-height: initial !important;
}
.familyaddNwOne{
    backdrop-filter: blur(5px);
    position: fixed !important;
    bottom: 55px !important;
    right: 0 !important;
    left: 0 !important;
    z-index: 999;
    width: 371px;
    margin: 0 auto;
}

/* New SignUp */
.mailAdd h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #4E4E4E;
    opacity: 0.5;
    margin-bottom: 28px;
    margin-top: 0px;
    font-family: 'OmnesMedium';
}
.mailAdd h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 42px;
    color: #904949;
    width: 100%;
    margin: auto;
    text-align: center;
    margin-bottom: 0px;
    margin-top: 30px;
}
.signup_gender h3{
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 42px;
    color: #904949;
    width: 100%;
    margin: auto;
    text-align: center;
    margin-bottom: 0px;
    margin-top: 30px;
}
.mailAdd {
    padding-top: 18px;
}
.selCntry {
    display: flex;
    margin-top: 26px;
}
.selCntryBtn {
    width: 33.333%;
    text-align: center;
}
.selCntryBtn button {
    color: #904949;
    font-family: 'OmnesSemiBold';
    outline: none!important;
    margin-top: 5px;
    padding: 18px 8px;
    background: #fbf6f3;
    border-radius: 18px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 94%;
    margin: 0 auto;
}
.selCntryActv {
    background: #ffffff !important;
}
.addPrz {
    margin-top: 21px;
}
.addPrz p {
    text-align: center;
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
    -ms-flex-align: center;
    align-items: center;
    color: #242424;
    opacity: 0.5;
}
.selHome {
    display: flex;
    margin-top: 26px;
}
.selHomeBtn {
    width: 33.333%;
    text-align: center;
}
.selHomeBtn button {
    color: #7f5e8d;
    font-family: 'OmnesSemiBold';
    outline: none!important;
    margin-top: 0px;
    padding: 20px 12px;
    background: transparent;
    border-radius: 18px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 94%;
    margin: 0 auto;
    border: 1px solid #cfc5d5;
}
.selHomeActv {
    background: #cfc5d5 !important;
}
.houseForm {
    margin-top: 35px;
}
.houseForm div {
    width: 100%;
}
.houseForm input {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #ffffff !important;
    color: #242424;
    outline: none;
    font-stretch: normal;
    letter-spacing: normal;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    padding: 15px 17px;
    border-radius: 15px;
    margin-bottom: 12px;
    font-family: 'OmnesMedium';
    z-index: 1;
}
.houseFormBtn button {
    color: #fff;
    outline: none!important;
    padding: 12px 34px;
    background: #F47C6A !important;
    border-radius: 30px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
    margin: 22px 0px 15px;
    box-shadow: 0 18px 20px -18px #FF5A6C;
    min-height: 52px;
    font-family: 'OmnesSemiBold';
}
.houseForm label {
    font-stretch: normal;
    font-family: 'OmnesRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    -ms-flex-align: center;
    align-items: center;
    color: #242424 !important;
    opacity: 0.4;
    margin-bottom: 0px;
}
.houseForm div:before {
    border-bottom: 2px solid #ecdcd9 !important;
    /* border-radius: 0px 0px 5px 5px; */
}

.hmAddon{
    display: flex;
    flex-direction: column;
}
.hmAddonLft{
    width: 66.333% !important;
}
.hmAddonRght{
    width: 33.333% !important;
}
.hmAddonRght {
    text-align: right;
    /* margin-top: 20px; */
}

.selSubMain{
    background: #fdfaf8b0;
    padding: 12px 8px;
    margin-top: 15px;
    border-radius: 20px;
}
.selSub {
    display: flex;
    flex-wrap: wrap;
}
.selSubBtn {
    width: 33.333%;
    text-align: center;
    margin: 4px 0px;
}
.selSubBtn button {
    color: #904949;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    margin-top: 0px;
    padding: 13px 6px;
    background: #fbf8f5;
    border-radius: 22px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 95%;
    margin: 0 auto;
    border: 1px solid #efdedb;
}
.selSubActv {
    background: #efdedb !important;
}
.selSubBtn button:hover {
    background: #efdedb !important;
}
.selHomeBtn button:hover {
    background: #cfc5d5 !important;
}
.buzSub {
    display: flex;
    background: rgb(255, 255, 255, 0.5);
    padding: 12px 5px;
    margin-top: -13px !important;
    margin-bottom: 20px !important;
    padding-top: 24px;
    border-radius: 0px 0px 20px 20px;
}
.buzSubBtn {
    width: 33.333%;
    text-align: center;
}
.buzSubBtn button {
    color: rgb(36, 36, 36, 0.5);
    outline: none !important;
    padding: 15px 4px;
    background: #ffffff;
    border-radius: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 95%;
    margin: 0 auto;
    font-family: 'OmnesSemiBold';
    border: 1px solid rgba(0, 0, 0, 0.1);
}
.buzSubActv {
    color: #242424 !important;
    background: #ffffff !important;
    opacity: 1;
}
.buzSubFirst {
    margin-bottom: 8px;
}
.buzSubBtn button:hover {
    color: #242424 !important;
    background: #ffffff !important;
    opacity: 1;
}
.buzHomeBtn button:hover {
    background: #cfc5d5 !important;
}
.common_mainSgn{
    padding: 12px 22px;
    /* padding-bottom: 168px; */
}
.buzSubBtn button:hover {
    color: #242424 !important;
    background: #ffffff !important;
    opacity: 1;
}
.houseFormAdd { 
    margin-top: 20px;
}
.houseForm .MuiSwitch-colorSecondary {
    color: #904949;
}
.houseForm span.MuiSwitch-track{
    background: #904949;
}
.selCntryBtn button:hover {
    background: #ffffff !important;
}


/* sub css */
/* .subForm {
    margin-top: 20px;
} */
.subForm div {
    width: 100%;
}
.subForm div:before {
    border-bottom: 2px solid #ecdcd9 !important;
}
.subForm div:after {
    border-bottom: 2px solid #9a8282 !important;
}
.subFormAdd .MuiInputAdornment-positionStart {
    width: initial !important;
}
.subForm input, .rurlForm input {
    width: 100%;
    border: none;
    border-bottom: none !important;
    outline: none;
    letter-spacing: normal;
    font-size: 18px;
    margin-bottom: 0px;
    line-height: 20px;
    color: #242424;
    font-style: normal;
    font-weight: 500;
    font-family: 'OmnesMedium';
}
.subForm .MuiInputAdornment-positionStart,
.rurlForm .MuiInputAdornment-positionStart {
    width: initial !important;
}
.subForm .MuiInputAdornment-positionStart p,
.rurlForm .MuiInputAdornment-positionStart p {
    font-stretch: normal;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    align-items: center;
    color: #242424 !important;
    opacity: 0.4;
    margin-bottom: 0px;
    font-family: 'OmnesMedium';
}
.subForm .MuiFormControl-marginNormal {
    margin-bottom: 12px;
}

/* Rural section */

.selRurlSecnd .selRurlBtn{
    width: 50% !important;
    text-align: center !important;
}
.selRurlFirst {
    margin-bottom: 8px;
}
.selRurl {
    display: flex;
}
.selRurlBtn {
    width: 33.333%;
    text-align: center;
}
.selRurlBtn button {
    color: #4E4E4E;
    outline: none !important;
    padding: 13px 6px;
    background: #ffffff;
    border-radius: 22px;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 95%;
    margin: 0 auto;
    min-height: 52px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-family: 'OmnesSemiBold';
}
/* .selRurlActv {
    background: #f47c6a1a !important;
} */
.selRurlMain {
    background: #fdfaf8b0;
    padding: 12px 5px;
    margin-top: 15px;
    border-radius: 20px;
}

/* .rurlForm {
    margin-top: 20px;
} */
.rurlForm div {
    width: 100%;
}
.rurlForm div:before {
    border-bottom: 2px solid #ecdcd9 !important;
}
.rurlForm div:after {
    border-bottom: 2px solid #9a8282 !important;
}

/* .rurlForm .MuiInputAdornment-positionStart {
    width: initial !important;
}
.rurlForm .MuiInputAdornment-positionStart p {
    font-stretch: normal;
    font-family: 'OmnesRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    -ms-flex-align: center;
    align-items: center;
    color: #242424 !important;
    opacity: 0.4;
    margin-bottom: 0px;
} */

/* .rurlForm input {
    width: 100%;
    border: none;
    border-bottom: 2px solid #f1eaea;
    outline: none;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'OmnesRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    color: #242424;
    margin-bottom: 0px;
} */

.selRurlActv:before{
    background-image: url('../images/rght2.png');
    width: 32px;
    height: 32px;
    content: '';
    position: absolute;
    left: 3px;
    background-repeat: no-repeat;
    background-size: contain;
}
.selRurlActv {
    padding-left: 35px !important;
}


.rurlAddon {
    display: flex;
    margin-bottom: 5px;
}
.rurlAddonLft {
    width: 66.333% !important;
}
.rurlAddonRght {
    text-align: right;
    width: 33.333% !important;
}
.rurlAddonLft label {
    font-stretch: normal;
    font-family: 'OmnesRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    -ms-flex-align: center;
    align-items: center;
    color: #242424 !important;
    opacity: 0.4;
    margin-bottom: 0px;
}
.rurlAddonRght .MuiSwitch-colorSecondary {
    color: #904949;
}
.rurlAddonRght span.MuiSwitch-track {
    background: #904949;
}
.rurlAddonBtn button {
    color: #ffffff;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    padding: 14px 34px;
    background: #eb6771 !important;
    border-radius: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
}
.rurlAddonMain {
    margin-top: 30px;
    margin-bottom: 10px;
}
.rurlAddonBtn {
    margin-top: 18px;
}

/* .houseForm input:focus {
    border-bottom: 2px solid #FC6172;
    color: #FC6172;
    opacity: 1;
}

.houseForm div:after {
    border-bottom: 2px solid #FC6172 !important;
    border-radius: 0px 0px 5px 5px;
} */

/* .hmAddonLft{
    font-stretch: normal;
    font-family: 'OmnesRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    align-items: center;
    color: #242424 !important;
    opacity: 0.4;
    margin-bottom: 0px;
} */

.hmAddonLft div{
    width: 100%;
}

.hmAddon{
    width: 100%;
}

.additionaldiv{
    margin-top: 15px;
}

.additionaldiv2{
    margin-top: 10px;
}

.hmAddonRghtfalse{
    text-align: right;
    margin-top: 0px;
}

.hmAddonRghtfalse{
    width: 33.333% !important;
}

.hmAddonRghtStreetAddress {
    margin-top: 0px !important;
}

.parentaddonlft{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.hmAddonRghtfalse span.MuiIconButton-label{
    color: #8e5758;
}
.hmAddonRghtfalse span.MuiSwitch-track {
    background-color: #8e5758;
}

.hmAddonLft input {
    width: 100%;
    border: none;
    border-bottom: 2px solid #f1eaea;
    outline: none;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'OmnesRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    color: #242424;
    margin-bottom: 0px;
}
.hmAddonLft label {
    font-stretch: normal;
    font-family: 'OmnesRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    -ms-flex-align: center;
    align-items: center;
    color: #242424 !important;
    opacity: 0.4;
    margin-bottom: 0px;
}
.hmAddonLft div:after {
    border-bottom: 2px solid #FC6172 !important;
}
.hmAddonLft div:before {
    border-bottom: 2px solid #ecdcd9 !important;
}

.subForm .MuiInputAdornment-positionStart,
.rurlForm .MuiInputAdornment-positionStart {
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    align-items: center;
    color: #242424 !important;
    opacity: 0.4;
    margin-bottom: 0px;
    font-family: 'OmnesRegular';
}
.subForm div:before {
    border-bottom: 2px solid #ecdcd9 !important;
}
.subForm div:after {
    border-bottom: 2px solid #FC6172 !important;
}

.rurlForm .MuiInputBase-adornedStart {
    margin-top: 20px;
}

/* ---------- */

/* .rurlForm input {
    width: 100%;
    border: none;
    border-bottom: 2px solid #f1eaea;
    outline: none;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'OmnesRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    color: #242424;
    margin-bottom: 0px; 
}
.rurlForm .MuiInputAdornment-positionStart {
    font-stretch: normal;
    font-family: 'OmnesRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    -ms-flex-align: center;
    align-items: center;
    color: #242424 !important;
    opacity: 0.4;
    margin-bottom: 0px;
} */

.rurlForm div:after {
    border-bottom: 2px solid #FC6172 !important;
}
.rurlForm div:before {
    border-bottom: 2px solid #ecdcd9 !important;
}
.hmAddonLft .MuiFormControl-marginNormal {
    margin-bottom: 0px;
}
.poBoxOptional{
    margin-bottom: 5px;
}

@media screen and (max-width: 414px) {
.selRurlActv:before {
    width: 25px !important;
    height: 25px !important;
    left: 11px !important;
}
.selRurlActv {
    padding-left: 15px !important;
    padding-right: 3px !important;
}
.selRurlBtn button {
    font-size: 12px;
 }
}

.address_child_section {
    font-size: 14px !important;
}

.address_child_section p{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #242424;
    opacity: 0.5;
    margin-top: 0px;
    font-family: 'OmnesRegular';
}

.address_parent_section {
    min-height: 25px !important;
    max-height: 250px !important;
    background: #f4f0fb;
    padding: 15px 15px;
    padding-top: 20px;
    margin-top: -22px;
    margin-bottom: 12px;
    border-radius: 0px 0px 20px 20px;
}

.enter_manually{
    width: 100%;
    text-align: center;
}

.success_message p{
    color: green;
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    font-family: 'OmnesRegular';
    opacity: 0.9;
    margin-top: 5px;
    margin-bottom: 0px;
}

.barcodesvg svg{
    width: 100% !important;
}

.child_count{
    z-index: 999 !important;
}

.winClaimRght a {
    text-align: center !important;
}

.sign_backlink{
    width: 28px;
    min-height: 28px;
}


iframe.iframe_story_media{
    border-radius: 20px;
    border: none;
}

.terms_close{
    width: 10px;
    position: absolute;
    cursor: pointer;
    top: -16px;
    right: -8px;
}

.white_circle_div img{
    /* background-color: #ffffff;  */
    /* padding: 5px 9px 5px 6px; */
    display: inline-block;
    width: 24px;
    height: 24px;
    /* border-radius: 4px; */
    /* opacity: 0.8; */
    border-radius: 20px;
  }

  .helpdiv a{
    background-color: #ffffff;
    padding: 5px 9px 5px 6px;
    display: inline-block;
    width: 52px;
    border-radius: 4px;
    opacity: 0.8;
    border-radius: 20px;
  }

  .helpdiv{
      display: none;
  }

  .lightbox{
    height: 80%;
    width: 80%;
    display: none;
    position: fixed;
    top: 6%;
    left: 10%;
    background: #fff;
    z-index: 1;
    overflow: auto;
  }

  .redeem_social_share{
    border-radius: 0px 0px 20px 20px;
    background: #faeeed;
  }

  .freebie_redeeemed{
      background: rgb(251,245,245) !important;
  }

  .discover_icon_div{
    border-radius: 20px;
    position: relative;
    width: 51px;
    height: 51px;
    background: #ee5d6f;
  }

  .discover_icon_div img{
    position: absolute;
    width: 28px !important;
    border-radius: 0px !important;
    top: 22%;
    right: 22%;
  }

  .discover_icon_div .freebie{
    top: 22% !important;
    right: 22% !important;
  }

  .perks_tag{
    padding: 10px 14px;
    background: #ee5c6e;
    display: initial;
    border-radius: 14px;
    color: white;
    font-size: 15px;
    margin-right: 12px;
  }

  .perks_tag img{
    width: 20px;
    margin-right: 6px;
    margin-bottom: 4px;
  }

/* Site Sidebar css */

/* body {
    background-image: url("../images/siteBackground.jpg");
    background-size: contain;
    background-repeat: repeat;
    background-attachment: fixed;
} */

  .siteSidebarIner{
    /* background-image: url("../images/deskMob.png"); */
    /* background-image: url("../images/1234.jpg"); */
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0 auto;
    display: table;
    border-radius: 0px 0px 20px 20px;
}
.siteSidebarIner iframe {
    padding-bottom: 5px;
}
.AddsiteSidebar{
    position: relative;  
}
  .siteSidebar {
    background-image: url("../images/desktopBg2.png");
    top: 24%;
    background-repeat: no-repeat;
    padding: 5px 64px 5px 25px;
    background-size: contain;
    height: 365px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    width: 251px;
    position: fixed;
    bottom: 83px;
    right: 0px;
    left: 622px;
    cursor: pointer;
    margin: 0 auto;
   }
 
   .siteSidebar h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 25px;
    margin-bottom: 0px;
    font-family: 'OmnesRegular';
}
.siteSidebar h1 strong {
    color: #806695;
    font-family: 'OmnesSemiBold';
}
.siteSidebar h1 b {
    color: #090806;
    font-family: 'OmnesSemiBold';
}
.siteSidebar p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    margin-top: 10px;
    margin-bottom: 12px;
    font-family: 'OmnesRegular';
}
.siteSidebar p b {
    color: #806695;
    font-weight: 800;
    font-family: 'OmnesSemiBold';
}
.siteSidebar img {
    width: 66px;
}
.onwebShow{
    display: block;
}
@media (max-width: 759px){
    .siteSidebar {
        display: none !important;
    }
}
@media (max-width: 576px){
    /* body {
        background-image: none !important;
        background-attachment: initial !important;
    } */
    .onwebShow {
        display: block;
    }
    .videoSection, .videoPreVewUpr {
        padding: 0px;
    }
}
@media (min-width: 577px){
    .onMobileShow{
        display: none;  
    }
}

/* End of Site Sidebar */

.faq_label{
    display: none !important;
}

.custom_faqs_main{
    display: none !important;
}

.story_media{
    /* display:none; */
}

.play_button{
    position: absolute;
    top: 42%;
    bottom: 0;
    right: 0;
    left: 44%;
    width: 14% !important;
    height: 20% !important;
}

.acCelebfie img {
    width: 100%;
}
.acCelebfie {
    margin: 72px 0px 25px;
}
.actvAC h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 27px;
    text-align: center;
    letter-spacing: -.02em;
    color: #4E4E4E;
    margin-bottom: 0px;
    font-family: 'OmnesSemiBold';
}
.actvAC p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #242424;
    opacity: 0.5;
    margin-bottom: 1px;
    font-family: 'OmnesRegular';
}
.actvACpara {
    margin: 18px 0px 38px;
}
.activSent img {
    width: 86px;
}
.activSent {
    text-align: center;
    margin-bottom: 25px;
}
.noteTextAC p {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 13px;
    text-align: center;
    color: #242424;
    opacity: 0.5;
    margin-bottom: 0px;
    font-family: 'OmnesRegular';
}
.noteTextAC {
    margin-top: 28px;
    margin-bottom: 0px;
    padding: 0px 12px;
}
/* this css is for fixed Button */
.hometwo_redem {
    position: fixed;
    bottom: 83px;
    right: 0;
    left: 0;
    z-index: 10;
    width: 300px;
    cursor: pointer;
    margin: 0 auto;
    text-align: center;
}

.hometwo_redem_session{
    position: fixed;
    bottom: 80px;
    right: 0;
    left: 0;
    z-index: 10;
    width: 300px;
    cursor: pointer;
    margin: 0 auto;
    text-align: center; 
}

.hometwo_redem_session_safari{
    position: fixed;
    bottom: 175px;
    right: 0;
    left: 0;
    z-index: 10;
    width: 300px;
    cursor: pointer;
    margin: 0 auto;
    text-align: center; 
}

.freebie_redeem_fixed{
    position: fixed;
    bottom: 80px;
    right: 0;
    left: 0;
    z-index: 11;
    width: 335px;
    cursor: pointer;
    margin: 0 auto;
    text-align: center;
}

.contest_enter_fixed{
    position: fixed !important;
    bottom: 80px;
    right: 0;
    left: 0;
    z-index: 11;
    width: 292px !important;
    cursor: pointer !important;
    margin: 0 auto !important;
    text-align: center !important;
}
/*End of this css is for fixed Button */

.welLogin label {
    align-items: center;
    margin: 0px;
    margin-bottom: 22px;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 114.21%;
    text-align: center;
    text-transform: capitalize;
    color: #FFDD3F;
    font-family: 'OmnesSemiBold';
}
.membrsProv h2 {
    align-items: center;
    margin: 0px;
    margin-top: 18px;
    margin-bottom: 15px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #4E4E4E;
    font-family: 'OmnesSemiBold';
}
.membrsProv p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: #4E4E4E;
    padding: 0px 14%;
    line-height: 16px;
    align-items: center;
    margin: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: 'OmnesRegular';
}

.migrate_note {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 17px;
    align-items: center;
    text-align: center;
    color: #242424;
    margin: 0px;
    font-family: 'OmnesRegular';
    margin-top: 10px;
}

.loader_div{
    margin-top:50%
}

.view_detail{
    display: flex;
    justify-content: space-evenly;
}
.view_detail div{
    width: 80%;
}

.view_detail button{
    width: 100% !important;
    padding: 11px 17px !important;
    font-size: 17px !important;
}

.next_offer{
    color: #fff !important;
    background: #ee5c6e !important;
    width: 73% !important;
    margin-bottom: 9% !important;
}

.facebook{
    vertical-align: middle!important;
    border-style: none!important;
    color: #fff!important;
    font-size: 14px;
    border-radius: 15px !important;
    text-align: center !important;
    outline: none !important;
    width: 100% !important;
    height: 52px;
    font-family: 'OmnesRegular';
    /* padding: 12px 34px !important; */
}

.loginFB{
    margin-bottom: 16px;
    text-align: center;
}

.loginFB button{
    outline: none !important;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    align-items: center;    
    background: linear-gradient(90deg, #66B0C4 7.5%, #4267B2 77%, #4267B2 100%);
    border-radius: 30px !important;
    font-family: 'OmnesSemiBold';
}

/* .membrsProv{
    margin-bottom: 35px
} */

.add_child_continue button{
    background: #F47C6A !important;
    color: #ffffff !important;
    border-radius: 30px;
}

.add_child_next p{
    font-weight: 700;
    opacity: 1;
    vertical-align: middle;
    font-size: 18px;
    margin-top: 23px;
}

.add_child_left label{
    font-size: 45px !important;
    font-weight: 200;
    color: #ffffff;
    background: #ee5e70;
    padding: 2px 0px !important;
    margin-top: 0px;
}


.want_more_div{
    /* display: flex;
    justify-content: space-between; */
    margin-top: 9%;
}

.explore_div{
    width: 70% !important;
    padding: 12px 34px;
    background: #ffffff !important;
    color: #4E4E4E !important;
    margin: 11px 0px !important;
    border-radius: 30px !important;
    margin-top: 0px !important;
    box-shadow: none !important;
}
.next_offer_div{
    /* width: 308px !important; */
    margin: 11px 0px !important;
}
.cstmChildPlus {
    font-style: normal;
    font-size: 35px;
    color: #ffffff;
    padding: 4px 7px 4px;
    border-radius: 60px;
    text-align: center;
    width: 60px;
    height: 60px;
    background: #AE86E0;
    margin-left: 0px;
    cursor: pointer;
    font-family: 'OmnesMedium';
    display: flex;
    align-items: center;
    justify-content: center;
}
a.cstmChild {
    display: flex;
    align-items: center;
    justify-content: center;
}
.cstmChildTxt {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    color: #242424;
    margin-bottom: 0px;
    padding-left: 12px;
}

@media (max-width: 767px) and (min-width: 568px){
    .arrow_left, .arrow_rght {
        width: 540px !important;
    }
}


.three_hearts_div{
    display: flex;
    justify-content: center;
}

.three_hearts_div img{
margin: 0 3px;
height: 18px;
/* width: 20px; */
}
.likeDisMain {
background: #fcf7f4;
border-radius: 20px 20px 12px 12px;
}
.likeDis {
display: flex;
padding: 12px 12px;
justify-content: center;
align-items: center;
}
.likeDisFrst {
width: 30%;
}
.likeDisMid{
width: 60%;
}
.likeDisLst {
    width: 10%;
}
.likeDisFrst span {
background: #ffffff;
width: 30px;
height: 30px;
display: inline-block;
text-align: center;
border-radius: 16px;
padding: 2px 0px;
color: #7C5E8C;
font-family: 'OmnesSemiBold';
font-weight: 700;
}
.likeDisFrst img {
margin-right: 10px;
width: 20px;
}
.likeDisMid label {
font-weight: bold;
font-size: 16px;
color: #242424;
font-family: 'OmnesSemiBold';
margin-top: 0px;
margin-bottom: 0px;
}
.likeDisLst {
text-align: right;
}
.likeDisLst a {
font-weight: bold;
font-size: 24px;
color: #242424;
font-family: 'OmnesSemiBold';
margin-top: 0px;
margin-bottom: 0px;
cursor: pointer;
}

.likeDisMain2{
background: #fcf4ed;
border-radius: 20px 20px 12px 12px;
}
.likeDisMain3{
background: #fbf1e9;
border-radius: 20px 20px 12px 12px;
}
.likeDisLstAdd a {
    background: #f15e71;
    color: #ffffff !important;
    /* display: inline-block; */
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    font-size: 23px;
    padding: 0px 10px;
    cursor: pointer;
    vertical-align: middle;
    width: 38px;
    height: 38px;
}
.fix_main.fixed-header ul.nav.nav-tabs {
    margin-bottom: 30px !important;
}
.sharegain_swiper img {
    -webkit-box-shadow: -11px 12px 15px 2px #dcdcdc;
    -moz-box-shadow: -11px 12px 15px 2px #dcdcdc;
    box-shadow: -11px 12px 15px 2px #dcdcdc;
    border-radius: 50%;
}
.sessionCntntDtails .sessionAddOns{
    -webkit-box-shadow: 0px 2px 7px 2px rgba(166,124,124,0.44);
    -moz-box-shadow: 0px 2px 7px 2px rgba(166,124,124,0.44);
    box-shadow: 0px 2px 7px 2px rgba(166,124,124,0.44);
}
.fixFotrBtn a {
    -webkit-box-shadow: 0px 2px 7px 2px rgba(166,124,124,0.44);
    -moz-box-shadow: 0px 2px 7px 2px rgba(166,124,124,0.44);
    box-shadow: 0px 2px 7px 2px rgba(166,124,124,0.44);
}
.discover_icon_div img {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.freebiesImges {
    max-height: none !important;
    border-radius:0px;
    /* -webkit-box-shadow: 0px 2px 7px 2px rgba(166,124,124,0.44);
    -moz-box-shadow: 0px 2px 7px 2px rgba(166,124,124,0.44);
    box-shadow: 0px 2px 7px 2px rgba(166,124,124,0.44); */
}
.contests_dtlImges {
    -webkit-box-shadow: 0px 2px 7px 2px rgba(166,124,124,0.44);
    -moz-box-shadow: 0px 2px 7px 2px rgba(166,124,124,0.44);
    box-shadow: 0px 2px 7px 2px rgba(166,124,124,0.44);
}
.loginFB button{
    box-shadow: 0px 4px 11px 0px #00000024;
}
.signup_opt button{
    box-shadow: 0 18px 20px -18px #FF5A6C;
}
/* .hometwo_redem button,.hometwo_redem_session button,.hometwo_redem_session_safari button, 
.signup_submit button, .call_link button, .houseFormBtn button, 
.prent_submit .MuiButton-text.MuiButton-textPrimary, .addchild_submit button, 
.add_child_continue button, .contest_opt button, .contest_opt_policy_advisor button, 
.load_more button, .gainextra_submit button{
    -webkit-box-shadow: 0px 2px 7px 2px rgba(166,124,124,0.44);
    -moz-box-shadow: 0px 2px 7px 2px rgba(166,124,124,0.44);
    box-shadow: 0px 2px 7px 2px rgba(166,124,124,0.44);
} */
.hometwo_redem button,.hometwo_redem_session button,
.hometwo_redem_session_safari button, .add_child_continue button, .contest_opt button, 
.contest_opt_policy_advisor button, .load_more button, 
.gainextra_submit button{
    /* -webkit-box-shadow: 0px 2px 7px 2px rgba(166,124,124,0.44);
    -moz-box-shadow: 0px 2px 7px 2px rgba(166,124,124,0.44);
    box-shadow: 0px 2px 7px 2px rgba(166,124,124,0.44); */
    box-shadow: 0 18px 20px -18px #FF5A6C;
}
.extra_fremain, .view_detail button {
    /* -webkit-box-shadow: 0px 2px 7px 2px rgba(166,124,124,0.44);
    -moz-box-shadow: 0px 2px 7px 2px rgba(166,124,124,0.44);
    box-shadow: 0px 2px 7px 2px rgba(166,124,124,0.44); */
    box-shadow: 0 18px 20px -18px #FF5A6C;
}

/* .opendealInner .closeModel {
    -webkit-box-shadow: 0px 2px 7px 2px rgba(166,124,124,0.44);
    -moz-box-shadow: 0px 2px 7px 2px rgba(166,124,124,0.44);
    box-shadow: 0px 2px 7px 2px rgba(166,124,124,0.44);
} */

/* Close my account CSS */
.close-account h5 {
    font-weight: 600;
}


.settings_menu{
    background-color: #FAF4F0;
    padding: 7px 9px;
    border-radius: 7px;
}

.notice_warning1 p{
    color: #4E4E4E;
    font-weight: 600;
    display: block;
    margin: 0px;
    opacity: initial !important;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 12px;
    font-family: 'OmnesSemiBold';
}

.notice_warning1 {
    text-align: center;
}

.notice_warning_text{
    border: 0px;
    border-bottom: solid 1px;
}

.margin0 {
    margin: 0!important;
}

.discover_image{
    width: 80%;
    padding-top: 0px;
}

.mb-25{
    margin-bottom: 20px;
}

.phone_number_label{
    font-size: 1.3em;
    color: #9a6867;
    opacity: 0.6;
}

.white_overlay{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: 0.3;
}

.activeLine1.show:after {
    visibility: initial;
}
.activeLine2.show:after {
    visibility: initial;
}

.activeLine_smoking.show:after {
    visibility: initial;
}

.disable_link{
    opacity: 0.5;
    pointer-events: none;
}

.receive_samples{
    color: #FF5A6C !important;
    background: #ffffff !important;
    /* margin-bottom: 20px !important; */
    box-shadow: 0px 3px 13px rgb(214 199 199) !important;
    font-size: 15px !important;
}


.declaration_short a{
    font-style: unset;
    font-weight: 550;
    font-size: 11px;
    line-height: 120%;
    text-align: center;
    color: #242424 !important;
}

.declaration_short_nestle a{
    font-style: unset;
    font-weight: 550;
    font-size: 11.5px;
    line-height: 120%;
    text-align: center;
    color: #242424 !important;
}

.declaration_short_nestle p span{
    font-size: 11.5px !important;
}

/* .terms_content a {
    color: #242424 !important;
    font-weight: 550;
    text-decoration: underline;
} */

.survey_sec button:last-child{
    margin-right: 0px !important;
}
.survey_sec button{
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #ffffff !important;
    color: #242424;
    outline: none;
    font-stretch: normal;
    letter-spacing: normal;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    padding: 14px 17px;
    border-radius: 30px;
    font-family: 'OmnesMedium';
    text-align: center;
    text-transform: capitalize;
    margin-right: 6px;
    width: 47%;
    max-height: 52px;
    display: inline-block;
}

.survey_sec {
    width: 100%;
    text-align: left;
    padding-left: 10px;
}
.survey_sec label {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    font-family: 'OmnesSemiBold';
    color: #a6a6a6;
    margin-top: 0px;
    margin-bottom: 6px;
    padding-left: 4px;
    line-height: 20px;
}

.setposition {
    position: relative;
}
.setposition h1 {
    text-align: left;
    padding-left: 34px;
}

.setposition h1 {
    font-size: 20px
}
.completeEntry {
    position: relative;
}
.completeEntry a img {
    position: absolute;
    top: -4px;
    left: 0px;
    width: 30px;
}
.completeEntry h1 {
    padding-left: 34px;
    text-align: left !important;
    color: #9049499e;
}

.survey_active_button{
    color: #AE86E0 !important;
    /* background: #66BD99 !important; */
}

.survey_dropdown select{
    /* width: 80%;
    background: transparent;
    border: none;
    border-bottom: 1px solid #924c4c;
    color: #986666; */
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #ffffff !important;
    color: #242424;
    outline: none;
    font-stretch: normal;
    letter-spacing: normal;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    padding: 13px 17px;
    border-radius: 15px;
    min-height: 52px;
    font-family: 'OmnesMedium';
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("../images/selectDown.svg");
    background-repeat: no-repeat;
    background-position: calc(100% - 15px) center;
    padding-right: 20px;
}
.survey_textfield{
    width: 80%;
    border: none;
    border-bottom: 1px solid #924c4c;
    color: #986666;
}
.survey_dropdown select:focus-visible {
    outline: none;
}

.sessionEntrContainer .survey_section{
    /* background-image: url(https://app2.family.one/images/survey-background.png);
    margin-left: -24px;
    margin-right: -24px;
    padding: 22px 22px !important; */
    /* z-index: 999;*/
    margin-bottom: 38px;
}
.survey_sub_section {
    z-index: 999;
    background: #f9eae2 !important;
    margin-bottom: 0px;
} 
.meter {
    height: 32px;
    position: relative;
    margin: 60px 0 20px 0;
    background: #f29baf;
}
.amount {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    transition: all 0.8s;
    /* background: #f4b0b5; */
    width: 0;
    border-radius: 0px 18px 18px 0px;
}
.progress_test:before {
    /* content: attr(data-amount)"% Complete"; */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    text-align: center;
    line-height: 31px;
}
/* .loader_button {
    display: block;
    height: 100%;
    position: relative;
    overflow: hidden;
    background-image: linear-gradient( 64deg, #ed3b44 80%, #f29baf00 0%);
}

.loader_test{
    display: inline-block;
    background-color: cyan;
    overflow: hidden;
    height: 18px;
    width: 80px;
    transition: width 1.5s;
}

.loader_btn {
    background-image: linear-gradient( 64deg , rgb(237, 59, 68) 0%, rgb(242, 155, 175) 0%);
    -webkit-transition: width 1s;
     transition: max-width 1s, min-width 1s;
     width: auto;
}

.loader_btn-primary {
    max-width: 330px;
    min-width: 230px;
}
  
.loader_btn-secondary {
    max-width: 60px;
    min-width: 40px;
    text-align: left;
} */
.progress_test {
    color: #ffffff;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    background: #F47C6A !important;
    border-radius: 30px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
    height: 52px;
    margin-top: 22px;
    padding: 10px 10px;
    box-shadow: 0 18px 20px -18px #FF5A6C;
    position: relative;
}
.progress_test.done {
    color: #fff;
    outline: none !important;
    padding: 12px 34px;
    background: linear-gradient( 
158.95deg
 ,#ff5a6c -28.17%,#f27381 366.38%);
    border-radius: 30px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
    box-shadow: 0 3px 13px rgb(252 97 114 / 65%);
    margin: 22px 0;
}
.progress_test span {
    position: absolute;
    left: 0px;
    right: 0px;
    text-align: center;
    top: 0px;
    bottom: 0px;
    z-index: 1;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
button.parent-skip{
    background: transparent;
    margin-top: 4%;
    margin-bottom: 3%;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #8f8f8e;
}
.select_rel {
    margin-top: 16px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.select_rel button{
    text-transform: capitalize;
    display: flex;
    color: #4E4E4E;
    font-size: 15px;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    padding: 11px 18px;
    width: 130px;
    border-radius: 50px;
    line-height: 17px;
    border: 1px solid #e9e9e9 !important;
    font-family: 'OmnesSemiBold';
    margin: 6px 7px;
    min-height: 80px;
}

.select_rel .button1{
    background-color: #F27381;
}

.select_rel .button1:hover{
    background-color: #ed4463;
}

.select_rel .button2{
    background-color: #FF9B80;
}

.select_rel .button2:hover{
    background-color: #ff8362;
}

.select_rel .button3{
    background-color: #F2BFB2;
}

.select_rel .button3:hover{
    background-color: #f39b7b;
}

.select_rel .button4{
    background-color: #F6C661;
}

.select_rel .button4:hover{
    background-color: #f6c04e;
}

.epilogue_survey_sec button:last-child{
    margin-right: 0px !important;
}
.epilogue_survey_sec button{
    color: #9c5d5d;
    font-family: 'OmnesSemiBold';
    outline: none!important;
    /* padding: 14px 5px; */
    background: #f5f5f5;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    margin-right: 6px;
    width: 47%;
    display: inline-block;
    border-radius: 20px;
    font-size: 16px;
    padding: 21px 0px;
}

.epilogue_survey_sec {
    width: 100%;
}
.epilogue_survey_sec label {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    font-family: 'OmnesSemiBold';
    color: #a6a6a6;
    margin-top: 0px;
    margin-bottom: 6px;
    padding-left: 4px;
    line-height: 20px;
}

.epilogue_survey_sec.multi_survey_btns button{
    color: #9c5d5d;
    font-family: 'OmnesSemiBold';
    outline: none!important;
    /* padding: 14px 5px; */
    background: #f5f5f5;
    font-style: normal;
    font-weight: 600;
    /* line-height: 21px; */
    /* align-items: flex-end; */
    text-align: left;
    text-transform: capitalize;
    margin-right: 0px;
    width: 85%;
    height: 70px;
    /* display: table; */
    border-radius: 20px;
    font-size: 16px;
    padding: 12px 15px;
    margin-bottom: 8px;
}
.epilogue_select{
    margin-top: -25px;
    text-align: center;
    margin-left: -35px;
}

.no_type_display{
    opacity:0;
    pointer-events: none;
}

.survey_sec.multi_survey_btns button{
    color: #9c5d5d;
    font-family: 'OmnesSemiBold';
    outline: none!important;
    /* padding: 14px 5px; */
    background: #f5f5f5;
    font-style: normal;
    font-weight: 600;
    /* line-height: 7px; */
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    margin-right: 0px;
    width: 100%;
    display: inline-block;
    border-radius: 8px;
    font-size: 14px;
    padding: 17px 0px;
    margin-bottom: 8px;
}

.not_eligible_enter{
    color: #9c6c6b;
    font-weight: 700;
    margin-left: 1px;
    z-index: 1;
    width: 56px !important;
    height: 40px !important;
    display: inline-block;
    border-radius: 50% 50%;
    font-size: 14px;
    position: relative;
    text-align: center !important;
    padding: 12px 0px 12px;
    font-family: 'OmnesSemiBold';
}

.not_eligible_add_child button{
    color: #fff;
    font-family: 'OmnesSemiBold';
    outline: none!important;
    padding: 14px 34px;
    background: linear-gradient(158.95deg,#ff5a6c -28.17%,#f27381 366.38%);
    border-radius: 18px;
    font-weight: 600;
    line-height: 20px;
    align-items: center;
    text-transform: capitalize;
    width: 100%;
    margin-top: 25px;
    box-shadow: 0 3px 13px rgb(252 97 114 / 65%);
    min-height: 52px;
}

.profile_display_name{
    display: flex;
    justify-content: space-between;
}

.profile_display_name h1{
    font-weight: 600;
    font-size: 24px;
    line-height: 27px;
    color: #4E4E4E;
    font-family: 'OmnesSemiBold';
}

.profile_display_name img{
    margin-left: 5px;
    width: 32px;
}

.borderProgress {
    position: relative;
}

.borderProgress img{
    width: 18px;
    height: 18px;
    position: absolute;
    left: 2px;
    top: -6px;
}

.name_later{
    margin-top: 18px;
    text-align: center;
}

.name_later button{
    color: #fff;
    outline: none!important;
    padding: 12px 34px;
    background: #F47C6A !important;
    border-radius: 30px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
    margin: 22px 0px 15px;
    box-shadow: 0 18px 20px -18px #FF5A6C;
    min-height: 52px;
    font-family: 'OmnesSemiBold';
}

.fixed_editNm{
    height: 194px;
    overflow-x: hidden;
    padding-bottom: 12px;
    overflow-y: auto!important;
}

.editNm {
    margin-top: 10px;
    padding: 9px 9px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 15px;
    width: 100%;
    background: transparent;
    border: 1px solid #0000001A;
}

.editNmLftOne span {
    background: #f2eff0;
    width: 68px;
    height: 68px;
    padding: 20px 0px;
    border-radius: 42px;
    text-align: center;
    margin-bottom: 0px !important;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 34px;
    color: #94CEDB;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: 'OmnesSemiBold';
}
.ExistingAddons .editNmLftOne span {
    color: #719E7F;
    background-color: rgba(113, 158, 127, 0.3);
}
.ExpectantAddons .editNmLftOne span {
    color: #927BC3;
    background-color: #ad85df75;
}
.editNmRght button {
    background-color: #fceaea;
    border: none;
    color: #ff6e83;
    padding: 10px 20px;
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;
    border-radius: 16px;
}

.editNmLft {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.editNmLftTwo label {
    display: block;
    color: #242424;
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
    margin-bottom: 1px;
    font-family: 'OmnesMedium';
}

.editNmLftTwo span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #242424;
    opacity: 0.5;
    margin: 0px;
    font-family: 'OmnesMedium';
}

.editNmLftTwo {
    padding-left: 10px;
}


.main_child_popup p{
    font-weight: 400;
    display: block;
    margin: 0px;
    color: #242424;
    opacity: .5;
    font-size: 14px;
    line-height: 17px;
    font-family: 'OmnesRegular';
}

.main_child_popup{
    min-height: 592px;
}

.info_head{
    justify-content: center;
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
}

.info_head h2{
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 27px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #4E4E4E;
    margin-top: 0px;
    margin-right: 0px;
    font-family: 'OmnesSemiBold';
}

.info_head span{
    border: 1px solid #d1bebc;
    background: #d1bebc;
    color: #ffffff;
    border-radius: 20px;
    height: 22px;
    width: 22px;
    font-size: 14px;
    text-align: center;
    margin-top: 5px;
}

.unknwnchild_head_info{
    margin-bottom: 150px;
}

.unknwnchild_head_info h3{
    margin-bottom: 25px;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 114.21%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #914949;
    font-family: 'Montserrat', sans-serif;
}

.unknwnchild_head_info .addOneChild p{
    text-align: justify;
    font-weight: 600;
    display: block;
    margin: 0px;
    color: #bcb1b4;
    font-family: 'OmnesSemiBold';
    opacity: initial !important;
    font-size: 16px;
    line-height: 16px;
}


.im-buttons, h5 {
    display: inline-block;
  }
  .im-buttons label, h5 label {
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.65;
  }
  .im-buttons label span, h5 label span {
    display: inline-block;
  }
  
  .radio {
    margin: 16px 0;
    display: inline-block;
    cursor: pointer;
  }
  .radio input {
    display: none;
  }
  .radio input#a-button + span:after {
    border: #fc5f70 2px solid;
  }
  
  .radio input + span {
    line-height: 22px;
    height: 22px;
    padding-left: 22px;
    position: relative;
  }
  .radio input + span:not(:empty) {
    padding-left: 30px;
    margin-left: 20px;
  }
  .radio input + span:before, .radio input + span:after {
    content: "";
    width: 22px;
    height: 22px;
    display: block;
    border-radius: 50%;
    left: 0;
    top: 0;
    position: absolute;
  }
  .radio input + span:before {
    transition: background 0.2s ease, transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2);
  }
  .radio input + span#a-button:before {
    background: #1681d8;
  }
  
  .radio input + span:after {
    background: transparent;
    transform: scale(0.78);
    transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4);
  }
  .radio input:checked + span:before {
    transform: scale(1.04);
  }
  .radio input:checked + span:after {
    transform: scale(0.4);
    transition: transform 0.3s ease;
  }
  .radio input#a-button:checked + span:before {
    background: transparent;
    border: 2px solid #fc5f70;
  }
  .radio input#a-button:checked + span:after {
    background: #fc5f70;
  }
  
  .radio:hover input + span:before {
    transform: scale(0.92);
  }
  .radio:hover input + span:after {
    transform: scale(0.74);
  }
  .radio:hover input:checked + span:after {
    transform: scale(0.4);
  }

/* Start of Updated Design CSS */
.common_mainCstm{
    /* background: linear-gradient(180deg, #FFFFFF 0%, #FFF1F0 81.25%); */
    background: linear-gradient(180deg, #FFFFFF 0%, #F2F2F2 81.25%);
    padding: 0px;
    /* padding-bottom: 240px; */
}
.welcmStup {
    /* background: #FFFFFF;
    border-radius: 0px 0px 150px 150px; */
    padding: 12px 20px 0px;
    position: relative;
    /* margin-bottom: 42px; */
    margin-bottom: 20px;
    background-repeat: no-repeat;
    background-image: url("../images/welcomeBg.svg");
    background-size: cover;
    background-position: bottom center;
    /* text-align: center;
    margin-top: 18%;
    margin-bottom: 11%; */
}
.signupHere img {
    width: 46.92px;
    height: 46.92px;
    cursor: pointer;
    text-align: center;
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 8px;
    margin: 0 auto;
    border-radius: 30px;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px; */
    /* box-shadow: 0px 5px 9px rgb(252 97 113 / 76%);
    background: #fc6171; */
}
.loginSocialArea{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
}
.welcmStupBg {
    text-align: center;
    background-repeat: no-repeat;
    /* background-image: url("../images/StarCstm.gif"); */
    padding: 44px 0px 0px;
    background-size: cover;
    min-height: 349px;
    background-position: center right;
}
.signupConectCircle {
    background-repeat: no-repeat;
    background-image: url("../images/circleBg2.png");
    background-size: initial;
    background-position: left bottom;
    background-repeat: no-repeat;
    /* background-position: left bottom;
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px; */
}
.signupConectCircle .fa-facebook-f:before, 
.signupConectCircle .fa-facebook:before {
    display: none;
}
.signupConect {
    background-repeat: no-repeat;
    /* background-image: url("../images/HeartCstm.gif"); */
    background-size: contain;
    background-position: center bottom;
}
.signupConectIner {
    padding: 0px 32px 62px;
}

.safari-signupConectIner{
    /* padding: 12px 22px 150px !important; */
    padding: 12px 22px 92px !important;
    outline: none;
}
.common_mainIner {
    padding: 12px 22px;
    /* padding-bottom: 168px; */
    /* box-shadow: 0px 1px 2px 0px #d4b8b61f; */
    /* background: linear-gradient(180deg, #FFFFFF 0%, #FFF1F0 81.25%); */
    /* background: linear-gradient(180deg, #FFFFFF 0%, #F2F2F2 81.25%); */
}
.verifyAcSetBg{
    background-image: url("../images/circleBg2.png");
    background-size: initial;
    background-position: left bottom;
    background-repeat: no-repeat;
}
/* .signupConectCircleInr {
    background-repeat: no-repeat;
    background-image: url("../images/circleBg2.png");
    background-size: initial;
    background-position: left bottom;
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
}
.signupConectInr {
    background-repeat: no-repeat;
    background-image: url("../images/HeartCstm.gif");
    background-size: contain;
    background-position: center bottom;
    min-height: 168px;
} */
.adDobIcon .MuiInput-formControl:after{
    background-image: url('../images/vectorDob.png');
    width: 19px;
    height: 20px;
    content: '' !important;
    position: absolute;
    left: initial;
    right: 0px !important;
    background-repeat: no-repeat;
    background-size: contain;
    top: 10px;
    cursor: pointer;
    transform: initial;
    border-bottom: none;
    pointer-events: initial;
}

.welcomeBg {
    background-repeat: no-repeat;
    background-image: url("../images/circleBg2.png");
    background-size: initial;
    background-position: left bottom;
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
}
.welcomeBgIner {
    background-repeat: no-repeat;
    background-image: url("../images/HeartCstm.gif");
    background-size: contain;
    background-position: center bottom;
}

a.foterIconAct:after {
    width: 4.83px;
    height: 4.83px;
    background: #F47C6A;
    border-radius: 20px;
    content: "";
    bottom: -16px;
    left: 0px;
    right: 0px;
    position: absolute;
    margin: 0 auto;
}
span.numPts {
    position: absolute;
    top: 3px;
    left: 0px;
    right: 0px;
    margin: 0 auto;
    font-style: normal;
    font-size: 12px;
    font-weight: 300;
    color: #FFFFFF;
    line-height: 13px;
    font-family: 'OmnesRegular';
}
span.numPts b {
    display: block;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 2px;
}
.msgAccount p {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #242424 !important;
    opacity: 0.7;
    margin: 0px;
    font-family: 'OmnesRegular';
}

span.invtCup {
    position: absolute;
    width: 40px;
    height: 40px;
    right: -15px;
    top: -17px;
    background: #FF5A6C;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    box-shadow: -3px 4px 8px -2px rgb(252 96 112 / 69%);
}
span.invtCup img {
    height: 18px;
    width: initial !important;
}
.parent_invite {
    background-repeat: no-repeat;
    background-image: url("../images/inviteBg.png");
    background-size: cover;
    background-position: center center;
    text-align: center;
    margin-top: 18%;
    margin-bottom: 11%;
}
.prent_submit2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 44px;
}
.prent_submit2 button:first-child {
    /* box-shadow: 0px 2px 10px 0px rgb(252 96 112 / 69%); */
    color: #ffffff;
}
.prent_submit2 button {
    width: 47%;
    display: block;
    min-height: 131px !important;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 16px;
    font-family: 'OmnesSemiBold';
    color: #90494a;
    padding: 12px 24px;
    text-transform: initial;
}
.prent_submit2 button:last-child {
    background: transparent !important;
    border: 1px solid #ecd7d5;
}
.prent_submit2 button img {
    margin-bottom: 14px;
}
.inviteType .build_content p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #242424;
    opacity: 0.7;
    margin-top: 22px;
    margin-bottom: 0px;
}
.inviteAdd_parent {
    background-repeat: no-repeat;
    background-image: url("../images/inviteBg.png");
    background-size: cover;
    background-position: center center;
    text-align: center;
    margin-bottom: 11%;
}
.addRelativeCstm .point_benefits {
    margin-top: 62px;
}
.addRelativeCstm .familyadd_upr button {
    box-shadow: initial !important;
    color: #ffffff;
    width: 85%;
    min-height: 52px;
    background: #F47C6A !important;
    border-radius: 30px !important;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    font-family: 'OmnesSemiBold';
}
.addRelativeCstm .point_benefits h2 {
    line-height: 114.21%;
}
.addRelativeCstm .all_family {
    border-radius: 20px;
    padding: 12px 9px 12px 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #ffffff !important;
}
.addRelativeCstm .all_relative_rght p {
    margin-bottom: 0px;
    font-size: 14px;
    line-height: 16px;
    font-family: 'OmnesRegular';
}
.addRelativeCstm .all_relative_rght {
    padding-top: 0px;
}
.addRelativeCstm .all_relative_rght label {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: #242424;
    margin-bottom: 6px;
    font-family: 'OmnesMedium';
}
.addRelativeCstm .all_family:hover {
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    cursor: pointer;
}
.addRelativeCstm .all_family_left label {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 34;
    color: #927BC4;
    width: 60.53px;
    height: 60.53px;
    font-family: 'OmnesSemiBold';
}
.parentinvite_content .addOneChild {
    border: 1px solid #f1eaea;
    box-sizing: border-box;
    border-radius: 20px;
    padding: 12px 8px;
    margin-bottom: 16px;
}
.parentinvite_content .addOneChild:last-child {
    padding: 0px;
    border: none;
    margin-top: 34px;
    margin-bottom: 0px;
}
button.prent_submitNo {
    color: #ffffff;
}
.parentinvite_content .addOneChild p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #4E4E4E !important;
    margin-bottom: 0px;
}
.parentinvite_content .prent_submit button:last-child {
    margin: 0px !important;
    background-color: transparent !important;
    background: transparent !important;
    min-height: initial !important;
}
.parentinvite_content .prent_submit button {
    min-height: 52px;
    margin-top: 0px;
    margin-bottom: 15px;
}

.alterEmail h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 114.21%;
    text-align: center;
    /* letter-spacing: -0.02em; */
    color: #914949;
    font-family: 'OmnesSemiBold';
    margin-bottom: 14px;
}
.alterEmail p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 114.21%;
    text-align: center;
    /* letter-spacing: -0.01em; */
    color: #242424;
    opacity: 0.7;
    font-family: 'OmnesRegular';
    margin-bottom: 0px;
}
.alterEmailForm {
    min-height: 220px;
    padding-top: 0px;
}
.alterEmailUpr {
    padding: 25px 24px 16px !important;
}
.alterEmailFormInr label {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    color: #242424;
    display: block;
    font-family: 'OmnesRegular';
    margin-top: 12px;
    margin-bottom: 0px;
}
.alterEmailFormInr {
    border: 1px solid #f2eaea;
    box-sizing: border-box;
    border-radius: 20px;
    padding: 16px 22px;
    text-align: center;
    margin-top: 26px;
}
.alterEmailFormInr img {
    height: 22px;
}
.acceptDecline button:last-child {
    border-radius: 20px;
    background: #fef0f1 !important;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: #FC6071 !important;
    margin-top: 18px;
}
.acceptDecline {
    padding: 38px 27px !important;
}
.childNwUpr label {
    display: inline-flex;
    align-items: center;
}
span.childNw {
    width: 32.39px;
    height: 15.47px;
    background: #FF5A6C;
    border-radius: 20px;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 138.05%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    /* letter-spacing: 0.01em; */
    color: #FFFEFE;
    text-transform: capitalize;
    margin-left: 7px;
    font-family: 'OmnesSemiBold';
    padding-top: 2px;
}
.selectFltr {
    text-align: right;
    margin-bottom: 22px;
}
.selectFltr select {
    width: 100%;
    min-height: 46px;
    background: rgba(141, 87, 87, 0.05);
    border: 1px solid rgba(141, 87, 87, 0.05);
    border-radius: 20px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    color: #242424;
    padding: 0px 8px 0px 14px;
    outline: none;
    font-family: 'OmnesSemiBold';
    background-image: url("../images/selectArow.png");
    background-repeat: no-repeat;
    background-position: calc(100% - 0.75rem) center !important;
    -moz-appearance:none !important;
    -webkit-appearance: none !important; 
    appearance: none !important;
}
.accept_decline .notify_left {
    display: none;
}
.notify_acpt {
    width: 100%;
    text-align: center;
}
.no_notification {
    background: #FFFEFE;
    border-radius: 0px 0px 20px 20px;
    padding-bottom: 3px;
}
.notify_acpt button:last-child {
    margin-left: 12px !important;
    background: #ffffff !important;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.notify_acpt button {
    border-radius: 20px;
    width: 42%;
    min-height: 52px;
}
.notify_section {
    margin-bottom: 12px;
}
.notifyListMsg span {
    width: 48.77px;
    height: 48.77px;
    background: #fef0f2;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.notifyList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 28px;
}
.notifyListMsg span a {
    position: absolute;
    width: 15.82px;
    height: 15.82px;
    right: 0px;
    top: 0px;
    background: #FC6071;
    border-radius: 40px;
    font-style: normal;
    font-weight: 800;
    font-size: 9px;
    line-height: 138.05%;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.01em;
    color: #FFFEFE !important;
    text-align: center;
    font-family: 'OmnesSemiBold';
}
.notifyList label {
    margin-bottom: 0px;
}

/* li.mainIcon img {
    width: 26px !important;
    height: initial !important;
}
li.perksIcon img {
    width: 26px !important;
    height: initial !important;
}
li.familyIcon img {
    width: 25px !important;
    height: initial !important;
}
li.profileIcon img {
    width: 27px !important;
    height: initial !important;
} */

.fix_main.fixed-header ul.nav.nav-tabs {
    padding-bottom: 4px;
}

.relativeopt{
    width: 100% !important;
}
.relativeopt button{
    padding: 11px 30px;
    margin: 0px 2px 7px;
}
.myChildLbl label {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    align-items: center;
    color: #242424 !important;
    opacity: initial;
    font-family: 'OmnesSemiBold';
    margin-bottom: 10px;
}
.myChildLblSec {
    margin-top: 28px;
}
.myChildInput {
    min-height: 52px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    border: 1px solid #a27574;
    width: 100% !important;
    padding: 8px 14px;
}
.myChildInput p {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 114.21%;
    color: #242424;
    font-family: 'OmnesSemiBold';
    margin: 0px;
    cursor: pointer;
}
img.selectArow {
    position: absolute;
    top: 2px;
    right: 0px;
    cursor: pointer;
}
.selectRelation label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    align-items: center;
    color: #242424;
    font-family: 'OmnesRegular';
    margin-bottom: 0px !important;
    padding: 0px;
}
.selectRelation div img {
    position: initial !important;
}
.selectRelation {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}
.selectRelationModel .modal-content {
    padding: 15px 22px 0px;
    top: 15%;
    font-family: 'Montserrat', sans-serif;
    min-height: 259px;
    background: #FCF3F3 !important;
    box-shadow: 0px 4px 34px rgb(141 87 87 / 40%);
    border-radius: 20px 20px 0px 0px;
    left: 0px !important;
}

.relativeopt {
    width: 100% !important;
}
.childRel label {
    font-family: 'OmnesSemiBold';
    color: #914949 !important;
    margin-top: 0px;
    display: block;
    margin-bottom: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 114.21%;
}
.relativeopt button {
    padding: 11px 30px;
    margin: 0px 2px 7px;
}
.childRel button {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #92494b;
    border: 2px solid #9b6b6b21;
    box-sizing: border-box;
    border-radius: 22px;
    font-family: 'OmnesSemiBold';
    text-transform: capitalize;
    outline: none !important;
    padding: 11px 0px;
    margin-right: 0px;
    min-width: 100px;
    margin: 0px 2px 10px;
}
.childRel button:hover {
    background: linear-gradient(117.02deg, #85C8D5 -28.17%, #78BFCD 366.38%);
    color: #ffffff;
}
.relation_active {
    background: linear-gradient(117.02deg, #85C8D5 -28.17%, #78BFCD 366.38%) !important;
    color: #ffffff !important;
}
.selectRelationModel {
    margin-bottom: 0px;
}
.selectRelationModel {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0px;
}
.selectRelationBtn label {
    font-family: 'OmnesSemiBold';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #914949 !important;
    margin-top: 0px;
    display: block;
    margin-bottom: 10px;
    opacity: 1;
}
.selectRelationBtn button {
    color: #92494b;
    border: 2px solid #9b6b6b21;
    box-sizing: border-box;
    border-radius: 22px;
    font-family: 'OmnesSemiBold';
    text-transform: capitalize;
    outline: none !important;
    padding: 14px 9px;
    margin-right: 0px;
    min-width: 100px;
    margin: 0px 2px 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 114.21%;
    color: #242424;
}
.selectRelationBtn2 label {
    color: #bba6bd !important;
}
.selectRelationBtn2 {
    margin-top: 12px;
}
.selectRelationBtn2 button {
    color: #a087a8;
    border: 1px solid #e6d3e4;
}
.selectOk {
    text-align: center;
    margin-top: 18px;
    margin-bottom: 8px;
}
.selectOk button {
    background: #f4e1e4;
    border-radius: 20px;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    color: #FD6071;
    text-transform: capitalize;
    width: 130px;
    margin: 0px 4px;
    padding: 18px 0px;
    outline: none !important;
}
.acceptmodel {
    height: 100%;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}
.acceptmodel .modal-content {
    top: 15%;
    font-family: 'Montserrat', sans-serif;
    width: 219.38px;
    height: 188.27px;
    background: #ffffff !important;
    border-radius: 20px;
    left: 0px;
    right: 0px;
    margin: 0 auto;
    padding: 15px 15px;
}
.acceptmodel .modal-header {
    position: absolute;
    top: 7px;
    right: 9px;
    z-index: 9;
}
img.clearaccept_img {
    width: 17px;
    max-width: 32px;
    position: absolute;
    top: 3px;
    right: 0;
    cursor: pointer;
}
.invitationAceptUpr {
    position: relative;
}
.invitationAcept {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    flex-direction: column;
}
.invitationAcept label {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    display: block;
    color: #4A494C;
    opacity: 0.8;
    margin-top: 20px;
    margin-bottom: 0px;
    text-align: center;
    width: 133px;
    font-family: 'OmnesSemiBold';
}
.loaderUpr {
    margin-top: 32px;
}
.loaderSpin {
    width: 100%;
    min-height: 145px;
    background: #FFFFFF;
    border-radius: 20px;
    border: 1px solid #cbe8dd42;
    display: flex;
    align-items: center;
    justify-content: center;
}
.loaderSpin a {
    width: 81.54px;
    height: 81.54px;
    cursor: pointer;
    background: #f6fbf9;
    border-radius: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.loaderSpin a:hover img {
    transform: rotate(2turn);
    transition: .6s ease-out;
    cursor: pointer;
}
@media screen and (max-width: 575px) {
    .selectRelationModel {
        margin: 0px;
    }
}
@media (min-width: 576px){
 .selectRelationModel {
    max-width: 368px !important;
 }
}

.inside_option {
    display: flex;
}
.inside_option .button2,.inside_option .button4 {
    margin-left: 8px;
}

.mailAddCstm h2 {
    text-align: center;
    margin-top: 26px;
    margin-bottom: 17px;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 27px;
    color: #4E4E4E;
    font-family: 'OmnesSemiBold';
} 
.addParagraph {
    margin: 10px 0px 24px;
}
.addParagraph p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    margin: 0px;
    color: #000000;
    opacity: 0.5;
    font-family: 'OmnesRegular';
    text-align: center;
}
.cstmSelCountry {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #ffffff !important;
    color: #242424;
    outline: none;
    letter-spacing: normal;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    padding: 16px 17px;
    border-radius: 15px;
    font-family: 'OmnesMedium';
    max-height: 52px;
    position: relative;
    margin-bottom: 15px;
}
.cstmSelCountry p {
    margin: 0px;
    outline: none;
    letter-spacing: normal;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    border-radius: 15px;
    font-family: 'OmnesMedium';
    max-height: 52px;
    color: #242424;
}
img.cstmSelectArow {
    position: absolute;
    top: 17px;
    right: 17px;
    cursor: pointer;
    width: 10px;
}
.selCanadaUpr {
    width: 100%;
    /* min-height: 65.81px; */
    background: rgb(255, 255, 255, 0.5) !important;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.selCanadaUpr .selCanadaBtn {
    width: 32.33%;
}
.selCanadaBtn button {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    text-transform: none;
    text-transform: initial;
    outline: none;
    width: 100%;
    min-height: 52.53px;
    border-radius: 20px;
    color: #4E4E4E;
    opacity: 0.5;
    font-family: 'OmnesSemiBold';
}
.selCanadaBtn button:hover {
    background: #ffffff !important;
    opacity: 1;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
}
button.selCanadaActv {
    background: #ffffff;
    opacity: 1;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.selCanadaMain {
    width: 100%;
    /* background: #f9efee; */
    border-radius: 20px;
    margin-top: 15px;
}
.selSubMain2Upr {
    margin-top: 20px;
    padding-bottom: 0px;
}
.selSubMain2Cntry {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #ffffff !important;
    color: #242424;
    outline: none;
    letter-spacing: normal;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    padding: 16px 17px;
    border-radius: 15px;
    font-family: 'OmnesMedium';
    max-height: 52px;
    position: relative;
}
.selSubMain2Cntry p {
    margin: 0px;
    outline: none;
    letter-spacing: normal;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    border-radius: 15px;
    font-family: 'OmnesMedium';
    max-height: 52px;
    color: #242424;
}
.buzSub2 {
    margin-top: 8px;
    margin-bottom: 2px;
}
.selRuralMain {
    width: 100%;
    background: #f9efee;
    border-radius: 20px;
    margin-top: 0px;
}
.selRuralMain .selSubMain2Upr {
    margin-top: 0px;
}
img.selectArowCntry {
    position: absolute;
    top: 18px;
    right: 18px;
    cursor: pointer;
    width: 10px;
}
.skilltestAddons .country_dataList div:last-child a {
    margin-bottom: 0px !important;
}
.skilltestAddons .country_data {
    width: 100%;
    background: #FFFFFF;
    border-radius: 20px;
    padding: 25px 22px 32px 27px;
    margin-top: 0px;
}
.skilltestAddons .country_ac {
    display: block;
}
.skilltestAddons .country_ac {
    display: block;
    position: relative;
}
.skilltestAddons .country_acRght {
    position: absolute;
    right: 0px;
    top: -2px;
}
.skilltestAddons .country_ac .country_acLft {
    width: 100%;
    margin-bottom: 10px;
}
.skilltestAddons .country_acRght .closeAddressModel {
    font-size: 24px;
    font-weight: 500;
}
.skilltestAddons .country_acLft label {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #4E4E4E;
}
.skilltestAddons .country_dataList a {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #4E4E4E;
    margin-bottom: 18px !important;
    cursor: pointer;
    opacity: 0.5;
    font-family: 'OmnesRegular';
    padding-right: 36px;
}
.skilltestAddons .cntrySelect {
    position: absolute;
    right: 10px;
    top: 4px;
}
.chooseCountryAddons .country_dataList {
    height: 310px;
}
.skilltestAddons {
    margin-top: 16%;
}
.cst_close{
    top: -5px !important;
    right: -7px !important;
    width: 26px !important;
}
.common_mainCstmRmv {
    padding-bottom: 0px;
}
.signupConectCircleHght, .signupConectHght {
    min-height: 138px;
}
.signupConectIner button {
    z-index: 111;
}
.SurveyWinTxt {  
    width: 100%;
    min-height: 219.06px;
    background: #FFFFFF;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 12px 42px;
}
.SurveyWinTxt label {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 114.21%;
    text-align: center;
    /* letter-spacing: -0.02em; */
    color: #914949;
    margin: 24px 0px 16px;
    font-family: 'OmnesSemiBold';
}
.SurveyWinTxt p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.01em;
    color: #242424;
    opacity: 0.5;
    margin: 0px;
    font-family: 'OmnesRegular';
}
.recordTake button {
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    width: 100%;
    color: #9A6A6A;
    border: none;
    background: #FFFFFF;
    border-radius: 20px;
    min-height: 52px;
    outline: none;
    cursor: pointer;
    font-family: 'OmnesSemiBold';
}
.recordTake button:hover {
    background: linear-gradient(117.02deg, #FF5A6C -28.17%, #F27381 366.38%);
    color: #ffffff;
}
button.recordTakeActv {
    background: linear-gradient(117.02deg, #FF5A6C -28.17%, #F27381 366.38%);
    color: #ffffff;
}
.recordTake {
    margin-top: 15px;
    margin-bottom: 20px;
}
.pressStart {
    background: #fff8f7;
    min-height: 69px;
    border-radius: 20px;
    padding: 16px 21px;
    margin-bottom: 32px;
}
.pressStart p {
    margin: 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 120%;
    text-align: center;
    color: rgba(141, 87, 87, 0.5);
}
.pressStart p b {
    color: #9A6A6A;
    font-weight: initial;
}
.discardVdo {
    background: #ffffff;
    border-radius: 20px;
    padding: 52px 22px 24px;
    min-height: 253.05px;
    position: relative;
}
.discardVdo label {
    display: block;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 114.21%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #914949;
    margin-bottom: 26px;
    font-family: 'OmnesSemiBold';
}
.discardBtns button {
    background: #ffeff1;
    display: block;
    width: 100%;
    border-radius: 20px;
    min-height: 52px;
    cursor: pointer;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    color: #FC6071;
    outline: none;
    font-family: 'OmnesSemiBold';
}
button.discardBtnsActv {
    background: linear-gradient(117.02deg, #FF5A6C -28.17%, #F27381 366.38%);
    color: #ffffff;
}
.discardBtns button:hover {
    background: linear-gradient(117.02deg, #FF5A6C -28.17%, #F27381 366.38%);
    color: #ffffff;
}
.discardBtns button:first-child {
    margin-bottom: 17px;
}
a.discardClose {
    position: absolute;
    top: 15px;
    right: 18px;
    display: inline;
    cursor: pointer;
}
.discardVdoUpr, .congDetailUpr {
    margin-top: 10%;
}
.congDetail {
    background: #ffffff;
    border-radius: 20px;
    padding: 52px 18px 32px;
    position: relative;
    text-align: center;
}
.congOpnPopup button {
    text-decoration: none;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    width: 100%;
    color: #9A6A6A;
    border: none;
    background: #FFFFFF;
    text-transform: initial;
    border-radius: 20px;
    min-height: 52px;
    outline: none;
    cursor: pointer; 
    font-family: 'OmnesSemiBold';
}
.congDetailTxt label {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 114.21%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #914949;
    display: block;
    margin-bottom: 20px;
    font-family: 'OmnesSemiBold';
}
.congDetailTxt p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 114.21%;
    text-align: center;
    letter-spacing: -0.01em;
    color: #242424;
    opacity: 0.7;
    margin: 0px;
    margin-top: 12px;
    font-family: 'OmnesRegular';
}
.congOpnPopup {
    margin-bottom: 12px;
}
.videoTxt label {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 114.21%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #914949;
    display: block;
    margin-bottom: 10px;
    font-family: 'OmnesSemiBold';
}
.videoTxt p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 114.21%;
    text-align: center;
    letter-spacing: -0.01em;
    color: #242424;
    opacity: 0.7;
    margin: 0px;
    font-family: 'OmnesRegular';
}
.videoSize {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.videoSize strong {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 114.21%;
    text-align: center;
    letter-spacing: -0.01em;
    color: #242424;
    margin: 0px;
    padding: 0px;
    font-family: 'OmnesSemiBold';
}
.videoSize span {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 114.21%;
    text-align: center;
    letter-spacing: -0.01em;
    color: #242424;
    opacity: 0.8;
    font-family: 'OmnesMedium';
}
.videoSizeTime {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.videoSizeTime p {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 114.21%;
    text-align: center;
    letter-spacing: -0.01em;
    color: #242424;
    opacity: 0.8;
    margin-bottom: 0px;
}
.videoSizeTime p strong {
    color: #66BD99;
}
.videoSizeCal {
    background: #fffcfc;
    border: 1px dashed #ff5a6c5c;
    border-radius: 20px;
    min-height: 125px;
    padding: 12px 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.vdoProgres {
    background: #f2efef;
}
.vdoProgresInr {
    height: 6.97px;
    background: #66BD99;
    border-radius: 20px;
    margin: 22px 0px 15px;
}
.videoTxt {
    margin-bottom: 30px;
}
.videoSizeTime a:before {
    content: "";
    position: absolute;
    width: 2.65px;
    height: 2.65px;
    left: 2px;
    top: 7px;
    background: #242424;
}
.videoSizeTime p a {
    padding-left: 9px;
    margin-left: 2px;
    position: relative;
}
.videoSection {
    padding-bottom: 0px;
    background: transparent;
}
.videoRecord {
    width: 371px;
    margin: 0 auto;
    z-index: 999;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    position: fixed;
}
.videoRecord .bFuRbw {
    top: initial;
    bottom: 10px;
}
.videoSection h1, .videoPreVewUpr h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 103%;
    letter-spacing: 0.04em;
    color: #904949;
    margin: 12px 0px;
    font-family: muli,sans-serif;
}
.videoPreVew{
    position: relative;
}
.videoPreVew video {
    object-fit: initial;
    width: 100%;
    min-height: 100vh;
}
.videoControl {
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 4%;
    margin-top: 8px;
    padding-bottom: 15px;
    position: absolute;
    bottom: 125px;
    left: 0px;
    right: 0px;
}
.videoControl button {
    min-height: 52px;
    width: 48%;
    text-align: center;
    border: none;
    cursor: pointer;
    border-radius: 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    outline: none;
    text-transform: initial;
    font-family: 'OmnesSemiBold';
}
button.recordAgain {
    background: #FFFFFF !important;
    color: #904949;
}
button.recordAgain:hover {
    border: 1px solid #904949;
}
button.recordSave {
    background: linear-gradient(117.02deg, #FF5A6C -28.17%, #F27381 366.38%);
    color: #ffffff;
}
button.recordSave:hover {
    background: #ffffff;
    color: #FF5A6C;
    border: 1px solid #FF5A6C;
}
.videoPreVewUpr {
    padding-bottom: 0px;
    min-height: initial !important;
}
.instant_hend2.entyUploadVdo {
    padding: 21px 19px !important;
}
.pressStartRecod {
    margin-bottom: 0px;
}
.videoSection {
    position: relative;
}
.videoSection .videoRecord::before{
    background-color: transparent;
    cursor: pointer;
    position: absolute;
    width: 20px;
    height: 20px;
    right: 3px;
    top: 3px;
    z-index: 999;
    border-radius: 9px;
    background-size: contain;
    background-repeat: no-repeat;
    content: "";
    /* background-image: url("../images/closeIcon.png"); */
}
/* Servey screen style */
.revwPending {
    background: #ffffff;
    border-radius: 20px;
    padding: 52px 32px 35px;
    position: relative;
}
img.closeRvew {
    position: absolute;
    top: 15px;
    right: 15px;
    display: inline;
    cursor: pointer;
    height: 11px;
}
.revwPending{
    text-align: center;
}
.revwPendingTxt label {
    display: block;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 114.21%;
    text-align: center;
    /* letter-spacing: -0.02em; */
    color: #914949;
    margin: 0px;
    font-family: 'OmnesSemiBold';
}
.revwPendingTxt p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 114.21%;
    color: #242424;
    opacity: 0.7;
    margin-bottom: 0px;
    font-family: 'OmnesRegular';
}
.revwPendingTxt img {
    margin: 32px 0px 34px;
}
.reviewBtn button {
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    width: 90%;
    color: #9A6A6A;
    border: none;
    background: #FFFFFF;
    border-radius: 20px;
    min-height: 52px;
    outline: none;
    cursor: pointer;
    font-family: 'OmnesSemiBold';
}
button.reviewBtnActv {
    background: linear-gradient(117.02deg, #FF5A6C -28.17%, #F27381 366.38%);
    color: #ffffff;
}
.reviewBtn {
    text-align: center;
    margin: 20px 0px;
}
.survyDetailInr {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    min-height: 219.06px;
    background: #FFFFFF;
    border-radius: 20px;
}
.survyDetailUpr {
    padding: 0px 20px;
}
.survyDetail button {
    min-height: 52px;
    background: linear-gradient(117.02deg, #FF5A6C -28.17%, #F27381 366.38%);
    border-radius: 20px;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    margin-top: 26px;
    text-transform: initial;
    font-family: 'OmnesSemiBold';
    width: 100%;
}
.imgCross{
    height: 20px;
    width: 20px;
    z-index: 999999;
    position: absolute;
   right: 8px;
    top: 8px;
}
.srvyVdo img, .srvyVdo video {
    width: 100%;
}
.srvyVdoDetail {
    position: relative;
    width: 100%;
    padding: 18px;
    background: #FFFFFF;
    border-radius: 20px;
}
.srvyVdo {
    position: relative;
    margin-bottom: 28px;
}
.srvyVdo a.playVdo {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    width: 64.86px;
    height: 64.86px;
    border-radius: 44px;
    background: rgba(0, 0, 0, 0.5);
    border: 2px solid #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 22% auto;
}
a.playVdo img {
    height: 26px;
}
.srvyVdo label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 114.21%;
    color: #242424;
    opacity: 0.7;
    font-family: 'OmnesMedium';
    margin-top: 16px;
    margin-bottom: 0px;
    display: block;
}
.revwStatus label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #242424;
    display: block;
    margin: 0px;
    font-family: 'OmnesMedium';
}
.inReviewBtn a {
    padding: 5px 6px;
    width: 135.69px;
    height: 36.93px;
    left: 42.49px;
    top: 480.78px;
    background: #fffaea;
    border-radius: 100px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #FCCE0B !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: 'OmnesSemiBold';
}
.revwStatus p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 114.21%;
    letter-spacing: -0.01em;
    color: #242424;
    opacity: 0.7;
    margin: 0px;
    font-family: 'OmnesRegular';
}
.inReviewBtn {
    margin-top: 6px;
    margin-bottom: 18px;
}
.inReviewBtn a img {
    margin-right: 8px;
}
.revwStatus {
    margin-bottom: 17px;
}

video.playVdo {
    height: 100%;
    border-radius: 20px;
}
.frameBaner img {
    width: 100%;
    border-radius: 20px;
}
.frameLogo img {
    height: 41px;
}
.frameLogo {
    text-align: center;
    margin-top: 16px;
    margin-bottom: 12px;
}
.frameTxt p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    margin-bottom: 10px;
    font-family: 'OmnesRegular';
}
.frameTxt p:last-child {
    margin-bottom: 0px;
}
.frameTxt {
    margin-top: 32px;
    margin-bottom: 0px;
}
.frameTxt p b {
    font-size: 18px;
    font-weight: 600 !important;
    font-family: 'OmnesMedium';
}
.statusRvw {
    margin: 62px auto 70px;
    display: flex;
    text-align: center;
    width: 265px;
    min-height: 198.62px;
    background: #fffae7;
    border-radius: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}
.statusRvw label {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 11px;
    display: block;
    letter-spacing: -0.01em;
    color: #904949;
    margin-bottom: 17px;
}
.statusRvw p {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 11px;
    letter-spacing: -0.01em;
    color: #FCCE0B;
    margin-top: 12px;
    margin-bottom: 0px;
}
.statusRvwMsk1 {
    position: absolute;
    left: -27px;
    top: 15px;
}
.statusRvwMsk2 {
    position: absolute;
    right: -27px;
    bottom: 15px;
}
.frameSectionUpr .container {
    width: 624px;
    margin: 0 auto;
}
.frameSection {
    padding: 12px 26px 35px;
    padding-bottom: 0px;
}
.footerCtsm {
    margin-top: 30px;
    margin-bottom: 15px;
    text-align: center;
}
.footerLogo a {
    display: inline-block;
}
.footerLogo img {
    height: 25px;
    cursor: pointer;
}
.footerSocial a {
    display: inline-block;
    margin: 0px 4px;
}
.footerSocial {
    margin-top: 10px;
}
.frameBaner {
    text-align: center;
    background-repeat: no-repeat;
    background-image: url("../images/frame1Bg.jpg");
    padding: 22px 12px;
    background-size: cover;
    min-height: 325px;
    border-radius: 20px;
}
.frameBaner h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    line-height: 31px;
    color: #3E3897;
    margin: 0px;
    font-family: 'OmnesSemiBold';
}
.signFooterBg {
    width: 100%;
    min-height: 236.04px;
    background: #FE5D6E;
    box-shadow: 0px 0px 24px rgb(255 90 108 / 23%);
    border-radius: 20px;
    margin-top: 0px;
}
.frameSectionBg {
    background: #FFFFFF;
    box-shadow: 0px 0px 24px rgb(255 90 108 / 23%);
    border-radius: 30px;
}
.signFooter h3 {
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    text-transform: capitalize;
    color: #FFFFFF;
    margin-bottom: 10px;
    font-family: 'OmnesSemiBold';
}
.signFooter p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    opacity: 0.8;
    padding: 0px 58px;
    font-family: 'OmnesRegular';
}
.signUphere button {
    cursor: pointer;
    width: 166.62px;
    height: 45.94px;
    background: #FF8D9A;
    border: 1px solid #FFFFFF;
    border-radius: 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 11px;
    /* letter-spacing: -0.01em; */
    color: #FFFFFF;
    outline: none !important;
    font-family: 'OmnesSemiBold';
}
.signUphere {
    text-align: center;
    margin-top: 8px;
}
.allBest label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    display: block;
    margin-bottom: 2px;
    font-family: 'OmnesRegular';
}
.signFooter {
    padding: 22px 21px;
    position: relative;
}
.signFooter::before {
    top: 15px;
    bottom: 16px;
    position: absolute;
    left: 0px;
    content: '';
    height: 76px;
    width: 57px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('../images/line1.svg');
}
.signFooter::after {
    bottom: 0px;
    position: absolute;
    right: 6px;
    content: '';
    height: 92px;
    width: 137px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('../images/line2.svg');
}

.mailBoxMain {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 95vh;
}
.mailBoxUpr {
    text-align: center;
    margin-bottom: 5px;
}
.mailBox {
    display: inline-block;
    position: relative;
}
span.Union1 {
    position: absolute;
    left: 0px;
    top: 20px;
}
span.Union2 {
    position: absolute;
    right: 0px;
    top: 20px;
}
.mailBoxMain h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 114.21%;
    text-align: center;
    /* letter-spacing: -0.02em; */
    color: #914949;
    font-family: 'OmnesRegular';
    margin-bottom: 35px;
}
.chkMail label {
    margin-top: 0px;
    font-style: normal;
    font-weight: 800;
    font-size:28px;
    line-height: 103%;
    text-align: center;
    text-transform: capitalize;
    color: #8C4B49;
    margin-bottom: 3px;
    font-family: 'OmnesSemiBold';
    display: block;
}
.chkMail p {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 103%;
    text-align: center;
    letter-spacing: 0.04em;
    color: #904949;
    opacity: 0.7;
    margin-top: 13px;
    margin-bottom: 15px;
    font-family: 'OmnesMedium'; 
}
.scanMailTxt label {
    margin-bottom: 2px;
    display: block;
    font-style: normal;
    font-weight: 900;
    font-size: 28px;
    line-height: 103%;
    text-align: center;
    text-transform: uppercase;
    color: #8C4B49;
}
.scanMail {
    text-align: center;
    margin-bottom: 60px;
}
.scanMail div{
    width: 180px;
    height: 180px;
    background: rgba(144, 73, 73, 0.05);
    mix-blend-mode: multiply;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}
.scanMailTxt p {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 103%;
    text-align: center;
    letter-spacing: 0.04em;
    color: #904949;
    opacity: 0.7;
    margin-top: 13px;
    margin-bottom: 0px;
    font-family: 'OmnesMedium'; 
}
.scanMailTxt {
    position: relative;
}
.scanMailArow {
    position: relative;
    width: 200px;
    text-align: center;
    margin: 0 auto;
}
.scanMailArow::before {
    top: -52px;
    position: absolute;
    left: 0px;
    content: '';
    height: 76px;
    width: 45px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('../images/topArow2.png');
}


.record-button-bg{
height:calc(100vh - 100px);
width:100%
}
.bg-video{
    background-color: #000000;
    height: 100vh;
}
.render-actions__ActionsWrapper-sc-180d3oy-0, .sEcEU{
    bottom: 100px !important;
}
.videoClass{
    margin-bottom: 0px !important;
}
.survey_up.setposition h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: #4E4E4E;
    font-family: 'OmnesSemiBold';
    padding-left: 0px;
    text-align: center;
}
.surveyUpBg {
    text-align: center;
    width: 100%;
    background: #FFFFFF;
    padding: 30px 36px 38px 36px;
    border-radius: 0px 0px 20px 20px;
    min-height: 146px;
}
.surveyUpBgBack {
    text-align: center;
    width: 100%;
    background: #FFFFFF;
    padding: 28px 36px 0px 36px;
    border-radius: 20px 20px 0px 0px;
}
.surveyUpBgSuvery h1 {
    margin-bottom: 0px;
}
.surveyUpBg h1 {
    margin-bottom: 24px;
}
.surveyUpBgBack a img {
    position: absolute;
    top: 24px;
    left: 35px;
}
.survey_form .survey_textfield, 
.survey_form .MuiInput-underline.MuiInputBase-formControl {
    margin-left: 0px !important;
}
.all_children_close a {
    position: relative;
    display: inline-block;
    line-height: 25px;
}
/* .editInfo {
    display: none;
} */
/* .editInfoUpr:hover .editInfo {
    display: block;
} */
ul.editInfo {
    text-align: left;
    position: absolute;
    right: 0px;
    top: 22px;
    padding: 12px 8px;
    margin-bottom: 0px;
    min-width: 129px;
    background: #FFFFFF;
    z-index: 999999;
    box-shadow: -4px 4px 54px rgb(144 73 73 / 24%);
    border-radius: 12px 2px 12px 12px;
}
ul.editInfo li {
    list-style-type: none;
    border-radius: 34px;
    padding: 4px 9px;
}
ul.editInfo li a {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #242424 !important;
    font-family: 'OmnesMedium';
}
.all_children_section .all_children{
    background: #FFFFFF;
    border-radius: 20px;
    /* padding: 12px 13px; */
}
.all_children_close a {
    color: #242424;
    cursor: pointer;
    color: #000000;
}
.editInfo li:hover {
    background: #f9f6f6;
}
ul.editInfo li:last-child a {
    color: #FF5A6C !important;
}
.plusChildNW a {
    padding-bottom: 5px;
    color: #ffffff !important;
    width: 60.53px;
    height: 60.53px;
    background: #927BC4;
    border-radius: 60px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 38px;
    font-family: 'OmnesMedium';
    cursor: pointer;
}
.plusChildNW {
    text-align: center;
}

.all_children_rghtCstm {
    padding-left: 16px;
}
.checkboxCustom {
    opacity: 0;
    position: absolute;   
}
.checkboxCustom, .checkboxCustomLabel, .checkboxCustomLabel {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    margin-bottom: 0px !important;
}
.checkboxCustomLabel, .checkboxCustomLabel {
    position: relative;
    outline: none !important;
    border: none !important;
}
.checkboxCustom + .checkboxCustomLabel:before, .checkboxCustomLabel:before {
    content: '';
    background: transparent;
    border: 2px solid #f2eff4;
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    padding: 2px;
    margin-right: 10px;
    text-align: center;
    border-radius: 23px;
}
.rcheckboxCustom + .checkboxCustomLabel:before {
    border-radius: 50%;
}
.checkboxCustom:checked + .checkboxCustomLabel:before {
    content: "";
    background: url("../images/rghtTick.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #f2e5ea;
    border-color: #f2e5ea;
}
.checkbox-custom:focus + .checkboxCustomLabel, .checkboxCustom:focus + .checkboxCustomLabel {
  outline: 1px solid #ddd; 
}
.allChildrenUpr {
    background: #ffffff;
    padding-top: 4px;
    padding-bottom: 18px;
}

.newCustomSharePoint {
    justify-content: center;
    margin-top: 48px;
}

.customShrScrenTabsRel{
    padding-top: 90px;
}

.pending-clock{
    width: 20px;
    margin-right: 8px;
    margin-top: -4px;
}

.height-auto {
    height: auto !important;
}

.p-108 {
    padding-bottom: 108px !important;
}

.allChildrenUpr {
    background: #ffffff;
    position: relative;
    margin-bottom: 12px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 9px 10px;
    border-radius: 15px;
}
.allChildrenUpr .all_childrenChild {
    padding: 8px 12px;
    margin-bottom: 10px;
    margin-left: 65px;
    margin-right: 15px;
    position: relative;
    background: #fcfafa;
}
/* .allChildrenUpr .all_childrenChild:last-child {
    margin-bottom: 0px;
} */
.all_childrenChild .all_children_rght {
    padding-left: 20px;
}
.allChildrenUpr:after {
    content: "";
    min-width: 3px;
    background: #F4EEF6;
    position: absolute;
    bottom: 72px;
    left: 43px;
    top: 76px;
}
.all_childrenChild::after {
    text-align: center;
    background-repeat: no-repeat;
    background-image: url("../images/lineArow22.png");
    background-size: contain;
    content: "";
    min-height: 29px;
    min-width: 16px;
    position: absolute;
    left: -22px;
    bottom: 0px;
    top: 18px;
 }

.children_tab_container {
    display: flex;
    padding: 0px;
    margin-bottom: 0px;
    position: relative;
    justify-content: center;
    align-items: center;
}

.own-child{
    width: 100%;
    border: 1px solid #e9e9e9 !important;
    background-color: transparent;
    text-transform: capitalize;
    color: #4E4E4E;
    font-size: 15px;
    line-height: 17px;
    font-weight: 600;
    padding: 16px 5px;
    border-radius: 30px;
    margin: 0 auto !important;
    font-family: 'OmnesSemiBold';
    text-align: center;
    margin-top: 12px !important;
    min-height: 52px;
}

.cst_message p{
    font-weight: 600; 
    color: #914949; 
    cursor: default;
}

.cst_message a{
    color: #914949; 
    font-weight: 600;
    text-decoration:none; 
    cursor:pointer; 
}

.artBoard_allparadiv a span{ 
    color: #000000;
    font-weight: 600;
    cursor: pointer;
    text-decoration: underline !important;
}

.margin-left-five-per {
    margin-left: 5%;
}
.instant_redem2Upr {
    border-radius: 20px;
    background: #ffffff;
}
.instantRmvWht {
    background: transparent !important;
    padding: 0px !important;
    margin-top: 0px !important;
}
.instant_upr2Upr {
    margin-top: 0px !important;
}

.read-more-cst{
    font-size: 13px!important;
    font-weight: 600!important;
}


@media screen and (max-width: 767px) {
    .arrow_left, .arrow_rght {
       width: 371px !important;
    }
}

.tomme-coupon{
    font-size: 14px !important;
    opacity: 1!important;
    margin-top: 15px!important;
    font-weight: 600!important;
}

.instant_hend3 h5 {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    /* letter-spacing: -0.01em; */
    color: #7C5E8C;
    font-family: 'OmnesSemiBold';
    margin-top: 20px;
}
.margin-no-top{
    margin-top: 0px!important;
}
.cmnTabPrgrm label {
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    margin-top: 5px;
    margin-bottom: 6px;
    cursor: pointer;
    display: block;
    font-family: 'OmnesMedium';
}
.allTabClr label {
    color: #F47C6A;
}
.freebiesTabClr label {
    color: #66B0C4;
}
.contestTabClr label {
    color: #AE86E0;
}
.perksTabClr label {
    color: #719E7F;
}
.storyTabClr label {
    color: #C3C3C3;
}
.surveyTabClr label {
    color: #FDD500;
}
.discover_icon_divAdd{
    background: transparent !important;
    background-color: transparent !important;
    padding: 0px !important;
}
.discover_icon_divAdd img {
    width: 50px !important;
    top: 12%;
}
.sharegain_swiper .swiper-pagination-bullet {
    width: 5px !important;
    height: 5px !important;
    background: #FDD500 !important;
    opacity: initial !important;
}
.sharegain_swiper .swiper-pagination-bullet-active {
    background-color: #F47C6A !important;
}
.webMenu {
    background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%) !important;
}
.fixFotrBtn a {
    background: #F47C6A !important;
    border-radius: 30px !important;
}
a.giveAwayWrap, a.surveyMainWrap, .storyCardInfo a.instant_tags,
.surveyMainWrap {
    background: transparent;
    padding: 0px;
}
a.giveAwayWrap span.giveAwayTag {
    color: #AE86E0;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    font-family: 'OmnesMedium';
}
.giveAwayWrap img {
    height: 42px !important;
    width: 42px !important;
    margin-bottom: 0px !important;
}
.giveAwayWrapTxt label, .giveAwayWrapTxt p,
.freeBieWrapTxt label, .freeBieWrapTxt p,
.perkCardInfo .freebies_iner label, .perkCardInfo .freebies_iner p,
.survey_section .freebies_iner label, .survey_section .freebies_iner p,
.freebies_iner label, .freebies_iner p {
    color: #4E4E4E !important;
}
.giveAwayCard .sessionAddOns,
.freebieCard .sessionAddOns,
.perkCardInfo .sessionAddOns,
.sessionCntntDtails .sessionAddOns,
.storyCardInfo .sessionAddOns {
    box-shadow: 0px 0px 5px 0px #00000033 !important;
    background: linear-gradient(180deg, #FFFFFF 57.5%, #F2F2F2 100%) !important;
}
.giveAwayDetail .sim_section p, 
.giveAwayDetail .sim_section label,
.freebieDetail .sim_section p, 
.freebieDetail .sim_section label {
    color: #4E4E4E !important;
    opacity: initial !important;
}
.giveAwayDetail .descp_all label,
.freebieDetail .descp_all label {
    color: #242424 !important;
}
.giveAwayDetail .descp_all p,
.freebieDetail .descp_all p {
    color: #7f7f7f !important;
    opacity: 1 !important;
}
.giveAwayDetail .descp_all,
.freebieDetail .descp_all {
    border-left: 2px solid #f5f1f1;
}
.giveAwayDetail .ends_date p,
.freebieDetail .ends_date p {
    color: #AE86E0 !important;
    opacity: initial !important;
}
.giveAwayDetail .contest_process1 p,
.freebieDetail .contest_process1 p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #719E7F !important;
    opacity: 1 !important;
    font-family: 'OmnesRegular';
}
.giveAwayDetail .contest_opt button,
.freebieDetail .contest_opt button {
    background: #F47C6A !important;
    border-radius: 30px;
    min-height: 52px;
}
/* .giveAwayDetail .instant_upr2,
.freebieDetail .instant_upr2 {
    background: #FFFFFF;
} */
.giveAwayDetail .instant_upr2 {
    background: #FFFFFF;
}
.giveAwayDetail .declaration_instant2 p,
.freebieDetail .declaration_instant2 p {
    color: #8f8f8e;
}
.giveAwayDetail span.terms_all.terms_active,
.giveAwayDetail strong,
.freebieDetail span.terms_all.terms_active,
.freebieDetail strong  {
    color: #242424 !important;
    font-weight: 400 !important;
    font-family: 'OmnesRegular' !important;
}
.freebieDetail .one_social_ne1 p {
    color: #8f8f8e !important;
}
.freebieDetail .not_eligible_enter {
    color: #000000 !important;
}
.giveAwaySession .freebies_iner label,
.giveAwaySession .freebies_iner p,
.freebieSession .freebies_iner label,
.freebieSession .freebies_iner p,
.perks_dtl .perks_dtl_iner label,
.perks_dtl .perks_dtl_iner p  {
    color: #4E4E4E !important;
    opacity: initial !important;
}
.giveAwaySession .descp_freebies label,
.freebieSession .descp_freebies label,
.perks_dtl .perks_all label {
    color: #242424 !important;
}
.giveAwaySession .descp_freebies p, 
.freebieSession .descp_freebies p,
.perks_dtl .perks_all p {
    color: #7d7d7c !important;
    opacity: initial !important;
}
.giveAwaySession .ends_date p,
.freebieSession .ends_date p {
    color: #AE86E0 !important;
    opacity: initial !important;
}
.giveAwaySession .hometwo_redem_session button {
    background: #F47C6A !important;
    border-radius: 30px;
    box-shadow: 0px 4px 8px #f27d6ead;
}

.freebieMainWrap {
    background: transparent !important;
    padding: 0px !important;
}
a.freebieMainWrap img {
    height: 42px !important;
    width: 42px !important;
    margin-bottom: 0px;
}
a.freebieMainWrap span {
    color: #66B0C4;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    font-family: 'OmnesRegular';
}
a.freebieSesonWrap {
    background: transparent;
    padding: 0px;
}
.freebieSesonWrap img {
    height: 42px !important;
    width: 42px !important;
    margin-bottom: 0px !important;
}
a.freebieSesonWrap span.giveAwayTag {
    color: #66B0C4;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
}
.freebieDetail .not_eligible_add_child button {
    box-shadow: 0px 4px 8px #f27d6ead !important;
    background: #F47C6A !important;
    border-radius: 30px !important;
}
.completeYrEntry label {
    color: #4E4E4E;
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    display: inline-block;
    margin-bottom: 0px !important;
    font-family: 'OmnesSemiBold';
}
.phone_verify_backlinkCstm img {
    background-color: transparent !important;
    padding: 0px !important;
    height: 10px;
}
.phone_verify_backlinkCstm {
    top: 0px !important;
}
.enterMobCstm label {
    color: #F47C6A;
    font-size: 18px;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    margin-top: 22px;
    margin-bottom: 0px;
    font-family: 'OmnesSemiBold';
}
.contest_process1Cstm {
    margin-top: 20px;
}
.entrPhoneTxt p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    color: #242424;
    opacity: 0.5;
    margin-top: 16px;
    margin-bottom: 0px;
    padding: 0px 10px;
    font-family: 'OmnesRegular';
}
.contest_process1Cstm {
    background: rgb(242, 242, 242, 0.5);
    padding: 7px 8px;
    border-radius: 16px;
    min-height: 52px;
}
.contest_process1Cstm .phone_select_class:before {
    border-bottom: none !important;
}
.contest_process1Cstm input {
    border-bottom: none !important;
    color: #4E4E4E;
    font-size: 18px;
    line-height: 22px !important;
    font-weight: 500;
}
.contest_process1Cstm .MuiSelect-select.MuiSelect-selectMenu{
    color: #242424;
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
    font-family: 'OmnesMedium';
}
.contest_process1Cstm .contest_process1 input {
    color: #4E4E4E;
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
}
.instant_hend2Cstm {
    background: #fff;
    box-shadow: rgb(99 99 99 / 7%) 0px 2px 8px 0px;
}
.fiveone_ptsCstm span.numPts {
    top: 0px;
}
.fiveone_ptsCstm span.numPts b {
    font-size: 25px;
    font-weight: 900;
    display: block;
    margin-bottom: 4px;
    font-family: 'OmnesBold';
}
.instant_upr3Cstm {
    /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; */
    background: #FFFFFF;
    padding-bottom: 7px;
    box-shadow: 0px 1px 4px 0px #0000001A;
}
.instantDetailsSpc {
    margin-top: 20px;
}
img.discover_image.entered_tick_image {
    width: 210px;
    height: 210px;
    padding-top: 0px;
}
a.bonusTagNw {
    padding: 6px 10px 6px 6px;
    min-width: 85px;
    border: 1px solid #F2F2F2;
    background: transparent;
    border-radius: 30px;
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    flex-wrap: nowrap;
    margin-left: 10px;
}
a.bonusTagNw span {
    padding-top: 2px;
    color: #4E4E4E;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    vertical-align: middle;
    font-family: 'OmnesMedium';
}
a.bonusTagNw img {
    margin-bottom: 0px;
    height: 24px;
    width: 24px;
    margin-right: 3px;
    vertical-align: middle;
}
a.entrPointUpdte {
    position: absolute;
    top: 1px;
    right: 0px;
    background: #F47C6A !important;
    padding: 4px !important;
    width: 37px;
    height: 37px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    border-radius: 50px !important;
}
a.entrPointUpdte label {
    font-size: 16px;
    line-height: 13px;
    margin-bottom: 2px;
    color: #fff;
    font-weight: 800;
    display: block;
}
a.entrPointUpdte span {
    font-size: 12px;
    line-height: 12px;
    color: #fff;
    font-weight: 400;
    display: block;
}
.instantDetailsRw {
    position: relative;
}
.commonMainInerBg{
    /* background: linear-gradient(180deg, #FFFFFF 0%, #F2F2F2 81.25%) !important; */
    outline: none;
}
.discover_headLeft2 {
    margin: 0px 10px;
    padding: 2px 12px;
    padding-top: 16px;
}
.buildFamilyMain {
    margin-top: 12% !important;
}
.prent_submit button.MuiButton-textPrimary {
    background: #F47C6A !important;
    border-radius: 30px;
    /* box-shadow: 0px 2px 7px 2px rgba(166,124,124,0.44); */
}
.buildFamilyUpr img {
    height: 136px !important;
    width: auto !important;
    cursor: pointer;
}
.expct_child label {
    color: #AE86E0 !important;
}
.expctChildLast label {
    color: #719E7F !important;
}
.unchild_infoBg2 {
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    border-radius: 50px;
}
.unknwnchild_contentNw img.popup_close_button {
    height: 10px;
    width: 10px;
}
.unknwnchild_contentNw .unknwnchild_head {
    margin-top: 12px;
}
button.ntc_btnCncl {
    margin: 0 auto;
    width: 100%;
    outline: none !important;
    padding: 0px !important;
    margin-top: 16px;
    background: transparent !important;
    background-color: transparent !important;
    display: inline-block !important;
}
button.ntc_btnCncl span {
    color: #4E4E4E !important;
    font-family: 'OmnesSemiBold';
    font-weight: 600;
    font-size: 14px !important;
    line-height: 20px;
    opacity: 0.5;
    text-transform: capitalize;
    outline: none !important;
}
.insideSel1{
    color: #F47C6A !important;
    opacity: initial !important;
}
.insideSel2{
    color: #FFD200 !important;
    opacity: initial !important;
}
.insideSel3{
    color: #AE86E0 !important;
    opacity: initial !important;
}
.insideSel4{
    color: #66B0C4 !important;
    opacity: initial !important;
}
.extragainTabsCustm a.nav-link {
    padding: 0px !important;
    background: transparent !important;
}
.extragainTabsCustm ul.nav.nav-tabs {
    background: transparent !important;
}
.extraTabDtl {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: block;
    width: 100%;
    margin-right: 11px;
}
.extraTabDtl img, .extraTabDtl label {
    display: block;
}
.extraTabDtl label {
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    margin-top: 8px;
    margin-bottom: 0px;
    color: #66B0C4;
    font-family: 'OmnesMedium';
}
.extraTabDtl2 label {
    color: #AE86E0 !important;
}
.add_familySocial3 {
    width: 100% !important;
}
.profileHead {
    display: flex;
    width: 100%;
}
.andres_verified {
    padding-left: 28px;
    width: 80%;
}
.countPoints {
    text-align: right;
    margin-bottom: 5px;
}
.countPoints label, .countPoints span {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    /* font-family: 'OmnesSemiBold'; */
    font-family: 'OmnesBold';
}
.countPoints label {
    color: #f47c6a;
}
.countPoints span {
    color: #fdd500;
}
a.addFmlyNw button { 
    color: #fff;
    outline: none !important;
    padding: 9px 16px;
    background: #F47C6A !important;
    border-radius: 30px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: initial;
    width: 100%;
    min-height: 52px;
    min-width: 143px;
    font-family: 'OmnesSemiBold';
}
.help_labelNw{
    text-align: center;
}
.perks_titleNw {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #4E4E4E;
    opacity: 0.5;
    font-family: 'OmnesMedium';
}
.sign_backlinkNw { 
    /* background: #F2F2F2;
    width: 34px;
    height: 34px; */
    background: transparent;
    width: 24px !important;
    height: 24px !important;
    min-height: initial !important;
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    z-index: 999;
    cursor: pointer;
}
/* .sign_backlinkNw img {
    width: 11px;
} */
.signup_form input[type="text"]::-webkit-input-placeholder,
.signup_form input[type="email"]::-webkit-input-placeholder { 
    color: rgb(36, 36, 36, 0.5);
}
.signup_form input[type="text"]::-moz-placeholder,
.signup_form input[type="email"]::-moz-placeholder { 
    color: rgb(36, 36, 36, 0.5);
}
.signup_form input[type="text"]:-ms-input-placeholder,
.signup_form input[type="email"]:-ms-input-placeholder { 
    color: rgb(36, 36, 36, 0.5);
}
.signup_form input[type="text"]:-moz-placeholder,
.signup_form input[type="email"]:-moz-placeholder { 
    color: rgb(36, 36, 36, 0.5);
}
.ExpectantLbl label {
    color: #AE86E0 !important;
}
.ExistingLbl label {
    color: #719E7F !important;
}

.infoEntry p {
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    color: #242424;
    opacity: 0.5;
    font-family: 'OmnesRegular';
}
.infoEntry {
    text-align: center;
    margin-top: 32px !important;
}
.addrelative_tabs li.nav-item:first-child a.active.nav-link {
    color: #AE86E0 !important;
}
button#allchild_grow {
    background: #F47C6A !important;
    color: #ffffff !important;
    border-radius: 30px;
}

.all_children_close a span {
    cursor: pointer;
    color: #000000 !important;
    font-size: 30px;
    font-family: 'OmnesRegular';
}
.extragain_tabs.fix_main .nav-link {
    opacity: initial !important;
}
.perksTagBtn img {
    height: 42px !important;
    width: 42px !important;
    margin-bottom: 0px;
    margin-right: 7px;
}
.perksTagBtn {
    margin-right: 8px;
    color: #739e80 !important;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    font-family: 'OmnesMedium';
    background-color: transparent !important;
    background: transparent !important;
}
.perks_dtl .deal_redem button {
    background: #F47C6A !important;
    border-radius: 30px;
    /* box-shadow: 0px 4px 8px #f27d6ead; */
}
.perks_dtl span.terms_all{
    color: #000000 !important;   
}
.perks_dtl .deal_frrebies {
    color: #000000 !important;
    opacity: 50%;
}
.perks_dtl .perks_loc p {
    color: #000000 !important;
    font-family: 'OmnesRegular';
}
.contests_content .extra_contmain {
    box-shadow: 0px 0px 5px 0px #00000033;
    background: linear-gradient(180deg, #FFFFFF 57.5%, #F2F2F2 100%);
}
.extragainTabsCustm a.nav-link {
    opacity: initial !important;
}
.extragainTabsCustm a.active.nav-link {
    border: 0px !important;
}
.extragainTabsCustm {
    margin-bottom: 28px;
}
.perks_dtl .deal_frrebies span {
    display: inline-block;
}
.extra_fremain {
    box-shadow: 0px 0px 5px 0px #00000033 !important;
    background: linear-gradient(180deg, #FFFFFF 57.5%, #F2F2F2 100%) !important;
}
.social_activityleft label, .social_activityright label {
    margin-bottom: 15px !important;
}
.social_activityleft p {
    padding-top: 12px;
}
.bonuseInfo {
    text-align: center;
    background: rgba(174, 134, 224, 0.4);
    border-radius: 15px;
    padding: 14px 12px 18px 12px;
}
.bonuseInfo h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    margin-top: 4px;
    margin-bottom: 4px;
}
.bonuseInfo p {
    font-weight: 400;
    margin-bottom: 0px;
    font-size: 14px;
    line-height: 17px;
}
.enterGiveUp p {
    color: #4E4E4E;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    margin: 19px 10px;
}
button.explore_div {
    background-color: #ffffff !important;
    opacity: 1 !important;
    border: 1px solid #e5e5e5;
    min-height: 52px;
    margin-bottom: 0px !important;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    font-family: 'OmnesSemiBold';
    color: rgb(78, 78, 78, 0.8) !important;
}

.freebie_redeeemedNw {
    background: #ffffff;
    border-radius: 20px;
    /* padding: 28px 28px; */
    padding: 24px 24px;
    box-shadow: 0px 1px 4px 0px #0000001A;
}
.lend_hendNw {
    text-align: center;
    width: 100%;
    border-radius: 20px;
}
.lend_hendNw h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: #66B0C4;
    margin-top: 5px;
    font-family: 'OmnesSemiBold';
}
.lend_hendNw p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    color: #4E4E4E;
    margin-bottom: 0px;
    font-family: 'OmnesRegular';
}
/* .plus_connect_uprNw {
    padding: 24px 18px;
    background: rgb(251,245,245);
} */
.familyshare_submitNw {
    text-align: center;
}
.familyshare_submitNw button {
    outline: none !important;
    padding: 12px 34px;
    border-radius: 30px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
    color: #4E4E4E;
    margin-top: 18px;
    min-height: 52px;
    font-family: 'OmnesSemiBold';
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #ffffff !important;
}
.detailsSocial_shareNw {
    padding-top: 26px;
    display: flex;
    justify-content: space-between;
}
.detailsSocial_shareNw p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #4E4E4E;
    margin-bottom: 0px;
    padding: 15px 0px;
    font-family: 'OmnesSemiBold';
}
.detailsSocial_shareNw a {
    cursor: pointer;
}
.program-pageNw img.social_nw {
    width: 52px;
}
.detailsSocial_shareNw a img {
    height: 40px;
    width: 40px !important;
}
.detailsSocial_shareNw div a:first-child {
    margin-right: 5px;
} 
.social_activright label b,
.social_activright label strong {
    font-weight: 700 !important;
    line-height: 17px;
    font-family: 'OmnesBold' !important;
}
.social_activright label b {
    color: #AE86E0 !important;
}
.social_activright label strong {
    color: #FFDD3F !important;
}
.signErorMsg {
    color: #AE86E0 !important;
}
.editUsrInfo {
    margin-bottom: 12px;
}

.editUsrInfo input[type="text"]::-webkit-input-placeholder,
.editUsrInfo input[type="email"]::-webkit-input-placeholder,
.editUsrInfo input[type="date"]::-webkit-input-placeholder,
.editUsrInfoMask input::-webkit-input-placeholder,
.editUsrInfo textarea::-webkit-input-placeholder,
.rm_transform input#select_date::-webkit-input-placeholder { 
    color: rgb(36, 36, 36, 0.3);
}
.editUsrInfo input[type="text"]::-moz-placeholder,
.editUsrInfo input[type="email"]::-moz-placeholder,
.editUsrInfo input[type="date"]::-moz-placeholder,
.editUsrInfoMask input::-moz-placeholder,
.editUsrInfo textarea::-moz-placeholder,
.rm_transform input#select_date::-moz-placeholder { 
    color: rgb(36, 36, 36, 0.3);
}
.editUsrInfo input[type="text"]:-ms-input-placeholder,
.editUsrInfo input[type="email"]:-ms-input-placeholder,
.editUsrInfo input[type="date"]:-ms-input-placeholder,
.editUsrInfoMask input:-ms-input-placeholder,
.editUsrInfo textarea:-ms-input-placeholder,
.rm_transform input#select_date:-ms-input-placeholder { 
    color: rgb(36, 36, 36, 0.3);
}
.editUsrInfo input[type="text"]:-moz-placeholder,
.editUsrInfo input[type="email"]:-moz-placeholder,
.editUsrInfo input[type="date"]:-moz-placeholder,
.editUsrInfoMask input:-moz-placeholder,
.editUsrInfo textarea:-moz-placeholder,
.rm_transform input#select_date:-moz-placeholder { 
    color: rgb(36, 36, 36, 0.3);
}

.editUsrInfo input, 
.editUsrInfo input[type="text"], 
.editUsrInfo input[type="email"],
.editUsrInfo input[type="date"],
.editUsrInfoMask input,
.editUsrInfo textarea {
    max-width: 100% !important;
    width: 100% !important;
    min-width: 100% !important;
    border: 1px solid rgba(0, 0, 0, 0.1);
    outline: none;
    font-stretch: normal;
    letter-spacing: normal;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    padding: 14px 15px;
    border-radius: 15px;
    margin-bottom: 0px;
    background: #ffffff;
    min-height: 52px;
    resize: none;
    color: rgb(36, 36, 36, 0.5);
    font-family: 'OmnesMedium';
}
.editUsrInfoSuprt textarea { 
    min-height: 150px;
}
.editUsrInfo label { 
    color: #242424 !important;
    display: block;
    text-align: left;
    opacity: 0.3;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 5px !important;
    font-family: 'OmnesMedium';
}
.editAddresUsr h3 {
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    color: #4E4E4E;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    cursor: pointer;
    margin-top: 0px;
    font-family: 'OmnesSemiBold';
}
.editAddresUsr {
    margin-top: -4px;
}
.profileBkImg {
    /* background-color: #f1ece6;
    width: 34px;
    height: 34px; */
    background-color: transparent;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    padding-right: 3px;
}
.signup_passChng {
    text-align: center;
    margin-top: 71px;
}
.usrChngPassInr .MuiInput-underline:before,
.usrChngPassInr .MuiInput-underline:after,
.signup_password .MuiInput-underline:before,
.signup_password .MuiInput-underline:after {
    border-bottom: none !important;
    outline: none !important;
}
.usrChngPassInr input,
.show_password input[type="password"],
.show_password input[type="text"],
.signup_pass input,
.restorepass_form input {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #ffffff !important;
    color: #4E4E4E;
    outline: none;
    font-stretch: normal;
    letter-spacing: normal;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    padding: 14.32px 17px;
    padding-right: 40px;
    border-radius: 15px;
    font-family: 'OmnesMedium';
}
.usrChngPassInr div svg, .usrChngPassInr div img {
    position: absolute;
    right: 10px !important;
    top: 20px !important;
    color: #7a3e41 !important;
}
.usrChngPassInr label {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #4E4E4E;
    display: block;
    text-align: left;
    margin-bottom: 8px;
    font-family: 'OmnesSemiBold';
}
.usrChngPassInr {
    margin-bottom: 20px;
}
.usrChngPassUpr {
    /* margin-top: 30px; */
    outline: none;
}
.usrChngPassUpr button {
    margin-top: 45px;
}

.usrChngPassInr input[type="text"]::-webkit-input-placeholder,
.usrChngPassInr input[type="password"]::-webkit-input-placeholder,
.show_password input[type="password"]::-webkit-input-placeholder,
.show_password input[type="text"]::-webkit-input-placeholder,
.signup_pass input::-webkit-input-placeholder,
.restorepass_form input::-webkit-input-placeholder { 
    color: #242424;
}
.usrChngPassInr input[type="text"]::-moz-placeholder,
.usrChngPassInr input[type="password"]::-moz-placeholder,
.show_password input[type="password"]::-moz-placeholder,
.show_password input[type="text"]::-moz-placeholder,
.signup_pass input::-moz-placeholder,
.restorepass_form input::-moz-placeholder { 
    color: #242424;
}
.usrChngPassInr input[type="text"]:-ms-input-placeholder,
.usrChngPassInr input[type="password"]:-ms-input-placeholder,
.show_password input[type="password"]:-ms-input-placeholder,
.show_password input[type="text"]:-ms-input-placeholder,
.signup_pass input:-ms-input-placeholder,
.restorepass_form input:-ms-input-placeholder { 
    color: #242424;
}
.usrChngPassInr input[type="text"]:-moz-placeholder,
.usrChngPassInr input[type="password"]:-moz-placeholder,
.show_password input[type="password"]:-moz-placeholder,
.show_password input[type="text"]:-moz-placeholder,
.signup_pass input:-moz-placeholder,
.restorepass_form input:-moz-placeholder { 
    color: #242424;
}
.usrChngPassUpr .signup_password p {
    color: #AE86E0 !important;
    opacity: initial !important;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    font-family: 'OmnesRegular';
}
.settingInfo .usr_setting label {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #4E4E4E;
}
.usr_setting2 {
    margin-top: 20px;
    margin-bottom: 24px;
}
.usr_setting2 label {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #4E4E4E;
    display: block;
    margin-bottom: 5px;
    font-family: 'OmnesSemiBold';
}
.usr_setting2 p a {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: rgb(36, 36, 36, 0.5) !important;
    cursor: pointer;
    display: block;
    text-align: center;
    width: 100%;
    padding: 15px 12px;
    font-family: 'OmnesMedium';
}
.settings_menu2 {
    background-color: #ffffff;
    border-radius: 15px;
    border: 1px solid #e5e5e5;
}
.usr_setting2 p {
    margin-bottom: 0px !important;
    line-height: 18px;
}
.usr_setting2 p span {
    min-width: 28px;
    display: inline-block;
}
.userlogout2 {
    border-bottom: none !important;
}
.connectSocial a {
    display: inline-block;
    margin-right: 10px;
    cursor: pointer;
}
.userlogout2Btn button {
    background: #ffffff !important;
    outline: none !important;
    display: inline-block;
    border: 1px solid rgba(0, 0, 0, 0.1);
    min-width: 85px;
    min-height: 52px;
    color: #4E4E4E;
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;
    border-radius: 30px;
    margin-top: 36px;
    font-weight: 500;
    font-family: 'OmnesMedium';
}
.connectSocial {
    margin-top: 10px;
}
.setting_mainInner {
    /* padding: 8px 22px 0px; */
    padding-top: 0px;
}
.setting_notify {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #fdf2f0;
    border-radius: 15px;
    margin-bottom: 10px;
    min-height: 52px;
    padding: 12px 14px;
    width: 100% !important;
}
.usr_setting label {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #4E4E4E;
    display: block;
    margin-bottom: 0px;
    font-family: 'OmnesSemiBold';
}
.usr_setting p {
    margin-bottom: 0px;
    line-height: 18px;
}
.usr_setting p a {
    font-style: normal;
    font-size: 18px;
    line-height: 20px;
    color: #242424 !important;
    cursor: pointer;
    font-weight: 500;
    font-family: 'OmnesMedium';
}
.setting_main {
    background: #fff;
    border: 1px solid #f5f5f5fc;
    /* padding-left: 0px;
    padding-right: 0px; */
}
.usr_setting {
    /* padding: 0px 22px;  */
    margin-top: 0px;
}
.usr_setting p span {
    min-width: 28px;
    display: inline-block;
}
.usr_setting label:first-child {
    margin-bottom: 8px;
}
 .cbx {
    -webkit-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
  }
  .cbx span {
    display: inline-block;
    vertical-align: middle;
    transform: translate3d(0, 0, 0);
  }
  .cbx span:first-child {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    transform: scale(1);
    vertical-align: middle;
    border: 1px solid #B9B8C3;
    transition: all 0.2s ease;
  }
  .cbx span:first-child svg {
    position: absolute;
    z-index: 1;
    top: 7px;
    left: 5px;
    fill: none;
    stroke: #000000;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 16px;
    stroke-dashoffset: 16px;
    transition: all 0.3s ease;
    transition-delay: 0.1s;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .cbx span:first-child:before {
    content: "";
    width: 100%;
    height: 100%;
    background: #506EEC;
    display: block;
    transform: scale(0);
    opacity: 1;
    border-radius: 50%;
    transition-delay: 0.2s;
  }
  .cbx span:last-child {
    margin-left: 8px;
  }
  .cbx:hover span:first-child {
    border: 1px solid #B9B8C3 !important;
  }
  .inp-cbx:checked + .cbx span:first-child {
    border-color: #f47c6a;
    background: #F47C6A;
    animation: check 0.6s ease;
  }
  .inp-cbx:checked + .cbx span:first-child svg {
    stroke-dashoffset: 0;
  }
  .inp-cbx:checked + .cbx span:first-child:before {
    transform: scale(2.2);
    opacity: 0;
    transition: all 0.6s ease;
  }
  .inp-cbx:checked + .cbx span:last-child {
    color: #B9B8C3;
    transition: all 0.3s ease;
  }
  .inp-cbx:checked:disabled + .cbx span:first-child {
    background: #fee6e4 !important;
    border: 1px solid rgba(36, 36, 36, 0.5);
  }
  .inp-cbx:checked:disabled + .cbx span:first-child svg {
    display: none !important;
  }
  .settingCheckedNo p a,
  .settingdisabled p a {
    opacity: 0.5;
  }
  .acuntStatus {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 26px 0px 26px;
  }
  .acuntStatus label {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 0px;
    font-family: 'OmnesSemiBold';
  }
  .settingActvAc a {
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: #AE86E0 !important;
    border: 1px solid #AE86E0;
    min-height: 52px;
    min-width: 156px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    font-family: 'OmnesSemiBold';
  }
  .settingClsAc {
    text-align: center;
  }
  .settingClsAc a {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #ffffff !important;
    color: #4E4E4E !important;
    outline: none;
    font-stretch: normal;
    letter-spacing: normal;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    padding: 15px 17px;
    border-radius: 30px;
    font-family: 'OmnesMedium';
    display: inline-flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    width: 90%;
    margin: 0 auto;
    cursor: pointer;
}
.settingClsAc p {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #4E4E4E;
    opacity: 0.5;
    margin-top: 4px;
    margin-bottom: 0px;
    font-family: 'OmnesMedium';
}
.ntc_headInr p {
    font-weight: 400;
    display: block;
    margin: 0px;
    color: #242424;
    opacity: 0.5;
    font-size: 14px;
    line-height: 17px;
    font-family: 'OmnesRegular';
}
.noticAgreeInput span:first-child {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    -webkit-transform: scale(1);
    transform: scale(1);
    vertical-align: middle;
    border: 1px solid #B9B8C3;
    transition: all 0.2s ease;
}
.noticAgreeInput label.cbx span {
    margin-left: 0px !important;
    background: #fef2f2;
}
.noticAgreeInput {
    display: flex;
    align-content: flex-start;
    text-align: left;
}
.noticAgreeInput p {
    padding-left: 9px;
}
input.notice_warning_text {
    margin-top: 5px;
    width: 100%;
    border: 1px solid #e5e5e5;
    color: #242424;
    outline: none;
    font-stretch: normal;
    letter-spacing: normal;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    padding: 15px 17px;
    border-radius: 15px;
    font-family: 'OmnesMedium';
    min-height: 52px;
}
.notice_warningcstm p {
    font-size: 16px;
    line-height: 18px;
} 
.notice_warningcstm p span {
    color: #AE86E0
}
.closeEmailAcInr label {
    display: block;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #4E4E4E;
    margin-bottom: 4px;
    font-family: 'OmnesSemiBold';
}
.closeEmailAcInr h4 {
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #242424;
    margin-bottom: 0px;
    opacity: 0.5;
    font-family: 'OmnesRegular';
}
.closeEmailAcInr p {
    display: block;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #4E4E4E;
    margin-bottom: 6px;
    font-family: 'OmnesSemiBold';
}
.closeEmailAcInr {
    text-align: center;
}
.closeEmailAcInr img {
    margin-top: 15px;
    margin-bottom: 14px;
}
input#select_date {
    font-family: 'OmnesRegular' !important;
}
.add_emails h3 {
    color: #AE86E0;
    text-align: left;
    font-size: 16px;
    margin-bottom: 12px;
    font-family: 'OmnesMedium';
}
.gainextra_formFull .error_message {
    color: #AE86E0 !important;
    margin-top: 10px !important;
}

.setng_content {
    display: flex;
    padding: 13px 20px;
    background: #ffffff;
    border-radius: 30px;
    margin-bottom: 15px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
}
.setng_contentLeft {
    width: 70%;
}
.setng_contentLeft span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #4E4E4E;
    font-family: 'OmnesMedium';
}
.setng_contentRght {
    width: 30%;
    text-align: right;
}
.setng_contentRght a {
    cursor: pointer;
}
.setng_contentRght img {
    width: 8px;
}
.setng_contentUpr label {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #4E4E4E;
    display: block;
    margin-bottom: 5px;
    font-family: 'OmnesSemiBold';
}
.settingBkIcon {
    z-index: 999999 !important;
}
.phoneEntrMsg p {
    color: #719E7F !important;
    opacity: 1 !important;
    text-align: left;
    font-family: 'OmnesMedium';
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    margin-top: 2px !important;
}
.verifyCodeUpr .phone_verify_backlink img {
    background: transparent;
}
.verificationCode label {
    color: #242424 !important;
    opacity: 0.5 !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: block;
    text-align: left;
}
.signup_form .signup_first, .signup_form .signup_last,
.signup_pass input {
    width: 100%;
    margin-bottom: 8px;
    margin-top: 4px;
}
.verificationCode label {
    font-weight: 400;
    size: 14px;
    line-height: 20px;
    display: block;
    margin-bottom: 2px;
    font-family: 'OmnesRegular';
}
.verificationCode input {
    width: 100%;
    border: none;
    background: rgb(242, 242, 242, 0.5);
    color: #242424;
    outline: none;
    font-stretch: normal;
    letter-spacing: normal;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 19px !important;
    padding: 15.32px 17px !important;
    border-radius: 15px !important;
    font-family: 'OmnesMedium';
}
.verificationCode .MuiInput-underline:before,
.verificationCode .MuiInput-underline:after {
    border-bottom: 0px !important;
    outline: none !important;
}
.verificationCode .MuiFormControl-root.MuiTextField-root {
    width: 100% !important;
    max-width: 100% !important;
}
.verfiyCodeInfo p, .verfiyCodeInfo span {
    font-weight: 500;
    color: #AE86E0;
    opacity: 1 !important;
    size: 14px;
    line-height: 19px;
    font-family: 'OmnesMedium';
}
.verfiyCodeInfo .resend_active p,
.verfiyCodeInfo .resend p {
    text-align: left;
}
.verfiyCodeInfo .resend_active span {
    display: inline-block !important;
}
.loginFormField input {
    opacity: initial !important;
    width: 100%;
    border: 1px solid #e5e5e5;
    color: #242424;
    outline: none;
    font-stretch: normal;
    letter-spacing: normal;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    padding: 15px 17px;
    border-radius: 15px;
    font-family: 'OmnesMedium';
}
.loginFormField input::-webkit-input-placeholder { 
    color: rgb(36, 36, 36, 0.5);
}
.loginFormField input:-ms-input-placeholder { 
    color: rgb(36, 36, 36, 0.5);
}
.loginFormField input::placeholder {
    color: rgb(36, 36, 36, 0.5);
}
.loginFormField .MuiInput-underline:before,
.loginFormField .MuiInput-underline:after {
    border-bottom: 0px !important;
    outline: none !important;
}
.loginYourAc h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 27px;
    text-align: center;
    color: #4E4E4E;
    margin-bottom: 0px;
    font-family: 'OmnesSemiBold';
}
.loginFormCstm {
    margin-top: 42px;
}

.homeSession_upr {
    padding-bottom: 130px;
    border-radius: 20px;
    margin-top: 25px;
}
.session_hendupr {
    text-align: center;
    width: 100%;
    background: #FFFFFF;
    padding: 20px 24px 22px;
    border-radius: 20px;
    box-shadow: 0px 0px 4px 0px #0000001A;
}
.session_hend h3 {
    color: #242424;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    margin: 14px 0px 6px;
    font-family: 'OmnesSemiBold';
}
.session_hend p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    color: #242424;
    opacity: 0.5;
    margin-bottom: 0px;
    font-family: 'OmnesRegular';
}
a.cat1NonAct img {
    height: 24px;
    width: auto !important;
}
a.cat2NonAct img {
    height: 24px;
    width: auto !important;
}
a.cat3NonAct img {
    height: 24px;
    width: auto !important;
}
a.cat4NonAct img {
    height: 22px;
    width: auto !important;
}
ul.perks_nav li.nav-item a.active.nav-link {
    background: rgb(115, 158, 128) !important;
    color: rgb(255, 255, 255) !important;
}
.rsp_model img.popup_close_button {
    height: 10px !important;
    width: 10px !important;
}
button.rspView {
    margin-top: 7px;
    background: #F47C6A !important;
    color: #FFF;
    outline: none !important;
    padding: 15px 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    text-transform: capitalize;
    width: 100%;
    border-radius: 30px;
    min-height: 52px;
    font-family: 'OmnesMedium';
}
.profileApply button {
    color: #ffffff;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    background: #F47C6A !important;
    border-radius: 30px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
    height: 52px;
    margin-top: 22px;
    padding: 10px 10px;
    box-shadow: 0 18px 20px -18px #FF5A6C;
}
.addonePhone { 
    color: #ffffff;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    background: #F47C6A !important;
    border-radius: 30px;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    height: 52px;
    margin-top: 0px;
    margin-bottom: 12px;
    padding: 10px 10px;
}
.freebiesImges {
    position: relative;
}
img.giveAwayRight {
    height: 60px;
    width: 60px;
    position: absolute;
    right: -10px;
    top: -12px;
    border: 1px solid #F2F2F2;
    border-radius: 50px;
    z-index: 1;
}
.survey_section {
    position: relative;
}
.survey_section img.giveAwayRight {
    z-index: 1111;
    right: 12px;
    top: 9px;
}
.extragain_tabs.fix_main.fixed-header {
    z-index: 999999;
}
a.surveyMainWrap span {
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    color: #FDC500;
    font-family: 'OmnesMedium';
}
a.surveyMainWrap img {
    height: 42px !important;
    width: 42px !important;
    margin-bottom: 0px !important;
}
.enterdIcon {
    margin-bottom: 5px;
}
.editDobusr input {
    box-shadow: none !important;
    outline: none !important;
}
.editUsrInfo.editDobusr {
    margin-bottom: 6px;
}
.editUsrInfo.editDobusr input#select_date {
    box-sizing: border-box !important;
}
.editDobusr .MuiFormHelperText-root.Mui-error{
    color: #AE86E0 !important;
    text-align: left;
    font-size: 16px;
    margin-bottom: 12px;
    font-family: 'OmnesRegular';
}
.verification_process1Cstm p.error_message {
    color: #AE86E0 !important;
    font-family: 'OmnesRegular' !important;
    margin-bottom: 8px;
}
.extragain_tabs.fix_main {
    margin-top: 5px;
}


.wantFreebieUpr {
    /* background: #fdf7f7; */
    border-radius: 20px;
}
.wantFreebieInr {
    background: #faeeed;
    border-radius: 20px;
    margin-top: 25px;
}
.wantFreebieSocl {
    background: #fdf7f6;
    border-radius: 20px 20px;
}
.wantFreebieInstnt {
    background: #ffffff;
    border-radius: 20px;
    box-shadow: 0px 1px 4px 0px #0000001A;
}
.wantFreeInstntInr {
    text-align: center;
    width: 100%;
    background: #FFFFFF;
    padding: 28px 36px 32px;
    border-radius: 20px;
}
.wantFreeInstntInr h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    color: #66B0C4;
    margin-top: 14px;
    margin-bottom: 12px;
    font-family: 'OmnesSemiBold';
}
.wantFreeInstntInr p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    color: #4E4E4E;
    margin-bottom: 0px;
    font-family: 'OmnesMedium';
}
.freeBeiEligibBtn button {
    color: #fff;
    outline: none!important;
    padding: 14px 34px;
    background: #F47C6A !important;
    border-radius: 30px;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    align-items: center;
    text-transform: capitalize;
    width: 100%;
    margin-top: 25px;
    min-height: 52px;
    font-family: 'OmnesSemiBold';
}
.wantFreeImg img {
    padding: 0px 4px;
}
.extragain_tabs.addrelative_tabs a.nav-link {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    font-family: 'OmnesSemiBold';
}
span.inviteparentIcon {
    display: inline-block;
    margin-top: 8px;
    margin-bottom: 2px;
}
.point_benefits h2 { 
    /* margin-top: 48px; */
    outline: none;
}
.point_benefits {
    margin-bottom: 28px;
}
.addrelation_formCstm input[type="text"],
.addrelation_formCstm input[type="email"] {
    width: 100%;
    border: 1px solid #e5e5e5;
    color: #242424;
    outline: none;
    font-stretch: normal;
    letter-spacing: normal;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    padding: 15px 17px;
    border-radius: 15px;
    font-family: 'OmnesMedium';
    margin-bottom: 18px;
    margin-top: 0px;
}
.addrelation_formCstm input[type="text"]::-webkit-input-placeholder,
.addrelation_formCstm input[type="email"]::-webkit-input-placeholder { 
    color: rgb(36, 36, 36, 0.5);
}
.addrelation_formCstm input[type="text"]::-moz-placeholder,
.addrelation_formCstm input[type="email"]::-moz-placeholder { 
    color: rgb(36, 36, 36, 0.5);
}
.addrelation_formCstm input[type="text"]:-ms-input-placeholder,
.addrelation_formCstm input[type="email"]:-ms-input-placeholder { 
    color: rgb(36, 36, 36, 0.5);
}
.addrelation_formCstm input[type="text"]:-moz-placeholder,
.addrelation_formCstm input[type="email"]:-moz-placeholder { 
    color: rgb(36, 36, 36, 0.5);
}
.addrelation_formCstm .clearimg {
    bottom: 33px;
}
.myChildLblSecCstm {
    margin-top: 0px;
}
.signup_submitCstm button#add_relative_continue {
    margin-top: 10px;
}
.myChildLblSecCstm label {
    font-weight: 600;
    font-size: 24px;
    line-height: 27px;
    font-family: 'OmnesSemiBold';
    color: #4E4E4E !important;
}
.myChildLblSecCstm .myChildInput {
    width: 100%;
    border: 1px solid #e5e5e5;
    color: #242424;
    outline: none;
    font-stretch: normal;
    letter-spacing: normal;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    padding: 15px 17px;
    border-radius: 15px;
    font-family: 'OmnesMedium';
    margin-bottom: 18px;
    margin-top: 0px;
}
.myChildLblSecCstm .myChildInput p {
    color: rgb(36, 36, 36, 0.5);
}
.myChildLblSecCstm img.selectArow {
    top: 7px;
}
.selectRelationModelCstm .modal-content {
    background: #fff !important;
    width: 95%;
    margin: 0 auto;
    padding: 15px 15px 0px;
}
.txtFieldRght img {
    right: 0px;
    top: -18px;
    position: absolute;
    z-index: 999;
    cursor: pointer;
}
.selectRelationBtnCstm {
    position: relative;
}
.relBtnGrp {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}
.selectRelationBtnCstm button {
    border: 1px solid rgb(0, 0, 0, 0.1);
    border-radius: 30px;
    text-transform: capitalize;
    outline: none !important;
    padding: 14px 9px;
    width: 31%;
    min-height: 52px;
    margin-bottom: 12px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #4E4E4E;
    font-family: 'OmnesSemiBold';
}
.txtFieldLft label {
    font-weight: 600;
    font-size: 24px;
    line-height: 27px;
    margin-top: 14px;
    margin-bottom: 10px;
    display: block;
    text-align: center;
    font-family: 'OmnesSemiBold';
}
.selRelLbl label {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #242424;
    opacity: 0.5;
    display: block;
    text-align: center;
    margin-bottom: 15px;
    font-family: 'OmnesRegular';
}

.addOptBtns label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #242424;
    margin-top: 0px;
    display: block;
    margin-bottom: 10px;
    opacity: 0.5;
    text-align: center;
    font-family: 'OmnesRegular';
}
.addOptBtns button {
    border: 1px solid rgb(0, 0, 0, 0.1);
    border-radius: 30px;
    text-transform: capitalize;
    outline: none !important;
    padding: 14px 9px;
    width: 31%;
    min-height: 52px;
    margin-bottom: 12px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #4E4E4E;
    font-family: 'OmnesSemiBold';
}
.addOptBtnsInr {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.chldrnSelect {
    margin-top: 48px;
    display: block;
    text-align: center;
}
.chldrnSelect p {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 27px;
    text-align: center;
    color: #4E4E4E;
    margin-top: 36px;
    margin-bottom: 0px;
    font-family: 'OmnesSemiBold';
}

.restoreNumEmail input {
    width: 100%;
    border: 1px solid #e5e5e5;
    color: #242424;
    outline: none;
    font-stretch: normal;
    letter-spacing: normal;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    padding: 15px 17px;
    border-radius: 15px;
    font-family: 'OmnesMedium';
}
.restoreNumEmail .MuiInput-underline:after,
.restoreNumEmail .MuiInput-underline:before {
    border: none !important;
    border-bottom: none !important;
    outline: none !important;
}
.restoreNumEmail .MuiFormControl-root { 
    width: 100% !important;
    max-width: 100% !important;
    box-sizing: border-box;
}
.phoneOptCstm {
    margin-top: 20px;
}
.perks_allCstm {
    border-left: 2px solid #faf6f5;
    padding-left: 16px;
    padding-bottom: 5px;
    margin-bottom: 25px;
}
.perks_allCstm label {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 17px;
    color: #242424; 
    font-family: 'OmnesSemiBold';
}
.perks_allCstm h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    color: #719E7F;
    margin-bottom: 10px;
    font-family: 'OmnesMedium';
}
.perks_allInrCstm p { 
    color: #000000;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    opacity: 0.5;
    font-family: 'OmnesRegular';
}
.deal_frrebiesCstm p {
    margin-bottom: 0px;
}
.deal_frrebiesCstm {
    background: rgb(255, 255, 255, 0.4);
    padding: 16px 12px;
    border-radius: 20px;
}
.deal_frrebiesCstm p {
    font-family: 'OmnesRegular';
}
button.onlineModel {
    background: #F47C6A !important;
    margin-bottom: 8px;
    box-shadow: 0 3px 13px rgba(252,97,114,.65);
}
.dealMdlClose {
    top: 18px;
    right: 20px;
    bottom: 30px;
    position: absolute;
    cursor: pointer;
}
/* .redemptionHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    position: relative;
} */
.redemptionHead img {
    cursor: pointer;
}
.redemptionHeadLft img{
    position: absolute;
    left: 14px;
    top: 30px;
}
.redemptionHeadRght img{
    position: absolute;
    right: 16px;
    top: 30px;
}
.notifyCards {
    background: transparent;
    border-radius: 0px;
}
.notifyCards .notify {
    background: #FFFEFE;
    padding: 12px 12px;
    border-radius: 15px;
}
.call_content p strong {
    font-family: 'OmnesSemiBold';
    font-weight: 600;
    display: inline-block;
}
.moisback_sectwoCstm {
    width: 80%;
    margin: 0 auto;
}
.all_family_sectionCstm {
    margin-top: 0px;
    padding-top: 0px;
}
.all_family_rghtCstm label {
    font-style: normal;
    font-weight: 500;
    color: #242424;
    font-size: 18px;
    line-height: 20px;
    margin-top: 0;
    margin-bottom: 0;
    font-family: "OmnesMedium";
}
.all_family_rghtCstm p {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: .01em;
    opacity: .5;
    margin: 0;
    font-style: normal;
    font-weight: 500;
    color: #242424;
    font-family: "OmnesMedium";
}
.all_familyCstm {
    display: flex;
    padding: 0;
    margin-bottom: 0;
    position: relative;
    justify-content: center;
    align-items: center;
}
.all_family_rghtCstm, .all_family_tickCstm {
    padding-top: 0px;
}
.all_familyCstm {
    background: #fff;
    position: relative;
    margin-bottom: 12px;
    border: 1px solid #e5e5e5;
    padding: 9px 10px;
    border-radius: 15px;
}
.addFmlyBtn button { 
    background: #f47c6a !important;
    border-radius: 30px;
    display: inline-block;
    padding: 16px 17px;
    color: #fff!important;
    font-style: normal;
    width: 100%;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    font-family: "OmnesSemiBold";
    /* box-shadow: 0 4px 8px rgba(242,125,110,.6784313725490196); */
    box-shadow: 0 18px 20px -18px #FF5A6C;
    outline: none !important;
}
.addrelative_tabs ul.nav.nav-tabs li.nav-item:first-child a {
    color: #AE86E0 !important;
}
.addrelative_tabs ul.nav.nav-tabs li.nav-item:last-child a {
    color: #4E4E4E !important;
}
.nextnum_div text.MuiStepIcon-text {
    font-family: 'OmnesMedium' !important;
}
.storyMainWrap span.giveAwayTag {
    color: #c3c3c3;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    font-family: 'OmnesMedium';
}
.storyMainWrap img {
    height: 42px !important;
    width: 42px !important;
    margin-bottom: 0px !important;
}
.storiesData .dtl_descp_sub label {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #242424 !important;
    font-family: 'OmnesSemiBold';
}
.storiesData .dtl_descp_sub p {
    color: #7f7f7f !important;
    opacity: 1 !important;
    font-family: 'OmnesRegular';
}
.dtl_descp_sub ol li {
    font-family: 'OmnesRegular';
}
/* survey detail */
/* div.phone_verify_backlink.termScreenArow {
    left: 18px !important;
    top: 14px;
    position: absolute;
    cursor: pointer;
} */
.instantRedmBabyReg {
    background: #FFFFFF;
    box-shadow: 0px 1px 4px 0px #0000001A;
    padding: 20px 22px;
    border-radius: 20px;
}
.babyRegUprMain .editChildInfo_stper,
.babyRegUprMain .editChildInfo_stper2,
.babyRegUprMain .editChildInfo_stper3{
    margin-top: 15px;
    padding: 0px 22px 26px 22px;
}
.babyRegUprMain .contest_process1 {
    margin: 0px !important;
}
.social_connect_uprmain .instant_hend3 {
    margin-bottom: 12px;
}
.lend_hendupr .instant_upr3 {
    padding-bottom: 5px;
}
.survey_startCstm .instant_redem2 {
    padding: 10px 0px;
}
.progressSurvey {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    width: 100%;
    margin-bottom: 15px;
    position: relative;
}
.progressSurvey ul {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-bottom: 0px;
    width: 100%;
    padding-left: 10%;
}
.progressSurvey ul li {
    list-style-type: none;
}
.survey_dropdown select::-webkit-input-placeholder {
    color: rgb(36, 36, 36, 0.5);
}
.survey_dropdown select:-ms-input-placeholder {
    color: rgb(36, 36, 36, 0.5);
}
.survey_dropdown select::placeholder {
    color: rgb(36, 36, 36, 0.5);
}

.survey_dropdown select option:first-child {
    color: rgba(36, 36, 36, 0.5);
}
.entrDobUsrSurvy input {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #ffffff !important;
    color: #242424;
    outline: none;
    font-stretch: normal;
    letter-spacing: normal;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px !important;
    padding: 14.32px 17px !important;
    border-radius: 15px !important;
    font-family: 'OmnesMedium' !important;
}
.survey_formCstm input.MuiInput-input{
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #ffffff !important;
    color: #242424; 
    outline: none;
    font-stretch: normal;
    letter-spacing: normal;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    padding: 14.32px 17px !important;
    border-radius: 15px;
    font-family: 'OmnesMedium';
    max-height: 52px;
}
.survey_formCstm div {
    width: 100%;
}
.survey_startCstm button.MuiButtonBase-root {
    margin: 14px 0 22px;
}
.srvyMainUpr .instant_upr2 {
    background: transparent;
    border-radius: initial;
    margin-top: 25px;
}
.srvyMainUpr .instant_hend2 {
    background: transparent !important;
    box-shadow: initial !important;
    padding: 0px !important;
}
.survayInrForm {
    background: #FFFFFF;
    box-shadow: 0px 1px 4px 0px #0000001A;
    padding: 13px 20px;
    padding-bottom: 22px;
    border-radius: 20px;
}
.progressSurvey ul li img {
    width: 100%;
}
.surveyWinCard .instant_hend3_ne1 {
    min-height: 116px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.srvyMainUpr .instant_redem2 {
    background-color: rgb(255, 255, 255, 0.4);
    border-radius: 30px;
    padding: 16px 16px;
    margin-top: 20px;
    font-family: 'OmnesSemiBold';
    min-height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.srvyMainUpr p.error_message {
    opacity: initial;
    margin-bottom: 0px;
}
.surveyDetailUpr {
    background: #FFFFFF;
    box-shadow: 0px 1px 4px 0px #0000001A;
}
.surveyDetailUpr .social_connect_upr {
    background: rgb(242, 242, 242, 0.2);
}
.surveyDetailUpr .instant_hend3 h3 {
    color: #FDD500;
}
.surveyDetailUpr .fiveone_pts3 a img {
    text-align: center;
    width: 60px !important;
    height: 60px !important;
}
.surveyDetailUpr .social_activright label span:first-child {
    color: #ae86df !important;
}
.social_activright label span:last-child {
    color: #fdd501 !important;
}
.social_activityright a img {
    border-radius: 30px;
}
/*end survey detail */
.houseForm h5 {
    color: #4E4E4E;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 17px;
    margin-bottom: 14px;
    display: block;
    font-family: 'OmnesRegular';
}
.houseForm .MuiInput-underline:after,
.houseForm .MuiInput-underline:before { 
    border: none !important;
    border-bottom: none !important;
    outline: none !important;
}
.address_parent_section .address_child_section:last-child p {
    margin-bottom: 0px;
}
.subForm input, .rurlForm input {
    border-bottom: none !important;
}
.subForm .MuiInput-underline:after,
.subForm .MuiInput-underline:before,
.rurlForm .MuiInput-underline:after,
.rurlForm .MuiInput-underline:before {
    border: none !important;
    border-bottom: none !important;
    outline: none !important;
}
.subForm .MuiInputBase-adornedStart,
.rurlForm .MuiInputBase-adornedStart {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #ffffff !important;
    color: #242424;
    outline: none;
    font-stretch: normal;
    letter-spacing: normal;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    padding: 9px 17px; 
    border-radius: 15px;
    font-family: 'OmnesMedium';
    max-height: 52px;
}
.modlAiUpr button.close,
.modal-footer button {
    font-family: 'OmnesRegular';
}
.modlAiUpr .modal-body {
    font-family: 'OmnesRegular';
    padding: 12px 15px; 
}
.modlAiUpr h5.modal-title {
    font-family: 'OmnesMedium';
}
.progressSurvey div {
    position: absolute;
    left: 0px;
    top: 0px;
}
.notify_rght p, .notify_rght p label {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: #242424;
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: 'OmnesMedium';
}
.profileTp { 
    margin-bottom: 28px;
    text-align: center;
    margin-top: 20px;
}
.profileTp h1, .setingChngPass h1 { 
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    color: #4E4E4E;
    opacity: 0.5;
    font-family: 'OmnesMedium';
    text-align: center;
    margin-bottom: 22px;
    margin-top: 0px;
}
.profileTp h2, .setingChngPass h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 27px;
    color: #4E4E4E;
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: 'OmnesSemiBold';
    text-align: center;
}
.TermCon_Body .terms_content p br {
    display: none;
}
.lottieLoader svg {
    height: 140px !important;
    width: 140px !important;
}
.main_site_load .lottieLoader {
    margin-top: 0px;
}
.lottieLoadCntr {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}
.LottieLoaderTabMid {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40vh;
}
.program_entry_loader .lottieLoader svg {
    height: 120px !important;
    width: 120px !important;
}
.startRocket img {
    height: 56px;
    width: auto;
}
.noNotify {
    text-align: center;
    margin-top: 26px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #4E4E4E;
    font-family: 'OmnesMedium';
}
.editUsrInfoMask {
    position: relative;
}
.addMaskPhone {
    position: absolute;
    right: 12px;
    top: 38px;
    width: initial !important;
}
.addMaskPhone h3 {
    color: #AE86E0;
    text-align: left;
    font-size: 16px;
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: 'OmnesMedium';
    cursor: pointer;
}
.editUsrInfoMask input {
    padding-right: 65px;
}
.phone_verify_backlink.phone_verify_backlinkCstm img {
    margin: 0px;
}
.scrollToTopClass { 
    position: fixed; 
    bottom: 10px;
    right: 0px;
    left: 0px;
    z-index: 10;
    width: 327px;
    cursor: pointer;
    margin: 0px auto;
    text-align: center;
}
img.dataCalendarIcon {
    position: absolute;
    right: 10px;
    top: 14px;
    color: #242424;
}
.addChildDateIcon input {
    background-image: url('../../assets/images/CalendarIcon.svg') !important;
    background-size: 22px !important;
    background-repeat: no-repeat !important;
    cursor: pointer;
    background-position: calc(100% - 12px) center !important;
}
.TermCon_model .terms_content strong {
    font-family: 'OmnesSemiBold';
}
.TermCon_model .terms_content ol li, 
.TermCon_model .terms_content ul li {
    margin-bottom: 8px;
}
img.outerDotBg {
    position: absolute;
    right: 0px;
    bottom: -14px;
}
img.signUpPurpalBg { 
    position: absolute;
    left: 0px;
    top: 0px;
    height: 80px;
}
.addPurpalLineBg {
    background-image: url('../../assets/images/purpalLine2.svg');
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    background-position: right;
    padding-bottom: 22px;
}
.addPurpalLineBgInr { 
    background-image: url('../../assets/images/pulpleLineouter.png');
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    background-position: right;
    padding-bottom: 22px;
}
.outerDotSlider{ 
    position: absolute;
    right: 0px;
    bottom: -14px; 
}
.outerDotSliderLogin{
    position: absolute;
    left: 0px;
    bottom: -6px;
}
.outerDotSliderLogout{
    position: absolute;
    right: 0px;
    bottom: 2px;   
}
div#tab_content_start .extragain_tabs.fix_main li a {
    background: transparent;
}
.welcmStup, .addSliderBg {
    padding-bottom: 12px;
    min-height: 373px;
}
.cmnMainSpcAddon {
    margin-top: 15px;
}
.rmvChildButton button {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #242424;
    opacity: 0.5;
    text-transform: capitalize;
    text-align: center;
    outline: none !important;
    background: transparent !important;
    background-color: transparent !important;
}
.rmvChildButton {
    text-align: center;
    margin-top: 15px !important;
}
.addChildPrnt img {
    position: absolute;
    top: 6px;
    right: 6px;
    cursor: pointer;
}
.addChildPrnt {
    position: relative;
}
.addChildPrnt button { 
    text-align: left;
    width: 100%;
    outline: none;
    font-stretch: normal;
    letter-spacing: normal;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    color: rgb(36, 36, 36, 0.5);
    border-radius: 30px;
    padding: 15.5px 12px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    text-transform: capitalize;
    font-family: 'OmnesSemiBold';
    background: #ffffff !important;
    background-color: #ffffff !important;
}
.getStartedButton {
    transition: bottom 0.3s ease;
}
.fixedAtBottom {
    position: fixed;
    bottom: 144px;
    right: 0;
    left: 0;
    margin: 0 auto;
    width: 327px;
}
.progressFreebie ul {
    padding-left: 0px;
}
.progressFreebie {
    margin-top: 6px;
    margin-bottom: -4px;
}
.termScreenArow img {
    width: initial !important;
    background: transparent !important;
    padding: 0px !important;
    border-radius: 0px !important;
}
.termScreenHead h1 {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 0px !important;
}
.termScreenHead {
    margin-bottom: 16px !important;
}
.contestProcsFreeBei {
    margin-top: 16px;
}
.contestProcsFreeBei p {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
    opacity: 0.5;
    margin-bottom: 6px;
    font-family: 'OmnesRegular';
}
.progressFreebie {
    margin-top: 6px;
    margin-bottom: 5px;
}
.entrFreeBeiBg{
    background: #FFFFFF;
    box-shadow: 0px 1px 4px 0px #0000001A;
    padding: 20px 22px;
    border-radius: 20px;
}
.instantUprTerm {
    background: transparent;
    border: 0px;
}
.instantUprTerm .instant_redem2 {
    background: #FFFFFF;
    border-radius: 20px;
    padding: 20px 20px;
}
.instantUprTerm .editChildInfo_stper, 
.instantUprTerm .editChildInfo_stper2, 
.instantUprTerm .editChildInfo_stper3 {
    margin-top: 15px;
    padding: 0px 22px 26px 22px;
}
.prgrmFreebieDetail {
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    height: 140px;
    width: 140px;
    margin: 0 auto;
}
.prgrmFreebieDetail svg {
    height: 140px !important;
    width: 140px !important;
}
.usrLoginSetBg, 
.common_mainInerSpc,
.signupCirclBg,
.usrPhoneSetBg,
.usPassSetBg, .usrGenderSetBg,
.usrBuildSetBg {
    background-image: url("../images/circleBg2.png");
    background-size: initial;
    background-position: left bottom;
    background-repeat: no-repeat;
}
.common_mainInerSpcBg {
    background-repeat: no-repeat;
    background-image: url("../images/HeartCstm.gif");
    background-size: contain;
    background-position: center bottom;
}
.freeBeiSetUpr, .addGiveAwayCards {
    scroll-behavior: auto;
}


.houseFormWrap input {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #ffffff !important;
    color: #242424;
    outline: none;
    font-stretch: normal;
    letter-spacing: normal;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    padding: 15px 17px;
    border-radius: 15px;
    margin-bottom: 12px;
    font-family: 'OmnesMedium';
    z-index: 1;
}
.houseFormWrap .MuiInput-underline:after, 
.houseFormWrap .MuiInput-underline:before {
    border: none !important;
    border-bottom: none !important;
    outline: none !important;
}
.houseFormWrap div {
    width: 100%;
}
.addressParentUpr {
    min-height: 25px !important;
    max-height: 250px !important;
    background: #f4f0fb;
    padding: 15px 15px;
    padding-top: 20px;
    margin-top: -22px;
    margin-bottom: 12px;
    border-radius: 0px 0px 20px 20px;
}
.addressParentUpr {
    height: 320px;
    overflow-y: auto;
    overflow-x: hidden;
}
/* .houseFormWrap div {
    width: 100%;
} */
.addressChildUpr {
    font-size: 14px !important;
}
.addressChildUpr {
    position: relative;
    font-size: 12px;
}
.addressChildUpr p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #242424;
    opacity: 0.5;
    margin-top: 0px;
    font-family: 'OmnesRegular';
}
.enterManullyBtn {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}
.enterManullyBtn button {
    width: 50% !important;
    margin-top: 0px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    align-items: center !important;
    text-align: center !important;
    padding: 12px 0px !important;
    border-radius: 30px !important;
    font-family: 'OmnesSemiBold' !important;
    outline: none !important;
    color: #4E4E4E !important;
    min-height: 52px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #ffffff !important;
}
.selCntryAddon {
    margin-bottom: 15px;
}

.subFormAddon .MuiInputBase-adornedStart, 
.rurlFormAddon .MuiInputBase-adornedStart {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #ffffff !important;
    color: #242424;
    outline: none;
    font-stretch: normal;
    letter-spacing: normal;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    padding: 7px 9px;
    border-radius: 15px;
    font-family: 'OmnesMedium';
    max-height: 53px;
    min-height: 53px;
}

.subFormAddon .MuiInputAdornment-positionStart, 
.rurlFormAddon .MuiInputAdornment-positionStart {
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    align-items: center;
    color: #242424 !important;
    opacity: 0.4;
    margin-bottom: 0px;
    font-family: 'OmnesRegular';
}
.subFormAddon .MuiInputAdornment-positionStart, 
.rurlFormAddon .MuiInputAdornment-positionStart {
    width: initial !important;
}
.subFormAddon .MuiInputAdornment-positionStart p, 
.rurlFormAddon .MuiInputAdornment-positionStart p {
    font-stretch: normal;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    align-items: center;
    color: #242424 !important;
    margin-bottom: 0px;
    font-family: 'OmnesMedium';
}
.rurlFormAddon input {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #ffffff !important;
    color: #242424;
    outline: none;
    font-stretch: normal;
    letter-spacing: normal;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    padding: 15px 17px;
    border-radius: 15px;
    margin-bottom: 12px;
    font-family: 'OmnesMedium';
    z-index: 1;
}
.strAdress label {
    margin-bottom: 0px;
    display: inline-block;
    color: #909090;
    font-size: 16px;
}
div.strAdressLft {
    width: 30%;
}
div.strAdressRght {
    width: 70%;
    position: relative;
}
.strAdress {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
}
div.strAdressMid {
    margin-top: 10px;
    width: 12px;
    height: 0.1px;
    border: 1px solid #000;
}
.additionalFields .inp-cbx:checked + .cbx span:first-child {
    border-color: #fbdd3f;
    background: #fbdd3f;
    animation: check 0.6s ease;
}
.additionalFields label {
    margin-bottom: 8px;
    display: inline-block;
    color: #909090;
    font-size: 16px;
}
.additionalFields .cbx span:first-child {
    border: 1px solid #fbdd3f;
}
.additionalFields .cbx span:first-child svg {
    stroke: #ffffff;
}
.additionalFields {
    display: flex;
    align-items: center;
    margin-top: 4px;
}
.additionalFields .cbx span:last-child {
    margin-left: 0px;
}
.additionalFields .notifyCheck {
    width: auto;
    margin-right: 7px;
}
.addinalInfo {
    display: flex;
    gap: 12px;
}
.houseFormWrap .poBoxOptional {
    margin-bottom: 0px;
}
.houseFormWrap .rurlFormAddon {
    margin-bottom: 12px;
}
.rurlFrmAddUpr {
    display: flex;
    gap: 12px;
}
.houseFormWrapBtn button {
    color: #fff;
    outline: none!important;
    padding: 12px 34px;
    background: #F47C6A !important;
    border-radius: 30px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
    margin: 0px;
    box-shadow: 0 18px 20px -18px #FF5A6C;
    min-height: 52px;
    font-family: 'OmnesSemiBold';
}
.houseFormWrap .houseFormWrapBtn button {
    color: #fff;
    outline: none!important;
    padding: 12px 34px;
    background: #F47C6A !important;
    border-radius: 30px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
    margin: 0px;
    box-shadow: 0 18px 20px -18px #FF5A6C;
    min-height: 52px;
    font-family: 'OmnesSemiBold';
}
.mailAddresGrpEdt button {
    color: #4e4e4e;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    align-items: center;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    font-family: 'OmnesSemiBold';
}
.mailAddresGrpCntnu button {
    padding: 12px 34px;
    background: #F47C6A !important;
    border-radius: 30px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
    margin: 40px 0px 0px 0px;
    box-shadow: 0 18px 20px -18px #FF5A6C;
    min-height: 52px;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    border: none !important;
    color: #fff !important;
    cursor: pointer;
}
.mailAddresGrpEdt {
    text-align: center;
    margin-top: 16px;
}
.mailAdrsList {
    background-color: #f9f9f9;
    padding: 10px 18px;
    text-align: center;
    margin-bottom: 15px;
    border: 2px solid #e1e1e1;
    border-radius: 18px;
}
.mailAdrsList input {
    padding: 2px 2px;
    font-family: 'OmnesMedium';
}
.mailAdrsList span {
    padding: 0px 2px;
    font-family: 'OmnesMedium';
}
.mailAdrsList label {
    margin-bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.mailAdrsList input[type="radio"] {
    opacity: 0;
    position: absolute;
    left: 0px;
}
.mailAdrsList.checked {
    background-color: #e7dbf6;
    border: 2px solid #dcd3e8;
}
.strAdressRght ul {
    min-height: 25px !important;
    max-height: 134px !important;
    background: #f4f0fb;
    padding: 15px 15px;
    padding-top: 20px;
    margin-top: -22px;
    margin-bottom: 12px;
    border-radius: 0px 0px 20px 20px;
    height: 134px;
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    z-index: 111;
    left: 0px;
    right: 0px;
}
.StreetAddOn input {
    z-index: 999999;
}
.strAdressRght ul li {
    list-style-type: none;
    cursor: pointer;
}
.selctCrctAddress label {
    color: #4e4e4e;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    display: block;
    text-align: center;
    margin-top: 22px;
    margin-bottom: 18px;
    font-family: 'OmnesMedium';
}
.mailAddresGrpEdt button:disabled {
    opacity: 0.5;
    cursor: initial;
}
.addinalInfo input, .rurlFormAddon input {
    color: #9c9c9c;
}
.adresCorectQues label {
    color: #ae86e0;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    display: block;
    text-align: center;
    margin-top: 36px;
    margin-bottom: 20px;
    font-family: 'OmnesMedium';
}
.adrsDetailSet p{
    padding: 10px 18px;
    text-align: center;
    margin-bottom: 15px;
    border-radius: 18px;
    background-color: #e7dbf6;
    border: 2px solid #dcd3e8;
    font-family: 'OmnesRegular';
    margin-bottom: 0px;
}
.adrsDetailSet, .mailAdrsList {
    width: 90% !important;
    margin: 0 auto;
}
.mailAdrsList {
    margin-bottom: 18px;
}
.perkCardInfo .common_mainAddOn, 
.sessionEntrContainer .common_mainAddOn {
    background: transparent !important;
}

.editagain_tabs .extragainTabsCustm a.nav-link {
    width: 100% !important;
}

div#tab_content_start div#navbar {
    /* z-index: 9999999; */
    z-index: 1050 !important;

}

.custom_prizemodel .modal-content {
    border-radius: 20px !important;
}

.custom_editchildBody.modal-body .editchild_content button:last-child {
    background: transparent !important;
    color: #4e4e4e !important;
}

.babyRegUprMain .phone_verify_backlink.termScreenArow {
    top: 16px !important;
    position: absolute;
    left: 20px !important;
    cursor: pointer;
}
.babyRegUprMain .contest_up h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
}
/* .fixFotrBtn, .fixFotrBtn a, .signup_opt button,
.login_opt button, .story_share, .deal_redem button, 
.addchild_submit button, .profileApply button, .contest_opt button,
.gainextra_submit button, .logup_submit button, .signup_submit button,
.call_link button, .houseFormBtn button, .phone_opt button,
.prent_submit button, .addbuild_submit button, .name_later button,
.freebies_redem button, .findMoreBtn button, .freeBeiEligibBtn button,
.progress_test, .settingClsAc a, .add_familySocial3 button, .familyshare_submitNw button,
.hometwo_redem_session button, .hometwo_redem button, .kidcode_submit button,
.your_odds button, .ntcUpdate button, .editChildInfo_stper button,
.editChildInfo_stper2 button, .editChildInfo_stper3 button {
    width: 280px !important;
    max-width: initial;
    min-width: initial;
}
.addFmlyBtn button, .addRelativeCstm .familyadd_upr button{
    margin: 0 auto;
    width: 280px !important;
    max-width: initial;
    min-width: initial;
    display: block;
}
.editChildInfo_stper button, .editChildInfo_stper2 button,
.editChildInfo_stper3 button {
    margin: 6px auto 0px !important;
}
.your_odds button {
    margin: 0 auto;
    display: block;
}
.revwPending {
    padding: 52px 22px 35px;
}
.contest_process1Cstm {
    z-index: 1;
}
.wantFreeInstntInr {
    padding: 28px 21px 32px;
}
.extra_fremain {
    padding: 25px 22px;
}
.add_familySocial3, .instant_redem2, .houseFormBtn {
    text-align: center;
}
.signupConectIner .loginFB button {
    margin: 0 auto;
    width: 280px !important;
    max-width: initial;
    min-width: initial;
    display: block;
}
.instant_redem2 button {
    margin: 10px auto !important;
    width: 280px !important;
    max-width: initial;
    min-width: initial;
    display: block;
}
.prent_submit {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
button.explore_div {
    width: 232px !important;
}
.progress_test {
    width: 280px;
    max-width: initial;
    min-width: initial;
    margin: 0 auto;
    margin-top: 22px;
    display: block;
}
.progress_test.done {
    width: 280px;
    max-width: initial;
    min-width: initial;
    margin: 22px auto;
    display: block;
} */

@media screen and (max-width: 767px) {
    .nextslides {
      bottom: 8%;
      right: 2%;
    }
    .signupConectCircle {
      padding-bottom: 8vh;
      outline: none;
    }
    /* html, body {
      background-color: transparent !important;
      background: transparent !important;
    } */
    /* .program-page-container, .program-page {
        max-width: 100% !important;
        width: 100% !important;
    } */
    .gain_extra h1 {
        width: 70%;
    }
    /* .container {
        position: relative;
        width: 100% !important;
        height: auto !important;
        opacity: 1 !important;
    } */
}
@keyframes check {
    50% {
        transform: scale(1.2); 
    }
}
/* @media (min-width: 600px) and (max-width: 767px) {
    .addSliderBg {
        min-height: 670px;
    }
} */
@media screen and (max-width: 599px) {
    .gain_extra h1 {
        width: 80%;
    }
    /* .welcmStup, .addSliderBg {
        height: 542px;
        min-height: initial;
    } */
    .addSliderBg {
        height: 542px;
        min-height: initial;
    }
}
@media screen and (max-width: 568px){
    .nextslides {
        bottom: 2%;
        right: -3%;
    }
}
@media screen and (max-width: 560px) {
    /* .welcmStup, .addSliderBg {
        height: 516px;
        min-height: initial;
    } */
    .addSliderBg {
        height: 516px;
        min-height: initial;
    }
}
@media screen and (max-width: 460px) {
    /* .welcmStup, .addSliderBg {
       min-height: 424px;
       height: initial;
    } */
    .addSliderBg {
        min-height: 424px;
        height: initial;
     }
    .gain_extra {
        margin-top: 20%;
    }
    .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
        bottom: 0px !important;
    }
} 

.ntvcontent_app{
    background: #ffffff;
    border-radius: 20px;
    padding: 52px 32px 35px;
    position: relative;
}.ntcBody_phoneTransferRequest{
    margin-top: 50%;
} 
.text_underline_blue{
    text-decoration: underline;
    color: #0a0ac9;
}