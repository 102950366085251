/*
#some-program.js .preloaded-content{
  display: none;
  padding: 1.5em;
}

#some-program.js .preloaded-content p{
  line-height: 1.4em;
}

#some-program .program-card.active .preloaded-content{
  display: block;
}

.program-card{
  position: relative;
  height: 300px;
  overflow: hidden;
  border-radius: 5px;
  margin: 24px auto;
  cursor: pointer;
  background: #f9e9e6;
  width: 100%;
}

.program-card.active{
  position: absolute;
  z-index: 100;
  padding: 0;
  margin: 0;
  cursor: default;
}


.program-card-background{
  width: 100%;
  height: 50%;
  background-position: 50% 50%;
  background-color: #333;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
}

.program-card h2,
.program-card-background h2{
  color: white;
  margin: 0;
  padding: .25em;
  position: absolute;
  bottom: 0;
  text-shadow: -2px 2px 3px black;
}

.program-card .close{
  position: absolute;
  top: 1em;
  right: 1em;
  width: 32px;
  height: 32px;
  background: transparent url(https://media.wponlinesupport.com/wp-content/plugins/wpos-support-plus-responsive-ticket-system/asset/images/close_btn.png) no-repeat 50% 50%;
  background-size: contain;
    z-index: 100;
  cursor: pointer;
  opacity: .8;
  overflow: hidden;
  white-space: nowrap;
  text-indent: 110%;
}

.program-card .close{
  display: none;
}
.program-card.active .close{
    display: block;
  }

.program-card .close:hover{
  opacity: 1;
}

.program-card.inactive {
    display: none;
}

*/
/*
.program_card{
    position: relative;
      cursor: pointer;
  }
  
  .program_card.active {
    cursor: default;
    position: absolute;
    cursor: pointer;
    background: #fff;
    z-index: 99999;
    left: 0;  
}

.program_card.active .preloaded-content{
    display: block; 

}

.program_card.inactive {
    display: none;
}
  
  #btn-box{
    width: 150px;
    height: 45px;
  }
  
  .close-btn{
    fill: #231f20;
    cursor: pointer;
  }
  .close{
    margin-bottom: 20px;
  }
  .preloaded-content {
       display: none;
       position: absolute;
       cursor: pointer;
       background: #fff;
       z-index: 99999;
       left: 0;
       opacity: 0;
     }
  
     .display_none{
      display: none;   
     }

     .fixed-header{
        z-index: 1;
        position: sticky;
        top: 0;
        background-color: #fefbfa;
        padding-top: 36px;
        padding-bottom: 40px;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
     } */


.absolute { position: absolute; }
.fixed { position: absolute; }
.parent { 
  width: 100%;
  height: 100%;
  background:red;
}
.child {
    width: 100%;
    height: 50%;
    background: blue;
    top: 0;
    left: 0;
}
.preloaded-content {
  opacity: 0;
  display: none;   
}
  
.display_none{
  display: none;   
}

.fixed-header{
  z-index: 1;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  /* background-color: #fcf5f1; */
  background-color: #ffff;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: -12px;
  margin-right: -12px;
  padding-top: 18px !important;
  padding-bottom: 20px !important;
}